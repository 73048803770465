// ==============================================
// MIXINS - Custom scrollbar
// ==============================================

@mixin custom-scrollbar($width: 4px, $track-color: #e5e5e5, $thumb-color: $c-pink) {

    &::-webkit-scrollbar {
        width: $width;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 0;
        box-shadow: inset 0 0 6px $track-color;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $thumb-color;
        border-radius: 0;
        box-shadow: none;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
        background: $thumb-color;
    }
}
