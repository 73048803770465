// ==============================================
// BUTTONS - Native
// ==============================================

// Configuration
$button-icons-color:              lighten($c-black, 30%);

//
//  Magento filled buttons
//  _____________________________________________

.action {
    &.action-apply,
    &.action.apply,
    &.action-auth-toggle,
    &.action-login,
    &.action-menu-item,
    &.action-show-popup,
    &.continue,
    &.checkout,
    &.clear,
    &.compare,
    &.create,
    &.customize,
    &.login,
    &.save,
    &.select,
    &.share,
    &.submit,
    &.subscribe,
    &.tocart,
    &.update,
    &.viewcart,
    &.action-register {
        @extend .btn;
    }
}

//
//  Magento links buttons
//  _____________________________________________

.action {
    &.add,
    &.action-remind {
        @extend .link-1;
    }
}

//
//  Magento icon buttons
//  _____________________________________________

.action {
    &.action-edit,
    &.delete,
    &.action-delete,
    &.mailto,
    &.next,
    &.previous,
    &.sorter-action,
    &.tocompare,
    &.towishlist {
        color: $button-icons-color;
        text-decoration: none;

        &::before {
            content: "";
            @extend %icomoon;
            margin-right: 1rem;
        }
    }
}

// Edit
.action.action-edit {
    &::before {
        content: "\e601";
    }
}

// Delete
.action.delete,
.action.action-delete {
    &::before {
        content: "\e916";
    }
}

// Wishlist
.action.towishlist::before {
    content: "\e941";
}

// Compare
.action.tocompare::before {
    content: "\e61e";
}

// Sorter
.action.sorter-action::before {
    content: "↓";
}

// Next
.action.previous,
.action.next {
    &::before {
        content: "\e902";
    }
}

// Previous
.action.previous {
    transform: scaleX(-1);
}

// Mail
.action.mailto::before {
    content: "\e61d";
}

// Full width button on mobile
@include breakpoint(only-md) {
    .actions-toolbar button.action,
    .actions-toolbar .primary a.action,
    form button.action {
        display: block;
        width: 100%;
    }
}
