// ==============================================
// CS BLOCK : Slideshow
// ==============================================

//
// JS Perf
//  _____________________________________________

.csb-slideshow__item + .csb-slideshow__item {

    & {
        display: none;
    }

    .slick-track & {
        display: block;
    }
}

//
// Styles
//  _____________________________________________

.csb-slideshow {
    min-height: 26rem;

    @include breakpoint(lg) {
        min-height: 36rem;
    }

    @include breakpoint(xl) {
        min-height: 55rem;
    }

    .container {
        padding: 0;
    }

    @include only_ie {

        .slick-list {
            max-height: 57rem;
        }
    }

    // link
    .csb-slideshow__link {
        display: block;
        background-color: $c-grey-light;
    }

    // Arrows
    .slick-arrow {
        opacity: 0;
        transition: opacity .3s;
    }

    &:hover {

        .slick-arrow {
            opacity: 1;
        }
    }
}
