.mt-m-ProductsTabs {
    margin: {
        top: 50px;
        bottom: 60px;
    };

    &-nav {
        display: flex;
        align-items: baseline;
        justify-content: center;
        margin-bottom: 30px;
        &-item {
            @include fs(20px, 24px);
            font-family: $f-museo-700;
            opacity: 0.3;
            margin: 0 23px;

            &[data-selected] {
                @include fs(35px, 42px);
                opacity: 1;

                a {
                    color: $c-grey-dark;
                }
            }
        }
    }

    &-content {
        &-item {
            &-list {
                .products-grid {
                    margin: 0;

                    .product-items.list {
                        display: flex;
                        flex-wrap: wrap;

                        margin: {
                            left: 0;
                            right: 0;
                        };

                        li.product-item {
                            width: calc(#{100%/5} - #{(30px*4)/5});
                            padding: 0;

                            & + .product-item {
                                margin-left: 30px;
                            }
                        }
                    }
                }
            }

            &-action {
                margin-top: 20px;
                text-align: center;

                &-btn {
                    display: inline-block;

                }
            }
        }
    }

    @include breakpoint(only-xl) {
        &-content {
            &-item {
                &-list {
                    .products-grid .product-items.list {
                        margin: {
                            left: 0;
                            right: 0;
                        };

                        li.product-item {
                            width: calc(#{100%/4} - #{(20px*3)/4});
                            padding: 0;
                            margin-bottom: 20px;

                            & + .product-item {
                                margin-left: 20px;
                            }

                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }
                &-action {
                    margin-top: 15px;
                }
            }
        }
    }


    @include breakpoint(only-md) {
        margin: {
            top: 40px;
            bottom: 40px;
        };
        &-nav {
            justify-content: flex-start;
            margin-bottom: 20px;
            &-item {
                @include fs(16px, 20px);
                margin: 0 10px;
                &[data-selected] {
                    @include fs(25px, 31px);
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
        &-content {
            &-item {
                &-list {
                    .products-grid .product-items.list {
                        width: 100%;
                        li.product-item {
                            width: calc(#{100%/2} - #{20px/2});
                            max-width: initial;

                            & + .product-item {
                                margin-left: 20px;
                            }
                            &:nth-child(2n+1),
                            &:nth-child(3n+1) {
                                margin-left: 0;
                            }

                            &:nth-last-child(2) {
                                margin-left: 20px;
                            }

                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }

                &-action {
                    margin-top: 10px;
                    &-btn {
                        &.mt-c-Btn {
                            @include fs(15px, 22px);
                            padding: 15px 20px;
                        }
                    }
                }
            }
        }
    }
}
