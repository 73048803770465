// ==============================================
//  Lists
// ==============================================

.list-1 {

    li {
        position: relative;
        line-height: 1.5;
        padding-left: 10px;

        a {
            color: $c-base;
            text-decoration: underline;
        }

        & + li {
            margin-top: 10px;
        }

        // Bullet
        &:before {
            content: "";
            position: absolute;
            top: 8px;
            left: 0;
            @include circle(5px);
            background-color: $c-base;
        }
    }
}
