//
//  Product description
//  _____________________________________________

.product-info-main {

    @include breakpoint(only-md) {
        position: relative;
        margin-top: 18px;
    }

    &::after {
        content:'';
        display: table;
        clear: both;
    }

    .product-reviews-summary,
    .product-size,
    .product-colors,
    .product-info-price,
    .product-add-form,
    .product-social-links {
        margin-bottom: 2rem;
    }

    // Seller name
    .seller-name {
        display: inline-block;
        margin-bottom: 10px;
        font-size: 1.4rem;
        white-space: nowrap;

        /* Icon */
        span[class^="icon"] {
            font-size: 2rem;
            vertical-align: sub;
        }

        .label,
        .offer-shop-name {
            color: $c-red;
        }
    }

    // Reviews
    .product-reviews-summary {
        margin-bottom: 2.4rem;

        @include breakpoint(lg) {
            margin-bottom: 3rem;
        }

        .action {
            font-size: 1.2rem;

            &.view {
                display: flex;
            }

            &.add {
                text-decoration: none;

                &:hover span {
                    text-decoration: none;
                }

                span {
                    text-decoration: underline;
                }
            }
        }

        // Stars
        .rating-summary {
            @extend %rating-summary;

        }
    }

    // Top infos
    .product-top-infos {
        margin-bottom: 2.4rem;
        padding-bottom: 2.4rem;
        border-bottom: 1px solid $c-new-grey-5;

        // List
        .infos-list {
            margin-bottom: 2rem;

            @include breakpoint(only-md) {
                display: none;
            }

            @include breakpoint(lg) {
                margin-bottom: 2.2rem;
            }
        }

        // More
        .infos-more {
            a {
                color: $c-grey-light2;
            }
        }
    }

    // Options - colors & sizes
    .product-size,
    .product-option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .product-size-selected {
            font-size: 1.8rem;
            font-weight: bold;
        }

        label {
            width: 100%;
        }

        select {
            flex: 1 1 0;
        }
    }

    .product-option {
        select {
            margin-left: 1rem;
        }
    }

    // Form add to
    > .product-add-form {
        position: relative;
        margin-bottom: 2.4rem;

        // Reset mobile for wish list cta absolute position
        @include breakpoint(only-md) {
            position: static;
        }

        // Price
        .price-box {
            @extend %price-box;
            font-family: $f-avenir-medium;
            font-weight: 500;
            margin-bottom: 2.4rem;
            @include breakpoint(md) {
                margin-bottom: 2.6rem;
            }

            .before-configurable-price {
                margin-right: 1rem;
            }

            .offer-old-price {
                font-size: 1.6rem;
                margin-right: 0.5rem;
            }
        }

        // Add to cart
        .box-tocart {
            // Qty & price wrapper
            .field-qty-price {
                @include breakpoint(md) {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    align-items: flex-end;
                    gap: 4.8rem;

                    &.field.qty {
                        flex-grow: 1;
                    }
                }
            }

            // Field
            .field {
                .label {
                    font-size: 1.4rem;
                    color: #000;
                    text-transform: uppercase;
                    margin-bottom: 0;

                    @include breakpoint(only-md) {
                        display: none;
                    }
                }

                &.qty {
                    margin-bottom: 2.6rem;

                    @include breakpoint(only-md) {
                        display: flex;
                        align-items: center;
                        flex-direction: row-reverse;
                        justify-content: flex-end;

                        .input-text {
                            max-width: 6rem;
                        }
                    }

                    .field-head {
                        @include breakpoint(md) {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 10px;
                            flex-wrap: wrap;
                            margin-bottom: 1rem;
                        }
                    }

                    .control {
                        flex-grow: 1;
                    }

                    input.qty {
                        flex: 1;
                        max-width: none;
                    }
                }
            }

            // Size guide
            a.product-size-guide {
                display: block;
                color: $c-grey-light2;
                text-decoration: underline;
                cursor: pointer;
                white-space: nowrap;

                @include breakpoint(only-md) {
                    display: inline-block;
                    font-size: 1.2rem;
                    margin-right: 3rem;
                    margin-left: 2.2rem;
                }
            }

            // Actions
            .actions {
                display: flex;
                flex-wrap: wrap;
                gap: 1.2rem;

                .action {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    white-space: nowrap;
                    @extend .btn;

                    @include breakpoint(xs) {
                        flex: 1;
                        width: auto;
                    }

                    &::before {
                        margin-right: 1rem;
                        @extend %icomoon;
                    }
                }

                .tocart {
                    &::before {
                        font-size: 2rem;
                        content: "\e94c";
                    }
                }
            }

            .price-after {
                display: inline-block;
                margin-left: 8px;
                font-weight: normal;
            }
        }

        // Add to wishlist
        // Set again btn style cause element can be exclude of box-tocart actions element
        .towishlist {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            white-space: nowrap;
            @extend .btn;
            @extend .btn--grey-light;

            &::before {
                font-size: 1.8rem;
            }
        }
    }

    // Product storelocator & shipping method
    .product-shipping-method {
        border: 1px solid $c-new-grey-5;
        border-radius: 4px;
        background-color: $c-grey-4;
        text-align: left;
        font-family: $f-avenir-medium;

        @include breakpoint(lg) {
            display: flex;
        }

        .available strong {
            color: $c-available-2;
        }
        .unavailable strong {
            color: $c-unavailable;
        }
        strong {
            font-weight: normal;
        }
    }

    .stock-info {
        flex: 1;
        padding: 16px;

        @include breakpoint(lg) {
            padding: 16px 24px;
        }

        & + .stock-info {
            border-top: 1px solid $c-new-grey-5;

            @include breakpoint(lg) {
                border-left: 1px solid $c-new-grey-5;
                border-top: none;
            }
        }

        &.delivery {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 10px;

            .stock-info-head {
                margin-bottom: 0;
            }

            .stock-info-head-title {
                display: flex;
                align-items: center;
            }

            [data-trigger=table-metrage] {
                cursor: pointer;
                font-size: 1rem;
                white-space: nowrap;
                margin-left: 5px;

                &::before {
                    content:"(";
                }
                &::after {
                    content: ")";
                }

                span {
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .stock-info-head {
            display: flex;
            gap: 4px;
            margin-bottom: 10px;
            font-family: $f-avenir;
            font-weight: bold;

            [class^=icon] {
                font-weight: bold;
                font-size: 1.5rem;
                margin-right: 4px;
            }

            .shipped-by {
                font-size: 1.2rem;
                font-weight: normal;
            }
        }

        .stock-info-content {
            line-height: 1.45;
            font-size: 1.4rem;

            [class^=icon] {
                display: inline-block;
                vertical-align: middle;
            }
        }

        .available {
            color: $c-available-2;
        }

        .limited-stock {
            color: $c-available-2;
        }

        .unavailable {
            color: $c-unavailable;
        }

        .partner-unavailable {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 0 5px;
        }

        &.storelocator a > span{
            text-decoration: underline;

            &:hover,
            &.available,
            &.unavailable {
                text-decoration: none;
            }

            &.available,
            &.limited-stock {
                color: $c-available-2;
            }
            &.unavailable {
                color: $c-unavailable;
            }
        }
    }

    .shipping-reassurance {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: fit-content;
        margin: 0 auto;
        justify-content: space-around;
        padding: 25px 0;
        gap: 20px;

        @include breakpoint(md) {
            align-items: center;
            flex-direction: row;
            width: auto;
        }

        li,
        a {
            display: flex;
            align-items: center;
            gap: 6px;
            font-weight: bold;

            span::before {
                font-weight: bold;
                font-size: 2rem;
            }
        }
    }

    .product-offers-choicebox {
        padding-top: 25px;
        border-top: 1px solid $c-new-grey-5;

        .trigger-choice-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 18px 16px;
            border: 1px solid $c-new-grey-5;
            line-height: 1.4;

            &:hover {
                cursor: pointer;

                > .icon-arrow-right {
                    color: $c-red;
                    transform: translateX(6px) scaleY(-1);

                    &::before {
                        transform: scale(1.4);
                    }
                }
            }

            > .icon-arrow-right {
                transform: translateX(0) scaleY(-1);
                transition: all .3s ease-in-out;
                font-size: 1rem;

                &::before {
                    display: block;
                    transform: scale(1);
                    transition: transform .3s ease-in-out;
                }
            }

            h3,
            .price {
                font-weight: bold;
            }

            h3 {
                margin-bottom: 1rem;
            }
        }

        h3 [class^=icon] {
            font-weight: bold;
            font-size: 1.5rem;
        }

        .link {
            color: $c-red;
            text-decoration: underline;
        }

        // Hide element before modal initialization
        .table-wrapper.product-offers {
            display: none;
        }
    }
}

@import "product-sizes";
@import "product-colors";

