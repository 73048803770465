// ==============================================
// CUSTOMER ACCOUNT EDIT
// ==============================================

body.customer-account-edit {

    // Form
    .form.form-edit-account {

        input[type="checkbox"] {
            @extend .checkbox--small;

            + label {
                margin-right: 3rem;
            }
        }

        // Label
        .fieldset.password label,
        .field.fullname > label,
        .field-name-prefix > label,
        .field-name-lastname > label,
        .field-name-firstname > label {
            @include visually-hidden;
        }

        // Input
        input[type="text"],
        input[type="password"],
        input[type="email"] {
            @extend .input--placeholder;
        }

        // Legend
        div.legend {
            @extend .title-1;
            padding-bottom: 1.8rem;
            margin-bottom: 2rem;
            text-transform: inherit;
            background-image: url('../images/background/sep-h.png');
            background-position: bottom left;
            background-repeat: repeat-x;
            color: $c-base;

            @include breakpoint(lg) {
                padding-bottom: 2rem;
                margin-bottom: 3rem;
            }

            @include breakpoint(xl) {
                font-size: 3.5rem;
            }
        }

        // Fieldset
        .fieldset {

            // Infos
            &.info {

                .field.choice {

                    @include breakpoint(md) {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
            }

            // Newsletter
            &.newsletter-subscription {
                margin-top: 3rem;

                .description {
                    margin-bottom: 1.5rem;

                    strong {
                        font-family: $f-avenir-black;
                    }
                }
            }
        }

        // Field
        .field {

            &.fullname {

                @include breakpoint(md) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }

                > .field-name-prefix {

                    @include breakpoint(md) {
                        width: 100%;
                    }

                    > .control {

                        input[type="radio"] + label {
                            margin-right: 1rem;

                            @include breakpoint(md) {
                                margin-right: 2rem;
                            }
                        }
                    }
                }

                > .field-name-lastname,
                > .field-name-firstname {
                    margin-top: 1.5rem;

                    @include breakpoint(md) {
                        width: 48%;
                    }

                    @include breakpoint(lg) {
                        width: 49%;
                    }
                }
            }

            &.password-info {
                display: none;
            }
        }

        // New password
        .field-password-new {

            @include breakpoint(md) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            > .field {

                @include breakpoint(md) {
                    width: 48%;
                }

                @include breakpoint(lg) {
                    width: 49%;
                }
            }
        }

        // Actions toolbar
        .actions-toolbar {
            display: flex;
            align-items: center;

            .primary {
                margin-left: auto;
            }
        }
    }
}
