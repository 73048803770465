// ==============================================
// FORMS - Radios
// ==============================================

//
//  Configuration
//  _____________________________________________

$radio-width:             16px;
$radio-height:            $radio-width;
$radio-border-weight:           1px;
$radio-color:                   #999;

.am-ranges {

    input[type="radio"] {
        @extend .visually-hidden;

        & + a {
            position: relative;
            align-items: center;
            padding-left: calc(#{$radio-width} + 7px);
            min-height: $radio-width;
            font-size: 1.2rem;
            line-height: $radio-width;
            cursor: pointer;

            @include breakpoint(md) {
                padding-left: calc(#{$radio-width} + 15px);
            }

            &::before,
            &::after {
                position: absolute;
                content: "";
                cursor: pointer;
                border-radius: 100%;
                transition: all .2s;
            }

            // Fake radio
            &::before {
                left: 0;
                width: $radio-width;
                height: $radio-height;
                border: $radio-border-weight solid $radio-color;
                background-color: $c-white;
            }

            // Round
            &::after {
                width: calc(#{$radio-width} - 6px);
                height: calc(#{$radio-height} - 6px);
                left: calc((#{$radio-width} + #{$radio-border-weight} * 2) / 2);
                background-color: $radio-color;
                opacity: 0;
                transform: translateX(-50%);
            }
        }

        &:checked {
            & + a {

                // Round
                &::after {
                    background-color: $c-red;
                    opacity: 1;
                    transition: opacity .2s;
                }
            }
        }
    }
}
