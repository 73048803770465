// ==============================================
// SIDEBAR MENU
// ==============================================

//
//  Desktop
//  _____________________________________________

//  Configuration

$collapsible-nav-content-padding:           2rem 0 4rem 0;
$collapsible-nav-desktop-bg-color:          $c-grey-4;

$collapsible-nav-item-color:                $c-base;
$collapsible-nav-item-padding:              1rem 2rem;
$collapsible-nav-item-current-color:        $c-pink;


.block-collapsible-nav {

    // Title
    .title {
        @extend .visually-hidden;
    }

    // Content
    .content {
        padding: $collapsible-nav-content-padding;
        background-color: $collapsible-nav-desktop-bg-color;
    }

    // Item
    li.item {
        line-height: 1;
        margin: 0;

        &::before {
            @extend .visually-hidden;
        }

        a,
        &.current > strong {
            display: flex;
            align-items: center;
            padding: $collapsible-nav-item-padding;
            color: $collapsible-nav-item-color;
            font-size: 1.5rem;
            line-height: 1.5;

            // Icons
            &::before {
                display: flex;
                justify-content: center;
                width: 5.2rem;
                margin-right: 1.5rem;
                @extend %icomoon;
                font-size: 3rem;
            }
        }

        &.current {

            > strong,
            > a {
                font-family: $f-museoSlab-700;
                font-size: 2rem;
                color: $collapsible-nav-item-current-color;

                &::before {
                    align-items: center;
                    background-color: $c-white;
                    @include circle(5.2rem);
                }
            }

            > a > strong {

                &::before {
                    display: none;
                }
            }
        }

        a {
            text-decoration: none;
            transition: color .4s;

            &:hover {
                color: $collapsible-nav-item-current-color;
            }
        }

        &:nth-child(1) {

            a,
            strong {

                &::before {
                    content: "\e926";
                }
            }
        }

        &:nth-child(2) {

            a,
            strong {
                &::before {
                    content: "\e90b"
                }
            }
        }

        &:nth-child(3) {

            a,
            strong {
                &::before {
                    content: "\e91a";
                }
            }
        }

        &:nth-child(4) {

            a,
            strong {
                &::before {
                    content: "\e90e";
                }
            }
        }

        &:nth-child(5) {

            a,
            strong {
                &::before {
                    content: "\e90d";
                }
            }
        }

        &:nth-child(6) {

            a,
            strong {
                &::before {
                    content: "\e941";
                }
            }
        }

        &:nth-child(7) {

            a,
            strong {
                &::before {
                    content: "\e91c";
                }
            }
        }

        &:last-child{

            a,
            strong {
                &::before {
                    content: "\e907";
                }
            }
        }
    }
}

//
//  Tablets & Mobiles
//  _____________________________________________

// Configuration

$collapsible-nav-mobile-bg-color:       $c-white;
$collapsible-nav-mobile-title-padding:  15px 40px 15px 20px;
$collapsible-nav-mobile-border-color:   $border-color;

@include breakpoint(only-lg) {

    .block-collapsible-nav {
        width: 100%;
        z-index: 5;
        margin-bottom: 20px;
        background-color: $collapsible-nav-mobile-bg-color;

        // Title
        .title {
            position: relative;
            width: auto;
            height: auto;
            padding: $collapsible-nav-mobile-title-padding;
            border: 1px solid $collapsible-nav-mobile-border-color;
            text-decoration: none;
            font-weight: bold;
            cursor: pointer;

            // Arrow
            &::after {
                position: absolute;
                display: inline-block;
                right: 10px;
                @include icomoon;
                content: "\e923";
                transition: all .1s linear;
            }

            &.active::after {
                transform: scaleY(-1);
            }
        }

        // Content
        .content {
            display: none;
            margin-left: -1px;
            margin-right: -1px;
            background: $c-white;
            border: 1px solid $collapsible-nav-mobile-border-color;
            border-top: 0;

            &.active {
                display: block;
            }
        }
    }
}
