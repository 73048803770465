// ==============================================
// ALGOLIA
// ==============================================

// Configuration
$sidebar-width: 27rem;
$layout-gutter-large: 3rem;
$listing-list-item-gutter: 0.5rem;
$listing-list-item-gutter-large: 1.5rem;
$panel-transition-duration: 0.3s;
$panel-transition-type: ease-out;

$itemsProgressBarHeight: 2px;
$progressBarBg: $c-pink-light;
$progressBarColor: $c-red;


#algolia-autocomplete-container {

    // Page category
    .page-layout-2columns-left &,
    .checkout-cart-index.page-layout-1column &,
    .catalog-product-view &,
    .cms-page-view &,
    .cms-no-route &,
    .contentmanager-content-view &,
    .contentmanager-contentlist-view & {

        .aa-dropdown-menu {

            @include breakpoint(md) {
                width: calc(100% + 8rem);
                margin-left: -4rem;
            }
        }
    }

    .has-not-top-header & {
        .aa-dropdown-menu {
            @include breakpoint(only-lg) {
                top: -60px !important;
            }
        }
    }

    .has-top-header & {
        .aa-dropdown-menu {
            @include breakpoint(only-lg) {
                top: -20px !important;
            }
        }
    }

    .aa-dropdown-menu {
        z-index: 12 !important;
        left: 0 !important;
        right: 0 !important;
        width: 100%;
        margin-top: 2rem;
        border: none;
        border-radius: 0;
        background: none;

        @include breakpoint(only-lg) {
            z-index: 9 !important;
            margin-top: 0;
        }

        // LAYOUT
        .autocomplete-wrapper {
            position: relative;
            display: block;
            width: 100%;
            max-width: 1600px;
            margin: 0 auto;
            background: $c-white;
            border-top: 3px solid $c-pink;
            box-shadow: 0 15px 20px rgba(black, .4);

            @include breakpoint(lg){
                &:after,
                &:before {
                    bottom: 100%;
                    left: 300px;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }

                &:after {
                    border-color: rgba($c-white, 0);
                    border-bottom-color: $c-white;
                    border-width: 10px;
                    margin-left: -10px;
                }

                &:before {
                    border-color: rgba($c-pink, 0);
                    border-bottom-color: $c-pink;
                    border-width: 14px;
                    margin-left: -14px;
                }
            }

            > div {
                padding: 3rem 2rem;

                @include breakpoint(lg) {
                    padding: 4rem 3rem;
                }

                @include breakpoint(xl) {

                    &.col-8 {
                        padding: 4rem 4.5rem 6rem;
                    }

                    &.col-2 {
                        padding: 6rem 4rem;
                    }
                }

                &.col-2:first-of-type {
                    background-color: $c-grey-light;
                }
            }

            @include breakpoint(md) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .col-8 {
                    width: 100%;
                    order: 1;
                }

                .col-2 {
                    width: 50%;

                    &:first-of-type {
                        background-color: $c-grey-light;
                        order: 2;
                    }

                    &:last-child {
                        order: 3;
                    }
                }
            }

            @include breakpoint(lg) {
                .col-8 {
                    width: 52%;
                }

                .col-2 {
                    width: 25%;

                    &:last-child {
                        width: 23%;
                    }
                }
            }
        }

        // Global items
        .algoliasearch-autocomplete-hit {
            padding: 0 !important;
        }

        // Block Products
        .col-8 {

            .aa-dataset-products {
                min-height: auto !important;
            }

            // No products
            .aa-no-results-products {
                min-height: auto;
                padding: 25px 0 0;

                .title {
                    margin-bottom: 2rem;
                    font-size: 1.4rem;
                    font-weight: normal;

                    @include breakpoint(md) {
                        margin-bottom: 3rem;
                    }
                }
            }

            .see-all {
                text-decoration: underline;
                transition: color.3s;

                &:hover,
                &:active {
                    color: $c-pink;
                }
            }
        }

        // Pages and Categories
        .col-2 {


            // No result
            .aa-no-results {
                padding: 0;
            }

            // List items
            .aa-suggestions {

            }

            // Item
            .aa-suggestion {

                .algoliasearch-autocomplete-hit {

                    .info {
                        margin-left: 0;
                        line-height: 1.4;
                        white-space: inherit;
                        text-overflow: inherit;
                        overflow: inherit;
                    }
                }
            }
        }
    }
}

// Page result

.catalogsearch-result-index {

    // Page title
    .page-title-wrapper {

        margin-bottom: 2rem;

        @include breakpoint(md) {
            margin-bottom: 4rem;
        }

        .page-title {
            color: $c-base;
            text-transform: inherit;
        }
    }

    .algolia-instant-selector-results {
        margin-top: 20px;
    }
}

/* ======================
   Algolia Upgrade
======================= */
// Autocomplete panel
.aa-Panel {
    position: absolute;
    z-index: 9 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100%;
    border: none;
    border-radius: 0;
    background: none;

    @include breakpoint(lg) {
        margin-top: 2rem;
        z-index: 12 !important;
    }
}

.aa-PanelLayout {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    background: $c-white;
    border-top: 3px solid $c-pink;
    box-shadow: 0 15px 20px rgba(black, .4);

    @include breakpoint(md) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    @include breakpoint(lg) {

        &:after,
        &:before {
            bottom: 100%;
            left: 300px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-color: rgba($c-white, 0);
            border-bottom-color: $c-white;
            border-width: 10px;
            margin-left: -10px;
        }

        &:before {
            border-color: rgba($c-pink, 0);
            border-bottom-color: $c-pink;
            border-width: 14px;
            margin-left: -14px;
        }
    }

    // Panel section
    .aa-Source {
        width: 100%;

        // Padding
        padding: 3rem 2rem;

        @include breakpoint(lg) {
            padding: 4rem 3rem;
        }

        @include breakpoint(xl) {
            padding: 6rem 4rem;

            &[data-autocomplete-source-id="products"] {
                padding: 4rem 4.5rem 6rem;
            }
        }

        //Set children order for mobile devices
        @include breakpoint(only-md) {
            order: 2;
            &[data-autocomplete-source-id="categories"] {
                order: 1;
            }
        }

        .aa-SourceHeader {
            font-family: $f-museo-700;
            font-size: 1.6rem;
            color: $c-grey;
            text-transform: inherit;

            @include breakpoint(lg) {
                font-size: 2rem;
            }
        }
        .aa-List {}
        .aa-Item {
            &[aria-selected="true"] {
                background-color: transparent;

                .algoliasearch-autocomplete-hit {
                    background-color: $c-grey-light;
                }
            }
        }

        mark {
            font-weight: 700;
            font-style: normal;
            background: none;
        }

        // Product section
        &[data-autocomplete-source-id="products"] {
            display: flex;
            flex-wrap: wrap;

            @include breakpoint(md) {
                width: 100%;
            }
            @include breakpoint(lg) {
                width: 52%;
            }

            // Set children order
            .aa-SourceHeader {
                order: 1;
            }
            .aa-SourceFooter {
                order: 2;
            }
            .aa-List {
                order: 3;
            }

            // Products section footer
            .aa-SourceFooter {
                // Reset default algolia css
                #autocomplete-products-footer {
                    display: block;
                    position: static;
                    width: auto;
                    background: none;
                    padding: 0;
                }
            }

            .aa-SourceHeader,
            .aa-SourceFooter {
                align-self: baseline;

                @include breakpoint(only-xs) {
                    a.btn {
                        padding-left: .8rem;
                        padding-right: .8rem;
                    }
                }
            }

            .aa-SourceHeader {
                padding: 0;
                margin-right: 2rem;

                @include breakpoint(lg) {
                    margin-right: 4rem;
                }
            }

            // Products
            .aa-List {
                margin: 0 -2rem;

                @include breakpoint(sm) {
                    display: flex !important;
                    flex-wrap: wrap;
                    width: 100%;
                }

                .aa-Item {
                    padding: 2.5rem 2rem;
                    width: 100%;

                    @include breakpoint(sm) {
                        width: 50%;
                    }

                    @include breakpoint(md) {
                        width: 33.3333%;
                    }

                    @include breakpoint(only-md) {
                        &:nth-child(n+7) {
                            display: none;
                        }
                    }
                }
            }

            .algoliasearch-autocomplete-hit {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
            }

            .thumb {
                width: 9rem;
                height: 9rem;
                margin-right: 1rem;

                > img {
                    width: 100%;
                }
            }

            .info {
                width: calc(100% - 10rem);
                margin: 0;
                font-size: 1.4rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                @include breakpoint(sm) {
                    max-width: 11rem;
                }

                .algoliasearch-autocomplete-category {
                    display: none;
                }

                .algoliasearch-autocomplete-price {
                    height: auto;
                    margin-top: 1rem;
                    font-size: 1em;
                    color: inherit;

                    .before_special {
                        color: #aaaaaa;
                        text-decoration: line-through;
                        font-size: 12px;
                    }
                }
            }
        }

        // Others sections
        &[data-autocomplete-source-id="categories"],
        &[data-autocomplete-source-id="blogs"] {
            @include breakpoint(md) {
                width: 50%;
            }
            @include breakpoint(lg) {
                width: 23%;
            }

            .aa-SourceHeader {
                margin-bottom: 2rem;

                @include breakpoint(lg) {
                    margin-bottom: 3rem;
                }
            }

            .aa-List {
                .aa-Item {
                    & + .aa-Item {
                        margin-top: 1.5rem;
                    }
                }
            }
        }

        // Categories section
        &[data-autocomplete-source-id="categories"] {
            background-color: $c-grey-light;
        }

        // BLogs section
        &[data-autocomplete-source-id="categories"] .algoliasearch-autocomplete-hit {
            small {
                color: $c-red;
            }
        }
    }
}

// Sticky
body.have-sticky {
    .aa-PanelLayout {
        @include breakpoint(lg) {
            &:after,
            &:before {
                left: 60%;
            }

            &:after {
                border-color: rgba($c-grey-light, 0);
                border-bottom-color: $c-grey-light;
            }
        }
    }
}


// ==============================================
// INSTANT SEARCH RESULT PAGE
// ==============================================

.algolia-instant-selector-results {}

#algolia_instant_selector {
    margin: 0;


    //
    //  CATEGORY FILTER - SIDEBAR
    //  _____________________________________________
    #algolia-left-container {

        // Parent item - Previous action
        .item.previous > a {
            display: flex;
            align-items: center;
            font-weight: bold;

            &:hover::before {
                right: 12px;
            }

            &::before {
                content:"\e927";
                @extend %icomoon;
                font-size: 2rem;
                transform: scaleX(-1);
                position: relative;
                right: 0;
                transition: right .3s ease-out;
            }

            .label {
                margin-left: 5px;
            }
        }

        // First level item
        .items  {
            & > li.item {
                box-shadow: 0 0 24px 0 transparent;
                transition: all .3s ease-out;

                &._active {
                    box-shadow: 0 0 24px 0 #0000001A;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border-radius: 15px;
                }
            }
        }

        // Default item
        .item {
            &._active {
                margin: 1rem 0 !important; // Override algolia important

                > .am-filter-item {
                    font-family: $f-avenir;
                    font-weight: bold;
                }

                > .am-collapse-icon {
                    display: none;
                }

                > .items-children {
                    display: block !important;
                }
            }

            > a {
                display: flex;
                align-items: baseline;
                padding: 8px 45px 8px 25px;
                transition: color .4s;
                line-height: 1.2;

                > .label {
                    font-size: 1.5rem;
                    margin-right: 0;
                    margin-bottom: 0;
                    font-family: $f-avenir;
                }

                &:hover {
                    color: $c-red;
                }
            }

            > ul.items,
            > ul.items.items-children {
                &.level-1 {
                    > li {
                        > ul.level-2 {
                            padding-top: 0;
                            padding-bottom: 0;
                            padding-left: 1rem;
                            border-bottom: none;
                            margin-bottom: 2rem;

                            > li {
                                > a {
                                    &.am_shopby_link_selected {
                                        color: $c-red;
                                    }
                                }

                                > ul.level-3 {
                                    padding-top: 0;
                                    padding-bottom: 0;
                                    padding-left: 1rem;
                                    border-bottom: none;
                                    margin-bottom: 0;

                                    > li {
                                        > a {
                                            &.am_shopby_link_selected {
                                                color: $c-red;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            > ul.items.items-children {
                &:not([style="display: none;"]) {
                    & > .item {
                        animation-name: hideBeforeShowing,movingLeftToRight;
                        animation-duration: .6s;
                    }
                }
            }
        }

        > ul.items > li.item > ul.items > li.item {
            a.am_shopby_link_selected + ul span.label,
            a:not(.am_shopby_link_selected) .label,
            a:not(.am_shopby_link_selected) + ul .label {
                font-size: 1.5rem;
            }

            &.is-open,
            .is-open {
                > .am-collapse-icon {
                    &::after {
                        transform: scale(1);
                        opacity: 1;
                    }
                }
            }

            ul.level-2 .am-collapse-icon::before {
                color: $c-grey-5;
            }

            .am-collapse-icon {
                top: -2px;
                left: auto;
                right: 10px;
                width: 40px;
                height: 40px;
                transition: top .1s ease-out;

                &:not(.no-close):hover::before {
                    transform: scale(1.5);
                }

                &::before,
                &::after {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 11px;
                    right: 11px;
                    left: auto;
                    width: 18px;
                    height: 18px;
                    @extend %icomoon;
                    background: none;
                }

                &::before {
                    content: "\e967";
                    color: $c-pink-light;
                    font-size: 1.8rem;
                    transform: scale(1);
                    transition: transform .3s linear;
                }

                &::after {
                    content: "\e943";
                    transform: scale(-1);
                    transition: transform .3s ease-out;
                    background: none;
                    border-radius: 100%;
                    font-size: 0.8rem;
                }
            }
        }
    }


    //
    //  RESET
    //  _____________________________________________

    .row{
        margin-left: 0;
        margin-right: 0;
        &::before,
        &::after {
            display: none;
        }

    }

    //
    //  SECTIONS
    //  _____________________________________________

    & > .row {
        @include breakpoint(only-lg) {
            .algolia-left-container {
                @include visually-hidden;
            }
        }
        @include breakpoint(lg) {
            display: flex;

            .algolia-left-container {
                width: $sidebar-width;
            }
            .algolia-right-container {
                width: calc(100% - #{$sidebar-width});
                padding-left: $layout-gutter-large;
            }
        }
    }

    //
    //  FILTERS (FACETS)
    //  _____________________________________________

    .facets-panel {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        z-index: 20;
        width: 100vw;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        transition: opacity $panel-transition-duration $panel-transition-type, visibility $panel-transition-duration $panel-transition-type;
        font-family: $f-avenir;
        text-align: left;

        &.is-active {
            visibility: visible;
            opacity: 1;

            .facets-panel-overlay {
                opacity: 1;
            }

            .facets-panel-container {
                right: 0;
            }
        }
    }

    .facets-panel-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity $panel-transition-duration $panel-transition-type;
        background-color: rgba(0,0,0,0.3);
    }

    .facets-panel-container {
        display: flex;
        flex-direction: column;
        padding: 25px 10px 80px;
        width: 100%;
        max-width: 780px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -100%;
        transition: right $panel-transition-duration $panel-transition-type;
        background-color: $c-white;
        border-radius: 40px 0 0 40px;

        @media (-moz-touch-enabled){
            padding: 25px 10px 60px;
        }

        @include breakpoint(md) {
            padding-top: 55px;
            padding-bottom: 45px;
        }

        // Content padding
        .facets-panel-header,
        .instant-search-facets-container {
            padding-left: 20px;
            padding-right: 20px;

            @include breakpoint(md) {
                padding-left: 35px;
                padding-right: 30px;
            }
        }

        //
        // Panel Header
        //
        .facets-panel-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2.8rem;
            position: relative;
        }

        // Title
        .facets-panel-title {
            display: flex;
            align-items: center;
            @extend .title-2;
            @include breakpoint(md) {
                font-size: 2.2rem;
            }
        }

        // Close panel action
        .close-facets-panel {
            font-size: 1.4rem;
            margin-left: 1rem;
            padding: 10px;
            border: 1px solid $c-new-grey-5;
            border-radius: 100%;
            color: $c-red;
            transition: color 0.3s linear;

            &:hover {
                cursor: pointer;
            }
        }

        .facets-panel-submit {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 2rem;
        }

        // Facets container
        .instant-search-facets-container {
            overflow: auto;
            padding-right: 2rem;

            @include custom-scrollbar();
        }

        // Clear refinements
        #clear-refinements {}
        .ais-ClearRefinements {}

        .ais-ClearRefinements-button {
            display: inline-block;
            color: $c-red;
            font-size: 1.4rem;
            margin-bottom: 2.5rem;
            margin-top: 1rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            text-decoration: underline;

            &--disabled {
                display: none;
            }
        }

        // Current refinements
        #current-refinements {
            width: 100%;
        }
        .ais-CurrentRefinements {

        }
        .ais-CurrentRefinements-list {
            @include visually-hidden;
        }
        .ais-CurrentRefinements-item { }

        .ais-CurrentRefinements-label {
            display: none;
        }

        .ais-CurrentRefinements-category {
            display: inline-block;
            position: relative;
            padding: 7px 10px 7px 25px;
            margin-right: 1rem;
            margin-bottom: 1rem;
            background-color: $c-pink-light;
            border-radius: 14px;
            color: $c-grey-dark2;
            font-size: 1.3rem;
            line-height: 1;

            .ais-CurrentRefinements-label {
                display: inline-block;

                &::after {
                    content:':';
                    display: inline-block;
                    margin: 0 4px;
                }
            }
        }

        .ais-CurrentRefinements-delete {
            position: absolute;
            left: 5px;
            top: 3px;
            padding: 6px;
            color: $c-red;
            font-size: 0;

            &::before {
                display: block;
                @extend %icomoon;
                content: "\e94e";
                font-size: 1rem;
                transform: rotate(-45deg);
            }
        }

        // Refinements
        #clear-refinements + [class^=is-widget-container] {
            border-top: 1px solid $c-new-grey-5;
        }

        [class^=is-widget-container] {
            & + [class^=is-widget-container] {}
        }

        .ais-Panel {
            border-bottom: 1px solid $c-new-grey-5;

            &.is-active {
                .ais-Panel-body {
                    display: block;
                    animation-name: hideBeforeShowing,movingTopToBottom;
                    animation-duration: .6s;
                    padding-bottom: 2rem;
                }
            }
            &:not(.is-active) {
                .ais-Panel-header::after {
                    transform: scaleY(-1);
                }
            }
        }

        .ais-Panel-header {
            display: flex;
            align-items: center;
            position: relative;
            padding: 16px 0;

            &:hover {
                cursor: pointer;
            }

            &::after {
                content: "\e943";
                display: inline-block;
                padding: 5px;
                margin-left: auto;
                transition: all .3s linear;
                transform: scaleY(1);
                border: 1px solid $c-new-grey-5;
                border-radius: 100%;
                @include icomoon;

                &:hover {
                    color: $c-red;
                }
            }

            .name {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                align-items: center;
                font-weight: bold;
                font-size: 1.6rem;

                @include breakpoint(md) {
                    font-size: 1.8rem;
                }
            }

            .subtitle {
                color: #969696;
                font-size: 1.4rem;
            }
        }

        .ais-Panel-body {
            display: none;
        }

        // Checkbox
        .ais-RefinementList-label {
            position: relative;
            display: inline-block;
            font-size: 1.4rem;
            line-height: $checkbox-height-custom;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: calc(#{$checkbox-width-custom} + 10px);
            min-height: $checkbox-height-custom;
            cursor: pointer;

            &::before,
            &::after {
                position: absolute;
                content: "";
                cursor: pointer;
                transition: all .2s;
            }

            // Fake checkbox
            &::before {
                left: 0;
                top: 8px;
                width: $checkbox-width-custom;
                height: $checkbox-height-custom;
                border: $checkbox-border-weight solid $checkbox-border-color-custom;
                background-color: $c-white;
                border-radius: $checkbox-border-radius;
            }

            // Check icon
            &::after {
                @extend %icomoon;
                content:"\e94e";
                left: calc(#{$checkbox-width-custom} / 2);
                top: calc((#{$checkbox-height-custom} / 2) + 8px);
                opacity: 0;
                font-size: 1rem;
                color: $checkbox-color-custom;
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &.checked::after {
                opacity: 1;
                transition: opacity .2s;
            }
        }

        // Hide list count
        .ais-RefinementList-count {
            @include visually-hidden;
        }

        // Hide algolia search box
        .ais-RefinementList-searchBox {
            @include visually-hidden;
        }

        // Refinement list items
        .ais-RefinementList-list {
            text-align: left;

            @include breakpoint(md) {
                column-count: 3;
            }
        }
        .ais-RefinementList-item {
            font-size: 1.4rem;
            list-style-type: none;
        }

        .select-storelocator {
            float: right;
            margin-top: -15px;

            a {
                color: $c-red;
                text-decoration: underline;
            }
        }
    }

    //
    //  RANGE SLIDER
    //  _____________________________________________
    .ais-Panel-body .ais-RangeSlider {
        max-width: 600px;
        padding-right: 30px;
        padding-left: 20px;
    }

    .ais-RangeSlider-handle {
        width: 20px;
        height: 20px;
        position: relative;
        z-index: 1;
        background: #FFFFFF;
        border: 1px solid $c-new-pink;
        border-radius: 50%;
        cursor: pointer;
    }

    .ais-RangeSlider-tooltip {
        position: absolute;
        background: #FFFFFF;
        top: -22px;
        font-size: .8em;
    }

    .ais-RangeSlider-value {
        width: 40px;
        position: absolute;
        text-align: center;
        margin-left: -20px;
        padding-top: 15px;
        font-size: .8em;
    }

    .ais-RangeSlider-marker {
        position: absolute;
        background: #DDD;
        margin-left: -1px;
        width: 1px;
        height: 5px;
    }

    // Range slider rheostat
    .ais-RangeSlider {
        .rheostat-progress {
            position: absolute;
            top: 1px;
            height: 4px;
            background-color: $c-new-pink;
        }
        .rheostat-background {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            height: 6px;
            top: 0;
            width: 100%;
            position: relative;
            background-color: $c-pink-light;
            border: 1px solid $c-pink-light;
        }
        .rheostat-handle {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: -20px;
            top: -17px;
            position: relative;
            z-index: 1;
            width: 40px;
            height: 40px;
            cursor: -webkit-grab;
            cursor: grab;

            &::before {
                content:"";
                background-color: $c-white;
                border: 1px solid $c-new-pink;
                border-radius: 50%;
                width: 20px;
                height: 20px;
            }
        }
        .rheostat-marker {
            margin-left: -1px;
            position: absolute;
            width: 1px;
            height: 5px;
            background-color: #aaa;
        }
        .rheostat {
            overflow: visible;
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    .rheostat-marker--large {
        height: 9px;
    }

    .rheostat-value {
        margin-left: 50%;
        padding-top: 15px;
        position: absolute;
        text-align: center;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .rheostat-tooltip {
        position: absolute;
        top: -20px;
        left: 50%;
        text-align: center;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }


    //
    //  SEARCH BAR
    //  _____________________________________________

    #instant-search-bar-container {
        @include visually-hidden;
        &::after {
            display: none;
        }
    }


    //
    //  TOOLBAR
    //  _____________________________________________
    .hits .infos {
        margin: 3rem 0;
        padding: 0;
        background: none;
        color: $c-grey-dark;
        line-height: 1;

        @include breakpoint(lg) {
            text-align: left;
        }

        strong {
            color: $c-grey-dark;
        }

        // Product counter
        .products-count {
            @include visually-hidden;
        }

        .ais-Stats {
            line-height: 1;
            margin-bottom: 1.4rem;
        }
        .ais-Stats-text {
            font-size: 1.4rem;
            strong {
                font-weight: bold;
            }
        }

        // Toolbar
        .toolbar {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            @include breakpoint(only-md) {
                gap: 10px;

                .facets-panel {
                    order: 1;
                }
                .view-filters {
                    order: 2;
                    flex-grow: 1;
                }
                .sorter {
                    order: 3;
                    flex-grow: 1;
                }
                .mt-main-filter {
                    order: 4;
                }
                .modes {
                    order: 5;
                }
            }

            @include breakpoint(md) {
                flex-wrap: nowrap;
            }

            &.before-sticky {
                transform: translateY(-100%);
            }

            &.is-sticky {
                position: fixed;
                z-index: 9;
                top: 0;
                left: 0;
                right: 0;
                padding: 10px 14px;
                background-color: $c-white;
                box-shadow: 0 2px 3px rgba(black, 0.14);
                transform: translateY(0);
                transition: transform 0.6s ease;
            }
        }

        // Display facets panel
        .view-filters {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            @include breakpoint(only-md) {
                padding: 7px 28px;
            }
            @media (max-width: 350px) {
                padding: 7px 20px;
            }

            i,
            .icon {
                font-size: 2rem;

                & + span {
                    margin-left: 0.5rem;
                }
            }

            .mt-counter {
                position: absolute;
                top: -4px;
                right: -4px;
                margin-right: 0;
                background-color: $c-grey-dark2;
            }
        }

        // MT Main Filter
        .mt-main-filter {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 28px;
            padding: 12px;
            border-radius: 40px;
            border: 1px solid $c-new-grey-5;
            transition: border-color .1s linear;

            &.is-active {
                border-color: $c-black;
            }

            .label {
                display: flex;
                align-items: center;
                gap: 5px;
                font-family: $f-avenir-medium;

                img {
                    max-width: 90px;

                    @include breakpoint(xs) {
                        max-width: 106px;
                    }
                }
            }
        }

        // Sorter
        .sorter {
            display: flex;
            align-items: center;
            position: relative;
            margin-left: auto;

            .sort-by-label {
                font-weight: bold;
                font-size: 1.5rem;

                @include breakpoint(only-lg) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin-right: 0;
                    pointer-events: none;
                    background-color: $c-grey-5;
                    border-radius: 40px;
                }

                @include breakpoint(lg) {
                    margin-right: 10px;
                    font-size: 1.4rem;
                    white-space: nowrap;

                    &::after {
                        content: ":";
                        display: inline-block;
                        margin-left: 3px;
                    }
                }
            }

            .algolia-sort {
                width: 100%;
            }

            .ais-SortBy-select {
                opacity: 0;
                @extend .mt-select;
                @include breakpoint(md) {
                    opacity: 1;
                }
            }
        }

        // Grid modes
        .modes {
            display: flex;
            align-items: center;
            margin-right: -5px;
            @include breakpoint(only-md) {
                margin-left: auto;
            }
            @include breakpoint(lg) {
                display: none;
            }
        }

        .modes-mode {
            color: $c-grey-5;
            font-size: 2rem;
            padding: 5px;
            height: 30px;
            width: 30px;

            &.is-active {
                color: $c-grey-dark;
            }
        }
    }

    //
    //  PRODUCTS GRID
    //  _____________________________________________
    #instant-search-results-container {}

    .ais-Hits,
    .ais-InfiniteHits {
        .ais-Hits-list,
        .ais-InfiniteHits-list {

            li.ais-Hits-item,
            li.ais-InfiniteHits-item {
                padding: 0 $listing-list-item-gutter;
                margin-bottom: 15px;

                @include breakpoint(md) {
                    margin-bottom: 30px;
                }

                // Fake product
                &.product-item--fake {
                    position: relative;
                }
            }

            // Grid view mode
            &:not(.list) {
                @include gutter-less($listing-list-item-gutter);

                @include breakpoint(only-xl) {
                    display: flex;
                    flex-wrap: wrap;

                    .result-content,
                    .result-sub-content {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;

                        @include only_ie {
                            flex: auto;
                        }
                    }

                    .product-item-info {
                        height: 100%;
                    }
                }

                @include breakpoint(xl) {
                    @include gutter-less($listing-list-item-gutter-large);

                    display: grid;
                    grid-template-columns: repeat(4,1fr);
                }

                li.ais-Hits-item,
                li.ais-InfiniteHits-item {
                    width: $w-half;

                    @include breakpoint(md) {
                        width: $w-third;
                    }

                    @include breakpoint(xl) {
                        width: auto;
                        padding: 0 $listing-list-item-gutter-large;
                    }
                }
            }

            // List view mode
            &.list {

            }
        }
    }

    // Infinite scroll
    .ais-InfiniteHits {
        text-align: center;

        // Actions nav
        & > .action {
            @extend .btn;
            display: inline-block;
            margin: 2rem auto;
        }

        .ais-InfiniteHits-loadPrevious,
        .ais-InfiniteHits-loadMore {
            &--disabled {
                @include visually-hidden;
            }
        }

        .ais-InfiniteHits-loadMore {}
    }


    //
    //  PRODUCT ITEM CONTENT
    //  _____________________________________________
    .ais-Hits-item > div,
    .ais-InfiniteHits-item > div {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow-y: hidden;
        height: 100%;

        // Hover support
        @media (hover : hover), (-ms-high-contrast : none) {
            &:hover {
                .result-content-inner {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    }

    .result {
        display: block;
        margin: 0;
        padding: 0;
        border: none;
        background-color: white;

        &:hover {
            border-color: #b9b9b9;
            text-decoration: none;
        }

        // Container of the image and action items displayed to hover
        .result-top-content {
            position: relative;
            overflow: hidden;
        }

        .result-thumbnail {
            display: block;
            text-align: center;

            img {
                display: block;
                height: auto;
                width: 100%;
            }

            .no-image {
                display: block;
                height: 100%;
                background: #FAFAFA;
            }
        }

        .result-content-inner {
            display: none;

            @include breakpoint(lg) {
                position: absolute;
                z-index: 2;
                right: -1px;
                bottom: 0;
                left: -1px;
                padding: 22px 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $c-white;
                opacity: 0;
                transform: translateY(100%);
                transition: transform 0.4s, opacity 0.4s;
            }

            // Actions wrapper
            .actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                white-space: nowrap;
            }

            // Add to wishlist
            .actions-secondary {
                width: 3rem;
                height: 3rem;
                padding: 0;
                margin-right: 0;
                background-color: $c-white;
                border-radius: 100%;
                border: none;
                font-size: 0;

                @include breakpoint(lg) {
                    width: 5rem;
                    height: 5rem;
                    background-color: transparent;
                }

                .towishlist {
                    &:hover {
                        color: $c-pink;
                    }
                    span {
                        @extend .visually-hidden;
                    }

                    &::before {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        font-size: 1.8rem;
                        transition: color .3s ease-out;

                        @include breakpoint(lg) {
                            font-size: 2.4rem;
                        }
                    }
                }
            }
        }

        // Product details
        .result-sub-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            flex-grow: 1;
            position: relative;
            padding-top: 2rem;
            background-color: $c-white;
            text-align: left;

            @include breakpoint(only-sm) {
                padding-top: 1rem;
            }

            .seller-name {
                font-size: 1.2rem;
                margin-bottom: 1rem;

                img {
                    display: inline-block;
                    vertical-align: bottom;
                    margin-left: 2px;
                    width: 100px;
                }
            }

            .before_special {
                margin-right: 5px;
                font-family: $f-avenir;
                font-size: 1.2rem;

                @include breakpoint(md) {
                    font-size: 1.4rem;
                }
            }

            .promotion-value {
                color: $c-red;
                font-size: 1.4rem;
            }
            .after_special.promotion {
                color: $c-red;
                font-size: 1.2rem;
                white-space: nowrap;
                @include breakpoint(md) {
                    font-size: 1.4rem;
                }
            }
            .price-after {
                font-size: 1.2rem;
                font-family: $f-avenir;
            }
        }

        .result-title {
            margin-bottom: 0.6rem;
            color: $c-grey;
            font-family: $f-avenir-medium;
            font-size: 1.4rem;
            line-height: 1;
            text-align: left;

            @include breakpoint(lg) {
                font-size: 1.6rem;
                margin-bottom: 1rem;
            }

            &.text-ellipsis {
                max-width: 200px;
            }
        }

        // Customer Reviews
        .product-item-reviews {
            margin-bottom: 10px;
            height: 20px;
        }
        .product-reviews-summary {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .rating-summary {
                @extend %rating-summary;
            }

            .reviews-actions {
                > a.action.view {

                    &::before {
                        content: '(';
                    }

                    &::after {
                        content: ')';
                        margin-left: -10px;
                    }

                    @include breakpoint(only-sm) {
                        font-size: 1.2rem;
                    }

                    > span {
                        @extend .visually-hidden;
                    }
                }
            }
        }

        .price {
            margin-bottom: 0.5rem;
            margin-top: auto;
            text-align: left;
            font-size: 1.4rem;
            color: $c-grey;

            @include breakpoint(lg) {
                margin-bottom: 0.8rem;
                font-size: 1.5rem;
            }
        }

        .price-wrapper {
            height: auto;
            font-size: 1.4rem;
            font-family: $f-avenir-black;

            @include breakpoint(md) {
                font-size: 1.5rem;
            }
        }

        // Hide add to cart form temporarily
        form[data-role="tocart-form"] {
            display: none;
        }
    }

    //
    //  FAKE PRODUCT ITEM CONTENT
    //  _____________________________________________
    .ais-Hits-item > .fake-product-item,
    .ais-InfiniteHits-item > .fake-product-item {
        .result-wrapper {
            &,
            .result,
            .result-content,
            .result-top-content,
            .result-thumbnail {
                height: 100%;
            }

            .result {
                .result-content-inner,
                .result-sub-content {
                    display: none;
                }
            }
        }
    }

    // Progress bar
    .instant-search-progress {

        .items-counter {
            color: #969696;
            font-size: 1.4rem;
            margin-bottom: 1.5rem;
            text-align: center;
        }

        .items-progress-bar {
            display: block;
            height: $itemsProgressBarHeight;
            width: 100%;
            max-width: 255px;
            margin: 0 auto;
            overflow: hidden;
            position: relative;
            background-color: $c-pink-light;

            span {
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                background-color: $c-red;
            }
        }
    }
}


.mt-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    height: 3rem;
    width: 3rem;
    background-color: $c-new-pink;
    border-radius: 100%;
    color: $c-white;
    font-family: $f-avenir;
    font-size: 1.3rem;
    font-weight: bold;

    &.small {
        height: 2rem;
        width: 2rem;
        font-size: 1.2rem;
    }

    // Hide html element if no refinement
    &[data-counter="current_refinements"] {
        display: none;

        &.is-showing {
            display: flex;
        }
    }
}

// Hide listing magento
.amasty-shopby-product-list {
    opacity: 0;
}
