// ==============================================
// ORDER VIEW
// ==============================================

body.account {

    &.sales-order-view,
    &.sales-order-shipment,
    &.sales-order-creditmemo,
    &.marketplace-order-view,
    &.marketplace-order-message,
    &.marketplace-order-documents,
    &.marketplace-order-evaluation,
    &.marketplace-message-view,
    &.marketplace-message-index {

        .column.main {

            // Page title
            .order-view-header-container {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 1rem;
                padding-bottom: 2rem;
                background-image: url('../images/background/sep-h.png');
                background-position: bottom left;
                background-repeat: repeat-x;

                // Order back link
                .order-back-link {
                    margin-bottom: 2rem;
                    order: 1;
                    width: 100%;
                }

                // Page title
                .page-title-wrapper {
                    display: flex;
                    align-items: baseline;
                    padding-right: 2rem;
                    margin: 0;
                    order: 2;

                    @include breakpoint(only-sm) {
                        display: block;
                        font-size: 1.9rem;
                        text-align: left;
                    }

                    .order-status {

                        &::before {
                            content: '-';
                            padding: 0 .5rem;
                        }
                    }
                }

                // Actions
                .actions-toolbar.order-actions-toolbar {
                    margin-top: 0;
                    order: 3;

                    @include breakpoint(only-sm) {
                        display: block;
                        width: 100%;
                    }

                    a.action {
                        @extend .btn;
                        @extend .btn--green;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        font-size: 1.2rem;

                        &.print {
                            margin-right: 1rem;
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            color: $c-green !important;
                        }

                        @include breakpoint(only-sm) {
                            width: 100%;

                            &.print {
                                display: none;
                            }
                        }
                    }
                }
            }

            // order links
            .items.order-links {
                display: flex;
                flex-wrap: wrap;
                margin: 1.4rem -1rem;

                > li {
                    padding: 1rem;

                    &.current {

                        > strong {
                            background-color: $c-pink;
                            border-color: $c-pink;
                            color: $c-white;
                        }
                    }

                    > a,
                    > strong {
                        @extend .btn;
                        @extend .btn--grey-light;
                        font-family: $f-museoSlab-700;
                        font-size: 1.4rem;
                    }

                    > a {

                        &:hover,
                        &:focus,
                        &:active {
                            color: $c-pink !important;
                            background: $c-grey-light !important;
                            border-color: $c-grey-light;
                        }
                    }

                    > strong {
                        pointer-events: none;
                    }
                }
            }

            // date
            .order-date {
                margin: 2.5rem 0;
                font-size: 1.6rem;

                > .label {
                    font-family: $f-avenir-black;
                }
            }

            // block
            .block.block-order-details-view {
                margin-bottom: 0;
                flex: 1;



                .block-title {
                    @include visually-hidden;
                }

                .block-content {
                    background-color: $c-grey-4;
                    padding: 24px;

                    > .box {
                        width: auto;
                        flex: 1;

                        @include breakpoint(md) {
                            &:nth-child(-n+2) {
                                margin-bottom: 4rem;
                            }
                        }

                        // title
                        > .box-title {
                            font-family: $f-avenir;
                            margin-bottom: 1.6rem;
                            font-size: 1.4rem;
                            font-weight: bold;
                        }

                        // Content
                        &.box-order-shipping-method {

                            > .box-content {
                                font-size: 0;

                                h3 {
                                    font-size: 1.4rem;
                                    text-transform: lowercase;

                                    &::first-letter {
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .order-details-view {
                &-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    flex-wrap: wrap;
                    margin-top: 3rem;

                    @include breakpoint(md) {
                        margin-top: 4rem;
                        flex-wrap: nowrap;
                        gap: 0 32px;
                    }
                }

                &-actions {

                    width: 100%;

                    @include breakpoint(md) {
                        width: auto;
                    }

                    ul {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        align-items: center;


                        > li {
                            .btn {
                                width: 100%;
                                max-width: 265px;
                                display: flex;
                                gap: 10px;
                                align-items: center;

                                .icon {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }

            // Orders
            .order-details-items {
                .order-shop {
                    padding: 0 0 10px 0;

                    a {
                        color: $c-red;
                    }
                }
                // Title
                .order-title {
                    @extend .title-2;
                    text-transform: inherit;

                    @include breakpoint(only-sm) {
                        margin: 4rem 0 1rem;
                    }

                    @include breakpoint(md) {
                        margin-bottom: 3rem;
                    }

                    a {
                        transition: .4s color;

                        &:hover {
                            color: $c-pink;
                        }
                    }
                }

                // Product item
                .product-item {
                    display: flex;
                    padding-bottom: 24px;
                    margin-bottom: 24px;
                    border-bottom: 1x solid $c-grey-3;
                    line-height: 1.5;

                    .product-image {
                        padding-right: 10px;

                        @include breakpoint(md) {
                            padding-right: 30px;
                        }
                        img {
                            width: 150px;
                        }
                    }

                    .product-details {
                        padding-right: 10px;

                        @include breakpoint(xs) {
                            padding-top: 10px;
                        }
                    }

                    .name {
                        font-family: $f-avenir-medium;
                        margin-bottom: 5px;

                        @include breakpoint(sm) {
                            font-size: 1.6rem;
                        }
                    }

                    .title {
                        color: $c-grey-light2;
                    }

                    // Subtotal
                    .subtotal {
                        margin-left: auto;
                        font-weight: bold;

                        @include breakpoint(xs) {
                            padding-top: 10px;
                        }
                    }
                }
            }

            // Table order totals
            .table-order-totals {
                // Footer
                tfoot {

                    tr {
                        display: table-row;
                        border-bottom: 1px solid $border-color;

                        &.grand_total .price {
                            color: $c-red;
                        }

                        th,
                        td {
                            padding: 1.2rem 0;

                            @include breakpoint(only-md) {
                                width: 50%;
                                padding: 1rem 2rem;
                            }
                        }

                        th {
                            padding-right: 10px;
                        }
                        td {
                            text-align: right;
                            font-weight: bold;
                        }
                    }
                }
            }

            // Back btn
            .actions-toolbar {

                .action.back {
                    padding: 0;
                    background: none;
                    border: none;
                    font-family: $f-avenir;
                    font-size: 1.4rem;
                    color: $c-base;

                    &::before {
                        content: '< ';
                    }

                    span {
                        text-decoration: underline;
                    }
                }
            }
        }
    }


}

//
// View
// ________________________________________

body.sales-order-view {

    .column.main {

        .order-details-items {

            .order-title {
                display: flex;
                align-items: center;

                .separator {
                    display: none;
                }

                .action.track {
                    margin-left: auto;
                    font-size: 1.2rem;
                    text-decoration: underline;

                    @include breakpoint(md) {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}

//
// Shipment
// ________________________________________

body.sales-order-shipment {

    .data.table {

        // Order tracking
        &.table-order-tracking {

            tr {
                border: 1px solid $border-color;

                th,
                td {
                    border: none;
                }

                th {
                    font-family: $f-avenir-black;

                    @include breakpoint(only-md) {
                        display: none;
                    }
                }

                td {

                    a {
                        @extend .link-1;
                    }

                    @include breakpoint(only-md) {

                        &::before {
                            content: attr(data-th);
                        }
                    }
                }
            }
        }

        // Shipment
        &.shipment {

            .table-caption {
                @extend .title-3;
                text-align: left;
                text-transform: inherit;
            }

            th {
                padding: 1rem;
            }

            td {

                @include breakpoint(md) {

                    &.qty {
                        text-align: right;
                    }
                }
            }
        }
    }
}

//
// Creditmemo
// ________________________________________
body.account.sales-order-creditmemo {

    .column.main {
        .block.block-order-details-view {
            margin-top: 0;

            @include breakpoint(md) {
                margin-top: 0;
            }
        }
    }

    .order-details-items.creditmemo {

        .order-title {

            > strong {

                &::after {
                    content: '|';
                }
            }
        }

        .actions-toolbar {
            margin-top: 0;
            margin-bottom: 2rem;

            .action.print {
                text-decoration: underline;
                transition: color .4s;

                &:hover {
                    color: $c-pink
                }
            }
        }
    }
}


//
// marketplace order view
// ________________________________________
body.account.marketplace-order-view {

    .marketplace-message-form-new-button {
        display:none;
    }

    .marketplace-message-form-invoice-button {
        display:none;
    }
}
