// ==============================================
// VARIABLES - Fonts
// ==============================================

$f-system-sans-serif-fallback: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue", sans-serif;
$f-system-serif-fallback: Georgia, "Palatino Linotype", "Book Antiqua", Palatino, serif;
//
//  Base fonts
//  _____________________________________________
$f-avenir: "avenir", $f-system-sans-serif-fallback;
$f-avenir-medium: "avenir-medium", $f-system-sans-serif-fallback;
$f-avenir-heavy: "avenir-heavy", $f-system-sans-serif-fallback;
$f-avenir-black: "avenir-black", $f-system-sans-serif-fallback;

$f-halohandletter: "halohandletter", $f-system-serif-fallback;

$f-museo-500: "museo-500", $f-system-serif-fallback; // 500
$f-museo-700: "museo-700", $f-system-serif-fallback; // 700

$f-museoSlab-300: "museo-slab-300", $f-system-serif-fallback; // 300
$f-museoSlab-500: "museo-slab-500", $f-system-serif-fallback; // 500
$f-museoSlab-700: "museo-slab-700", $f-system-serif-fallback; // 700


//
//  Project fonts
//  _____________________________________________

$f-base:     $f-avenir;
$f-icons:    'icomoon';
