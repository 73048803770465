// ==============================================
// VARIABLES - Layout
// ==============================================

//
//  Containers
//  _____________________________________________

$container-width:           1560px;
$gutter-mob:                1.5rem;
$gutter:                    2rem;

//
//  Columns
//  _____________________________________________

$sidebar-width:             20%;
$main-column-width:         80%;

//
//  Blocks width
//  _____________________________________________

$w-full:        100%;
$w-half:        $w-full / 2;
$w-third:       $w-full / 3;
$w-fourth:      $w-full / 4;

//
//  Border radius
//  _____________________________________________

$radius-box:        10px;
$radius-input:      4px;
$radius-btn:        4px;
