// ==============================================
// UTILITIES
// ==============================================

//
//  Visible / Hidden class
//  _____________________________________________

$breakpoints: xs sm md lg xl;

// Visible only on mobile/tablet
@each $breakpoint in $breakpoints {
    .visible-#{$breakpoint} {
        @include breakpoint(#{$breakpoint}) {  display: none !important;  }
        @include breakpoint(only-#{$breakpoint}) {  display: block;  }
    }
}

// Visible only on desktop
@each $breakpoint in $breakpoints {
    .hidden-#{$breakpoint} {
        @include breakpoint(#{$breakpoint}) {  display: block;  }
        @include breakpoint(only-#{$breakpoint}) {  display: none !important;  }
    }
}

//
//  SEO friendly hidden
//  _____________________________________________

@mixin visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px; width: 1px;
    margin: -1px; padding: 0; border: 0;
}

._hidden,
.visually-hidden {
    @include visually-hidden;
}

//
//  Floats clearer
//  _____________________________________________

@mixin clearer {
    &::after {
        display: block;
        content: "";
        clear: both;
        font-size: 0;
        line-height: 0;
        height: 0;
        overflow: hidden;
    }
}

%clearer {
    @include clearer;
}

//
//  Responsive Grid Table :
//  Grid on desktop, blocks on mobile
//  _____________________________________________

%responsive-grid-table {
    & {
        display: table;
        width: 100%;
        table-layout: fixed;
        @include breakpoint(only-md) {
            display: block;
        }
    }

    & > * {
        display: table-cell;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        @include breakpoint(only-md) {
            display: block;
            padding-top: 20px;
            padding-right: 0;
            padding-left: 0;
        }
    }

    & > *:last-child {
        padding-right: 0;
    }

    & > *:first-child {
        padding-left: 0;
        @include breakpoint(only-md) {
            padding-top: 0;
        }
    }
}

//
//  Responsive product table
//  _____________________________________________

%responsive-product-table {
    & {
        width: 100%;
    }

    td,
    th {
        vertical-align: bottom;
    }

    th {
        padding-right: 20px;
        padding-bottom: 17px;
        white-space: nowrap;
    }

    td[data-th]:before,
    th {
        text-align: left;
        color: #8290A4;
    }

    @include breakpoint(md) {
        td {
            padding-top: 25px;
            padding-bottom: 25px;
            text-align: right;
        }

        td + td {
            padding-left: 10px;
        }
    }

    @include breakpoint(only-md) {
        &,
        thead,
        tbody,
        tfoot,
        tr,
        td {
            display: block;
        }

        th {
            display: none;
        }

        tr {
            z-index: 1;
            position: relative;
            padding-right: 60px;
        }

        td {
            margin-bottom: 10px;
        }

        td[data-th].qty,
        td[data-th].price {
            z-index: 1;
            position: relative;
            padding-left: 85px;
        }

        td[data-th].qty:before,
        td[data-th].price:before {
            z-index: 1;
            position: absolute;
            top: 50%;
            left: 0;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }

        td[data-th]:not(.item):before {
            content: attr(data-th);
            width: 75px;
        }

        td[data-th].subtotal:before {
            float: left;
            padding-right: 10px;
        }

        td[data-th].subtotal .actions-toolbar {
            z-index: 1;
            width: 50px;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

//
//  Price
//  _____________________________________________

//
//  Configuration
//  _____________________________________________

$price-font-family:     $f-avenir;
$price-color:           $c-base;
$price-new-color:       $c-red;

%price-box {
    display: flex;
    align-items: baseline;
    font-family: $price-font-family;

    .price-label {
        @include visually-hidden;
    }

    .price {
        font-size: 3rem;
        color: $price-color;
    }

    .old-price {
        margin-right: 1.5rem;

        .price {
            font-size: 1.6rem;
            color: $price-color;
            text-decoration: line-through;
        }
    }

    .percent-price {
        margin-right: 2rem;
        font-family: $f-avenir-black;
        font-size: 1.6rem;
        color: $price-new-color;
    }

    .special-price {

        .price,
        + .price-after {
            font-family: $f-avenir-black;
            color: $price-new-color;
        }
    }


}

%price-box--small {

    @extend  %price-box;

    .price {
        font-size: 1.5rem;
    }

    .old-price {
        margin-right: .5rem;

        .price {
            font-size: 1.4rem;
        }
    }

    .percent-price {
        margin-right: .5rem;
        font-size: 1.4rem;
    }
}

//
//  Product view
//  _____________________________________________


%product-page-block-title {
    margin-bottom: 3rem;
    font-family: $f-museo-700;
    font-size: 1.6rem;
    text-align: left;
    text-transform: uppercase;

    @include breakpoint(md) {
        margin-bottom: 4rem;
        font-size: 2rem;
    }

    @include breakpoint(xl) {
        margin-bottom: 6rem;
    }
}

%product-page-block-content {
    margin-bottom: 3rem;

    @include breakpoint(md) {
        margin-bottom: 6rem;
    }

    @include breakpoint(xl) {
        margin-bottom: 9rem;
    }
}

%tooltip {
    display: none;
    position: absolute;
    z-index: 1;
    top: 25px;
    left: 0;
    padding: 1rem;
    background-color: white;
    border: 1px solid $border-color;
    font-family: $f-avenir;
    font-size: 1.2rem;
    color: $c-base;
}

%dictionary {
    position: relative;
    font-family: $f-avenir-black;
    color: $c-pink;
    cursor: pointer;

    &::after {
        @extend %tooltip;
        content: attr(data-description);
        padding: 1rem;
        left: -2rem;
        min-width: 10rem;

        @include breakpoint(md) {
            min-width: 30rem;
            padding: 2rem;
        }
    }

    &:hover,
    &:focus,
    &:active {

        &::after {
            display: block;
        }
    }
}



//
//  Display animation
//  _____________________________________________

@keyframes hideBeforeShowing {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes movingLeftToRight {
    from {
        transform: translateX(-15px);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes movingRightToLeft {
    from {
        transform: translateX(15px);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes movingTopToBottom {
    from {
        transform: translateY(-10px);
    }

    to {
        transform: translateY(0);
    }
}
