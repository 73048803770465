.hotspot .product-info {
    height: auto;
    min-width: 250px;

    .price-box {
        flex-wrap: wrap;
    }
}

.mt-m-LookBookSlider {
    position: relative;
    z-index: 1;
    width: 100%;
    margin-bottom: 50px;

    &-container {
        position: relative;
        margin-left: auto;
        width: calc(100% - 265px);
    }

    &-slider {
        max-height: 645px!important;
        overflow: hidden;

        &-slide {
            $slide: &;
            position: relative;

            &-img {
                position: relative;
                max-height: calc(100vh - 160px);
                height: 642px;
                overflow: hidden;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0.45;
                    background: linear-gradient(90deg, $c-white 0%, rgba($c-white, 0) 100%);
                    pointer-events: none;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: auto;
                    min-height: 100%;
                    min-width: auto;
                    @include center-abs-xy();
                }

                &-double {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    & > div {
                        width: 50%;
                        position: relative;
                        height: 100%;
                        display: block;
                        overflow: hidden;
                    }
                }
            }

            &-content {
                @include center-abs-y();
                left: 150px;
                z-index: 2;
                color: $c-grey-dark;
                width: 600px;
                max-width: 100%;

                &-suptitle {
                    display: block;
                    position: relative;
                    font-family: $f-halohandletter;
                    font-weight: 500;
                    @include fs(40px, 51px);
                    @include rotate(-3deg);
                    margin-bottom: 15px;
                    color: $c-new-red;
                }

                &-title {
                    display: block;
                    @include fs(45px, 50px);
                    font-family: $f-museo-700;
                    margin-bottom: 10px;
                }

                &-txt {
                    font-family: $f-avenir;
                    font-weight: 500;
                    @include fs(22px, 30px);
                    margin-bottom: 20px;
                }

                &-btn {
                    margin-top: 20px;
                }
            }

            &-share {
                position: absolute;
                z-index: 2;
                top: 30px;
                right: 30px;
                border-radius: 1.86px;
                background-color: $c-white;
                box-shadow: 2px 2px 4px 0 rgba($c-black, 0.3);
                @include rotate(-4deg);
                @include fs0();
                width: 120px;

                &-icon {
                    position: absolute;
                    right: -14px;
                    top: -8px;
                    @include fs(38px, 38px);
                    color: $c-white;
                    width: 34px;
                    height: 33px;
                    display: block;
                    background: linear-gradient(120deg, #FFC551 0%, #D21AC7 56%, #E13878 100%);
                    border-radius: 100%;
                    overflow: hidden;

                    &:before {
                        display: block;
                        position: absolute;
                        left: -3px;
                        top: -2px;
                    }
                }

                &-img {
                    display: block;
                    margin: 10px auto;
                    width: 90px;
                    height: 90px;
                    background: linear-gradient(120deg, #FFC551 0%, #D21AC7 56%, #E13878 100%);
                    border-radius: 100%;
                    overflow: hidden;
                    position: relative;

                    & > span {
                        border-radius: 100%;
                        overflow: hidden;
                        border: 5px solid $c-white;
                        @include center-abs-xy();
                        width: 86px;
                        height: 86px;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }

                &-txt {
                    display: block;
                    max-width: 90px;
                    margin: 0 auto 10px;
                    text-align: center;
                    font-family: $f-avenir;
                    @include fs(12px, 16px);
                    font-weight: 300;

                    &-name {
                        color: $c-new-red;
                        @include fs(12px, 14px);
                        font-weight: 600;
                        display: block;
                        white-space: pre-wrap;
                        word-wrap: break-word;
                        max-width: 85px;
                        margin: 0 auto;
                    }
                }
            }

            &[data-txt-color="white"] {
                #{$slide} {
                    &-img {
                        &:before {
                            background: linear-gradient(90deg, $c-black 0%, rgba($c-black, 0) 100%);
                        }
                    }

                    &-content {
                        color: $c-white;

                        &-suptitle {
                            color: $c-white;
                        }
                    }
                }
            }
        }

        &.cycle-paused:after {
            display: none;
        }

        .hotspot {
            cursor: pointer;
            display: block;
            max-width: 20px;
            max-height: 20px;
            min-width: 20px;
            min-height: 20px;
            background-color: $c-white;
            box-shadow: 0 15px 20px rgba($c-black, 0.4);
            border-radius: 100%;
            @include fs(10px, 22px);
            @include transition(ease 0.2s);
            color: $c-new-red;
            z-index: 99999;
            &:before,
            &:after {
                content: '';
                display: block;
                @include center-abs-xy();
            }
            &:before {
                content: '\e94e';
                @include icomoon;
            }
            &:after {
                width: 34px;
                height: 34px;
                border: 1px dashed $c-white;
                border-radius: 100%;
            }
            &:hover {
                background: transparent;
                box-shadow: none;
                &:before {
                    width: 14px;
                    height: 14px;
                    border-radius: 100%;
                    @include fs0();
                    background-color: $c-new-red;
                }
                &:after {
                    background-color: rgba($c-white, 0.2);
                }
            }

            .product-item-info .product-item-details {
                padding-top: 0;
            }

            .product-info {
                background: {
                    image: none;
                    color: $c-white;
                };
                padding: 0;
                margin: 0;
                border-radius: 0;
                border: none;


                .left-detail {
                    display: flex;
                    align-items: stretch;
                    justify-content: space-between;
                    .product-image {
                        width: 80px;
                        height: 80px;
                        margin: {
                            top: 15px;
                            bottom: 15px;
                            left: 15px;
                            right: 10px;
                        }

                        & > a {
                            display: block;
                            overflow: hidden;
                            position: relative;
                            width: 80px;
                            height: 80px;
                            img {
                                @include center-abs-xy();
                                height: 100%;
                                width: auto;
                                max-width: initial;
                            }
                        }
                    }
                    .product-details {
                        margin: {
                            top: 15px;
                            bottom: 15px;
                        }
                        padding-right: 20px;
                        width: calc(100% - 120px); // 120px = Picture and actions width
                    }
                    .product-name {
                        font-family: $f-avenir-medium;
                        @include fs(14px, 16px);
                        color: $c-black;
                        margin-bottom: 5px;
                        display: block;
                    }
                    .price-box .price {
                        font-family: $f-avenir-heavy;
                        color: $c-black;
                        padding: 0;
                    }
                }

                .slider-product-link
                {
                    @include fs0();
                    background-color: $c-pink-light;
                    border-color: $c-pink-light;
                    padding: 0;
                    width: 40px;
                    height: 100%;
                    border-radius: 0;
                    @include transition(background ease 0.2s);
                    &:before {
                        content: '\e943';
                        @include icomoon;
                        @include fs(14px, 16px);
                        display: block;
                        @include rotate(90deg);
                        color: $c-black;
                    }

                    &:hover {
                        background-color: $c-pink-light-2;
                        border-color: $c-pink-light-2;
                    }
                }

                .pro-detail-div {
                    & > div:not(.left-detail):not(.product-image):not(.product-details):not(.actions-primary),
                    .left-detail > div:not(.product-image):not(.product-details):not(.actions-primary) {
                        display: block;
                        width: 100%;
                        & > a {
                            display: block;
                            position: relative;
                            padding: 15px;
                            margin: 0;
                            &:before {
                                content: '\e943';
                                @include icomoon;
                                @include fs(14px, 16px);
                                display: block;
                                @include rotate(90deg);
                                color: $c-black;
                                position: absolute;
                                right: 12px;
                                z-index: 2;
                            }
                            &:after {
                                content: '';
                                @include fs0();
                                background-color: $c-pink-light;
                                border-color: $c-pink-light;
                                padding: 0;
                                width: 40px;
                                height: 100%;
                                border-radius: 0;
                                @include transition(background ease 0.2s);
                                position: absolute;
                                right: 0;
                                top: 0;
                            }

                            &:hover {
                                &:after {
                                    background-color: $c-pink-light-2;
                                    border-color: $c-pink-light-2;
                                }
                            }
                        }
                    }
                }
            }

            .hotspot-inspiration {
                padding: 15px;

                h4 {
                    font-family: $f-museo-700;
                    @include fs(20px, 22px);
                    margin: 0;
                    color: $c-black;
                }

                .hotspot-inspiration-text-1 {
                    margin-bottom: 10px;
                    display: block;
                }
                .hotspot-inspiration-text-2 {
                    margin-bottom: 0;
                    display: block;
                }
            }
        }
    }

    &-arrows {
        &-left,
        &-right {
            @include center-abs-y();
            @include fs(35px, 35px);
            height: 100px;
            width: 100px;
            border-radius: 100%;
            color: $c-black;
            z-index: 999;
            cursor: pointer;
            display: block;

            & > span {
                display: block;
                width: initial;
                height: initial;
                background: none;
                @include center-abs-y();
            }
        }

        &-left {
            left: -50px;
            background: linear-gradient(270deg, rgba($c-white, 0.2) 0%, $c-white 100%);

            & > span {
                right: 13px;
                @include transform(translate(0, -50%) rotate(180deg));
            }
        }

        &-right {
            right: -50px;
            background: linear-gradient(270deg, $c-white 0%, rgba($c-white, 0.2) 100%);

            & > span {
                left: 13px;
            }
        }
    }

    &-dots {
        @include center-abs-x();
        bottom: -9px;
        z-index: 999;
        display: flex;
        align-items: center;
        @include fs0();

        span {
            width: 14px;
            height: 14px;
            margin: 0 5px;
            background-color: $c-white;
            @include transition(ease-out 0.2s);
            border-radius: 100%;
            position: relative;
            cursor: pointer;

            &:before {
                content: '';
                width: 7px;
                height: 7px;
                display: block;
                @include center-abs-xy();
                background-color: $c-new-red;
                border-radius: 100%;
                opacity: 0.2;
            }

            &.cycle-pager-active {
                width: 18px;
                height: 18px;

                &:before {
                    width: 9px;
                    height: 9px;
                    opacity: 1;
                }
            }
        }
    }

    @media screen and (max-width: 1530px) {
        &-container {
            width: calc(100% - 285px);
        }
    }

    @include breakpoint(only-xl) {
        position: relative;
        top: initial;
        left: initial;

        &-container {
            margin-left: 0;
            width: 100%;
        }

        &-slider {
            &-slide {
                &-content {
                    left: 110px;
                }
            }
        }
    }

    @include breakpoint(only-lg) {
        margin-bottom: 40px;
        &-slider {
            &-slide {
                &-content {
                    max-width: 500px;
                }
            }
        }
    }

    @include breakpoint(only-md) {
        &-slider {
            max-height: initial!important;
            &-slide {
                &-img {
                    padding-top: calc(#{(575/375)*100%});
                    height: 0;
                    min-height: initial;

                    &:before {
                        opacity: 0.55;
                        background: linear-gradient(180deg, $c-white 0%, rgba($c-white, 0) 100%);
                    }


                    &-double {
                        display: block;
                        position: absolute;
                        top: -30%;
                        left: 0;

                        & > div {
                            width: 100%;
                            height: 0;
                            padding-top: 100%;
                            top: 168px;
                        }
                    }
                }

                &-content {
                    @include transform(none);
                    top: 45px;
                    width: calc(100% - 30px);
                    left: 15px;

                    &-suptitle {
                        @include fs(36px, 46px);
                        margin-bottom: 3px;
                    }

                    &-title {
                        @include fs(32px, 37px);
                        margin-bottom: 5px;
                    }

                    &-txt {
                        @include fs(18px, 25px);
                        margin-bottom: 20px;
                    }
                }

                &-share {
                    left: 20px;
                    bottom: 35px;
                    right: initial;
                    top: initial;
                }
            }
        }

        &-arrows {
            display: none;
        }
    }
}
