// ==============================================
// BLOG - Reviews
// ==============================================

.blog-reviews {
    display: flex;
    flex-wrap: wrap;
    max-width: 1034px;
    margin: 6rem auto 0 auto;

    &__title {
        flex: 1;
        align-self: center;
        font-family: $f-museo-500;
        font-size: 1.5rem;
        line-height: 1;

        @include breakpoint(md) {
            padding-right: 3rem;
        }
    }

    > .review-btn {
        align-self: center;
        margin-left: auto;

        @include breakpoint(only-md) {
            width: 100%;
            margin-top: 1rem;
            margin-left: 0;
        }
    }

    &__list {
        width: 100%;
        margin-top: 2rem;
    }

    .review {
        display: flex;
        align-items: flex-start;
        margin-bottom: 4.8rem;

        .review-avatar {
            @include circle(3rem);
            overflow: hidden;
            margin-right: 1rem;

            @include breakpoint(md) {

                @include circle(6rem);
                margin-right: 3rem;
            }
        }

        .review-content {
            flex: 1;
        }

        .review-header {
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;

            @include breakpoint(only-md) {
                flex-direction: column-reverse;
            }

            div.review-reply {
                width: 100%;
                margin-top: .5rem;
                text-align: right;

                > .review-btn {
                    padding: 1rem 2rem;
                    font-size: 1.4rem;
                }
            }
        }

        // Author
        .review-author {
            font-family: $f-museoSlab-700;
            font-size: 1.6rem;
        }

        // Date
        .review-date {
            margin-bottom: 1.4rem;

            @include breakpoint(md) {
                margin-left: auto;
                margin-bottom: 0;
            }
        }

        // Description
        .review-description {
            margin: 1.4rem 0;
            line-height: 1.3;
            hyphens: auto;
        }

        // Answer
        .review-answer {

        }
    }
}
