//
// Sticky footer
// ______________________________________________

.page-main {

    &.sticky-footer-is-open {

        > * {
            pointer-events: none;
        }
    }
}

.product-sticky-footer {
    display: none;

    @include breakpoint(only-md) {
        $transition-timing-function: ease-in;
        $transition-delay: .4s;

        position: fixed;
        left: 0;
        bottom: 0vh;
        z-index: 2;
        display: block;
        width: 100%;
        min-height: 10rem;
        max-height: 100vh;
        overflow-y: auto;
        padding: 1rem;
        background-color: $c-white;
        border-top: 1px solid $c-grey-medium;
        border-bottom: 10px solid;
        transition: left $transition-delay $transition-timing-function;

        &.is-open {
            min-height: 25rem;
            box-shadow: 0 0 0px 1000px rgba(00,00,00, .35);

            .psf__header,
            .psf__content .box-tocart {
                display: none;
            }

        }
    }
}

// Product name & Price
.psf__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    color: $c-black;

    > .psf__product-name {
        max-width: 20rem;
        padding-right: 2rem;
        font-family: $f-museo-500;
    }

    .psf__product-price {

        .price-box {
            @extend %price-box--small;

            .before-configurable-price {
                margin-right: .5rem;
                font-size: 1rem;
            }
        }
    }
}

// Form
.psf__content {
    position: relative;
    min-height: 3rem;

    > form {
        font-size: 1.2rem;

        .fieldset {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        // Qty
        .field {

            &.qty {

                > .label {
                    @include visually-hidden;
                }

                > .control {

                    > .soon-qty-updater {
                        width: auto;
                    }
                }
            }
        }

        // Add to cart
        .actions {
            margin-left: 1rem;

            > .tocart {
                display: flex;
                align-items: center;
                height: 3rem;
                padding: 0 1rem;
                font-size: 1.2rem;

                &::before {
                    @extend %icomoon;
                    content: "\e903";
                    margin-right: .5rem;
                    font-size: 1.6rem;
                }
            }
        }
    }

    // Configurable
    .field.configurable {
        position: absolute;
        left: 0;
        max-width: 15rem;

        label {
            @include visually-hidden;
        }

        select {
            max-height: 3rem;
            padding: 8px 20px 8px 8px;
            background-position: right 6px center;
            font-size: 1.1rem;
        }


    }

    // Colors
    .product-colors {
        position: absolute;
        left: 0;
        width: 16rem;
        height: 3rem;
        overflow: hidden;

        > ul > * {
            pointer-events: none;
        }

        // Open
        &.is-open {
            position: fixed;
            overflow: inherit;
            z-index: 14;
            right: 0;
            bottom: 0;
            left: 0;
            width: auto;
            height: auto;
            min-height: 25rem;
            padding: 1rem;
            background-color: $c-white;
            border-top: 1px solid $c-grey-medium;
            border-bottom: 10px solid;
            pointer-events: none;

            > ul {
                padding-right: 3rem;

                > * {
                    pointer-events: auto;
                }

                .show-more-products {
                    visibility: visible;
                }
            }
            + .box-tocart {
                pointer-events: none;
            }

        }

        > .product-colors__label {
            display: none;
        }

        > .product-colors__list {
            padding-right: 0;

            > li {
                width: 3rem;
                height: 3rem;
                margin: 0 .3rem 1rem .3rem;
            }

            .show-more-products {
                visibility: hidden;
                top: .6rem;
                right: .6rem;
            }
        }
    }
}
