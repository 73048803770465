// ==============================================
// CS BLOCK CONFECTION : Engagement
// ==============================================

.csb-engagement {
    padding-bottom: 5rem;
    margin-bottom: 4rem;
    background-image: url("../images/background/sep-h.png");
    background-position: left bottom;
    background-repeat: repeat-x;

    @include breakpoint(lg) {
        margin-bottom: 6rem;
    }

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    .csb-engagement__title {
        display: block;
        width: 35.4rem;
        height: 5rem;
        margin: 0 auto 5rem;
        background-image: url("../images/background/ribbon.png");
        background-repeat: no-repeat;
        background-size: cover;
        font-size: 2.2rem;
        line-height: 5rem;
        color:$c-white;
        text-align: center;

        @include breakpoint(only-sm) {
            width: 28rem;
            height: 3.9rem;
            margin: 0 auto 3rem;
            line-height: 3.9rem;
            font-size: 2rem;
        }
    }

    .csb-engagement__list {
        max-width: 89rem;
        margin: 0 auto;
    }

    .csb-engagement__item {
        position: relative;

        &::before {
            @include circle(.9rem);
            position: absolute;
            top: 6px;
            display: block;
            background-color: $c-red;
            content: '';
        }

        + .csb-engagement__item {
            margin-top: 3rem;
        }

        > p {
            padding-left: 2.2rem;
            margin: 0;
            font-size: 1.6rem;
            line-height: 1.4;

            strong {
                font-family: $f-avenir-black;
            }
        }
    }
}
