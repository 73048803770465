// ==============================================
// Panel : login
// ==============================================

.mt-panel-container {

    .block-customer-login {
        height: 100%;
    }
}

.customer-login-section {
    padding: 3rem 2rem;

    @include breakpoint(md) {
        padding: 4rem 3rem;
    }

    &.section-registered-customer {
        padding-bottom: 1rem;
    }

    &.section-new-customer {

        .messages {
            color: $c-error;

            &:not(:empty) {
                padding: 1rem;
                margin-top: 1rem;
                border: 1px solid $c-error;
            }
        }
    }

    .block-title {
        margin-bottom: 2rem;

        @include breakpoint(md) {
            margin-bottom: 3rem;
        }
    }

    .actions-toolbar {
        text-align: center;
    }

    .form {
        .label {
            @extend .visually-hidden;
        }
    }
}
