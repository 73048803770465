// Back to top
.back-to-top {
    position: fixed;
    z-index: 1;
    left: initial!important;
    right: 2.5rem;
    bottom: 5rem;
    display: block;
    font-size: 1.2rem;
    color: $c-grey-dark;
    transition: opacity 0.3s linear;

    &.fadeout {
        opacity: 0;
    }
    &.fadein {
        opacity: 1;
    }
    .back-to-top__icon {
        position: relative;
        width: 4rem;
        height: 4rem;
        background-image: url('../images/icon/patate-grey2.svg');
        background-size: cover;
        display: block;

        &:hover {
            //color: $c-pink;
        }
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .back-to-top__label {
        @include visually-hidden;
    }

    @include breakpoint(only-xl) {
        right: 2rem;
        bottom: 4rem;
        left: auto;
    }
}
