// ==============================================
// ORDERS
// ==============================================

.account {

    // ie
    html[class*="ie"] & {

        .table-accordion {
            padding-left: 1px; // prevent border left visibility
        }
    }

    // Wrapper
    .table-wrapper {
        border: none;

        // Table
        table.table-order-items {
            width: calc(100% - 1px); // Fix border-right visibility

            &:not(.shipment) {

                caption {
                    @include visually-hidden;
                }
            }

            // Thead
            thead {

                th {
                    padding: 2rem 1rem;
                    font-family: $f-avenir-black;

                    @include breakpoint(xl) {
                        padding: 2rem;
                    }
                }
            }

            // Recent (dashboard)
            &.recent {

                thead {

                    th {

                        &.actions,
                        &.toggle {
                            font-size: 0;
                        }
                    }
                }

                th,
                td {

                    &.total,
                    &.status,
                    &.actions {
                        text-align: center;
                    }
                }

                td {

                    &.actions {

                        a {
                            margin: .5rem;
                        }
                    }
                }
            }

            td {
                border: none;

                &.actions {

                    a.action {

                        &.view,
                        &.order,
                        &.download {
                            @extend .btn;
                            padding: 1rem 1.5rem;
                            font-size: 1.2rem;

                            @include breakpoint(only-md) {
                                display: block;
                                width: 100%;
                                font-size: 1.4rem;
                            }
                        }

                        &.view {
                            @extend .btn--green;
                        }

                        @include breakpoint(only-md) {
                            margin: 0;

                            + a {
                                margin-top: 1rem;
                            }
                        }
                    }
                }

                &.toggle {
                    text-align: right;

                    @include breakpoint(only-md) {
                        justify-content: center;

                        &::before {
                            display: none;
                        }
                    }

                    span:not(.icon-submenu-arrow-copy) {
                        font-size: 0;
                    }

                    > div[data-role="collapsible"] {

                        &[aria-selected="true"] {

                            button {
                                transform: rotate(-180deg);
                            }
                        }

                        @include breakpoint(md) {
                            button {
                                transform: rotate(-90deg);
                            }

                            &[aria-selected="true"] {

                                button {
                                    transform: rotate(0deg);
                                }
                            }
                        }
                    }
                }
            }

            // Tbody
            > tbody {

                @include breakpoint(md) {
                    border: 1px solid $border-color;
                }

                > tr:not(.order-item):not(:first-child) {
                    border-top: 1px solid $border-color;
                }

                td {
                    padding: 2rem 1rem;
                    line-height: 1.2;

                    @include breakpoint(only-md) {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        padding: 1rem 0;
                        font-family: $f-museo-500;

                        &:not(.actions)::before {
                            font-family: $f-museo-500;
                        }

                        &:first-child {
                            padding-top: 2rem;
                        }

                        &:last-child {
                            padding-top: 2rem;
                            border-bottom: none;
                        }
                    }

                    @include breakpoint(xl) {
                        padding: 2rem;
                    }
                }
            }

            // History (Orders page)
            &.history {

                tbody {

                    > tr:not(.order-item):not(:first-child) {
                        border-top: none;
                    }

                    td {

                        @include breakpoint(only-md) {
                            padding: 1rem 0 1.5rem 0;
                            border-bottom: 1px solid $border-color;

                            &:first-child {
                                padding-top: 2rem;
                            }

                            &:last-child {
                                padding-top: 2rem;
                                border-bottom: none;
                            }
                        }

                        @include breakpoint(xl) {
                            padding: 1rem 2rem;
                        }
                    }
                }
            }

            // Item Details (collapsible)
            // Only on dashboard
            tr.order-item {

                > td {
                    padding: 0;

                    @include breakpoint(only-md) {

                        &::before {
                            display: none;
                        }
                    }
                }

                tbody {

                    > tr {
                        border: none;
                        border-bottom: 1px solid $border-color;

                        > td {

                            .price {
                                font-family: $f-museoSlab-700;

                                @include breakpoint(xl) {
                                    font-size: 1.7rem;
                                }
                            }

                            @include breakpoint(only-md) {

                                &::before {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                tfoot {

                    > tr {

                        &:first-child {
                            td {
                                padding-top: 2rem;
                            }
                        }

                        &:last-child {
                            td {
                                padding-bottom: 2rem;
                                font-family: $f-museoSlab-700;
                                font-size: 1.8rem;
                                text-align: left;

                                @include breakpoint(only-md) {
                                    font-size: 1.5rem;
                                }
                            }
                        }

                        td {
                            padding: 1rem 2rem;

                            @include breakpoint(only-md) {

                                &.th-like,
                                &.empty-cell {
                                    display: none;
                                }

                                border: none;
                            }
                        }
                    }
                }
            }
        }

        // Order wrapper
        &.order-wrapper {
            background-color: $c-grey-4;
            border: 1px solid $c-grey-3;

            .order-heading {
                background-color: $c-white;
                padding: 2rem 5rem 2rem 2rem;
                 &:hover {
                     cursor: pointer;
                 }

                &[data-role="title"] {
                    position: relative;

                    &[aria-selected="true"] {
                        [class^='icon'] {
                            transform: translateY(-50%) scaleY(1);
                        }
                    }

                    [class^='icon'] {
                        position: absolute;
                        right: 2rem;
                        top: 50%;
                        transform: translateY(-50%) scaleY(-1);
                        transition: transform .3s ease-in-out;

                        @include breakpoint(md) {
                            right: 3rem;
                        }
                    }
                }

                .order-date {
                    margin-bottom: 1.2rem;
                    font-size: 1.8rem;
                    font-family: $f-avenir-medium;
                    line-height: 1.2;
                }

                .amount {
                    display: block;
                    border-left: 1px solid $c-new-grey-5;
                    padding-left: 1rem;
                    margin-left: 1rem;

                    @include breakpoint(md) {
                        display: inline;
                    }
                }

                strong {
                    font-family: $f-avenir-black;
                    font-weight: bold;
                }
            }

            .order-content {
                border-top: 1px solid $c-grey-3;
                padding-left: 18px;
                padding-right: 18px;
            }

            // Order table
            table {

                .table-caption {
                    @include visually-hidden;
                }

                th {
                    font-family: $f-avenir-black;
                    padding: 15px 24px 12px 0;
                }

                tr {
                    td {
                        padding: 12px 24px 12px 0;
                    }
                }

                td.seller {
                    color: $c-red;

                    a {
                        color: $c-red;
                    }
                    @include breakpoint(md) {
                        width: 25%;
                    }
                }

                td.reference {
                    @include breakpoint(md) {
                        width: 15%;
                    }
                }

                td.total {
                    @include breakpoint(md) {
                        width: 10%;
                    }
                }

                td.status  {
                    @include breakpoint(md) {
                        width: 30%;
                    }

                    &.canceled,
                    &.in-progress,
                    &.completed {
                        color: $c-base;
                    }
                }

                td.actions {
                    @include breakpoint(md) {
                        width: 20%;
                    }
                    a {
                        font-size: 1.3rem;
                        padding: 11px 18px;
                    }
                }
            }
        }
    }
}

// Sales history page

.sales-order-history {

    .actions-toolbar {
        display: none;
    }
}
