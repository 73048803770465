// ==============================================
// UI - Overlay
// ==============================================

.mt-c-overlay,
.overlay {
    position: fixed;
    z-index: 30;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($c-black, 0.3);

    // Panel
    &.panel-is-open {
        z-index: 12;
        pointer-events: auto;
    }
}

.mt-c-overlay {
    z-index: 2;
    opacity: 0;
    pointer-events: none;

    &[data-show="true"] {
        opacity: 1;
        pointer-events: initial;
    }

    @include breakpoint(only-xl) {
        top: 0;
        @include transition(ease-in-out 0.5s);
    }
}

body {
    overflow-x: hidden;
    overflow-y: initial;
    &[data-hidden="true"] {
        overflow: hidden;
        padding-right: 15px;

        @include breakpoint(only-xl) {
            padding-right: 0;
        }
    }
}
