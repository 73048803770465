// ==============================================
// 404
// ==============================================

.cms-no-route {

    // Page title
    .page-title {
        @extend .title-1;
        margin-top: 3rem;
        margin-bottom: 2rem;
        text-align: center;
        letter-spacing: 6px;

        @include breakpoint(lg) {
            margin-top: 5rem;
            margin-bottom: 4rem;
        }
    }

    // Paragraphe
    .subtitle404 {
        margin-bottom: 4rem;
        font-size: 2rem;
        text-align: center;

        @include breakpoint(lg) {
            margin-bottom: 6rem;
            font-size: 2.4rem;
        }
    }

    // Action
    .buttons404 {
        margin-bottom: 5rem;
        text-align: center;

        @include breakpoint(lg) {
            margin-bottom: 10rem;
        }

        .button {
            @extend .btn;

            + .button {
                margin-left: 2rem;
                @extend .btn--bordered;
            }
        }
    }
}
