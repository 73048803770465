// ==============================================
// LAYOUT - Footer
// ==============================================
@import "../common/back-to-top";

.page-footer {

    @include breakpoint(only-md) {

        .container {
            padding: 0 1.5rem;
        }
    }

    a {
        transition: color 0.2s;

        &:hover {
            color: $c-grey-medium;
            transition: color 0.2s;
        }
    }

    .title-3 {
        margin-bottom: 1.5rem;
        font-size: 1.6rem;
        font-family: $f-avenir-medium;
        text-transform: none;

        @include breakpoint(md) {
            margin-bottom: 1.5rem;
        }
    }
}

//
// Top - Reassurance
// ----------------------------------------------

.footer-top {
    padding: 2rem 0;

    @include breakpoint(md) {
        padding: 3rem 0;
    }
}

.footer-reassurance {

    .reassurance {
        display: flex;
        justify-content: space-between;

        @include breakpoint(only-md) {
            flex-direction: column;

            li + li {
                margin-top: 2rem;
            }
        }

        > li {
            font-family: $f-avenir-medium;

            &:last-child {
                .icon-phone {
                    @extend %icomoon;
                    font-size: 3.2rem;

                    &::before {
                        content: "\e929";
                    }
                }
            }
        }
    }

}

.reassurance__item {
    padding: 0 0.6rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    @include breakpoint(lg) {
        flex-direction: row;
        text-align: left;
    }
    @include breakpoint(only-md) {
        flex-direction: row;
        text-align: left;
        padding: 0;
    }

    // Icon
    .reassurance__icon {
        display: block;
        position: relative;
        min-width: 6.4rem;
        height: 6rem;
        margin-bottom: 1rem;
        font-size: 3.4rem;
        background-image: url('../images/icon/patate-grey.svg');
        background-size: 100% 100%;

        > span {
            @include absolute-center;
        }

        @include breakpoint(lg) {
            margin-bottom: 0;
            margin-right: 1rem;
        }
        @include breakpoint(only-md) {
            min-width: 5rem;
            height: 4.7rem;
            margin-bottom: 0;
            margin-right: 1.5rem;
            font-size: 2rem;
        }
    }

    // Txt
    .reassurance__txt {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: $c-new-red;

        > span {
            display: block;
            margin-bottom: 1rem;
            color: black;

            @include breakpoint(only-md) {
                margin-bottom: 0.5rem;
            }
        }
    }
}

//
// Middle
// ----------------------------------------------

$footer-middle-border-color: $c-grey-medium;
$social-link-size: 2.8rem;

.footer-middle {
    background: $c-peach;
}

.footer-middle-section {
    padding: 3rem 0;

    &-logo {
        padding-top: 80px;
        background-image: url('../images/logo/logo.svg');
        background-size: 120px 57px;
        background-repeat: no-repeat;

        @include breakpoint(only-lg) {
            text-align: center;
            background-position: top center;
        }
    }

    &:first-child {
        border-bottom: 1px solid #EDDFDA;
    }

    @include breakpoint(lg) {
        &:last-child {
            display: flex;
        }
    }
}


// Newsletter
.footer-newsletter {
    position: relative;


    .newsletter__title {
        padding-right: 2rem;
        display: flex;
        align-items: center;

        strong {
            display: block;
            font-family: $f-halohandletter;
            font-size: 4rem;
            font-weight: normal;
            color: $c-red;
            transform: rotate(-2deg);
            letter-spacing: 0;
        }

        span {
            font-size: 1.3rem;
        }

        &_icon {
            display: block;
            position: relative;
            min-width: 4.5rem;
            height: 4.2rem;
            margin-right: 1rem;
            background-image: url('../images/icon/patate-red.svg');
            background-size: 100% 100%;

            [class^="icon-newsletter"] {
                position: absolute;
                font-size: 2.4rem;
                color: white;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        @include breakpoint(only-md) {
            display: block;
            width: 100%;
            border-bottom: 1px solid #EDDFDA;
            padding-bottom: 1.3rem;
            margin-bottom: 1.5rem;
            padding-right: 0;

            &_icon {
                position: absolute;
                top: 1rem;
                left: 0;
            }
            strong {
                font-size: 3.6rem;
                margin-bottom: 0.7rem;
            }
        }
    }

    $input-height: 40px;

    .block.newsletter {
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(only-md) {
            padding-left: 5.5rem;
            flex-direction: column;

            .content {
                width: 100%;
            }
        }
    }

    // Form
    form.form.subscribe {
        position: relative;
        display: block;
        max-width: 315px;

        @include breakpoint(only-md) {
            max-width: 100%;
        }
    }

    .field.newsletter {
        width: 100%;

        @include breakpoint(only-md) {

        }

        > .label {
            @include visually-hidden;
        }
    }

    .newsletter__baseline {
        width: 368px;
        padding: 0 3rem;
        margin-bottom: 0;
        border-left: 1px solid #EDDFDA;
        font-family: $f-avenir-medium;

        @include breakpoint(only-md) {
            font-size: 1.6rem;
            width: 100%;
            padding: 0;
            margin-bottom: 2rem;
            border-left: 0;
        }
    }

    .actions {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
    }

    // Input
    input[type="email"].newsletter-input {
        height: $input-height;
        padding: 0 6.5rem 0 1.5rem;
        background-color: white;
        line-height: $input-height;
        border: none;
        @include fs(14px, 19px);
        border-radius: 100px;

        &::placeholder {
            color: $c-new-grey-2;
        }

        &:-webkit-autofill {
            // @use !important due to style for remove other border on autofill
            -webkit-box-shadow: 0 0 0 1000px $c-grey-light inset;
        }
    }

    // Submit
    .action.subscribe {
        width: 5rem;
        line-height: $input-height;
        padding: 0;
        text-align: center;
        border-radius: 0 100px 100px 0;
        font-size: 1.4rem;
        border: none;
    }

    // Specific de styles
    html[lang="de"] &,
    html[lang="es"] & {
        .action.subscribe {
            width: auto;
            min-width: 50px;
            padding: 0 10px;
        }
    }
}


// Social
.footer-social {
    .mt-ratingbadge {
        width: 0;
        height: 0;
        overflow: hidden;
        position: fixed;
        bottom: 0;
        left: 0;

        @include breakpoint(md) {
            width: 16.5rem;
            height: 5.4rem;
        }

        > div {
            width: 100% !important;
            height: 100% !important;
            border: none !important;

            > iframe {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}

.social-container {

    @include breakpoint(only-lg) {
        margin-bottom: 2rem;
        text-align: center;
    }

    .title-3 {
        display: none;
    }
}

.social-links {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.6rem;

    @include breakpoint(only-md) {
        justify-content: center;
    }

    > li {
        margin: 0 0.6rem 1rem;

        &.twitter,
        &.linkedin {
            display: none;
        }
    }
}

.social-links__item {
    display: block;
    width: 3.7rem;
    height: 3.5rem;
    font-size: 3.5rem;
}

// Links
.footer-links {
    text-align: left;

    > br {
        display: none;
    }

    @include breakpoint(md) {
        display: flex;
    }

    .title-3 {
        padding: 2rem 2rem 2rem 0;

        @include breakpoint(md) {
            padding: 0;
        }
        @include breakpoint(only-md) {
            position: relative;
            margin-bottom: 0;

            &::after {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                font-family: $f-icons;
                content: "\e94e";
            }
            &[data-visible="true"]::after {
                content: "\e949";
            }
        }
    }

    .links-section {
        border-bottom: 1px solid #EDDFDA;

        @include breakpoint(only-md) {
            margin-left: 0;
        }
        @include breakpoint(only-lg) {
            &:first-child {
                margin-left: 0;
            }
        }
        @include breakpoint(md) {
            margin-left: 3rem;
            border-bottom: 0;
        }
    }

    .links {
        display: none;

        @include breakpoint(md) {
            display: block;
        }

        > li + li {
            margin-top: 2rem;

            @include breakpoint(md) {
                margin-top: 1.5rem;
            }
        }

        @include breakpoint(only-md) {
            li:last-child {
                margin-bottom: 2rem;
            }
        }
    }
}

// Call
.footer-call {
    margin-bottom: 2rem;
    min-width: 190px;

    .footer-call__section {
        &:first-child {
            margin-bottom: 1.5rem;
        }
    }

    .hours {
        line-height: 2rem;
    }

    .title-3 {
        display: none;
    }

    .call_img {
        width: 19rem;
        height: 2.3rem;
    }
}

//
// Bottom
// ----------------------------------------------

.footer-bottom {
    padding: 0.6rem 0;
    background-color: $c-red;
    color: $c-white;

    @include breakpoint(only-md) {
        padding: 1.3rem 0;
    }
}

.footer-infos {
    text-align: left;

    .logos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    img {
        max-height: 20px;
        width: auto;
        margin: 5px 8px 5px 0;
    }
    @include breakpoint(only-md) {
        margin-top: 2rem;
    }

    @include breakpoint(lg) {
        margin-left: 3rem;
    }

    [class^="infos-"] {
        margin-bottom: 3rem;

        @include breakpoint(only-md) {
            &.infos-payment {
                margin-bottom: 3rem;
            }
            &.infos-shipping {
                margin-bottom: 1rem;
            }
        }
    }
}

.footer-baseline {
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: center;
    display: flex;
    justify-content: space-between;

    a {
        color: $c-white;

        &:not(:first-child)::before {
            display: inline-block;
            margin: 0 5px;
            content: '-';
        }

        &:hover,
        &:active {
            &::before {
                color: $c-white;
            }
        }
    }

    @include breakpoint(only-md) {
        flex-direction: column;
    }
}
