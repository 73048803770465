// ==============================================
//  WYSIWYG blocks (User Content Manager)
// ==============================================

.cms-page-view:not(.cms-styleguide):not([class*="cms-inspirations-"]) {

    //
    //  Title wrapper
    //  _____________________________________________

    .page-title-wrapper {
        margin-bottom: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .page-title {
            @extend .title-1;
            color: $c-red;
            text-align: center;
            text-transform: uppercase;
        }

        @include breakpoint(only-md) {
            flex-direction: column;
            align-items: flex-start;
            padding-top: 10px;
        }
    }

    &.cms-politique-de-confidentialite {

        .page-title-wrapper {
            display: none;
        }
    }

    // Breadcrumb
    .breadcrumbs {
        margin: 3.5rem auto;
    }

    .ugc {
        max-width: 120rem;
        margin: 0 auto;

        h1, h2, h3, h4, h5, h6 {
            @extend .title-2;
        }

        h1 {
            margin: 0 0 3rem;
            font-size: 2rem;
            color: $c-red;
            text-align: center;

            &.entry-title {
                color: $c-base;
                text-align: left;
            }

            @include breakpoint(md) {
                font-size: 2.4rem;
            }

            @include breakpoint(lg) {
                font-size: 3rem;
            }
        }

        h2 {
            margin: 0 0 3rem;
            line-height: 1;
            text-align: center;
        }

        h3 {
            margin: 0 0 3rem;
            font-size: 1.4rem;
            line-height: 1;
        }

        p {
            margin: 0 0 3rem;
            line-height: 1.5;
            text-align: justify;

            a {
                color: $c-pink;
                transition: color .3s;

                &:hover {
                    color: $c-base;
                }
            }
        }

        a {
            color: $c-pink;
            transition: color .3s;

            &:hover {
                color: $c-base;
            }
        }


        ul {
            padding-left: 1rem;
            margin: 0 0 2rem;
            line-height: 1.5;

            li {
                font-size: 1.4rem;
            }
        }

        h1, h2, h3, h4, h5, h6, p, ul {

            & + h1, & + h2 {
                margin-bottom: 3rem;
            }

            & + h3, & + h4, & + h5, & + h6 {
                margin-bottom: 3rem;
            }

            & + p,  & + ul {
                margin-top: 0;
                margin-bottom: 3rem;
            }

            img {
                margin-bottom: 0;
            }

            strong {
                font-family: $f-avenir-black;
            }
        }

        table {

            tr {

                td {
                    vertical-align: middle;
                    padding: 1rem 1.5rem;
                    font-size: 1.4rem;
                    line-height: 1.5;
                }
            }
        }

        .button,
        .pink-button {
            @extend .btn;
        }
    }
}

// CGV
.cms-page-view:not(.cms-styleguide) .ugc,
.checkout-agreements-item-content {
    .col2-left-layout {

        @include breakpoint(lg) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        // Sidebar
        .col-left.sidebar {
            margin: 3rem 0;

            @include breakpoint(lg) {
                width: 25rem;
                margin: 0;
            }

            // Nav
            ul.fast-nav {
                padding: 2rem;
                margin: 0;
                background-color: $c-grey-light;
                list-style: none;
                font-family: $f-museo-500;

                > li {
                    padding: 0;
                    margin: 0;

                    &::before {
                        display: none;
                    }

                    > a {
                        display: block;
                        padding: .5rem 0;
                        font-size: 1.5rem;
                        text-decoration: none;
                        transition: color .4s;

                        &:hover {
                            color: $c-pink;
                        }
                    }
                }
            }
        }

        // Main
        .col-main {

            @include breakpoint(lg) {
                width: calc(100% - 35rem);
            }
        }
    }
}

.checkout-agreements-item-content {

    .col2-left-layout {

        // Main
        .col-main {

            @include breakpoint(lg) {
                width: calc(100% - 27rem);
            }
        }
    }
}
