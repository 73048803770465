.mt-m-CategoriesPush {
    position: relative;
    z-index: 1;
    margin: {
        top: 60px;
        bottom: 60px;
    }

    &-head {
        width: 100%;
        max-width: 720px;
        margin: 0 auto 30px;

        &-title {
            font-family: $f-museo-700;
            @include fs(35px, 42px);
            text-align: center;
            margin: 0 auto 10px;
            max-width: 100%;
            width: 680px;
            color: $c-grey-dark;
        }

        &-text {
            font-family: $f-avenir;
            @include fs(16px, 20px);
            text-align: center;
            color: $c-grey-1;
        }
    }

    &-content {
        & > div {
            @include fs0();
            display: flex;
            justify-content: space-between;
            position: relative;
        }

        &-item {
            $item: &;
            width: 33.33%;
            position: relative;

            &-heading {
                background-color: $c-white;
                width: calc(100% - 180px);
                min-width: calc(100% - 70px);
                @include center-abs-x();
                bottom: 40px;
                text-align: center;
                padding: 10px 20px 15px;

                &-suptitle {
                    font-family: $f-halohandletter;
                    @include fs(34px, 43px);
                    color: $c-red;
                }
                &-title {
                    font-family: $f-museo-500;
                    @include fs(16px, 20px);
                    color: $c-grey-dark;
                    display: block;
                    position: relative;
                    top: -5px;
                }
            }

            &-img {
                position: relative;
                overflow: hidden;
                img {
                    @include transition(ease-in 0.25s);
                    @include scale(1);
                }
            }

            &:hover {
                #{$item}-img {
                    img {
                        @include scale(1.2);
                    }
                }
            }
        }

        .hotspot {
            cursor: pointer;
            display: block;
            max-width: 20px;
            max-height: 20px;
            min-width: 20px;
            min-height: 20px;
            background-color: $c-white;
            box-shadow: 0 15px 20px rgba($c-black, 0.4);
            border-radius: 100%;
            @include fs(10px, 22px);
            @include transition(ease 0.2s);
            color: $c-new-red;
            z-index: 99999;
            &:before,
            &:after {
                content: '';
                display: block;
                @include center-abs-xy();
            }
            &:before {
                content: '\e94e';
                @include icomoon;
            }
            &:after {
                width: 34px;
                height: 34px;
                border: 1px dashed $c-white;
                border-radius: 100%;
            }
            &:hover {
                background: transparent;
                box-shadow: none;
                &:before {
                    width: 14px;
                    height: 14px;
                    border-radius: 100%;
                    @include fs0();
                    background-color: $c-new-red;
                }
                &:after {
                    background-color: rgba($c-white, 0.2);
                }
            }

            .product-item-info .product-item-details {
                padding-top: 0;
            }

            .product-info {
                background: {
                    image: none;
                    color: $c-white;
                };
                padding: 0;
                margin: 0;
                border-radius: 0;
                border: none;


                .left-detail {
                    display: flex;
                    align-items: stretch;
                    justify-content: space-between;
                    .product-image {
                        width: 80px;
                        height: 80px;
                        margin: {
                            top: 15px;
                            bottom: 15px;
                            left: 15px;
                            right: 10px;
                        }

                        & > a {
                            display: block;
                            overflow: hidden;
                            position: relative;
                            width: 80px;
                            height: 80px;
                            img {
                                @include center-abs-xy();
                                height: 100%;
                                width: auto;
                                max-width: initial;
                            }
                        }
                    }
                    .product-details {
                        margin: {
                            top: 15px;
                            bottom: 15px;
                        }
                        padding-right: 20px;
                        width: calc(100% - 120px); // 120px = Picture and actions width
                    }
                    .product-name {
                        font-family: $f-avenir-medium;
                        @include fs(14px, 16px);
                        color: $c-black;
                        margin-bottom: 5px;
                        display: block;
                    }
                    .price-box .price {
                        font-family: $f-avenir-heavy;
                        color: $c-black;
                        padding: 0;
                    }
                }

                .tocart,
                .slider-product-link
                {
                    @include fs0();
                    background-color: $c-pink-light;
                    border-color: $c-pink-light;
                    padding: 0;
                    width: 40px;
                    height: 100%;
                    border-radius: 0;
                    @include transition(background ease 0.2s);
                    &:before {
                        content: '\e943';
                        @include icomoon;
                        @include fs(14px, 16px);
                        display: block;
                        @include rotate(90deg);
                        color: $c-black;
                    }

                    &:hover {
                        background-color: $c-pink-light-2;
                        border-color: $c-pink-light-2;
                    }
                }

                .pro-detail-div {
                    & > div:not(.left-detail):not(.product-image):not(.product-details):not(.actions-primary),
                    .left-detail > div:not(.product-image):not(.product-details):not(.actions-primary) {
                        display: block;
                        width: 100%;
                        & > a {
                            display: block;
                            position: relative;
                            padding: 15px;
                            margin: 0;
                            &:before {
                                content: '\e943';
                                @include icomoon;
                                @include fs(14px, 16px);
                                display: block;
                                @include rotate(90deg);
                                color: $c-black;
                                position: absolute;
                                right: 12px;
                                z-index: 2;
                            }
                            &:after {
                                content: '';
                                @include fs0();
                                background-color: $c-pink-light;
                                border-color: $c-pink-light;
                                padding: 0;
                                width: 40px;
                                height: 100%;
                                border-radius: 0;
                                @include transition(background ease 0.2s);
                                position: absolute;
                                right: 0;
                                top: 0;
                            }

                            &:hover {
                                &:after {
                                    background-color: $c-pink-light-2;
                                    border-color: $c-pink-light-2;
                                }
                            }
                        }
                    }
                }
            }

            .hotspot-inspiration {
                padding: 15px;

                h4 {
                    font-family: $f-museo-700;
                    @include fs(20px, 22px);
                    margin: 0;
                    color: $c-black;
                }

                .hotspot-inspiration-text-1 {
                    margin-bottom: 10px;
                    display: block;
                }
                .hotspot-inspiration-text-2 {
                    margin-bottom: 0;
                    display: block;
                }
            }
        }
    }

    @include breakpoint(only-lg) {
        margin: {
            top: 45px;
            bottom: 40px;
        }

        &-head {
            margin-bottom: 30px;
            &-title {
                @include fs(25px, 31px);
                margin-bottom: 5px;
            }
        }

        &-content {
            & > div {
                display: block;
            }
            &-item {
                $item: &;
                width: 100%;

                &-img {
                    width: 100%;
                    height: 0;
                    padding-top: calc(#{(260/375)*100%});

                    img {
                        width: calc(100% + 20px);
                        height: auto;
                        @include center-abs-xy();
                    }
                }

                &-heading {
                    bottom: 20px;
                    padding: 5px 20px;
                    &-suptitle {
                        @include fs(30px, 37px);
                    }
                }

                &:hover {
                    #{$item}-img {
                        img {
                            @include transform(translate(-50%, -50%) scale(1.2));
                        }
                    }
                }
            }

            .hotspot {
                display: none;
            }
        }
    }
}
