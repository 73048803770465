// ==============================================
// Target 2 sell
// ==============================================

.box-collateral,
.crosssell {

    .checkout-cart-index & {
        clear: both;
    }

    @extend %product-page-block-content;

    @include breakpoint(only-md) {
        margin-top: 3rem;
    }

    .block-title {
        @extend %product-page-block-title;
    }

    #upsell-product-table,
    #crosssell-products-list {

        li {
            width: 100%;
            background-color: $c-white;

            > .product-image {
                display: block;
                width: 100%;
                margin-bottom: 2rem;

                > img {
                    display: block;
                }
            }

            > .product-name {
                height: auto;
                width: 100%;
                min-height: 3.5rem;
                margin-bottom: 1rem;
                font-size: 1.3rem;

                @include breakpoint(xs) {
                    font-size: 1.4rem;
                }
            }

            > .product-bottom {
                width: 100%;
                text-align: center;

                .price-box {
                    font-weight: bold;
                    text-align: left;
                    @extend %price-box--small;

                    .price {
                        font-size: 1.3rem !important;
                        @include breakpoint(md) {
                            font-size: 1.5rem !important;
                        }
                    }
                }

                .product-see {
                    display: none;
                }
            }
        }
    }
}
.similar_product_button {
    text-indent: -9999px;

    &::before {
        @extend %icomoon;
        content: "\e96d";
        display: block;
    }
}

.product {
    .similar_product_button  {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 6px;
        font-size: 13px;
        text-indent: inherit;
        white-space: nowrap;
        font-weight: bold;

        @include breakpoint(md) {
            margin-top: 15px;
        }

        &::before {
            font-size: 19px;
        }
    }
}
