// ==============================================
// VARIABLES - Colors
// ==============================================

//
//  Base colors
//  _____________________________________________

$c-black:           #000000;
$c-white:           #ffffff;
$c-transparent:     transparent;

//
//  Project colors
//  _____________________________________________

$c-grey:            #4c4c4c;
$c-grey-1:          #5E5E5E;
$c-grey-2:          #777575;
$c-grey-3:          #CACACA;
$c-grey-4:          #f8f8f8;
$c-grey-5:          #f0f0f0;
$c-grey-medium:     #cdcdcd;
$c-grey-dark:       #212121;
$c-grey-dark2:      #202122;
$c-grey-light:      #f2f2f2;
$c-grey-light2:     #979797;

$c-new-grey:        $c-grey-5;
$c-new-grey-2:      $c-grey-light2;
$c-new-grey-3:      #f7f7f7;
$c-new-grey-4:      #E4E4E4;
$c-new-grey-5:      #DEDEDE;

$c-base:            $c-grey-dark;

$c-red:             #b60d28;
$c-new-red:         #B70D26;
$c-red-dark:        #870016;
$c-pink:            #f67274;
$c-pink-light3:     #FADEDF;
$c-pink-light2:     #E0AFB6;
$c-pink-light:      #FDEDE8;
$c-pink-light-2:    #FCE1D9;
$c-new-pink:        #E55860;
$c-new-pink-2:      #C54c53;
$c-orange:          #ff7f62;
$c-green:           #71c3b9;
$c-green-2:         #59B057;
$c-lavender:        #838fd0;
$c-salmon:          #f9f0e7;
$c-peach:           #FDEDE8;

$c-pink-fid:        #E55860;
$c-blue-bg-fid:     #CAECEE;
$c-blue-fid:        #2AB6BC;
$c-blue-fid2:       #b0e4e6;
$c-blue-fid3:       #eaf8f8;
$c-orange-bg-fid:   #FAD1C5;
$c-orange-fid:      #EB4A19;
$c-red-bg-fid:      #F0CFD4;
$c-red-fid:         $c-red;
$c-error-fid:       #E55860;

//
//  Notifications
//  _____________________________________________

$c-success:         #2daf9f;
$c-info:            #acdae2;
$c-warning:         #f78b0b;
$c-error:           #ff0000;

//
//  Availability
//  _____________________________________________

$c-available:       $c-green;
$c-available-2:     $c-green-2;
$c-unavailable:     $c-error;
$c-limited-stock:   $c-orange;

//
//  Divers
//  _____________________________________________

$border-color: #ededed;
$overlay: rgba($c-black, 0.4);
$filters-box-shadow: 0 20px 150px #bbb;
