// ==============================================
//  Hero
// ==============================================

// block
.block-hero {
    background-image: url('../images/illustrations/composition-md.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem 0;
    margin-right: -2rem;
    margin-left: -2rem;
    text-align: center;

    @include breakpoint(md) {
        background-image: url('../images/illustrations/composition-lg.jpg');
        padding: 3rem 0;
    }

    @include breakpoint(lg) {
        background-image: url('../images/illustrations/composition-xl.jpg');
        padding: 6rem 0;
    }
}

// content
.block-hero-content {
    width: calc(100% - 4rem);
    max-width: 84rem;
    padding: 2rem;
    margin: 0 auto;
    background-color: $c-white;

    @include breakpoint(md) {
        padding: 3rem 2rem;
    }

    // Title
    .title-1 {
        margin-bottom: 2rem;
        line-height: 1.2;
        color: $c-base;
        text-align: center;
        text-transform: inherit;
    }
}
