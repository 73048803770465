// ==============================================
// CUSTOMER ACCOUNT
// ==============================================

//
//  Configuration
//  _____________________________________________

$account-sidebar-width:             30%;
$account-sidebar-max-width:         33rem;
$account-sidebar-gutter:            2%;

.account {

    @include breakpoint(only-lg) {

        .columns {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    .page-main {

        @include breakpoint(md) {
            margin-top: 0;
        }

        @include breakpoint(lg) {
            margin-top: 4rem;
        }

        @include breakpoint(xl) {
            margin-top: 5rem;
        }
    }

    //
    //  Main columns
    //  _____________________________________________

    @include breakpoint(lg) {
        &.page-layout-2columns-left {
            .sidebar-main,
            .sidebar-additional {
                width: $account-sidebar-width;
                max-width: $account-sidebar-max-width;
                padding-right: 0;
            }
        }

        &.page-layout-2columns-left {
            .column.main {
                width: calc(100% - #{$account-sidebar-width} - #{$account-sidebar-gutter});
            }
        }
    }

    @include breakpoint(xl) {
        &.page-layout-2columns-left {
            .column.main {
                width: calc(100% - #{$account-sidebar-max-width} - 7rem);
            }
        }
    }

    // Page title
    .page-title-wrapper {
        margin-bottom: 3rem;
        @extend .title-1;
        color: $c-base;
        text-transform: inherit;

        @include breakpoint(only-lg) {
            text-align: center;
        }
    }

    &:not(.sales-order-view):not(.sales-order-shipment):not(.sales-order-print):not(.sales-order-creditmemo):not(.marketplace-shop-view):not(.marketplace-order-view) {

        .page-title-wrapper {

            @include breakpoint(lg) {
                @include visually-hidden;
            }
        }
    }

    // Page title like
    &.sales-order-history,
    &.mt_goldeneyes-loyalty-index {

        // Page title
        .page-title-wrapper-like {
            @extend .title-1;
            color: $c-base;
            text-transform: inherit;

            @include breakpoint(lg) {
                padding-bottom: 2rem;
                margin-bottom: 3rem;
                background-image: url('../images/background/sep-h.png');
                background-position: bottom left;
                background-repeat: repeat-x;
            }
        }
    }

    .block-title {
        @extend .title-2;
        max-width: 66rem;
        padding-bottom: 2.8rem;
        margin-bottom: 3rem;
        font-family: $f-museoSlab-700;
        text-transform: inherit;
        background-image: url('../images/background/sep-h.png');
        background-position: bottom left;
        background-repeat: no-repeat;
    }

    .actions-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 3rem;

        .primary {

            .action {

                &.add {
                    @extend .btn;
                }
            }
        }

        .secondary {

            .back {

                &::before {
                    content: "←";
                    margin-right: 5px;
                    font-size: 1.6rem;
                }
            }
        }
    }

    //
    //  Sidebar
    //  _____________________________________________

    .sidebar {

        // Sidebar additional
        &.sidebar-additional {
            margin-top: 20px;

            .block + .block {
                margin-top: 10px;
            }
        }
    }

    //
    //  Dashboard
    //  _____________________________________________

    .column.main {

        .block {
            margin-bottom: 4.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        // Block dashboard
        .block {

            .box-title {
                display: block;
                margin-bottom: 1rem;
                font-family: $f-museoSlab-700;
                font-size: 1.6rem;
            }

            .block-content {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                @include breakpoint(only-md) {
                    flex-direction: column;
                }

                > .box {
                    position: relative;

                    @include breakpoint(md) {
                        width: 48%;
                    }

                    @include breakpoint(xl) {
                        width: 46%;
                    }

                    @include breakpoint(only-md) {
                        + .box {
                            margin-top: 3rem;
                        }
                    }

                    &.box-information {

                        .action.edit {
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }

                    &.box-fidelity {

                        .fidelity-points {
                            font-family: $f-avenir-black;
                            color: $c-pink;
                        }

                        .box-actions {
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }
                }

                .box-content + .box-actions {
                    margin-top: 15px;

                }

                .box-actions {

                    a {
                        font-family: $f-museoSlab-700;
                        text-decoration: underline;
                    }
                }
            }

            // block title
            &.block-dashboard-orders {

                .block-title {
                    display: flex;
                    justify-content: space-between;
                    max-width: 100%;

                    .action.view {
                        font-size: 1.4rem;
                        text-decoration: underline;

                        &:hover,
                        &:focus,
                        &:active {
                            color: $c-pink;
                        }
                    }
                }

                .orders-history {
                    width: 100%;
                }
            }
        }
    }

    //
    //  Table
    //  _____________________________________________

    @extend %order-table;

    // Fix for only addresses list on order detail
    .block-addresses-list {
        .block-content {
            flex-direction: column;
        }
    }

    //
    //  Pager
    //  _____________________________________________

    .pager {
        display: flex;
        align-items: center;
        justify-content: space-between;

        // Amount number
        .toolbar-amount {
            margin-bottom: 0;
        }

        .limiter {
            display: flex;
            align-items: center;

            .limiter-label {
                padding: 10px;
            }

            // Limiter select
            .limiter-options {
                margin-right: 10px;
                width: 70px;
            }
        }
    }
}

//
//  Account top
//  _____________________________________________

.mt-customer-account-dashboard-top {

    @include breakpoint(only-sm) {
        display: none;
    }

    margin-bottom: 3rem;

    @include breakpoint(lg) {
        display: flex;
        margin-bottom: 4rem;
    }

    // Welcome
    > .welcome-message {

        @include breakpoint(lg) {
            padding-right: 5%;
        }

        @include breakpoint(xl) {
            flex: 1;
            padding-right: 10%;
        }

        .welcome-title {
            margin-bottom: 2rem;
            font-family: $f-museoSlab-700;
            font-size: 2.6rem;

            @include breakpoint(md) {
                font-size: 3rem;
            }

            @include breakpoint(lg) {
                margin-bottom: 3rem;
            }

            @include breakpoint(xl) {
                margin-bottom: 7rem;
                font-size: 3.5rem;
            }
        }

        .welcome-baseline {
            margin-bottom: 2rem;
            font-size: 1.6rem;

            @include breakpoint(md) {
                font-size: 1.8rem;
            }
        }
    }

    // Question
    > .block {

        @include breakpoint(only-lg) {
            display: none;
        }

        @include breakpoint(lg) {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 2rem;
            background-color: $c-grey-4;
            text-align: center;

            &::before {
                position: absolute;
                z-index: -1;
                top: -1.5rem;
                right: 33%;
                bottom: 20%;
                left: -1.5rem;
                background-color: $c-green;
                content:'';
            }

            > .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 2rem;
                @include circle(5.2rem);
                background-color: $c-white;
                font-size: 3rem;
            }

            > .block-title {
                padding-bottom: 0;
                margin-bottom: 3rem;
                font-size: 1.6rem;
                background: none;

                h3 {
                    font-family: $f-museoSlab-700;
                }
            }

            > .content {

                a {
                    font-family: $f-avenir-black;
                    color: $c-pink;
                    text-decoration: underline;
                }

                strong {
                    font-family: $f-avenir-black;
                }

                .contact-faq {
                    margin-bottom: 2rem;

                    strong {
                        display: block;
                        width: 20rem;
                        margin: .5rem auto;
                    }
                }
            }
        }

        @include breakpoint(xl) {
            width: 374px;
        }
    }
}
