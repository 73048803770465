// ==============================================
// CHECKOUT - Shipping : Mondial Relay
// ==============================================

$map-width:             35rem;

.checkout-index-index {

    .chronorelais-pickup-content {
        position: relative;
        padding: 0 2rem 2rem;
        margin-top: -4px;
        background-color: $c-white;
        border: 1px solid $c-grey-dark2;
        border-top: 1px solid $c-white;


        .chronorelais-content {
            padding: 0;
        }

        .chronorelais-address,
        .chronorelais-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 10px;
            width: 100%;
            padding-left: 0;
            padding-right: 0;

            input,
            select {
                min-width: 10rem;
                padding-left: 1rem;
                padding-right: 1rem;
                height: 4rem;
                border-color: $c-grey-3;
                font-size: 1.4rem;
                line-height: 4rem;
            }
        }

        .chronorelais-map,
        .chronorelais-address {
            border: none;
        }

        .chronorelais-map {
            float: none;
            width: $map-width;
            z-index: 1;
        }

        // Address
        .chronorelais-address {
            padding: 1rem;
            column-gap: 10px;

            p {
                float: none;
                margin-top: 0;
                margin-right: 2rem;

                label {
                    margin-bottom: .5rem;
                    font-size: 1.4rem;
                }

                &#mr-address-code {
                    align-self: flex-start;

                    .chronorelais-pickup-code {
                        display: inline-block;
                        border: none;
                        line-height: 4rem;

                        label {
                            padding-left: 3rem;
                            border: none;

                            &.active {
                                background: none;
                                color: $c-base;
                            }
                        }
                    }
                }
            }

            .chronorelais-next span {
                background-color: $c-pink;
            }
        }
    }

    // Stores
    .chronorelais-list {
        width: 100%;

        // Error
        > p {
            padding: 1rem;
            border-color: $c-error;
            color: $c-error;
        }

        // List
        .col-list {
            width: calc(100% - #{$map-width} - 1rem);
            padding-right: 2rem;
            border: none;
            float: none;
        }

        li {
            width: 100%;
            padding: 1.6rem;
            border-color: $c-grey-3;

            &.active {
                background-color: $c-grey-light;
            }

            label {
                width: 100%;
                padding: 0 0 0 3rem !important;
                text-transform: lowercase;

                strong {
                    display: block;
                    margin-bottom: .5rem;
                    font-family: $f-avenir-black;
                    font-size: 1.6rem;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }

                a {
                    display: inline-block;
                    margin-top: 1rem;
                    background-color: $c-pink;
                }
            }
        }

        .chronorelais-telephone {
            clear: both;
            padding-top: 2rem;

            .mage-error {
                margin-top: .6rem;
                font-size:  1.2rem;
            }
        }

        .chronorelais-actions {
            position: static;
            margin-top: 2rem;
            text-align: center;

            button {
                float: none;

                &.chronorelais-previous {
                    display: none;
                }

                &.chronorelais-next span {
                    @extend .btn;
                    background-color: $c-pink;
                }
            }
        }
    }

    // Message
    .mgx-message {
        top: auto;
        bottom: -4rem;
        padding: 2rem;
        background: none;
        background-color: $c-white;
        border: 1px solid $c-error;
        color: $c-error;
        z-index: 2;

        .warning {
            background: none;
            padding: 0;
        }

        .colissimo-info {
            line-height: 1.3;
            color: $c-base;

            h2 {
                padding: 0;
                margin: 0 0 1rem 0;
                background: none;
                font-family: $f-avenir-heavy;
                font-size: 1.6rem;
                color: $c-black;
            }

            .colissimo-previous span {
                @extend .btn;
            }
        }
    }

    .header-top {
        display: none;
    }
}

@media screen and (max-width: 920px) {

    .checkout-index-index {

        .chronorelais-pickup-content {
            .chronorelais-list {
                flex-direction: column;

                > .col-list,
                .chronorelais-map {
                    display: block;
                    width: 100%;
                }

                > .col-list {
                    order: 2;
                    margin-bottom: 0;
                }

                .colissimo-map {
                    order: 1;
                }

                .chronorelais-actions {
                    order: 3;
                    padding-top: 0;
                    margin-bottom: 2rem;
                }
            }

            .chronorelais-address {
                justify-content: flex-start;

                p {
                    margin-bottom: 2rem;
                    margin-right: 0;
                }

                label {
                    display: block;
                }

                input {
                    width: auto;
                }

                .chronorelais-actions {
                    width: 100%;
                    margin: 0;
                    text-align: center;
                }
            }
        }
    }
}

@media screen and (max-width: 320px) {

    .checkout-index-index {

        .chronorelais-pickup-content {
            padding: 0;
            border: none;

            .chronorelais-address {
                margin-bottom: 0;

                p {
                    width: 100%;
                    margin-right: 0;

                    &#mr-address-code {

                        > label {
                            display: block;
                        }
                    }
                }

                input,
                select {
                    width: 100% !important;
                }
            }
        }
    }
}
