// ==============================================
// CHECKOUT - Progress bar
// ==============================================

//
//  Configuration
//  _____________________________________________

$progress-bar-icon-size:           2.2rem;
$progress-bar-text-size:           1.6rem;
$progress-bar-text-size-desktop:   1.8rem;
$progress-bar-text-color:          $c-grey-light2;
$progress-bar-border-color:        $c-new-grey-5;
$progress-bar-color-active:        $c-pink;
$progress-bar-item-max-width:      245px;

.opc-progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 30px;

    // Step
    li {
        display: none;
        position: relative;
        align-items: center;
        justify-content: center;
        gap: 5px;
        flex: 1;
        max-width: $progress-bar-item-max-width;
        padding: 15px 5px;
        border-bottom: 2px solid $progress-bar-border-color;
        color: $progress-bar-text-color;

        @include breakpoint(sm) {
            display: flex;
        }

        @include breakpoint(md) {
            gap: 10px;
        }

        // Link
        & > span {
            color: $progress-bar-text-color;
            font-size: $progress-bar-text-size;
            font-family: $f-museo-700;
            @include breakpoint(md) {
                font-size: $progress-bar-text-size-desktop;
            }
        }

        // Icon
        &::before {
            @extend %icomoon;
            font-size: $progress-bar-icon-size;
        }

        // Cart
        &:nth-child(1)::before {
            content: "\e94c";
        }

        // Shipping icon
        &:nth-child(2)::before {
            content: "\e922";
        }

        // Payments icon
        &:nth-child(3)::before {
            content: "\e94b";
        }

        // Active step
        &._active {
            display: flex;
            color: $progress-bar-color-active;
            border-color: $progress-bar-color-active;
            span {
                color: $progress-bar-color-active;
            }
        }

        // Completed step
        &._complete {
            &:hover {
                cursor: pointer;
            }
        }
    }
}
