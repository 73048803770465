// ==============================================
// SLIDESHOW
// ==============================================

//
// Configuration
//  _____________________________________________

$slick-arrow-width-s: 34px;
$slick-arrow-width-m: 40px;
$slick-arrow-bg-color: rgba($c-white, .7);

$slick-dot-width-s: 6px;
$slick-dot-width-m: 6px;
$slick-dot-bg-color: $c-grey-light;
$slick-dot-bg-color-active: $c-red;

//
//  Common
//  _____________________________________________

// Flex override
.slick-track {
    display: flex !important;

    .slick-slide {
        display: flex !important;
        height: auto;
        float: none;

        > div {
            width: 100%;
        }
    }
}

/* Flowbox */
.flowbox.flowbox-container {
    .slick-track {
        display: block !important;
    }

    .slick-initialized {
        .slick-slide {
            display: block !important;
        }
    }

    .slick-slide {
        float: left;
    }
}

/* Navigation arrows */
.slick-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    @include circle($slick-arrow-width-s);
    margin-top: -20px;
    background-color: $slick-arrow-bg-color;
    outline: none;
    font-size: 0;
    line-height: 0;
    color: $c-base;
    transition: background-color .4s;

    @include breakpoint(md) {
        @include circle($slick-arrow-width-m);
    }

    @include breakpoint(lg) {
        margin-top: -30px;
    }

    &:hover,
    &:focus {
        background-color: $c-white;

        &::before {
            opacity: 1;
        }
    }

    .slick-disabled,
    .slick-disabled {
        &::before {
            opacity: .25;
        }
    }

    // Arrows
    &::before {
        @extend %icomoon;
        font-size: 2rem;

        @include breakpoint(md) {
            font-size: 2.4rem;
        }
    }

    &.slick-prev {
        left: 20px;

        @include breakpoint(md) {
            left: 30px;
        }

        &::before {
            content: "\e927";
            transform: scaleX(-1);
        }
    }

    &.slick-next {
        right: 20px;

        @include breakpoint(md) {
            right: 30px;
        }

        &::before {
            content: "\e927";
        }
    }
}

.block-slider.box-up-sell ,
.block-slider.crosssell ,
.products-upsell ,
.mt-m-ProductsTabs-content-item {

    .slick-slider {
        padding-left: 0;
        padding-right: 0;
        @include breakpoint(only-md) {
            margin-right: -1.5rem;
        }
        @include breakpoint(md) {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    .slick-slide {
        margin-right: .5rem;
        margin-left: .5rem;

        @include breakpoint(lg) {
            margin-right: 1rem;
            margin-left: 1rem;
        }

        @include breakpoint(xl) {
            margin-right: 1.5rem;
            margin-left: 1.5rem;
        }
    }

    .slick-arrow {
        background-color: transparent;

        &:hover,
        &:focus {
            background-color: transparent;

            &::before {
                opacity: 1;
            }
        }

        &.slick-prev {
            left: 0;

            &::before {
                content: "\e930";
                transform: scaleX(-1);
            }
        }

        &.slick-next {
            right: 0;

            &::before {
                content: "\e930";
            }
        }
    }
}

.products-upsell .slick-arrow {

    &.slick-prev {
        left: 0;
    }

    &.slick-next {
        right: 0;
    }
}





// Dots
.slick-dots {
    display: flex;
    justify-content: center;
    margin-top: 5px;

    @include breakpoint(lg) {
        margin-top: 40px;
    }

    > li {
        margin: 0 5px;

        &.slick-active {

            button {
                background-color: $slick-dot-bg-color-active;
            }
        }
    }

    button {
        font-size: 0;
        @include circle($slick-dot-width-s);
        background-color: $slick-dot-bg-color;
        transition: background-color .3s;
        cursor: pointer;

        @include breakpoint(lg) {
            @include circle($slick-dot-width-m);
        }

        &:hover,
        &:active {
            background-color: $slick-dot-bg-color-active;
        }
    }

    .block-quickview-product & {

        > li {

            @include breakpoint(lg) {
                margin: 0 5px;
            }
        }
    }

    .product-info-detailed__content & {

        button {
            @include circle($slick-dot-width-m);
        }
    }
}
