// ==============================================
// Panel : contact
// ==============================================

.mt-panel-container {

    input {
        border-radius: 30px;
        background-color: #f0f0f0;
        &[name="qty"] {
            border-radius: 0;
            background-color: white;
        }
    }

    .block-general-contact {
        padding: 3rem 2rem;

        @include breakpoint(md) {
            padding: 4rem 3rem;
        }

        .block-title {
            margin-bottom: 2rem;

            @include breakpoint(md) {
                margin-bottom: 3rem;
            }
        }
    }

    .block-faq-content {
        margin-bottom: 3rem;

        @include breakpoint(md) {
            margin-bottom: 4rem;
        }
    }

    .block-phone-content {
        margin-top: 3rem;

        img {
            max-width: 200px;
        }
    }

    .block-subtitle {
        margin-bottom: 1.5rem;
        font-size: 1.6rem;
    }

    .btn {
        width: 100%;
    }

    .actions {

        .button-anchor {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 1rem 0;
            transition: color .4s;

            &:hover,
            &:active {
                color: $c-pink;
            }

            > [class*="icon-"] {
                width: 4rem;
                font-size: 3rem;
                text-align: center;

                &.icon-return {

                    &::before {
                        content: "\e931";
                        font-size: 4rem;
                    }
                }
            }

            > .button-anchor-text {
                margin-left: 3.5rem;
                font-family: $f-museo-500;
                font-size: 1.6rem;
            }


            // Icon Forum
            .icon-message {
                font-size: 4rem;
            }
        }
    }
}
