// ==============================================
// LOYALTY
// ==============================================

.table-loyalty {

    th,
    td {
        padding: 1rem;
    }

    th {
        font-family: $f-avenir-black;
    }
}