// ==============================================
// PAGER
// ==============================================

// Configuration
$pager-border-color:    #dbdbdb;
$pager-color-current:   $c-red;
$pager-color-hover:     $c-red;

.pages {

    .label {
        // Hide labels
        @extend .visually-hidden;
    }

    ul.items {
        display: flex;
        align-items: center;

        li.item {
            position: relative;
            z-index: 1;

            &:hover {
                z-index: 2;
            }

            + li.item {
                margin-left: -1px;
            }

            > * {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 4rem;
                height: 4rem;
                border: 1px solid $pager-border-color;
                font-size: 1.3rem;
                transition: color .4s, border-color .4s;
            }

            &.current {
                z-index: 2;

                strong.page {
                    color: $pager-color-current;
                    border-color: $pager-color-current;
                }
            }

            .page {
                font-family: $f-museo-500;
            }

            a.next,
            a.previous,
            a.page {

                &:hover {
                    color: $pager-color-hover;
                    border-color: currentColor;
                }
            }

            a.next,
            a.previous {
                font-size: 1.6rem;

                &:not(.jump) {

                    > span:not(.label) {
                        font-size: 0;

                        &::before {
                            @extend %icomoon;
                            font-size: 2rem;
                            content: "\e927";
                        }
                    }
                }
            }

            a.previous {

                &:not(.jump) {

                    > span:not(.label) {

                        &::before {
                            display: block;
                            transform: scaleX(-1);
                        }
                    }
                }
            }
        }
    }
}
