// ==============================================
// CHECKOUT - Cart
// ==============================================

//
//  Configuration
//  _____________________________________________

$checkout-summary-width:                28%;
$checkout-summary-width-l:              32.5rem;
$checkout-summary-margin:               3rem;
$checkout-summary-margin-l:             4rem;
$checkout-summary-bg-color:             $c-grey-light;
$checkout-desktop-product-visual-width: 10rem;
$checkout-mobile-product-visual-width:  6rem;
$checkout-summary-border:               1px solid $c-grey-3;

//
//  Global cart page
//  _____________________________________________

.checkout-cart-index {
    .page-title {}

    @include breakpoint(only-md) {
        .columns {
            padding-bottom: 0;
        }
    }

    // CS BLOCKS
    .cs-block {

        .container {
            padding: 0;
        }
    }
}

//
//  Layout
//  _____________________________________________

@include breakpoint(only-lg) {
    .cart-container {
        display: flex;
        flex-direction: column;
        > div { order: 3; }
    }

    .form-cart { order: 1; }
    .cart-summary { order: 2; }

    .form-cart,
    .cart-summary {
        margin-bottom: 2rem;
    }
}

@include breakpoint(lg) {
    .cart-container {
        &::after {
            display: block;
            content: "";
            clear: both;
            font-size: 0;
            line-height: 0;
            height: 0;
            overflow: hidden;
        }
    }

    .cart-summary {
        float: right;
        width: $checkout-summary-width;
        max-width: $checkout-summary-width-l;
        margin-left: $checkout-summary-margin;
        position: sticky;
        top: 20px;
        overflow: auto;
        max-height: 86vh;
    }

    .form-cart {
        float: left;
        width: calc(100% - #{$checkout-summary-width} - #{$checkout-summary-margin});
    }
}

@include breakpoint(xl) {
    .cart-summary {
        margin-left: $checkout-summary-margin-l;
    }

    .form-cart {
        width: calc(100% - #{$checkout-summary-width-l} - #{$checkout-summary-margin-l});
    }
}

//
//  Cart
//  _____________________________________________

.cart-container {

    // Summary
    .cart-summary {
        background-color: $checkout-summary-bg-color;

        // Title
        > .summary.title {
            display: block;
            padding: 2rem 2rem 1.6rem;
            @extend .title-1;
            color: $c-grey;
            line-height: 1;
            text-transform: inherit;
            font-size: 2rem;

            @include breakpoint(md) {
                font-size: 2.2rem;
            }
        }

        // Shipping
        > .block-shipping {
            margin-bottom: 1rem;
            @extend %collapsible-full;
        }

        > .free-shipping-info {
            padding: 1rem 2rem;

            li + li {
                margin-top: .5rem;
            }

            strong {
                font-family: $f-avenir-black;
            }
        }

        // Discount
        > .cart-discount {
            padding: 1.5rem 2rem;
            border-top: $checkout-summary-border;

            .loyalty-account {
                .icon-tickets-fid,
                .label {
                    color: $c-pink-fid;
                }

                p {
                    margin-top: 5px;
                    span {
                        color: $c-pink-fid;
                        font-family: $f-avenir-medium;
                    }
                }

                a {
                    text-decoration: underline;
                    font-family: $f-avenir-medium;

                    &.loyalty-account-title {
                        color: $c-pink-fid;
                    }
                }
            }

            > .block.discount {
                margin-top: 1.5rem;

                [data-role="title"] {
                    position: relative;
                    cursor: pointer;
                    font-size: 1.4rem;
                    line-height: 1.4;
                    text-decoration: none;

                    strong {
                        display: inline-block;
                        vertical-align: middle;
                        font-weight: 600;
                        margin-right: 5px;
                    }

                    // Arrow icon
                    [class^=icon-] {
                        padding: 4px;
                        border: 1px solid;
                        border-radius: 100%;
                        font-size: 0.6rem;
                        font-weight: bold;

                        &::before {
                            display: inline-block;
                            transform: scale(-1);
                            transition: transform .3s ease-in-out;
                        }
                    }

                    // Active
                    &[aria-selected="true"] {
                        [class^=icon-] {
                            &::before {
                                transform: scale(1);
                            }
                        }
                    }
                }

                form {
                    margin-top: 1rem;
                }

                .fieldset.coupon {
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    padding-left: 15px;
                    gap: 10px;
                    background-color: $c-white;
                    border-radius: 40px;
                    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.05);
                }

                .label {
                    @extend .visually-hidden;
                }

                .control {
                    input {
                        height: 4rem;
                        padding: 0 1.2rem 0 0;
                        border: none;
                        color: $c-grey-light2;
                        font-family: $f-avenir;

                        &::placeholder {
                            color: $c-grey-light2;
                        }
                    }
                }

                .actions-toolbar {
                    margin-top: 0;
                    .primary {
                        height: 100%;
                    }
                    .action {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 18px;
                        background-color: $c-white;
                        border-color: $c-white;
                        color: $c-pink;
                        font-family: $f-museo-700;
                        font-size: 1.4rem;
                        font-weight: bold;
                        border-radius: 30px;
                        transition: all .3s ease-in-out;

                        &:hover {
                            background-color: $c-pink;
                            color: $c-white;
                        }
                    }
                }
            }
        }

        > .cart-totals {
            padding: 0.3rem 2rem 0;
            border-top: $checkout-summary-border;
            margin-top: 2rem;

            .table.totals {
                width: 100%;
            }

            .table-caption {
                @extend .visually-hidden;
            }

            th,
            td {
                width: 50%;
                padding: 1.3rem 0;
                font-family: $f-avenir;
                font-weight: bold;
            }

            th {
                text-align: left;
                padding-right: 10px;
            }

            td {
                font-family: $f-avenir-black;
                text-align: right;
            }

            tr {
                // Cell borders
                &.totals.sub,
                &.totals.discount,
                &.totals.shipping {
                    th,
                    td {
                        border-bottom: $checkout-summary-border;
                    }
                }
                &.totals.sub {
                    th,
                    td {
                        padding-top: 1.6rem;
                    }
                }
                &.totals.shipping + .grand.totals {
                    th,
                    td {
                        border-top: none;
                    }
                }
                &.grand.totals {
                    th,
                    td {
                        border-top: $checkout-summary-border;
                    }
                }

                // Hide tax
                &.totals-tax {
                    display: none;
                }

                // Grand Total styles
                &.grand.totals {
                    th {
                        font-family: $f-museo-500;
                        font-size: 1.8rem;
                        -webkit-font-smoothing : antialiased;
                        -moz-osx-font-smoothing: grayscale;
                    }
                    td {
                        font-family: $f-avenir;
                        font-size: 1.8rem;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
            }
        }

        // Actions
        > .checkout.methods {
            text-align: center;
            padding-left: 2rem;
            padding-right: 2rem;
            padding-bottom: 2rem;

            .action {
                &.checkout {
                    @include breakpoint(lg) {
                        width: 100%;
                    }
                }

                &.multicheckout {
                    text-align: center;
                    @extend .link-1;
                }
            }
        }
    }

    // Reassurance
    .cart-reassurance {
        padding-top: 2.4rem;
        background-color: $c-white;
        text-align: center;

        @include breakpoint(only-md) {
            padding-left: 2rem;
            padding-right: 2rem;
            margin-top: 2rem;
        }

        .cart-reassurance__list li + li {
            margin-top: 6px;
        }

        .cart-reassurance__item {

            .reassurance-item__label,
            img {
                display: inline-block;
                vertical-align: middle;
                margin: 5px 5px 5px 0;
                @include breakpoint(md) {
                    margin-right: 8px;
                }
            }

            .reassurance-item__label {
                font-weight: bold;
            }

            &-payment {
                img {
                    max-height: 19px;
                    width: auto;

                    @include breakpoint(md) {
                        max-height: 20px;
                    }

                    &.store-de {
                        max-height: 17px;
                        @include breakpoint(md) {
                            max-height: 18px;
                        }
                    }
                }
            }

            &-call {
                img {
                    max-width: 18.2rem;
                }
            }

            &-shipping {
                padding: 12px 12px 16px;
                border: 2px solid $c-green;
                text-align: center;

                .item-shipping__label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .icon-shop {
                    margin-right: .5rem;
                    font-size: 2.2rem;
                    margin-bottom: 2px;

                    &:before {
                        content: "\e950";
                    }
                }

                button {
                    text-decoration: underline;
                }
            }
        }
    }

    // Orders
    .form-cart {

        // BLock message of number of deliveries message
        .mt-messages .inner {
            @include breakpoint(only-md) {
                align-items: baseline;
            }
        }

        // Block message of point earned with this order

        .cart-item-fidelity {

            .inner {
                @include breakpoint(md) {
                    display: flex;
                    align-items: center;
                }
            }

            .inner-left,
            .inner-right {
                padding: 14px;

                @include breakpoint(md) {
                    padding: 16px 32px;
                }
            }

            .inner-left {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                white-space: nowrap;
                border-bottom: 1px dashed $c-red;

                @include breakpoint(md) {
                    border-bottom: none;
                    border-right: 1px dashed $c-red;
                }

                i {
                    color: $c-pink-fid;
                    font-size: 3.4rem;
                }

                .text {
                    margin-bottom: -10px;
                    font-family: $f-avenir;
                    font-size: 1.5rem;
                    line-height: 0.5;

                    strong {
                        display: block;
                        margin-left: 2.4rem;
                        color: $c-pink-fid;
                        font-weight: bold;
                        font-size: 1.5rem;
                    }

                    div {
                        perspective: 0; // Tips to remove blurry-text cause by transform rotate
                        span {
                            display: block;
                            font-family: $f-halohandletter;
                            font-size: 4rem;
                            transform: rotate(-2deg);
                        }
                    }
                }
            }

            .inner-right {
                p {
                    margin-bottom: 0;
                }

                span {
                    color: $c-pink-fid;
                    font-weight: bold;
                }

                a {
                    text-decoration: underline;

                    @include breakpoint(only-md) {
                        margin-top: 5px;
                        &::before {
                            content: "";
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

//
//  Table orders
//  _____________________________________________


.cart {
    width: 100%;

    &.table-wrapper {
        margin-top: 2rem;

        @include breakpoint(md) {
            margin-top: 3rem;
        }
    }


    // Table border management
    &.table {
        @include breakpoint(lg) {
            border-collapse: initial;
        }

        > tbody {
            &:first-child .spacer {
                display: none;
            }
            &.item > tr {
                margin-top: -1px;
            }
            &.item.middle {
                & > tr {
                    border-radius: 0 0 0 0;
                    border: $checkout-summary-border;
                    @include breakpoint(lg) {
                        td {
                            border-bottom: $checkout-summary-border;

                            &:first-child {
                                border-bottom-left-radius: 0;
                                border-left: $checkout-summary-border;
                            }

                            &:last-child {
                                border-bottom-right-radius: 0;
                                border-right: $checkout-summary-border;
                            }
                        }
                    }
                }
            }
            &.item.last {
                & > tr {
                    border-radius: 0 0 4px 4px;
                    border: $checkout-summary-border;
                    @include breakpoint(lg) {
                        td {
                            border-bottom: $checkout-summary-border;

                            &:first-child {
                                border-bottom-left-radius: 4px;
                                border-left: $checkout-summary-border;
                            }

                            &:last-child {
                                border-bottom-right-radius: 4px;
                                border-right: $checkout-summary-border;
                            }
                        }
                    }
                }
            }
            > tr {
                &.fidelity-types,
                &.shipping-types, {
                    border: $checkout-summary-border;
                    @include breakpoint(lg) {
                        > td {
                            border-bottom: $checkout-summary-border;
                            &:first-child {
                                border-left: $checkout-summary-border;
                            }

                            &:last-child {
                                border-right: $checkout-summary-border;
                            }
                        }
                    }
                }

                // Fidelity
                &.fidelity-types {
                    border-radius: 0 0 4px 4px;
                    > td {
                        background-color: $c-pink-light;
                        border-radius: 0 0 4px 4px;
                    }
                }

                // Shipping
                &.shipping-types {
                    border-radius: 4px 4px 0 0;
                    @include breakpoint(lg) {
                        td {
                            border-bottom: $checkout-summary-border;
                            border-top: $checkout-summary-border;

                            &:first-child {
                                border-top-left-radius: 4px;
                            }

                            &:last-child {
                                border-top-right-radius: 4px;
                            }
                        }
                    }
                }
            }
        }
    }

    // Table content
    > tbody {
        background-color: $c-white;

        > tr {
            > td {
                padding: 1.6rem 2rem;
            }
            &.spacer td {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }
            &.shipping-types {
                background-color: $c-grey-4;
                font-weight: bold;

                @include breakpoint(only-lg) {
                    padding: 15px;
                }

                td {
                    vertical-align: middle;

                    & + td {
                        @include breakpoint(lg) {
                            text-align: right;
                        }
                    }

                    p {
                        margin-bottom: 0;
                    }
                }

                // Seller name
                .seller-name {
                    margin-bottom: 1rem;
                    @include breakpoint(lg) {
                        margin-bottom: 0;
                    }
                }

                // MT Seller
                .mt-logo {
                    display: inline-block;
                    vertical-align: middle;
                    max-width: 75px;
                }

                .seller-redirect {
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .shipping-estimate {
                    font-weight: bold;
                    line-height: 1.4;

                    span {
                        font-weight: normal;
                    }

                    b {
                        font-weight: bold;
                    }

                    .free-shipping-info {
                        color: $c-pink-fid;
                        font-size: 1.2rem;
                        margin-bottom: 0;

                        & + * {
                            margin-top: 0.5rem;
                        }
                    }

                    .icon-shop {
                        display: inline-block;
                        vertical-align: text-top;
                        font-size: 1.6rem;
                        margin-right: 5px;
                    }

                    button {
                        font-size: 1.3rem;
                        margin-left: 5px;
                    }
                }
            }
            &.item-info {
                > td {
                    vertical-align: top;
                    font-size: 1.5rem;

                    &:not(.item)[data-th]::before {
                        content:attr(data-th);
                        display: block;
                        margin-bottom: 5px;
                        color: $c-grey-light2;
                        font-size: 1.2rem;
                        font-weight: normal;
                        line-height: 1.4;
                        white-space: nowrap;

                        @include breakpoint(only-lg) {
                            margin-bottom: 0;
                            width: 70px;
                        }
                    }

                    // Product
                    &.item .item-inner {
                        display: flex;
                        font-size: 1.4rem;

                        // Visual
                        .product-item-photo {
                            width: $checkout-desktop-product-visual-width;
                            height: $checkout-desktop-product-visual-width;
                            overflow: hidden;
                            margin-right: 2.5rem;

                            @include breakpoint(only-lg) {
                                position: absolute;
                                top: 1.5rem;
                                left: 1.5rem;
                                width: $checkout-mobile-product-visual-width;
                                height: $checkout-mobile-product-visual-width;
                                margin-right: 0;
                            }


                            > .product-image-container,
                            .product-image-wrapper {
                                width: 100% !important;
                                height: 100% !important;
                                padding: 0 !important;
                            }
                        }

                        // Title
                        .product-item-name {
                            display: block;
                            margin-bottom: 1.4rem;
                            color: $c-black;
                            font-size: 1.4rem;
                            font-weight: bold;
                        }

                        // SKU
                        .sku {
                            color: $c-grey-light2;
                        }

                        // Options
                        .item-options {
                            @include clearer;
                            color: $c-grey-light2;

                            dt,
                            dd {
                                float: left;
                                margin-top: 1.4rem;
                            }

                            dt {
                                clear: left;
                                margin-right: .6rem;

                                &::after {
                                    content: " :";
                                }
                            }
                        }

                        .product-item-details {
                            flex: 1;
                        }
                    }

                    // Price
                    &.price,
                    &.subtotal {
                        font-weight: bold;
                    }

                    &.price {

                        .old-price {
                            text-decoration: line-through;
                            margin-right: 1rem;
                            font-size: 1.2rem;
                        }

                        .new-price {
                            color: $c-pink-fid;
                        }
                    }

                    // Availability
                    &.availability {
                        .stock {
                            &.available {
                                color: $c-available;
                            }
                            &.unavailable {
                                color: $c-unavailable;
                            }
                        }
                    }

                    // Quantity
                    &.qty {

                        label {
                            display: flex;
                            margin-bottom: 0;
                        }

                        input {
                            width: 5rem;

                            @include breakpoint(only-xs) {
                                width: 4rem;
                                padding: 0;
                                font-size: 1.2rem;
                            }
                        }

                        .soon-qty-updater {
                            @include breakpoint(only-lg) {
                                width: 3.5rem;
                                height: 3.5rem;
                                line-height: 3.5rem;
                            }

                            @include breakpoint(only-xs) {
                                font-size: 1.2rem;
                            }
                        }

                        .label {
                            @extend .visually-hidden;
                        }
                    }

                    // Items actions (edit / remove)
                    &.actions {
                        .actions-toolbar {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                            align-items: center;
                            margin-top: 0;

                            .action {
                                width: auto;
                                margin-bottom: 0;
                                padding: 10px;

                                &.action-delete {
                                    display: block;
                                    width: 4rem;
                                    height: 4rem;
                                    padding: 0;

                                    @include breakpoint(only-lg) {
                                        width: 2.6rem;
                                        height: 2.6rem;
                                    }

                                    &:hover {

                                        &::before {
                                            background-color: $c-pink;
                                            border-color: $c-pink;
                                            color: $c-white;
                                        }
                                    }

                                    &::before {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 100%;
                                        height: 100%;
                                        border: 1px solid $border-color;
                                        content: 'x';
                                        font-family: $f-avenir !important;
                                        color: $c-pink;
                                        transition: background-color .3s, border-color .3s, color .3s;
                                    }
                                }

                                // Icon
                                &::before {
                                    font-size: 2rem;
                                }

                                span {
                                    @extend .visually-hidden;
                                }
                            }
                        }
                    }
                }
            }

            &.fidelity-types {
                margin-top: -1px;
                td {
                    padding: 0;
                }
            }
        }
    }

    // Mobile version
    @include breakpoint(only-lg) {

        .cart {
            display: block;
            > tbody {
                display: block;
                > tr {
                    display: block;
                    &.item-info {
                        display: flex;
                        flex-wrap: wrap;
                        position: relative;
                        padding: 1.5rem 0 1.5rem calc(#{$checkout-mobile-product-visual-width} + 2.5rem);
                        min-height: calc(#{$checkout-mobile-product-visual-width} + 2rem);
                    }

                    > td {
                        display: flex;
                        align-items: center;
                        padding: 0;
                        gap: 5px;

                        &.item {
                            display: flex;
                            width: 100%;
                            min-height: 0;
                            padding-right: 4.6rem;
                            margin-bottom: 1rem;

                             // Title
                            .product-item-name {
                                margin-bottom: .6rem;
                                line-height: 1.3;
                            }

                            // Option
                            .item-options {
                                dt,
                                dd {
                                    margin-top: .6rem;
                                }
                            }
                        }

                        // Hide element
                        &.availability {
                            display: none;
                        }

                        // Quantity
                        &.qty {
                            width: 100%;
                            margin-bottom: 1.2rem;

                            input.qty {
                                margin-top: 0;
                                max-width: 6rem;

                                @include only_ie {
                                    padding: 0 10px;
                                }
                            }
                        }

                        &.price {
                            width: 100%;
                            padding: 0;
                            margin-bottom: 1rem;
                            font-size: 1.4rem;

                            .old-price {
                                font-size: 1.2rem;
                            }
                        }

                        &.subtotal {
                            width: 100%;
                        }

                        &.actions {
                            position: absolute;
                            top: 1.5rem;
                            right: 1.5rem;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(only-md) {

        .cart {
            margin-bottom: 0;

            > tbody {
                > tr {

                    > td {

                        &.item {

                            // Visual
                            .product-item-photo {
                                left: 1rem;
                            }
                        }

                        &.actions {
                            right: 1rem;
                        }
                    }
                }
            }
        }
    }

    &.main.actions {
        display: flex;
        justify-content: flex-end;

        // Hide continue and clear carth action buttons
        .continue,
        .clear {
            display: none;
        }

        .action {
            & + .action {
                margin-left: 16px;
            }
        }
    }
}

//
// Empty cart
// __________________________________________________________

.cart-empty {
    margin-top: 3rem;
    text-align: center;

    @include breakpoint(md) {
        margin-top: 5rem;
    }

    .cart-empty-title {
        letter-spacing: 3px;
    }

    .cart-empty-subtitle {
        margin: 1.5rem 0 2rem;
        font-size: 2rem;

        @include breakpoint(md) {
            font-size: 2.4rem;
        }
    }

    .icon-arrow-menu {
        display: inline-block;
        font-size: 5rem;
        transform: rotate(90deg);
    }
}
