@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -ms-transform: rotate($degrees); // IE9+
    transform: rotate($degrees);
}
@mixin scale($ratio) {
    -webkit-transform: scale($ratio);
    -ms-transform: scale($ratio); // IE9+
    transform: scale($ratio);
}

@mixin scaleX($ratio) {
    -webkit-transform: scaleX($ratio);
    -ms-transform: scaleX($ratio); // IE9+
    transform: scaleX($ratio);
}

@mixin scaleY($ratio) {
    -webkit-transform: scaleY($ratio);
    -ms-transform: scaleY($ratio); // IE9+
    transform: scaleY($ratio);
}

@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -ms-transform: translate($x, $y); // IE9+
    transform: translate($x, $y);
}
@mixin skew($x, $y) {
    -webkit-transform: skew($x, $y);
    -ms-transform: skewX($x) skewY($y);
    transform: skew($x, $y);
}
@mixin translate3d($x, $y, $z) {
    -webkit-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}

@mixin transform($transform...) {
    -webkit-transform: $transform;
    -ms-transform: $transform;
    transform: $transform;
}

@mixin transition($transition...) {
    -webkit-transition: $transition;
    transition: $transition;
}
