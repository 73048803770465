// ==============================================
// CHECKOUT - Success
// ==============================================

.checkout-onepage-success {

    // Success message
    .checkout-success {
        text-align: center;
    }

    .checkout-success-explanatory {
        margin-bottom: 3rem;

        @include breakpoint(lg) {
            margin-bottom: 4rem;
        }

        .checkout-success-email-info {
            margin-bottom: .5rem;
        }

        .checkout-success-strong {
            margin-bottom: 2rem;

            strong {
                font-family: $f-avenir-heavy;
            }

            @include breakpoint(lg) {
                margin-bottom: 3rem;
            }
        }

        .checkout-success-status-info {

            a {
                text-decoration: none;

                &:hover,
                &:focus,
                &:active {
                    color: $c-pink;
                }
            }
        }
    }
}
