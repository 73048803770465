//
// Colors
// ______________________________________________

.product-colors {

    > .product-colors__label {
        display: block;
        margin-bottom: 1rem;
        font-size: 1.4rem;
        color: $c-black;
        text-transform: uppercase;
    }

    > .product-colors__list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1.6rem;

        > li,
        > .show-more-products {
            margin: 0;
        }

        > li {
            width: 3rem;
            height: 3rem;
            overflow: hidden;

            @include breakpoint(md) {
                width: 4.4rem;
                height: 4.4rem;
                cursor: pointer;
            }

            &.selected {
                border: 2px solid $c-pink;
            }

            > img {
                display: block;
                width: 100%;
                height: 100%;
            }

            &.is-hidden {
                display: none;

                &.is-show {
                    display: block;
                }
            }
        }

        .show-more-products {
            @include circle(2.4rem);
            display: flex;
            border: 1px solid $c-grey;
            font-size: 0;
            position: relative;

            &::before,
            &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: '';
                width: 10px;
                height: 1px;
                background-color: $c-grey;
            }

            &::after {
                transform: translate(-50%, -50%) rotate(90deg);
            }

            &.is-active {

                &::after {
                    opacity: 0;
                }
            }
        }
    }
}
