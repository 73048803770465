// ==============================================
// MESSAGES & NOTIFICATIONS
// ==============================================

// Configuration
$notification-bar-padding: 28px 15px 28px 30px;

.notification {
    position: relative;
    width: 100%;
    padding: $notification-bar-padding;
    line-height: 1.4;
    text-align: center;
    border: 1px solid;

    // Icon
    &::before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: -30px;
        @extend %icomoon;
        font-size: 2.6rem;
    }

    a {
        text-decoration: underline;
    }

    &.notification--success {
        border-color: $c-success;

        &, a, a::before {
            color: $c-success
        }

        // Icon
        &::before {
            content: "\e925";
        }
    }

    &.notification--information {
        border-color: $c-info;

        &, a, a::before {
            color: $c-info;
        }

        // Icon
        &::before {
            content: "\e914";
        }
    }

    &.notification--warning {
        border-color: $c-warning;

        &, a, a::before {
            color: $c-warning;
        }

        // Icon
        &::before {
            content: "\e914";
        }
    }

    &.notification--error {
        border-color: $c-error;

        &, a, a::before {
            color: $c-error;
        }

        // Icon
        &::before {
            content: "\e914";
        }
    }
}

//
//  Magento natives notifications extends
//  _____________________________________________

.message {
    display: flex;
    justify-content: center;

    & + .message {
        margin-top: 10px;
    }

    &:nth-last-of-type(1){
        margin-bottom: 20px;
    }

    &.message-success {

        > div {
            @extend .notification;
            @extend .notification--success;
        }
    }

    &.notice,
    &.info,
    &.message-notice,
    &.message-progress,
    &.message-spinner,
    &.message-in-rating-edit {

        > div {
            @extend .notification;
            @extend .notification--information;
        }
    }

    &.message-warning {

        > div {
            @extend .notification;
            @extend .notification--warning;
        }
    }

    &.message-error,
    &.error {

        > div {
            @extend .notification;
            @extend .notification--error;
        }
    }

    &.demo {
        text-align: center;
        padding: 12px;
        font-size: 1.3rem;
        color: $c-warning;
        border: 1px solid $c-warning;

        p {
            margin-bottom: 0;
        }
    }
}

.mage-error {
    color: $c-error;
}

.cms-index-index {
    .column.main {
        > .messages {
             .messages {
                margin-top: 20px;
            }
        }
    }
}

.page-layout-1column {
    .column.main {
        > .messages {
            @extend .container;
        }
    }
}




//
//  Custom MT messages block
//  _____________________________________________
.mt-messages {
    border: 1px solid $c-blue-fid2;
    border-left: 5px solid $c-blue-fid;
    background-color: $c-blue-fid3;
    padding: 10px 15px 10px 20px;
    border-radius: 4px;

    .inner {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .icon span {
        font-size: 1.4rem;
    }

    .text {
        p {
            margin-bottom: 0;
            line-height: 1.7;
        }
        span {
            font-weight: bold;
        }
    }
}
