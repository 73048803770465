/*
 * styles for chrono relais coming from app/code/Chronopost/Chronorelais/view/frontend/web/css/source/_module.less
 * and modify
*/
.chronorelais-address input {
  width: 90px;
  max-width: 50%;
  display: inline;
}
.sp-methods .method-description {
  clear: both;
}
.sp-methods .method-image {
  padding: 0 10px;
}
.table-checkout-shipping-method > tbody tr > td {
  vertical-align: middle;
}
/*****************************
sw-Gmap
*******************************/
.chronomap {
  height: 384px;
  width: 100%;
  overflow: hidden !important;
  font: 82.5%/1.3 Arial, Helvetica, sans-serif;
  background-color: #E5E3DF;
  border: 1px solid #aaa;
    z-index: 1;
}
.chronomap * {
  float: none;
  font-family: Arial, sans-serif;
}
.chronomap a {
  color: #0000cc;
  outline: none;
}
.chronomap h4 {
  font-size: 1em;
  margin-bottom: 6px;
  border-bottom: 1px solid #ccc;
}
.chronomap h2 {
  font-size: 1em;
  margin: 2px 10px 6px 0;
  font-weight: bold;
}
/*****************************
Infowindow
*******************************/
.chronomap .sw-infowindow a {
  cursor: pointer;
}
#sw-infowindow-info {
  max-height: 400px;
}
#sw-infowindow-hor {
  height: 150px;
}
.sw-map-adresse {
  font-size: 1em;
  margin: 5px 0 8px 0;
  line-height: 1.45em;
}
.sw-map-tools a {
  font-size: .9em;
}
.chronomap .sw-desc {
  display: block;
  font-weight: bold;
  line-height: 16px;
  float: none !important;
  width: auto;
}
.chronomap .sw-help {
  display: block;
  float: none !important;
  width: auto;
  clear: both;
}
.sw-map-itin-tools {
  font-size: .8em;
  padding: 8px 0;
  display: block;
}
.sw-back-link {
  font-size: .8em;
  display: block;
  text-align: right;
  margin: 0;
}
#sw-sendbymail-wrap {
  height: 220px;
  overflow: hidden;
}
.chronomap .sw-textarea {
  height: 30px;
}
.sw-tool-itineraire {
  height: 220px;
}
/*****************************
Tables
*******************************/
.sw-table {
  border-collapse: collapse;
  width: 100%;
}
.sw-table td {
  font-size: 1em;
  border-bottom: 1px solid #ccc;
  padding: 1px 0 !important;
}
.sw-table th {
  background: #eee;
  font-size: 1em;
  border-top: 1px solid #555555;
}
#sw-table-horaire-client td,
#sw-table-horaire-depot td {
  width: 50%;
}
#sw-table-horaire-depot {
  margin-top: 8px;
}
/*****************************/
/*****************************
Chronopostsrdv
*******************************/
.global-desktop {
  min-width: 641px;
  display: block;
}
.global-desktop.onestepcheckout {
  display: none !important;
}
#rdvCarousel {
  width: 100%;
  height: auto;
  overflow: hidden;
}
#rdvCarouselContent {
  width: 500%;
}
#rdvCarouselContent .slide {
  float: left;
  width: 20%;
  /* 20% de 500% soit 100% */
  height: auto;
}
.rdvCarouselheader nav {
  font-size: 18px;
  overflow: hidden;
  margin-bottom: 5px;
}
.rdvCarouselheader nav a {
  background: url('../images/chronopost/arrows.png') 0 0 no-repeat;
  line-height: 35px;
  color: #7B7B7B;
  text-decoration: none;
}
.rdvCarouselheader nav .inactive a {
  color: #CCC;
}
.rdvCarouselheader .prev a {
  float: left;
  background-position: 0 0;
  padding-left: 28px;
}
.rdvCarouselheader .prev.inactive a {
  background-position: -280px 0;
}
.rdvCarouselheader .next a {
  float: right;
  background-position: right -280px top -65px;
  padding-right: 28px;
}
.rdvCarouselheader .next.inactive a {
  background-position: right 0 top -65px;
}
#rdvCarouselContent .date-time {
  border-collapse: collapse;
}
#rdvCarouselContent .date-time td,
#rdvCarouselContent .date-time th {
  padding: 10px 5px;
  text-align: center;
}
#rdvCarouselContent .date-time td {
  border-left: solid 1px #f3f3f3;
  border-bottom: solid 1px #f3f3f3;
  font-size: 1.14em;
  vertical-align: top;
  padding-bottom: 5px;
  background-color: #fff;
}
#rdvCarouselContent .date-time td span {
  white-space: nowrap;
}
#rdvCarouselContent .date-time td label {
  position: relative;
  display: block !important;
  padding: 0 !important;
  min-width: 0 !important;
  color: inherit !important;
  background: inherit !important;
}
#rdvCarouselContent .date-time td.unavailable {
  background-color: #e6e6e7;
  font-size: 0.9em;
}
#rdvCarouselContent .date-time td.eco {
  background-color: #e2f0e0;
}
#rdvCarouselContent .caption {
  background-color: #595a5c;
  font-size: 1.71em;
  overflow: hidden;
  color: #fff;
  padding: 10px 20px;
}
#rdvCarouselContent .date-row th {
  font-size: .86em;
  color: #fff;
  background-color: #8f9091;
  font-weight: normal;
  border-left: solid 1px #fff;
  border-bottom: solid 1px #fff;
  display: table-cell;
}
#rdvCarouselContent .date-row th:first-child {
  border-left: 0;
}
#rdvCarouselContent .date-row th span {
  font-size: 2.14em;
  display: block;
}
#rdvCarouselContent .date-row th.active,
#rdvCarouselContent .date-time td.active,
#rdvCarouselContent .time-cell.active {
  background-color: #797979 !important;
  color: #fff !important;
}
#rdvCarouselContent .time-cell {
  font-size: 1.4em;
  background-color: #8f9091;
  font-weight: normal;
  color: #fff;
  border-bottom: solid 1px #fff;
  white-space: nowrap;
}
.chronopostsrdv_noresult {
  position: absolute;
  background: #cccccc none repeat scroll 0 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
}
.popin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 360px;
  margin: -90px 0 0 -180px;
  background-color: #fff;
  z-index: 99999;
  padding: 25px;
  box-shadow: 0 5px 15px #ccc;
  font-size: 16px;
  display: block;
}
.popin a.close {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 15px;
}
.margB {
  margin-bottom: 30px;
}
.center {
  text-align: center;
}
/*****************************
Chronopostsrdv mobile
*******************************/
.global-mobile {
  width: 100%;
  height: 100%;
  max-width: 640px;
  margin: 0 auto;
  position: relative;
  display: none;
}
.global-mobile.onestepcheckout {
  display: block !important;
}
.global-mobile .header {
  width: auto;
}
#global-mobile h1 {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 5px;
  text-align: left;
}
.global-mobile .left {
  float: left;
}
.global-mobile .right {
  float: right;
}
.global-mobile .scroll {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
.global-mobile .scroll-v {
  overflow-y: auto;
  overflow-x: hidden;
}
.global-mobile .date-time {
  min-width: 100%;
  border-collapse: collapse;
}
.global-mobile .date-time th {
  padding: 5px;
  text-align: center;
}
.global-mobile .date-row th {
  color: #fff;
  background-color: #8f9091;
  font-weight: normal;
  border-left: solid 1px #fff;
  border-bottom: solid 1px #fff;
}
.global-mobile .date-row th:first-child {
  border-left: 0;
}
.global-mobile .date-row th span {
  font-size: 2.14em;
  display: block;
}
.global-mobile .time-list {
  border-left: solid 1px #f3f3f3;
  border-right: solid 1px #f3f3f3;
  position: relative;
}
.global-mobile .time-list ul {
  padding: 0;
}
.global-mobile .time-list li {
  display: table;
  width: 100%;
  border-bottom: solid 1px #f3f3f3;
  font-size: 1em;
}
.global-mobile .time-list li label {
  display: table-row !important;
  color: inherit !important;
  background: inherit !important;
}
.global-mobile .time-list li.unavailable {
  background-color: #e6e6e7;
}
.global-mobile .time-list li.unavailable .price-cell {
  font-size: .6em;
}
.global-mobile .time-list li.active,
.global-mobile .date-row th.active {
  background-color: #797979 !important;
  color: #fff !important;
}
.global-mobile .eco {
  background-color: #e2f0e0;
}
.global-mobile .time-cell,
.global-mobile .price-cell {
  display: table-cell;
  width: 30%;
  white-space: nowrap;
  padding: 20px;
}
.global-mobile .price-cell {
  width: 70%;
  padding-right: 60px;
  text-align: right;
}
.global-mobile .ic-eco {
  position: absolute;
  margin-left: 10px;
}
.global-mobile input {
  font-size: 18px;
  padding: 4px 30px;
  border: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
}
@media screen and (max-width: 720px) {
  .global-desktop {
    display: none;
  }
  .global-mobile {
    display: block;
  }
}

/* Form  */

#map-postcode-btn,
#map-postcode-btn:hover,
#map-postcode-btn:active,
#map-postcode-btn:visited{color:#fff}

#map-postcode-btn.input-text:not(.qty),
#map-postcode-btn.input[type="text"]
{
    display: inline;
}

button {
    float: none;
    &#map-postcode-btn span span {
        @extend .btn;
    }
}


.checkout-index-index {

    #chronomap_container_chronorelais {
        display: flex;
        align-items: center;
        padding: 1rem 3rem 3rem 2rem;
        margin-top: 0;
        background-color: $c-white;
        border-style: solid;
        border-color: $border-color;
        border-width: 0 4px 4px 4px;
    }

    .sp-methods-chronorelais dt {
        margin: 1rem 0;
    }
}

.chronorelais-list label{float:none}
.chronorelais-list .col-list{float:left;height:384px;width:388px;background:#fff;border:1px solid #ccc;overflow-y:scroll;overflow-x:hidden;margin:0;padding:0}
.chronorelais-list .chronorelais-map{width:500px;height:384px;float:right;border:1px solid #ccc}

.chronorelais-list li {
    border-bottom: 1px solid #ccc;
    padding: 0;
    float: left;
    list-style: none;
    margin: 0;
    width: 100%;
}

