.mt-l-Header {
    $header: &;
    position: relative;
    z-index: 10;

    &-top {
        position: relative;
        z-index: 5;
    }

    &-nav {
        &-links {
            display: none;
            background-color: $c-red-dark;
            padding: 0 20px;
            font-weight: 300;
            @include fs(12px, 18px);
            color: $c-white;

            li {
                position: relative;
                padding: 10px 0;

                a {
                    color: $c-white;
                    text-decoration: none;

                    &:hover {
                        & > span {
                            text-decoration: underline;
                        }
                    }

                    & > * {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    &::before {
                        @include icomoon;
                        font-size: 1.7rem;
                        vertical-align: sub;
                        margin-right: 5px;
                    }
                }

                & + li {
                    &:before {
                        content: '';
                        margin: 0 12px;
                        background-color: $c-white;
                        display: inline-block;
                        vertical-align: middle;
                        width: 3px;
                        height: 3px;
                        border-radius: 100%;
                        text-decoration: none;
                    }
                }

                &.contact-link {
                    a {
                        &::before {
                            content: "\e951";
                        }
                    }
                }

                &.fidelity-link {
                    a {
                        &::before {
                            content: "\e95f";
                        }
                    }
                }
            }
        }
    }

    &-shortcut {
        display: none;
        padding: {
            top: 5px;
            bottom: 15px;
        }
        background-color: $c-white;
        position: relative;
        z-index: 1;
    }

    &-search {
        position: relative;
        z-index: 4;
    }

    &[data-sticky="true"] {
        position: fixed;
        box-shadow: 0 15px 20px 0 rgba($c-black, 0.2);
        @include transition(ease-in-out 0.5s);
        top: -200px;
        width: 100%;

        #{$header}-nav {
            position: relative;
            z-index: 4;
        }

        #{$header}-shortcut {
            padding: 0;
        }
    }

    @include breakpoint(only-xl) {
        &-nav {
            position: absolute;
            pointer-events: initial;
            overflow: initial;
            z-index: 10;
            background-color: $c-white;
            @include transition(ease-in-out 0.5s);
            width: 100%;
            top: -9999px;

            &::-webkit-scrollbar {
                width: 2px;
                height: 0;

                &-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                }

                &-thumb {
                    background-color: $c-new-red;
                    outline: 1px solid $c-new-red;
                }
            }
            // Firefox
            scrollbar-width: thin;
            scrollbar-color: $c-new-red rgba(0, 0, 0, 0.1);

            &[data-show="true"] {
                top: 0;
            }

            &-links {
                display: flex;
                padding: 0 15px;
            }
        }

        &[data-sticky="true"] {
            #{$header}-nav {
                position: inherit;
            }
        }

    }

    @include breakpoint(only-xl) {
        @include breakpoint(md) {
            &-shortcut {
                display: block;
            }
        }
    }
}
