// ==============================================
// PRODUCTS LISTING
// ==============================================

// Configuration
$listing-list-item-gutter: 1rem;
$listing-list-item-gutter-large: 2rem;

// Space with toolbar
.products-grid,
.products-list {
    margin-bottom: 3rem;
}

.page-products {

    // Main title
    .page-title {
        @extend .title-1;
    }
}

// Back to top
.catalogsearch-result-index,
.catalog-category-view {
    @include breakpoint(only-lg) {

        .page-wrapper {
            padding-top: 0;
        }

        .page-header {
            position: static;
        }
    }

    @include breakpoint(only-md) {

        .page-main {
            margin-top: 2rem;
        }
    }
}

// Category view
.catalog-category-view {

    // Category view
    .category-view {

        .category-view-details {
            position: relative;

            @include breakpoint(lg) {
                padding: 5rem;
                min-height: 20rem;
            }
        }

        // title
        .category-name {
            @extend .title-1;
            line-height: 1;
            color: $c-base;

            @include breakpoint(only-md) {
                font-size: 2rem;
            }

            @include breakpoint(only-lg) {
                padding-right: 9rem;
            }
        }

        // Name
        .page-title-wrapper {
            @extend .visually-hidden;
        }

        @include breakpoint(only-lg) {

            .category-image,
            .category-description {
                display: none;
            }
        }

        @include breakpoint(lg) {
            position: relative;

            // image
            .category-image {
                position: absolute;
                overflow: hidden;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;

                img {
                    position: absolute;
                    object-fit: cover;
                    height: 100%;
                }

                // Description
                + .category-view-content {
                    max-width: 55rem;
                }
            }

            // Description
            .category-description {
                font-size: 1.3rem;
                line-height: 1.2;

                div[data-content-type="row"] > div {
                    margin-bottom: 0 !important; // Override page builder inline css
                }
            }
        }
    }

    // Mentions
    .bloc-legal-notice {

        @include breakpoint(only-lg) {
            @include gutter-less(2rem);
        }

        > .legal-notice-link {
            position: relative;
            display: block;
            padding: 2rem;
            text-align: center;
            transition: color .3s;

            @include breakpoint(lg) {
                margin-top: 2rem;
                background: $c-white;
            }

            &::after {
                display: inline-block;
                margin-left: 1rem;
                @extend %icomoon;
                content: "\e923";
                font-size: 1.2rem;
                transition: transform .3s;
            }

            &:hover,
            &.active {
                color: $c-pink;
            }

            &.active {
                z-index: 6;

                &::after {
                    transform: scale(-1);
                }
            }
        }

        > .legal-notice-container {
            position: absolute;
            z-index: 5;
            left: 0;
            right: 0;
            padding: 2rem;
            background: $c-white;

            @include breakpoint(lg) {
                box-shadow: $filters-box-shadow;
            }
        }
    }
}

// Search result page title
.catalogsearch-result-index {

    // title
    .page-title {
        line-height: 1;
        color: $c-base;

        @include breakpoint(only-md) {
            font-size: 2rem;
        }

        @include breakpoint(only-lg) {
            padding-right: 9rem;
        }
    }
}

//
//  Grid view
//  _____________________________________________

.page-products .products-grid {

    // Remove sorter in bottom toolbar
    + .toolbar-products {
        margin-bottom: 20px;

        .toolbar-sorter {
            display: none;
        }
    }

    .product-items.list {
        @include gutter-less($listing-list-item-gutter);

        @include breakpoint(only-xl) {
            display: flex;
            flex-wrap: wrap;

            .product-item-info,
            .product-item-details {
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                @include only_ie {
                    flex: auto;
                }
            }

            .product-item-info {
                height: 100%;
            }
        }

        @include breakpoint(xl) {
            @include gutter-less($listing-list-item-gutter-large);

            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }

        li.product-item {
            width: $w-half;
            max-width: calc(26rem + (#{$listing-list-item-gutter} * 2));
            padding: 0 $listing-list-item-gutter;
            margin-bottom: 40px;

            @include breakpoint(md) {
                width: $w-third;
                max-width: calc((26rem * 2) + (#{$listing-list-item-gutter} * 2));
            }

            @include breakpoint(xl) {
                width: auto;
                max-width: none;

                &.product-item--large {


                }

                &.product-item--left {
                    //grid-column-start: 1;
                    //grid-column-end: 3;
                }

                &.product-item--right {
                    //grid-column-start: 3;
                    //grid-column-end: 5;
                }
            }

            @include breakpoint(xxl) {
                max-width: calc(26rem + (#{$listing-list-item-gutter-large} * 2));
                padding: 0 $listing-list-item-gutter-large;

                &.product-item--large {
                    max-width: calc((26rem * 2) + (#{$listing-list-item-gutter-large} * 4));
                }
            }

            // Fake product
            &.product-item--fake {
                position: relative;
            }
        }
    }
}

//
//  List view
//  _____________________________________________

.products-list {

    li.product-item {
        & + li {
            margin-top: 20px;
        }
    }

    .product-item-info {
        display: flex;
    }

    .product-item-photo {
        margin-bottom: 0;
        margin-right: 10px;

        @include breakpoint(md) {
            margin-right: 30px;
        }
    }

    .product-item-details {
        flex-grow: 1;
    }
}
