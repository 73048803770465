// ==============================================
// LISTING FILTERS
// ==============================================

//
//  Configuration
//  _____________________________________________

$c-grey-sidebar:                    $c-grey-3;
$account-sidebar-delimiter:         1px solid $c-grey-sidebar;

//
//  Active filters
//  _____________________________________________

.filter-current {
    margin-bottom: 10px;
}

.filter-clear {
    display: inline-block;
    margin-bottom: 20px;
    @extend %clearer;
}

//
//  Options
//  _____________________________________________

.filter-options {

    .item {
        margin: 0 !important;

        > .am-collapse-icon {
            top: 12px;
            left: 100%;
            width: 11px;
            height: 11px;

            &::before,
            &::after{
                content: '';
                position: absolute;
                background-color: $c-grey-sidebar;
            }

            &::before {
                top: 5px;
                width: 11px;
                height: 1px;
            }

            &::after {
                top: 0;
                right: auto;
                left: 50%;
                transform: translateX(-50%);
                width: 1px;
                height: 11px;
                margin-top: 0;
                border: none;
            }

            &._active {

                &::after {
                    opacity: 0;
                }

                + input + a {
                    color: $c-red;
                }
            }
        }

        > a {
            display: flex;
            align-items: baseline;
            padding: 15px 35px 15px 0;
            transition: color .4s;

            > .label {
                margin-right: .5rem;
                margin-bottom: 0;
            }

            &:hover {
                color: $c-red;
            }
        }

        > ul.items,
        > ul.items.items-children {
            padding: 0;
            margin: 0;

            &.level-1 {

                > li {

                    > span {
                        &._active {

                            + input + a {
                                color: $c-base;
                            }
                        }
                    }

                    > a {
                        font-family: $f-avenir-black;

                        > .count {
                            color: $c-red;
                        }
                    }

                    > ul.level-2 {
                        padding-top: 1.5rem;
                        padding-bottom: 3rem;
                        padding-left: 1rem;
                        margin-bottom: 3rem;
                        border-bottom: 1px solid  $c-grey-3;

                        > li {

                            > a {

                                &.am_shopby_link_selected {
                                    color: $c-red;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

// Couleurs

$filter-img-size: 2rem;

.filter-options-content {
    .am-shopby-form[data-amshopby-filter="groupe_couleur"] {
        padding: 0;

        .am-swatch-options.groupe_couleur {
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: .3rem;

            @include breakpoint(sm) {
                margin-left: -1rem;
                margin-right: -1rem;
            }

            .am-swatch-wrapper {
                display: block;
                width: 50%;
                padding: 0 1rem 2rem 1rem !important;
                margin: 0 !important;

                @include breakpoint(sm) {
                    width: 33.3333%;
                }

                @include breakpoint(md) {
                    width: 25%;
                }

                @include breakpoint(lg) {
                    width: 20%;
                }

                @include breakpoint(xl) {
                    width: auto;
                }

                .am-swatch-link {
                    align-items: center;
                    padding: 0;

                    .swatch-option.image {
                        display: block;
                        min-width: $filter-img-size;
                        max-width: $filter-img-size;
                        width: $filter-img-size;
                        height: $filter-img-size;
                        padding: 0;
                        background-position: center;
                        background-size: cover;
                        pointer-events: none;
                    }

                    .am-shopby-swatch-label {
                        display: block;
                        max-width: none;

                        .label {
                            margin: 0;
                            line-height: 1;
                        }

                        .count,
                        .filter-count-label {
                            @extend .visually-hidden;
                        }
                    }
                }
            }
        }
    }
}


//
//  Sidebar
//  _____________________________________________

.sidebar.sidebar-main {

    // Title
    .block-title.filter-title {
        @include visually-hidden;
    }

    // Options
    .filter-options-content {

        > form {

            > ul.items {

                > li.item {

                    > ul.items {

                        > li.item {

                            > .am-collapse-icon {
                                top: 12px;
                            }

                            a {

                                &.am_shopby_link_selected {
                                    color: $c-red;

                                    + ul {
                                        display: block !important;

                                        span.label {
                                            font-size: 1.4rem;
                                        }
                                    }
                                }

                                &:not(.am_shopby_link_selected) {
                                    padding-top: 1rem;
                                    padding-bottom: 1rem;
                                    font-family: $f-avenir;

                                    .label {
                                        font-size: 1.2rem;
                                    }

                                    > .count {
                                        color: currentColor;
                                    }

                                    + ul {
                                        font-size: 1.2rem;

                                        .label {
                                            font-size: 1.2rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            ul.level-3 {
                padding-left: 1.5rem;
                padding-bottom: 2rem;
                margin-bottom: 2rem;
                border-bottom: 1px solid  $c-grey-3;

                > li {

                    > a {
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                    }
                }
            }
        }
    }
}
