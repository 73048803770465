// ==============================================
// GoldenEyes
// ==============================================

//
//  Cart popin
//  _____________________________________________
.loyalty-popup {

    // Layout
    @include breakpoint(lg) {
        display: flex;
        flex-wrap: wrap;

        > [class*="-card-block"] {
            width: 50%;
        }
    }

    .messages:not(:empty) {
        margin-bottom: 2rem;
    }

    // Confirm card
    .enter-card-block {

        @include breakpoint(only-lg) {
            margin-top: 3rem;
            margin-bottom: 4rem;
        }

        @include breakpoint(lg) {
            padding-right: 5rem;
        }
    }

    // Add new card
    .new-card-block {

        @include breakpoint(lg) {
            padding-left: 5rem;
            border-left: 1px solid $border-color;
        }
    }

    // Card
    .loyalty-blank {
        //max-width: 30rem;
        margin: 0 auto;

        > .redirect {

            @include breakpoint(only-lg) {

                a + a {
                    margin-top: 1rem;
                }
            }

            @include breakpoint(lg) {
                display: flex;
                justify-content: space-between;

                .btn {
                    width: 48%;
                }
            }
        }
    }

    // View
    .loyalty-view {
        width: 100%;

        @include breakpoint(only-md) {
            padding-top: 3rem;
        }

        .loyalty-info {
            margin-bottom: 3rem;
        }

        .customer-info {
            margin-bottom: 2rem;
            font-size: 1.6rem;
        }

        // Table
        .loyalty-table {
            width: 100%;

            th,
            td {
                text-align: left;
            }

            th {
                padding: 2rem;
                font-family: $f-avenir-black;

                @include breakpoint(only-md) {
                    display: none;
                }
            }

            td {
                padding: 1rem 2rem;

                @include breakpoint(only-md) {
                    display: flex;
                    justify-content: space-between;
                    padding: 0.5rem 0;

                    // Show data-th
                    &:not(.actions) {
                        &::before {
                            padding-right: 10px;
                            content: attr(data-th) ': ';
                            display: inline-block;
                            font-family: $f-avenir-black;
                        }
                    }

                    &.actions {
                        padding-top: 1.5rem;
                        padding-bottom: 0;
                    }
                }
            }

            tbody {

                @include breakpoint(md) {
                    border: 1px solid $border-color;
                }

                @include breakpoint(only-md) {

                    tr {
                        + tr {
                            td {
                                &:first-child {
                                    padding-top: 2rem;
                                    margin-top: 2rem;
                                    border-top: 1px solid $border-color;
                                }
                            }
                        }
                    }
                }
            }

            .btn {
                padding: 1rem 1.5rem;
                margin: 0;
                font-size: 1.2rem;
            }
        }

        .redirect {
            .btn {
                margin-top: 3rem;

                @include breakpoint(md) {
                    width: auto;
                }
            }
        }
    }

    // Commons
    .title-2 {
        margin-bottom: 2rem;
    }

    p {

        strong {
            font-family: $f-avenir-black;
        }
    }

    .btn {
        display: block;
        width: 100%;
        margin-top: 2rem;
        text-align: center;
    }
}

//
//  Refonte Loyalty
// __________________________________________________________________
.loyalty-account {
    a {
        text-decoration: none;

        & > * {
            display: inline-block;
            vertical-align: middle;
        }

        img {
            width: 30px;
            height: 22px;
            margin-right: 6px;
        }

        span {
            text-decoration: underline;
        }

        &:hover {
            span {
                text-decoration: none;
            }
        }
    }
}

.loyalty-blank {
    width: 100%;

    &-head {
        padding: 15px 40px;
        background-color: $c-grey-light;
        margin-top: -40px;
        margin-left: -40px;
        width: calc(100% + 80px);
        margin-bottom: 30px;

        &-container {
            width: 570px;
            max-width: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-points {
                order: 1;
                width: 170px;
                height: 145px;
                margin-right: 30px;
                text-align: center;
                position: relative;
                background-image: url('../images/account/icon/drawing-circle.svg');
                background-size: contain;
                background-repeat: no-repeat;

                &-content {
                    position: absolute;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    top: 50%;

                    &-number {
                        display: block;
                        font-family: $f-avenir-black;
                        color: $c-red;
                        font-size: 50px;
                        line-height: 60px;
                    }

                    &-label {
                        margin-top: -20px;
                        display: block;
                        font-family: $f-halohandletter;
                        color: $c-grey-dark2;
                        font-size: 50px;
                        line-height: 54px;
                    }
                }
            }

            &-txt {
                order: 2;
                width: calc(100% - 200px);

                &-hello {
                    font-family: $f-halohandletter;
                    font-size: 34px;
                    line-height: 36px;
                    margin-bottom: 5px;

                    & > span {
                        display: inline-block;
                        color: $c-red;
                        text-transform: lowercase;

                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }
                }

                &-title {
                    font-size: 30px;
                    line-height: 36px;
                    font-family: $f-museoSlab-700;
                    text-transform: none;
                    color: $c-grey-dark2;
                    margin-bottom: 12px;
                }

                &-card {
                    margin-bottom: 0;

                    [class^=icon] {
                        color: $c-pink-light2;
                        font-size: 2.2rem;
                        margin-right: 2px;
                    }

                    & > * {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    &-info {
        display: block;
        text-align: center;
        color: $c-grey-dark2;
        font-family: $f-avenir-medium;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        margin: 30px auto;

        span {
            font-weight: bold;
        }
    }

    &-offers {
        margin: 0 auto;
        width: 790px;
        max-width: 100%;
        &-block {
            $block: &;
            display: block;
            width: 100%;
            margin-bottom: 30px;
            background-color: $c-white;
            box-shadow: 2px 2px 10px 0 rgba($c-black, 0.1);

            &-head {
                padding: 15px 25px;
                font-size: 18px;
                line-height: 25px;
                font-family: $f-avenir;

                & > * {
                    display: inline-block;
                    vertical-align: middle;
                }

                img {
                    width: 36px;
                    height: 26px;
                    margin-right: 9px;
                }

                &-title {
                    & > span {
                        font-weight: 600;
                    }
                }
            }

            &-list {
                &-item {
                    padding: 25px 30px;
                    display: flex;
                    align-items: center;

                    & + & {
                        border-top: 1px solid $c-grey-5;
                    }

                    &-img {
                        width: 60px;
                        height: 60px;
                        margin-right: 25px;
                    }

                    &-content {
                        font-family: $f-avenir;
                        margin-right: auto;

                        &-title {
                            margin-right: 10px;
                            font-size: 16px;
                            line-height: 22px;
                            color: $c-grey-dark2;

                            & > strong {
                                font-weight: 600;
                            }
                        }

                        &-txt {
                            margin: 5px 0 0;
                            display: block;
                            font-size: 14px;
                            line-height: 19px;
                            color: $c-grey-light2;
                        }

                        &-point {
                            font-family: $f-avenir-medium;
                            text-align: center;
                            font-size: 10px;
                            line-height: 14px;
                            border-radius: 10px;
                            padding: 3px 6px;
                            font-weight: 600;
                        }

                        &-title,
                        &-point {
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }

                    &-actions {
                        &-btn {
                            display: block;
                            font-family: $f-museo-700;
                            color: $c-pink-fid;
                            background-color: $c-pink-light3;
                            transition: ease 0.5s;
                            font-size: 14px;
                            line-height: 1;
                            padding: 12px 20px;
                            border-radius: 2px;

                            &[data-hidden] {
                                background-color: $c-white;
                                color: $c-pink-light2;
                                border: 2px dashed $c-pink-light2;
                                & > span:last-child {
                                    display: block;
                                }
                            }

                            & > span {
                                & > * {
                                    display: inline-block;
                                    vertical-align: middle;
                                }
                                &:last-child {
                                    display: none;
                                }

                                img {
                                    width: 18px;
                                    height: 14px;
                                    margin-right: 3px;
                                }
                            }
                        }

                        input[type="radio"] + label {
                            padding: 9px 20px;
                            margin: 0;
                            &:before,
                            &:after {
                                display: none;
                            }
                        }

                        input[type="radio"]:checked + label {
                            background-color: $c-pink-fid;
                            color: $c-white;
                            & > span {
                                &:first-child {
                                    display: none;
                                }
                                &:last-child {
                                    display: block;
                                }
                            }
                        }
                    }

                    &-cta {
                        &-txt,
                        &-btn {
                            text-align: center;
                            display: block;
                        }

                        &-txt {
                            font-family: $f-avenir-medium;
                            font-size: 12px;
                            line-height: 16px;
                            color: $c-error-fid;
                            margin-bottom: 6px;
                            font-weight: 500;
                        }

                        &-btn {
                            font-family: $f-museo-500;
                            font-size: 14px;
                            line-height: 17px;
                            color: $c-grey-light2;
                            background-color: $c-grey-light;
                            padding: 9px 14px;
                        }
                    }
                }
            }

            &[data-type="others"] {
                #{$block}-head {
                    background-color: $c-pink-fid;
                    color: $c_white;

                    &-title {
                        & > span {
                            color: $c_white;
                        }
                    }
                }
            }

            &[data-type="100pts"] {
                #{$block}-head {
                    background-color: $c-blue-bg-fid;
                    color: $c-black;

                    &-title {
                        & > span {
                            color: $c-blue-fid;
                        }
                    }
                }

                #{$block}-list {
                    &-item-content-point {
                        border: 1px solid $c-blue-bg-fid;
                        color: $c-blue-fid;
                    }
                }
            }

            &[data-type="200pts"] {
                #{$block}-head {
                    background-color: $c-orange-bg-fid;
                    color: $c-black;

                    &-title {
                        & > span {
                            color: $c-orange-fid;
                        }
                    }
                }

                #{$block}-list {
                    &-item-content-point {
                        border: 1px solid $c-orange-bg-fid;
                        color: $c-orange-fid;
                    }
                }
            }

            &[data-type="400pts"] {
                #{$block}-head {
                    background-color: $c-red-bg-fid;
                    color: $c-black;

                    &-title {
                        & > span {
                            color: $c-red-fid;
                        }
                    }
                }

                #{$block}-list {
                    &-item-content-point {
                        border: 1px solid $c-red-bg-fid;
                        color: $c-red-fid;
                    }
                }
            }
        }
    }

    &-actions {
        &-btn.btn {
            width: 250px;
            max-width: 100%;
            margin: 30px auto 0;
        }
    }

    @include breakpoint(only-lg) {
        &-head {
            text-align: center;
            padding: 30px 40px 20px;

            &-container {
                display: block;

                &-points {
                    text-align: center;
                    background: none;
                    width: initial;
                    height: initial;
                    margin: 10px 0 0;

                    &-content {
                        position: relative;
                        left: initial;
                        top: initial;
                        transform: none;

                        &-number,
                        &-label {
                            font-size: 42px;
                            display: inline-block;
                            vertical-align: middle;
                        }

                        &-label {
                            margin: 0 0 -5px 5px;
                        }
                    }
                }

                &-txt {
                    width: 100%;
                }
            }
        }

        &-offers {
            &-block {
                &-head {
                    padding: 15px 20px;
                    font-size: 16px;
                    line-height: 22px;

                    img {
                        width: 30px;
                        height: 22px;
                    }
                }

                &-list {
                    &-item {
                        padding: 20px;
                        flex-wrap: wrap;

                        &-img {
                            width: 50px;
                            height: 50px;
                            margin-right: 15px;
                        }

                        &-content {
                            width: calc(100% - 65px);
                            margin: 0;
                            &-title {
                                font-size: 14px;
                            }
                            &-txt {
                                display: block;
                                margin-top: 5px;
                                margin-bottom: 0;
                            }
                        }

                        &-cta {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            margin-top: 20px;
                            width: 100%;

                            &-txt {
                                margin-right: 10px;
                                margin-bottom: 0;
                            }
                        }

                        &-actions {
                            margin-top: 20px;
                            margin-bottom: 5px;
                            display: block;
                            width: 100%;
                            text-align: center;

                            &-btn  {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(only-md) {
        &-head {
            margin-top: -40px;
        }
    }

    @include breakpoint(only-sm) {
        &-offers {
            &-block-list-item {
                &-cta {
                    min-width: 160px;
                    display: block;
                    text-align: center;

                    &-txt {
                        margin-right: 0;
                        margin-bottom: 10px;
                    }

                    &-btn {
                        display: inline-block;
                    }
                }
            }
        }
    }

    @media (max-width: 400px) {
        &-head {
            margin-top: -20px;
        }
    }
}

