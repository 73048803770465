// ==============================================
// FORMS - Tooltip
// ==============================================

//
//  Configuration
//  _____________________________________________

$tooltip-btn-width:             25px;
$tooltip-btn-border-color:      lighten($c-black, 80%);
$tooltip-btn-bg-color:          $c-white;
$tooltip-btn-icon-color:        lighten($c-black, 80%);
$tooltip-btn-icon-size:         2rem;

$tooltip-box-width:             270px;
$tooltip-box-border-color:      lighten($c-black, 80%);
$tooltip-box-bg-color:          $c-white;
$tooltip-box-radius:            $radius-input;
$tooltip-box-padding:           10px;

.control._with-tooltip {
    position: relative;

    input {
        margin-right: 10px;
        width: calc(100% - #{$tooltip-btn-width} - 12px);
    }

    .note {
        display: block;
        margin-top: 10px;
    }
}

.field-tooltip {
    position: absolute;
    top: 7px;
    right: 0;
    cursor: pointer;

    .label {
        span {
            @extend .visually-hidden;
        }
    }

    // Button
    .field-tooltip-action {
        display: inline-block;
        text-decoration: none;

        // Button icon
        &::before {
            content: '?';
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: $tooltip-btn-width;
            height: $tooltip-btn-width;
            border-radius: 50%;
            border: 1px solid $tooltip-btn-border-color;
            color: $tooltip-btn-icon-color;
            font-size: $tooltip-btn-icon-size;
            vertical-align: middle;
            overflow: hidden;
            background-color: $tooltip-btn-bg-color;
            transition: color .3s, border .3s;
        }
    }

    // On button click
    &._active {
        .field-tooltip-content {
            display: block;
        }
    }

    // On button click or hover
    &._active .field-tooltip-action,
    .field-tooltip-action:hover {
        &::before {
            border: 1px solid lighten($c-black, 30%);
            color: lighten($c-black, 30%);
        }
    }

    // Tooltip box
    .field-tooltip-content {
        display: none;
        position: absolute;
        right: 0;
        bottom: 32px;
        width: $tooltip-box-width;
        max-width: calc(100vw - #{$gutter});
        font-size: 1.4rem;
        line-height: 1.2;
        padding: $tooltip-box-padding;
        word-wrap: break-word;
        border-radius: $tooltip-box-radius;
        border: 1px solid $tooltip-box-border-color;
        background: $tooltip-box-bg-color;
        z-index: 2;
    }

    .stock-info-content & {
        position: relative;
        top: 0;

        .field-tooltip-content {
            bottom: 0;
            right: -10px;
            transform: translateY(100%);
        }
    }
}
