:root {
    --vh: 100vh;
}
.mt-l-HeaderNav {
    &-head {
        display: none;
        background-color: $c-new-red;
        color: $c-white;
        padding: 15px;
        position: relative;
        z-index: 3;

        & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &-title {
            font-family: $f-museo-500;
            @include fs(20px, 24px);
        }

        &-action {
            cursor: pointer;
            display: block;
            width: 34px;
            height: 34px;
            border: 1px solid rgba($c-white, 0.5);
            border-radius: 100%;
            padding: 10px;
            @include fs(13px);
        }

        &:before,
        &:after {
            content: '';
            width: 25px;
            height: 25px;
            display: block;
            position: absolute;
            z-index: 6;
            background-size: cover;
            top: calc(100% - 1px);
        }

        &:before {
            background-image: url('../images/icon/arrondis-inte-left.svg');
            left: 0;
        }

        &:after {
            background-image: url('../images/icon/arrondis-inte-right.svg');
            right: 0;
        }
    }

    &-entries {
        $entries: &;
        background-color: $c-white;
        border-top: 1px solid $c-new-grey-4;
        border-bottom: 1px solid $c-new-grey-4;
        pointer-events: initial;

        & > div {
            position: relative;
            z-index: 2;
        }

        &-list {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &-item {
                $item: &;
                border-left: 1px solid $c-new-grey-4;

                &:last-child {
                    border-right: 1px solid $c-new-grey-4;
                }

                a {
                    display: block;
                    padding: 13px 20px 14px;
                    font-family: $f-avenir-medium;
                    color: $c-grey-dark;
                    @include fs(16px, 22px);
                }

                &[data-link="products"] {
                    width: 265px;
                    border-left: none;
                    pointer-events: none;
                    position: relative;

                    #{$item}-close {
                        position: absolute;
                        right: 15px;
                        top: 12px;
                        display: none;
                        @include fs(20px, 20px);
                        @include transition(ease-in 0.25s);
                        cursor: pointer;

                        &:hover {
                            color: $c-new-red;
                        }
                    }

                    a {
                        padding: 12px 0;

                        &[data-current="true"] + span {
                            display: block;
                        }
                    }
                }

                &[data-link="simple"],
                &[data-link="tabs"] {
                    & > a {
                        position: relative;
                        @include transition(ease-out 0.5s);

                        &:before {
                            content: '';
                            width: 0;
                            height: 3px;
                            background-color: $c-new-pink;
                            @include transition(width ease-out 0.25s);
                            @include center-abs-x();
                            bottom: 0;
                        }
                    }

                    &[data-show="true"],
                    &:hover {
                        & > a {
                            color: $c-new-red;
                            background-color: $c-pink-light;

                            &:before {
                                width: 100%;
                            }
                        }
                    }
                }

                &[data-link="tabs"] {
                    &[data-show="true"] {
                        #{$entries}-subNav {
                            left: -20px;
                        }
                    }
                }

                &[data-color="pink"] {
                    a {
                        color: $c-new-pink;
                    }
                }
            }
        }

        &-subNav {
            position: absolute;
            background-color: $c-white;
            left: -9999px;
            @include transition(ease-in-out 0.8s);
            max-width: calc(100% + 40px);
            top: calc(100% + 1px);
            padding: {
                top: 30px;
                bottom: 75px;
                left: 20px;
            }
            box-shadow: 0 15px 20px 0 rgba($c-black, 0.2);

            @media screen and (max-width: 1575px) {
                overflow-y: scroll;
                overflow-x: hidden;
                max-height: calc(100vh - 135px);

                &::-webkit-scrollbar {
                    width: 2px;
                    height: 0;

                    &-track {
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                    }

                    &-thumb {
                        background-color: $c-new-red;
                        outline: 1px solid $c-new-red;
                    }
                }
                // Firefox
                scrollbar-width: thin;
                scrollbar-color: $c-new-red rgba(0, 0, 0, 0.1);
            }

            @media screen and (min-height: 1000px) {
                overflow: initial;
            }

            &:before,
            &:after {
                content: '';
                display: block;
                background-color: $c-white;
                position: absolute;
                height: 100%;
                top: 0;
            }

            &:before {
                right: 100%;
                width: calc(#{(1520px/2) + 265px});
                box-shadow: 0 15px 20px 0 rgba($c-black, 0.2);
                z-index: 1;
            }

            &:after {
                left: 0;
                width: 20px;
                z-index: 2;
            }

            &-close {
                position: absolute;
                right: 40px;
                top: 25px;
                @include fs(25px, 25px);
                @include transition(ease-in 0.25s);
                cursor: pointer;

                &:hover {
                    color: $c-new-red;
                }
            }

            &-title {
                font-family: $f-museo-500;
                @include fs(20px, 24px);
                color: $c-grey-dark;
                margin-bottom: 25px;

                &-icon {
                    position: relative;
                    display: none;
                    margin-right: 10px;
                    width: 24px;
                    height: 24px;

                    &:before {
                        position: relative;
                        z-index: 2;
                        color: $c-white;
                        @include fs(10px, 11px);
                        @include rotate(-90deg);
                        margin: 0 auto;
                        display: block;
                    }

                    &:after {
                        content: '';
                        display: block;
                        background-image: url('../images/icon/patate-pink.svg');
                        background-size: cover;
                        @include center-abs-xy();
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                    }
                }
            }

            &-content {
                display: flex;

                &-list {
                    width: 265px;

                    li {
                        display: block;

                        & > a {
                            padding: 0;
                            font-family: $f-avenir;
                            @include fs(14px, 19px);
                            font-weight: 300;
                            color: $c-black;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        & + li {
                            margin-top: 20px;
                        }
                    }
                }

                &-push {
                    width: calc(100% - 265px);
                    display: flex;
                    border-left: 1px solid $c-new-grey-5;
                    padding-left: 40px;
                    padding-right: 40px;

                    &-main {
                        width: 630px;
                        min-width: calc(100% - 330px);
                        display: flex;
                        flex-wrap: wrap;
                        margin-right: 30px;

                        &-item {
                            $item: &;
                            overflow: hidden;

                            & > a {
                                @include fs0();
                                position: relative;
                            }


                            &[data-size="small"] {
                                width: calc(50% - #{30px/2});
                                min-height: 255px;
                                margin-bottom: 30px;

                                #{$item}-title {
                                    font-family: $f-avenir-medium;
                                    @include fs(16px, 20px);
                                    margin-top: 10px;
                                }

                                #{$item}-img {
                                    overflow: hidden;
                                    position: relative;

                                    img {
                                        @include scale(1);
                                        @include transition(ease-out 0.5s);
                                    }
                                }

                                &:hover {
                                    #{$item}-img {
                                        img {
                                            @include scale(1.2);
                                        }
                                    }
                                }

                                & + [data-size="small"] {
                                    margin-left: 30px;
                                }
                            }

                            &[data-size="large"] {
                                width: 100%;

                                & > a {
                                    &:before {
                                        content: '';
                                        display: block;
                                        background: linear-gradient(270deg, transparent 0%, $c-black 60%);
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        left: 0;
                                        top: 0;
                                        opacity: 0.6;
                                    }
                                }

                                #{$item}-content {
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    left: 0;
                                    top: 0;
                                    padding: 20px 30px;
                                    z-index: 3;

                                    &-title {
                                        font-family: $f-museo-500;
                                        color: $c-white;
                                        @include fs(22px, 27px);
                                        margin-bottom: 5px;
                                        display: block;
                                    }

                                    &-desc {
                                        font-family: $f-avenir;
                                        font-weight: 300;
                                        @include fs(14px, 20px);
                                        color: $c-white;
                                        width: 425px;
                                        max-width: 100%;
                                        margin-bottom: 15px;
                                    }
                                }

                                #{$item}-img {
                                    min-height: 200px;

                                    &:before {
                                        content: '';
                                        display: block;
                                        background: linear-gradient(270deg, transparent 0%, $c-black 100%);
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        left: 0;
                                        top: 0;
                                        opacity: 0.6;
                                        z-index: 2;
                                    }

                                    img {
                                        @include center-abs-xy();
                                        width: auto;
                                        max-width: initial;
                                        height: 100%;
                                    }
                                }
                            }

                            & > a {
                                padding: 0;
                            }
                        }
                    }

                    &-aside {
                        width: 312px;

                        &-item {
                            $item: &;

                            & > a {
                                @include fs0();
                                position: relative;
                                overflow: hidden;
                                padding: 0;

                                &:before {
                                    content: '';
                                    display: block;
                                    background: linear-gradient(540deg, transparent 0%, $c-black 100%);
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    left: 0;
                                    top: 0;
                                    opacity: 0.6;
                                    z-index: 2;
                                }
                            }

                            &-img {
                                overflow: hidden;
                            }

                            &-content {
                                padding: 20px;
                                position: absolute;
                                z-index: 3;
                                width: 100%;
                                left: 0;
                                bottom: 0;

                                &-title {
                                    font-family: $f-museo-500;
                                    color: $c-white;
                                    @include fs(22px, 27px);
                                    margin-bottom: 5px;
                                    display: block;
                                }

                                &-desc {
                                    font-family: $f-avenir;
                                    font-weight: 300;
                                    @include fs(14px, 20px);
                                    color: $c-white;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-products {
        $products: &;
        pointer-events: none;
        position: absolute;
        @include transition(ease-in-out 0.8s);
        z-index: 1;
        width: 100%;

        & > div {
            position: relative;
            z-index: 1;
        }

        &-head {
            display: none;
            padding: 20px;
            margin-bottom: 5px;
            background-color: $c-pink-light;

            &-icon {
                position: relative;
                display: block;
                margin-right: 10px;
                width: 24px;
                height: 24px;

                &:before {
                    position: relative;
                    z-index: 2;
                    color: $c-white;
                    @include fs(10px, 11px);
                    @include rotate(-90deg);
                    margin: 0 auto;
                    display: block;
                }

                &:after {
                    content: '';
                    display: block;
                    background-image: url('../images/icon/patate-pink.svg');
                    background-size: cover;
                    @include center-abs-xy();
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
            }

            &-icon,
            &-title {
                display: inline-block;
                vertical-align: text-bottom;
            }

            &-title {
                color: $c-grey-dark;
                font-family: $f-museo-500;
                @include fs(16px, 24px);
            }
        }

        &-container {
            width: 265px;
            padding-right: 10px;

            &:before,
            &:after {
                content: '';
                display: block;
                background-color: $c-white;
                position: absolute;
            }

            &:before {
                height: calc(100% + 10px);
                top: 0;
                width: calc((265px * 2) + 1520px / 2);
                right: calc(100% - 265px);
                z-index: -1;
            }

            &:after {
                height: 10px;
                z-index: -1;
                top: -10px;
                width: 1520px;
                right: calc(100% - 265px);
            }
        }

        &-list {
            background-color: $c-white;
            width: calc(100% + 20px);
            height: 622px;
            max-height: calc(100vh - 200px);
            overflow-y: scroll;
            overflow-x: hidden;
            margin: 10px 0 10px -20px;
            padding: 10px 0 10px 10px;
            pointer-events: initial;

            &::-webkit-scrollbar {
                width: 2px;
                height: 0;

                &-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                }

                &-thumb {
                    background-color: $c-new-red;
                    outline: 1px solid $c-new-red;
                }
            }
            // Firefox
            scrollbar-width: thin;
            scrollbar-color: $c-new-red rgba(0, 0, 0, 0.1);

            &-item {
                width: 100%;
                padding-right: 15px;

                &[data-show="true"] {
                    #{$products}-subNav {
                        left: 265px;
                        overflow-x: hidden;
                        overflow-y: scroll;
                    }

                    & > a {
                        border-radius: 24.5px;
                        background-color: $c-white;
                        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

                        & > span {
                            color: $c-new-red;

                        }

                        &::before {
                            color: $c-white;
                            background-image: url('../images/icon/patate-red.svg');
                        }

                        &::after {
                            opacity: 1;
                            @include translate(0, -50%);
                        }
                    }
                }

                & > a {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    min-height: 55px;
                    position: relative;
                    @include transition(ease-out 0.25s);

                    &::before {
                        display: block;
                        @include icomoon;
                        font-size: 2rem;
                        text-align: center;
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        border-radius: 100%;
                        margin-right: 10px;
                        background: {
                            image: url('../images/icon/patate-grey.svg');
                            size: cover;
                            repeat: no-repeat;
                        }
                        position: absolute;
                        @include transition(ease-in 0.25s);
                        @include center-abs-y();
                        left: 10px;
                    }

                    &::after {
                        content: "\e927";
                        display: block;
                        @include icomoon;
                        font-size: 2.5rem;
                        @include center-abs-y();
                        @include transform(translate(-10px, -50%));
                        @include transition(ease-out 0.25s);
                        opacity: 0;
                        right: 10px;
                    }

                    & > span {
                        display: block;
                        @include fs(15px, 17px);
                        @include transition(ease-out 0.25s);
                        color: $c-grey-dark;
                        font-family: $f-avenir-medium;
                        font-weight: 300;
                        pointer-events: none;
                        padding: 0 40px 0 50px;
                    }
                }
            }
        }

        &-subNav {
            $subNav: &;
            position: absolute;
            background-color: $c-pink-light;
            left: -9999px;
            top: -10px;
            @include transition(ease-in-out 0.6s);
            padding: {
                top: 25px;
                bottom: 30px;
                left: 30px;
                right: 40px;
            }
            box-shadow: 5px 15px 20px 0 rgba($c-black, 0.2);
            width: calc(100% - 245px);
            max-width: 1100px;
            z-index: -2;
            overflow-y: scroll;
            overflow-x: initial;
            height: calc(100% + 20px);
            max-height: calc(100vh - 162px);

            &::-webkit-scrollbar {
                width: 2px;
                height: 0;

                &-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                }

                &-thumb {
                    background-color: $c-new-red;
                    outline: 1px solid $c-new-red;
                }
            }
            // Firefox
            scrollbar-width: thin;
            scrollbar-color: $c-new-red rgba(0, 0, 0, 0.1);

            &-head {
                display: block;
                margin-bottom: 20px;

                &-close {
                    position: absolute;
                    right: 40px;
                    top: 25px;
                    @include fs(25px, 25px);
                    @include transition(ease-in 0.25s);
                    cursor: pointer;
                    opacity: 1;
                    pointer-events: initial;

                    &[data-hide="true"] {
                        opacity: 0;
                        pointer-events: none;
                    }

                    &:hover {
                        color: $c-new-red;
                    }
                }

                &-title {
                    font-family: $f-museo-500;
                    @include fs(20px, 24px);
                    margin-right: 9px;
                    color: $c-black;

                    &-txt,
                    &-icon {
                        display: inline-block;
                        vertical-align: bottom;
                    }

                    &-icon {
                        display: none;
                        position: relative;
                        margin-right: 10px;
                        width: 24px;
                        height: 24px;

                        &:before {
                            position: relative;
                            z-index: 2;
                            color: $c-white;
                            @include fs(10px, 11px);
                            @include rotate(-90deg);
                            margin: 0 auto;
                            display: block;
                        }

                        &:after {
                            content: '';
                            display: block;
                            background-image: url('../images/icon/patate-pink.svg');
                            background-size: cover;
                            @include center-abs-xy();
                            width: 100%;
                            height: 100%;
                            z-index: 1;
                        }
                    }
                }

                &-link {
                    padding-top: 5px;
                }

                &-title,
                &-link {
                    display: inline-block;
                    vertical-align: middle;
                }

                &-shortcut {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    width: calc(100% + 60px);
                    margin-left: -30px;
                    overflow: scroll;
                    padding-left: 30px;
                    padding-right: 30px;

                    &::-webkit-scrollbar {
                        width: 0;
                        height: 0;
                    }
                    // Firefox
                    scrollbar-width: none;

                    &-item {
                        a {
                            display: block;
                            background-color: $c-new-pink;
                            @include transition(ease-out 0.5s);
                            border-radius: 15px;
                            color: $c-white;
                            padding: 6px 12px 5px;
                            text-transform: uppercase;
                            @include fs(13px, 15px);
                            white-space: nowrap;

                            &:hover {
                                background-color: $c-new-pink-2;
                            }
                        }

                        & + li {
                            margin-left: 10px;
                        }
                    }
                }
            }

            &-content {
                display: flex;
                align-items: flex-start;

                &-list {
                    width: calc(100% - 330px);
                    display: flex;

                    &-column {
                        width: 25%;

                        & + & {
                            margin-left: 30px;
                        }

                        &-title {
                            font-family: $f-avenir;
                            font-weight: bold;
                            @include fs(14px, 20px);
                            margin-bottom: 5px;
                            color: $c-black;
                        }

                        ul li {
                            font-family: $f-avenir;
                            @include fs(14px, 30px);

                            a:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                &-push {
                    width: 300px;
                    margin-left: 30px;

                    &-item {
                        $item: &;

                        & + & {
                            margin-top: 30px;
                        }

                        & > a {
                            @include fs0();
                            display: inline-block;
                            position: relative;
                            overflow: hidden;
                            padding: 0;

                            &:before {
                                content: '';
                                display: block;
                                background: linear-gradient(540deg, transparent 0%, $c-black 100%);
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                left: 0;
                                top: 0;
                                opacity: 0.6;
                                z-index: 2;
                            }
                        }

                        &-img {
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: auto;
                                @include scale(1);
                                @include transition(ease-out 0.5s);
                            }
                        }

                        &:hover {
                            #{$item}-img {
                                img {
                                    @include scale(1.2);
                                }
                            }
                        }

                        &-content {
                            padding: 20px;
                            position: absolute;
                            z-index: 3;
                            width: 100%;
                            left: 0;
                            bottom: 0;

                            &-title {
                                font-family: $f-museo-500;
                                color: $c-white;
                                @include fs(20px, 24px);
                                margin-bottom: 10px;
                                display: block;
                            }

                            &-desc {
                                margin-bottom: 0;
                                font-family: $f-avenir;
                                font-weight: 300;
                                @include fs(14px, 16px);
                                color: $c-white;
                            }
                        }
                    }
                }
            }

            &[data-type="custom"] {
                padding: 0;
                width: 360px;

                #{$subNav} {
                    &-container {
                        height: 100%;
                        position: relative;
                        z-index: 1;
                    }

                    &-column {
                        height: 100%;
                        background-color: $c-pink-light;
                        overflow: scroll;
                        padding: {
                            top: 25px;
                            bottom: 33px;
                            left: 30px;
                            right: 30px;
                        }

                        &::-webkit-scrollbar {
                            width: 2px;
                            height: 0;

                            &-track {
                                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                            }

                            &-thumb {
                                background-color: $c-new-red;
                                outline: 1px solid $c-new-red;
                            }
                        }
                        // Firefox
                        scrollbar-width: thin;
                        scrollbar-color: $c-new-red rgba(0, 0, 0, 0.1);

                        #{$subNav}-head {
                            margin-bottom: 15px;

                            &-close {
                                right: 30px;
                            }

                            &-link {
                                display: block;
                                margin-top: 5px;
                            }
                        }

                        &-list {
                            margin-bottom: 20px;

                            &-item {
                                @include transition(ease-in-out 0.5s);
                                border-radius: 24.5px;
                                margin-left: -13px;
                                width: calc(100% + 26px);
                                overflow: hidden;

                                & > a {
                                    display: block;
                                    position: relative;
                                    padding: 10px 13px;

                                    & > span {
                                        font-family: $f-avenir;
                                        color: $c-grey-dark;
                                        @include fs(14px, 20px);
                                        font-weight: 300;
                                        pointer-events: none;
                                    }

                                    &::after {
                                        content: "\e927";
                                        display: block;
                                        @include icomoon;
                                        font-size: 2.5rem;
                                        @include center-abs-y();
                                        @include transform(translate(-10px, -50%));
                                        @include transition(ease-out 0.25s);
                                        opacity: 0;
                                        right: 13px;
                                    }
                                }

                                &[data-show="true"] {
                                    background-color: $c-white;
                                    box-shadow: 0 2px 10px 0 rgba($c-black, 0.1);

                                    & > a {
                                        background-color: $c-white;
                                        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
                                        font-weight: 500;

                                        & > span {
                                            color: $c-new-red;
                                        }

                                        &::after {
                                            opacity: 1;
                                            @include translate(0, -50%);
                                        }
                                    }

                                    #{$subNav}-subCateg {
                                        left: 360px;
                                        box-shadow: 5px 15px 20px 0 rgba($c-black, 0.2);
                                    }
                                }
                            }
                        }

                        &-push {
                            position: relative;

                            & > a {
                                @include fs0();
                                display: inline-block;
                                position: relative;
                                overflow: hidden;
                                padding: 0;

                                &:before {
                                    content: '';
                                    display: block;
                                    background: linear-gradient(540deg, transparent 0%, $c-black 100%);
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    left: 0;
                                    top: 0;
                                    opacity: 0.6;
                                    z-index: 2;
                                }
                            }

                            &-img {
                                overflow: hidden;

                                img {
                                    @include scale(1);
                                    @include transition(ease-out 0.5s);
                                }
                            }

                            &:hover {
                                img {
                                    @include scale(1.2);
                                }

                            }

                            &-content {
                                padding: 20px;
                                position: absolute;
                                z-index: 3;
                                width: 100%;
                                left: 0;
                                bottom: 0;

                                &-title {
                                    font-family: $f-museo-500;
                                    color: $c-white;
                                    @include fs(20px, 24px);
                                    margin-bottom: 10px;
                                    display: block;
                                }

                                &-desc {
                                    margin-bottom: 0;
                                    font-family: $f-avenir;
                                    font-weight: 300;
                                    @include fs(14px, 16px);
                                    color: $c-white;
                                }
                            }
                        }
                    }

                    &-subCateg {
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: $c-white;
                        @include transition(ease-in-out 0.5s);
                        width: 360px;
                        padding: 25px 20px;
                        height: 100%;
                        z-index: -1;
                        overflow-y: scroll;
                        overflow-x: initial;
                        max-height: calc(100vh - 162px);

                        &::-webkit-scrollbar {
                            width: 2px;
                            height: 0;

                            &-track {
                                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                            }

                            &-thumb {
                                background-color: $c-new-red;
                                outline: 1px solid $c-new-red;
                            }
                        }

                        // Firefox
                        scrollbar-width: thin;
                        scrollbar-color: $c-new-red rgba(0, 0, 0, 0.1);

                        &-head {
                            display: block;
                            margin-bottom: 30px;

                            &-close {
                                position: absolute;
                                right: 20px;
                                top: 25px;
                                @include fs(25px, 25px);
                                @include transition(ease-in 0.25s);
                                cursor: pointer;

                                &:hover {
                                    color: $c-new-red;
                                }
                            }

                            &-title {
                                font-family: $f-museo-500;
                                @include fs(20px, 24px);
                                margin-right: 9px;
                                color: $c-black;

                                &-icon {
                                    display: none;
                                }
                            }

                            &-link {
                                display: block;
                                margin-top: 5px;
                            }
                        }

                        &-list {
                            &-item {
                                display: block;
                                font-family: $f-avenir;
                                @include fs(14px, 20px);

                                a:hover {
                                    text-decoration: underline;
                                }

                                & + li {
                                    margin-top: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &[data-open="true"] {
            #{$products}-subNav {
                &[data-type="custom"] {
                    overflow: initial;
                }
            }
        }

        &[data-hide="true"] {
            #{$products}-container {
                &::before {
                    z-index: 1;
                    width: 275px;
                    height: 100%;
                    right: calc(100% - 255px);
                    background-color: rgba($c-black, 0.3);
                }

                &:after {
                    opacity: 0;
                }
            }
        }
    }

    @include breakpoint(only-xl) {
        background-color: $c-new-red;
        &-head {
            display: block;
        }
        &-entries {
            $entries: &;
            padding: 0;

            &-list {
                display: block;

                &-item {
                    &[data-link="products"] {
                        pointer-events: initial;

                        a {
                            font-family: $f-avenir-medium;
                        }
                    }

                    &[data-link="products"],
                    &[data-link="simple"],
                    &[data-link="tabs"] {
                        display: block;
                        width: 100%;

                        & > a {
                            position: relative;
                            @include fs(16px, 20px);
                            padding: 20px 15px;

                            &::before {
                                display: none;
                            }

                            &::after {
                                content: "\e927";
                                display: block;
                                @include icomoon;
                                font-size: 2.5rem;
                                @include center-abs-y();
                                @include transition(ease-out 0.25s);
                                right: 20px;
                                color: $c-grey-dark;
                            }

                            &:hover {
                                background-color: transparent;
                            }
                        }

                        &:not([data-color="pink"]) {
                            & > a {
                                &:hover {
                                    color: $c-grey-dark;
                                }
                            }
                        }
                    }

                    &[data-link="tabs"] {
                        &[data-show="true"],
                        &:hover {
                            & > a {
                                background-color: $c-white;
                                color: $c-grey-dark;
                            }
                        }

                        &[data-show="true"] {
                            #{$entries}-subNav {
                                top: 0;
                                left: 0;
                            }
                        }
                    }

                    & + li {
                        border-bottom: 1px solid $c-new-grey-4;
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }

            &-subNav {
                z-index: 2;
                width: 100%;
                left: 0;
                top: -9999px;
                padding: 0;
                max-height: initial;
                overflow-x: hidden;
                overflow-y: scroll;
                height: calc(100vh - 64px);

                &::-webkit-scrollbar {
                    width: 2px;
                    height: 0;

                    &-track {
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                    }

                    &-thumb {
                        background-color: $c-new-red;
                        outline: 1px solid $c-new-red;
                    }
                }

                // Firefox
                scrollbar-width: thin;
                scrollbar-color: $c-new-red rgba(0, 0, 0, 0.1);

                &:before,
                &:after {
                    display: none;
                }

                &-close {
                    display: none;
                }

                &-title {
                    display: block;
                    padding: 15px;
                    background-color: $c-pink-light;
                    margin-bottom: 20px;
                    @include fs(16px, 24px);
                    cursor: pointer;

                    &-txt,
                    &-icon {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }

                &-content {
                    display: block;

                    &-list {
                        width: calc(100% - 40px);
                        margin: 0 15px 20px;
                        padding-bottom: 20px;
                        border-bottom: 1px solid $c-new-grey-4;

                        li {
                            & > a {
                                padding-top: 0;
                            }

                            & + li {
                                margin-top: 20px;
                            }
                        }
                    }

                    &-push {
                        width: 100%;
                        display: block;
                        padding: 0 15px 25px;
                        position: relative;
                        border: none;

                        &-main {
                            width: 100%;
                            min-width: initial;
                            margin: 0;

                            &-item {
                                $item: &;
                                margin-bottom: 20px;

                                &[data-size="small"] {
                                    width: calc(50% - 10px);
                                    margin-bottom: 20px;

                                    & + [data-size="small"] {
                                        margin-left: 20px;
                                    }
                                }

                                &[data-size="large"] {
                                    width: calc(50% - 10px);
                                    margin-bottom: 0;

                                    #{$item}-img {
                                        min-height: initial;
                                        padding-top: calc(#{(450/668)*100%});
                                    }

                                    #{$item}-content {
                                        height: initial;
                                        top: initial;
                                        bottom: 0;
                                        padding: 20px;
                                    }
                                }

                                &-content {
                                    &-desc {
                                        display: none;
                                    }
                                }

                                &-img {
                                    img {
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        &-aside {
                            position: absolute;
                            right: 20px;
                            bottom: 25px;
                            width: calc(50% - 30px);

                            &-item {
                                & > a {
                                    &:before {
                                        background: linear-gradient(270deg, transparent 0%, $c-black 60%);
                                    }
                                }

                                &-img {
                                    min-height: initial;
                                    padding-top: calc(#{(450/660)*100%});
                                    height: 0;

                                    img {
                                        @include center-abs-xy();
                                        width: 100%;
                                        height: auto;
                                    }
                                }

                                &-content {
                                    height: initial;
                                    top: initial;
                                    bottom: 0;
                                    padding: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-products {
            $products: &;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            width: 100%;
            @include transition(ease-in-out 0.5s);
            position: absolute;
            top: -9999px;
            z-index: 2;
            pointer-events: initial;
            background-color: $c-white;
            padding: 0;
            overflow-x: hidden;
            overflow-y: scroll;
            height: calc((var(--vh, 1h)* 100) - 54px);

            &::-webkit-scrollbar {
                width: 2px;
                height: 0;

                &-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                }

                &-thumb {
                    background-color: $c-new-red;
                    outline: 1px solid $c-new-red;
                }
            }

            // Firefox
            scrollbar-width: thin;
            scrollbar-color: $c-new-red rgba(0, 0, 0, 0.1);


            &[data-show="true"] {
                top: 64px;
            }

            &-head {
                display: block;
                cursor: pointer;
                padding: 20px 15px;
                margin-bottom: 0;

                &-close {
                    display: none;
                }
            }

            &-container {
                width: 100%;
                padding: 0 0 10px;

                &:before,
                &:after {
                    display: none;
                }
            }

            &-list {
                width: 100%;
                padding: 0;
                margin: 0;
                height: auto;
                overflow: hidden;
                max-height: initial;

                &-item {
                    padding: 0;

                    & > a {
                        padding: 10px 15px;

                        &:before {
                            left: 20px;
                        }

                        &:after {
                            opacity: 1;
                            right: 20px;
                            @include center-abs-y();
                        }

                        & > span {
                            padding-left: 55px;
                        }
                    }

                    & + li {
                        margin-top: 0;
                    }

                    &[data-show="true"] {
                        & > a {
                            border-radius: 0;
                            box-shadow: none;

                            & > span {
                                color: $c-grey-dark;
                            }

                            &:before {
                                color: $c-grey-dark;
                                background-image: url('../images/icon/patate-grey.svg');
                            }
                        }

                        #{$products}-subNav {
                            top: 0;
                            left: 0;
                            min-height: calc(100vh - 65px);
                            //overflow: initial;
                        }
                    }
                }
            }

            &-subNav {
                $subNav: &;
                background-color: $c-white;
                box-shadow: none;
                width: 100%;
                max-width: initial;
                min-height: 100%;
                z-index: 4;
                left: 0;
                top: -9999px;
                padding: 0;

                &-head {
                    [data-device="mob"] {
                        background-color: $c-pink-light;
                        padding: 15px;
                        margin-bottom: 15px;
                    }

                    &-close {
                        display: none;
                    }

                    &-title {
                        display: block;
                        @include fs(16px, 24px);
                        margin: {
                            right: 0;
                            bottom: 3px;
                        }
                        cursor: pointer;

                        &-icon {
                            margin-right: 6px;
                            display: inline-block;
                        }
                    }

                    &-link {
                        position: relative;
                        z-index: 8;
                        margin-left: 35px;
                    }

                    &-shortcut {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        overflow-x: scroll;
                        overflow-y: hidden;

                        &::-webkit-scrollbar {
                            width: 0;
                            height: 0;
                        }

                        // Firefox
                        scrollbar-width: none;

                        &-item {
                            &:first-child {
                                margin-left: 15px;
                            }

                            &:last-child {
                                margin-right: 15px;
                            }
                        }
                    }
                }

                &-content {
                    display: block;

                    &-list {
                        width: 100%;
                        display: block;
                        padding: 0 15px;

                        &-column {
                            $column: &;
                            width: 100%;
                            border-top: 1px solid $c-new-grey-4;
                            overflow: hidden;

                            & + & {
                                margin-left: 0;
                            }

                            &:last-child {
                                border-bottom: 1px solid $c-new-grey-4;
                            }

                            &-title {
                                cursor: pointer;
                                position: relative;
                                margin: 12px 0;

                                &:after {
                                    content: '\e94e';
                                    color: $c-new-red;
                                    display: block;
                                    width: 12px;
                                    height: 12px;
                                    @include fs(12px, 12px);
                                    @include icomoon();
                                    @include center-abs-y();
                                    right: 0;
                                }
                            }

                            ul {
                                height: 0;
                                @include transition(ease-in-out 0.5s);
                            }

                            &[data-show="true"] {
                                #{$column}-title {
                                    &:after {
                                        content: '\e949';
                                    }
                                }
                            }
                        }
                    }

                    &-push {
                        width: 100%;
                        padding: 0 15px 25px;
                        margin: 20px 0;
                        @include fs0();

                        &-item {
                            display: inline-block;
                            width: calc(50% - 10px);

                            &-img {
                                img {
                                    min-width: 100%;
                                }
                            }

                            & + & {
                                margin-top: 0;
                                margin-left: 20px;
                            }
                        }
                    }
                }

                &[data-type="custom"] {
                    width: 100%;
                    min-height: calc(100% - 64px);

                    #{$subNav}-column {
                        background-color: $c-white;
                        padding: 0;

                        #{$subNav}-head {
                            &-title {
                                margin-bottom: 0;
                            }

                            &-link {
                                margin-top: 0;
                            }
                        }

                        &-list {
                            padding: 0 15px;
                            margin-bottom: 15px;

                            &-item {
                                &[data-show="true"] {
                                    box-shadow: none;

                                    a {
                                        & > span {
                                            color: $c-grey-dark;
                                        }

                                        &:after {
                                            @include center-abs-y();
                                            right: 10px;
                                        }
                                    }

                                    #{$subNav}-subCateg {
                                        left: 0;
                                        top: 0;
                                        box-shadow: none;
                                    }
                                }

                                a {
                                    &:after {
                                        opacity: 1;
                                        right: 0;
                                    }
                                }
                            }
                        }

                        &-push {
                            padding: 0 15px;
                            margin-bottom: 20px;

                            & > a {
                                display: block;
                                width: calc(50% - 10px);
                            }

                            &-img {
                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    #{$subNav}-container {
                        position: initial;
                        width: 100%;
                    }

                    #{$subNav}-subCateg {
                        width: 100%;
                        min-height: calc(100vh - 54px);
                        max-height: initial;
                        z-index: 10;
                        top: -9999px;
                        left: 0;
                        background-color: $c-white;
                        padding: 0;

                        &-head {
                            display: block;
                            padding: 15px 20px;
                            background-color: $c-pink-light;
                            margin-bottom: 0;

                            &-close {
                                display: none;
                            }

                            &-title {
                                cursor: pointer;

                                &-icon {
                                    position: relative;
                                    display: block;
                                    margin-right: 10px;
                                    width: 24px;
                                    height: 24px;

                                    &:before {
                                        position: relative;
                                        z-index: 2;
                                        color: $c-white;
                                        @include fs(10px, 11px);
                                        @include rotate(-90deg);
                                        margin: 0 auto;
                                        display: block;
                                    }

                                    &:after {
                                        content: '';
                                        display: block;
                                        background-image: url('../images/icon/patate-pink.svg');
                                        background-size: cover;
                                        @include center-abs-xy();
                                        width: 100%;
                                        height: 100%;
                                        z-index: 1;
                                    }
                                }

                                &-icon,
                                &-txt {
                                    display: inline-block;
                                    vertical-align: middle;
                                }

                                &-txt {
                                    color: $c-grey-dark;
                                    font-family: $f-museo-500;
                                    @include fs(16px, 24px);
                                }
                            }

                            &-link {
                                margin: {
                                    top: 0;
                                    left: 40px;
                                }
                            }
                        }

                        &-list {
                            padding: 20px;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        &-containerMob {
            border-top-left-radius: 28px;
            border-top-right-radius: 28px;
            position: inherit;
            z-index: 1;
        }
    }

    @include breakpoint(only-md) {
        &-entries {
            $entries: &;

            &-list-item {
                margin: 0;
                &[data-link="products"] {
                    pointer-events: initial;
                    border-bottom: 1px solid $c-grey-light !important;
                }
            }

            &-subNav {
                &-content {
                    &-push {
                        &-main {
                            display: block;

                            &-item {
                                $item: &;
                                margin-bottom: 20px;

                                &[data-size="small"] {
                                    width: 100%;
                                    min-height: initial;
                                    margin-bottom: 20px;

                                    & + [data-size="small"] {
                                        margin-left: 0;
                                    }
                                }

                                &[data-size="large"] {
                                    width: 100%;
                                    margin-bottom: 20px;
                                }
                            }
                        }

                        &-aside {
                            width: 100%;
                            position: relative;
                            right: initial;
                            bottom: initial;
                        }
                    }
                }
            }
        }
        &-products {
            &-subNav {
                $subNav: &;

                &-content {
                    &-push {
                        &-item {
                            display: block;
                            width: 100%;

                            & > a {
                                width: 100%;
                            }

                            & + & {
                                margin-top: 15px;
                                margin-left: 0;
                            }
                        }
                    }
                }

                &[data-type="custom"] {
                    #{$subNav}-column {
                        &-push > a {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

body:not(.cms-home) {
    .mt-l-HeaderNav {
        &-entries-list-item[data-link="products"] {
            a {
                pointer-events: initial;
                position: relative;
                padding-left: 30px;

                &::before {
                    @include icomoon;
                    font-size: 1.8rem;
                    content: "\e948";
                    margin-right: 10px;
                    @include center-abs-y();
                    @include transition(ease-in-out 0.8s);
                    left: 0;
                }
            }
        }

        &-products {
            left: -9999px;

            &[data-show="true"] {
                left: 0;
            }

            &-container {
                padding-right: 0;
            }

            &-list {
                @include transition(ease-in-out 0.7s);
                width: calc(100% + 10px);
                max-width: 275px;
                height: 100%;
            }

            &-subNav {
                min-height: calc(100vh - 64px);

                &[data-type="custom"] .mt-l-HeaderNav-products-subNav-column {
                    min-height: calc(100vh - 64px);
                }

                &-subCateg {
                    min-height: calc(100vh - 64px);
                    height: initial;
                }
            }
        }

        @include breakpoint(only-xl) {
            &-entries-list-item[data-link="products"] {
                & > a {
                    padding-left: 20px;
                }
            }
            &-products {
                left: 0;

                &-list {
                    width: 100%;
                    max-width: initial;
                }
            }
        }
    }

    [data-sticky="true"] {
        .mt-l-HeaderNav {
            &-products {
                &-list {
                    height: 100%;
                }
            }
        }
    }
}

[data-sticky="true"] {
    .mt-l-HeaderNav {
        &-entries {
            $entries: &;
            border: none;

            &-list {
                position: absolute;
                z-index: 4;
                top: -76px;
                left: 160px;

                &-item {
                    border: none;
                    position: relative;

                    &:last-child {
                        border: none;
                    }

                    & > a {
                        padding: 27px 20px 27px;
                    }

                    &[data-link="products"] {
                        width: auto;
                        pointer-events: initial;

                        & > a {
                            padding-left: 30px;
                            padding-right: 20px;
                            margin-right: -1px;

                            &::before {
                                @include icomoon;
                                font-size: 1.8rem;
                                content: "\e948";
                                margin-right: 10px;
                                @include center-abs-y();
                                @include transition(ease-in-out 0.8s);
                                left: 0;
                            }

                            &::after {
                                position: relative;
                                display: inline-block;
                                margin-left: 15px;
                                width: 1px;
                                height: 16px;
                                background: #E4E4E4;
                                content: ' ';
                                top: 3px;
                            }

                            &[data-current="true"] {
                                &::before {
                                    display: none;
                                }

                                & + span {
                                    top: 22px;
                                    right: initial;
                                    left: 0;
                                }
                            }
                        }
                    }

                    &[data-link="simple"],
                    &[data-link="tabs"] {
                        & > a {
                            padding: {
                                left: 0;
                                right: 0;
                            }
                            margin-left: 10px;
                            margin-right: 10px;

                            &:before {
                                bottom: 0;
                            }
                        }

                        &[style] {
                            & > a {
                                margin-left: 20px;
                                margin-right: 20px;
                            }

                            &:hover {
                                & > a {
                                    width: calc(100% - 20px);
                                }
                            }
                        }
                    }

                    &[data-link="simple"] {
                        & + li {
                            & > a {
                                margin-left: 15px;
                            }
                        }
                    }

                    &[data-link="simple"],
                    &[data-link="tabs"],
                    &[data-link="products"] {
                        a {
                            position: initial;
                        }

                        &:hover {
                            & > a {
                                background-color: transparent;
                            }
                        }
                    }

                    &[data-link="tabs"] {
                        &[data-show="true"] {
                            & > a {
                                background-color: transparent;
                            }

                            .mt-l-HeaderNav-entries-subNav {
                                left: 0;
                            }
                        }
                    }
                }
            }

            &-subNav {
                position: fixed;
                max-width: 100%;
                max-height: calc(100vh - 73px);
                top: 73px;
                left: -9999px;
                overflow: hidden;

                &-content {
                    &-push {
                        &-main, &-aside {
                            &-item {
                                position: relative;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
        }

        &-products {
            position: absolute;
            top: 0;
            left: -9999px;
            z-index: 2;
            width: 100%;

            & > div {
                height: 100vh;
            }

            &-list {
                min-height: calc(100vh - 96px);
            }

            &[data-show="true"] {
                left: 0;
            }

            &-subNav {
                max-height: calc(100vh - 76px);

                &[data-type="custom"] {
                    .mt-l-HeaderNav-products-subNav-subCateg {
                        max-height: calc(100vh - 74px);
                    }
                }
            }
        }

        &[data-show="true"] {
            top: 34px;
        }

        @media (max-width: 1355px) {
            &-entries {
                &-list-item {
                    & > a {
                        padding: 20px 15px;
                    }
                    &[data-link="products"] > a:after {
                        display: none;
                    }
                    &[data-link="tabs"][data-show="true"] .mt-l-HeaderNav-entries-subNav {
                        top: 64px;
                    }
                }
                &-subNav {
                    //max-height: 100vh;
                    max-height: calc(100vh - 63px);
                    overflow: scroll;
                }
            }
            &-products {
                top: 54px;
            }
        }

        @include breakpoint(only-xl) {
            &-entries {
                $entries: &;

                &-list {
                    position: relative;
                    top: initial;
                    left: initial;

                    &-item {
                        &[data-link="simple"][data-color="pink"] + li > a {
                            margin: 0;
                            padding-left: 20px;
                        }

                        &[data-link="simple"],
                        &[data-link="tabs"] {
                            & > a {
                                border-bottom: 1px solid $c-new-grey-4;
                            }
                        }

                        &[data-link="products"] {
                            & > a {
                                padding: {
                                    left: 20px;
                                }
                            }
                        }

                        &[data-link="tabs"] {
                            &[data-show="true"] {
                                .mt-l-HeaderNav-entries-subNav {
                                    top: 64px;
                                }
                            }
                        }
                    }
                }
                &-subNav {
                    top: -9999px;
                    left: 0;
                }
            }
            &-products {
                position: fixed;
                left: 0;
                top: -9999px;

                &[data-show="true"] {
                    top: 64px;
                }
            }
            &-list {
                position: initial;
            }
        }
    }
}
