// ==============================================
// VIEW - Product full view
// ==============================================

// Configuration

$product-media-max-width:   680px;
$product-info-main-min-width: 320px;

//
//  Magento native
//  _____________________________________________

.page-layout-1column {

    &.catalog-product-view {

        // Breadcrumbs
        .breadcrumbs .items {
            margin-bottom: 2.5rem;
        }

        // Product title
        .page-title {
            margin-bottom: 1rem;
            @extend .title-1;
            line-height: 1.2;
            color: $c-base;
            text-transform: inherit;

            @include breakpoint(lg) {
                margin-bottom: 2rem;
            }
        }

        .column.main {
            // Reset css of div appended in DOM
            & > div[style*="clear:both;"] {
                height: auto !important;
            }
        }

        /* Product top contents */
        .product-top-contents {
            @include breakpoint(md) {
                display: flex;
                gap: 30px;
            }
            @include breakpoint(md) {
                gap: 70px;
            }
        }

        .product.media {
            @include breakpoint(md) {
                margin-bottom: 3rem;
                max-width: $product-media-max-width;
                width: 45%;
                flex: 1;
            }

            @include breakpoint(xl) {
                margin-bottom: 5rem;
            }
        }

        .product-info-main {
            margin-bottom: 2rem;

            @include breakpoint(only-md) {
                margin-bottom: 1.5rem;
            }

            @include breakpoint(md) {
                flex: 1;
                margin-bottom: 3rem;
                min-width: $product-info-main-min-width;
                width: 55%;
            }
        }

        .box-collateral {

            @include breakpoint(only-lg) {
                margin-top: 3rem;
            }
        }

        @include breakpoint(only-md) {

            .page-main {
                margin-top: 2rem !important;
            }

            .column.main {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

//
//  Product info main
//  _____________________________________________

@import "product-info-main";
@import "product-top-infos";

//
// Product infos details
//  _____________________________________________

@import "product-info-detailed";

//
// Reassurance
// ______________________________________________

@import "product-reassurance";

//
//  Review
//  _____________________________________________

@import "product-review";

//
// Related & Upsel
// ______________________________________________

@import "product-related-upsell";

//
// Sticky footer
// ______________________________________________

@import "product-sticky-footer";

//
// Flowbox
// ______________________________________________

@import "flowbox";
