// ==============================================
// TABLES - Orders
// ==============================================

// Configuration
$account-table-border:              1px solid $border-color;
$account-table-inner-border:        $account-table-border;
$account-table-padding-col:         11px 10px;

%order-table {
    .table-wrapper {
        width: 100%;
        margin-bottom: 20px;
        border-bottom: $account-table-border;
        overflow-x: auto;

        table {
            width: 100%;

            tr {
                th, td {
                    padding: $account-table-padding-col;
                    text-align: left;
                }
            }

            td {
                padding: 8px;
                line-height: 1.5;
                vertical-align: center;
                border-top: $account-table-inner-border;

                &.actions {
                    .action {
                        text-decoration: underline;

                        & + .action {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }

        // Table wrapper mobile
        @include breakpoint(only-md) {

            table {
                thead {
                    tr {
                        th {
                            display: none;
                        }
                    }
                }

                tbody, & tr {
                    display: block;
                }

                tbody {
                    tr {
                        td {
                            display: block;
                            border-bottom: none;
                            padding: 5px 10px;

                            // Show data-th
                            &:not(.actions) {
                                &::before {
                                    padding-right: 10px;
                                    content: attr(data-th) ': ';
                                    display: inline-block;
                                    font-family: $f-avenir-black;
                                }
                            }

                            // Add tbody delimiter
                            & + td:last-child {
                                border-bottom: 1px solid $border-color;
                                padding-bottom: 15px;
                            }
                        }

                        // Disable tbody delimiter
                        & + tr > td:last-child {
                            border: 0;
                        }
                    }
                }
            }
        }
    }
}
