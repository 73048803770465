.mt-c-Link {
    @include fs(14px, 20px);
    color: $c-new-red;
    font-family: $f-avenir-medium;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}
