// ==============================================
// GOLDENEYES
// ==============================================

.mt_goldeneyes-account-create {

    // Page layout
    .page-main {
        @extend .container;

        @include breakpoint(md) {
            margin-top: 0;
        }

        .columns {
            padding-bottom: 2rem;
        }
    }

    // Page title
    .page-title-wrapper {
        @extend .visually-hidden;
    }

    // Fidelity content
    .fidelity-content {

        // Form
        .create-account-section {
            max-width: 45rem;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2.4rem;

            @include breakpoint(md) {
                margin-bottom: 3rem;

                .fieldset {
                    display: flex;
                }

                .field {
                    flex: 1;
                }

                input.input-text,
                button.action.create {
                    height: 4rem;
                    padding-top: 0;
                    padding-bottom: 0;
                    line-height: 4rem;

                    @include breakpoint(md) {
                        height: 5rem;
                        line-height: 5rem;
                    }
                }
            }

            label {
                @extend .visually-hidden;
            }

            .actions-toolbar {
                margin-top: 1rem;

                @include breakpoint(md) {
                    margin-top: 0;
                }
            }
        }

        // Description
        .description-section {
            max-width: 45rem;
            margin-left: auto;
            margin-right: auto;
            text-align: center;

            > p {

                &:first-child {
                    margin-bottom: 2rem;
                }

                &:nth-child(2) {
                    margin-bottom: 3rem;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

//
// Loyalty
// __________________________________________________________________

.loyalty-block {

    // Layout
    @include breakpoint(md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .enter-card-block,
        .new-card-block {
            width: 46%;
        }
    }

    // Message
    .messages {
        width: 100%;

        > li {
            @extend .notification;
            @extend .notification--error;
            margin-bottom: 2rem;
        }
    }

    // Title
    h4.title-2 {
        margin-bottom: 2rem;
        text-align: center;

        @include breakpoint(only-md) {
            margin-bottom: 1.5rem;
        }
    }

    // Btns
    .btn {
        width: 100%;
        margin-top: 2rem;

        @include breakpoint(only-md) {
            margin-top: 1rem;
        }
    }

    // Points
    .fidelity-points {
        font-family: $f-avenir-black;
        color: $c-pink;
    }

    // Connexion
    .enter-card-block {

        @include breakpoint(only-md) {
            margin-bottom: 3rem;
        }

        //input + .mage-error {
        //    margin-top: .5rem;
        //}
    }

    // New
    .new-card-block {

        strong {
            font-family: $f-avenir-black;
        }
    }

    // blank
    .loyalty-blank {
        width: 30rem;
        margin: 0 auto;
        text-align: center;

        .customer-info {
            font-size: 1.6rem;
        }

        .btn {
            margin: 1rem auto;
        }
    }
}

.account.mt_goldeneyes-loyalty-index {

    .loyalty-account-view {
        width: 100%;
    }
}

//
//  Refonte Loyalty
// __________________________________________________________________

.loyalty {
    $loyalty: &;
    display: block;
    margin-bottom: 20px;

    &-head {
        text-transform: inherit;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 5px;
            background-size: cover;
            background-color: $c-pink-light;
            //-webkit-mask-image: url('../images/background/sep-h.png');
            mask-image: url('../images/background/sep-h.png');
            background-position: bottom left;
            background-repeat: repeat-x;
            margin-top: 20px;
        }

        &-title {
            font-size: 30px;
            line-height: 36px;
            font-family: $f-museoSlab-700;
            text-transform: none;
            color: $c-grey-dark2;
        }

        &-card {
            margin-bottom: 0;

            img {
                width: 30px;
                height: 22px;
                margin-right: 10px;
            }

            & > * {
                display: inline-block;
                vertical-align: middle;
            }

            .info {
                text-align: right;
                padding-top: 5px;
                display: block;
                font-weight: 600;
                font-family: $f-museo-700;
                font-size: 20px;
                line-height: 24px;
                text-transform: uppercase;
                color: $c-red;
            }
        }

        &-wallet {
            margin-top: 20px;

            a {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                gap: 10px;
            }

            img {
                border-radius: 8px;
                overflow: hidden;
                height: 48px;
                width: 48px;

                &.gpay {
                    border: 2px solid #ccc;
                }
            }

            .mt-c-Btn {
                padding: 12px 15px;
            }
        }
    }

    &-points {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 50px;

        &-totals {
            width: 170px;
            height: 145px;
            margin-right: 30px;
            text-align: center;
            position: relative;
            background-image: url('../images/account/icon/drawing-circle.svg');
            background-size: contain;
            background-repeat: no-repeat;

            &-content {
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;

                &-number {
                    display: block;
                    font-family: $f-avenir-black;
                    color: $c-red;
                    font-size: 50px;
                    line-height: 60px;
                }

                &-label {
                    margin-top: -20px;
                    display: block;
                    font-family: $f-halohandletter;
                    color: $c-grey-dark2;
                    font-size: 50px;
                    line-height: 54px;
                }
            }
        }

        &-count {
            width: calc(100% - 200px);

            &-bar {
                width: calc(100% - 35px);
                height: 3px;
                margin-top: 45px;
                border-radius: 2px;
                background-color: $c-pink-light;
                margin-bottom: 25px;
                position: relative;

                &-step {
                    $step: &;
                    text-align: center;
                    position: absolute;
                    top: -3px;

                    &:nth-child(1) {
                        left: 33.33%;
                        //&:before {
                        //    transition: ease-out 266ms;
                        //}
                    }

                    &:nth-child(2) {
                        left: 66.66%;
                        //&:before {
                        //    transition: ease-out 532ms;
                        //}
                    }

                    &:nth-child(3) {
                        right: -3px;
                        //&:before {
                        //    transition: ease-out 800ms;
                        //}
                    }

                    &[data-selected="true"] {
                        &:before {
                            background-color: $c-red;
                        }

                        &:nth-child(1) {
                            #{$step}-content span {
                                color: $c-blue-fid;
                            }
                        }

                        &:nth-child(2) {
                            #{$step}-content span {
                                color: $c-orange-fid;
                            }
                        }

                        &:nth-child(3) {
                            #{$step}-content span {
                                color: $c-red-fid;
                            }
                        }

                        #{$step}-content {
                            img {
                                display: none;

                                &[data-active-step] {
                                    display: block;
                                }
                            }

                            span {
                                font-weight: 600;
                            }
                        }
                    }

                    &-content {
                        position: absolute;
                        bottom: calc(100% + 8px);
                        left: 50%;
                        transform: translateX(-50%);
                        min-width: 100px;

                        img {
                            width: 30px;
                            height: 22px;
                            margin: 0 auto 5px;

                            &[data-active-step] {
                                display: none;
                            }
                        }

                        span {
                            display: block;
                            font-size: 12px;
                            line-height: 15px;
                            color: $c-pink-light2;
                        }
                    }

                    &:before {
                        content: '';
                        display: block;
                        width: 10px;
                        height: 10px;
                        background-color: $c-pink-light;
                        transition: ease-out 100ms;
                        border-radius: 100%;
                    }
                }

                &-progress {
                    display: block;
                    width: 0;
                    max-width: 100%;
                    height: 4px;
                    top: 0;
                    left: 0;
                    border-radius: 4px;
                    background-color: $c-red;
                    position: absolute;
                    transition: width ease-out 1200ms;
                }
            }

            &-txt {
                strong {
                    font-size: 16px;
                    font-family: $f-avenir-heavy;
                    color: $c-red;
                }
            }
        }
    }

    &-info {
        background-color: $c-white;
        box-shadow: 2px 2px 10px 0 rgba($c-black, 0.1);
        padding: 50px 80px;

        &-content {
            max-width: 745px;
            margin: 0 auto;

            &-title {
                font-family: $f-avenir-medium;
                text-align: center;
                font-size: 24px;
                line-height: 30px;
                font-weight: 500;
                margin-bottom: 20px;

                & > span {
                    color: $c-red;
                }
            }

            &-txt {
                text-align: center;

                & > * {
                    margin: 10px 0;
                }

                span {
                    font-weight: 600;
                    color: $c-red;
                }

                strong {
                    font-weight: 700;
                    color: $c-black;
                }

                .large {
                    font-family: $f-museo-700;
                    font-size: 20px;
                    line-height: 24px;
                    text-transform: uppercase;
                }

                .custom {
                    font-family: $f-halohandletter;
                    font-size: 30px;
                    line-height: 32px;
                    transform: rotate(-1deg);
                    margin: 20px 0;
                    & > span {
                        font-weight: 500;
                    }
                }

                img {
                    max-width: 100%;
                    width: 560px;
                    height: auto;
                    margin: 0 auto 35px;
                }
            }

            &-rgpd {
                font-size: 12px;
                line-height: 16px;
                color: $c-grey-light2;
            }
        }

        & + #{$loyalty}-offers {
            margin-top: 30px;
        }
    }

    &-offers {
        &-block {
            $block: &;
            display: block;
            width: 100%;
            margin-bottom: 30px;
            background-color: $c-white;
            box-shadow: 2px 2px 10px 0 rgba($c-black, 0.1);

            &-head {
                padding: 15px 25px;
                font-size: 18px;
                line-height: 25px;
                font-family: $f-avenir;

                & > * {
                    display: inline-block;
                    vertical-align: middle;
                }

                img {
                    width: 36px;
                    height: 26px;
                    margin-right: 9px;
                }

                &-title {
                    & > span {
                        font-weight: 600;
                    }
                }
            }

            &-list {
                &-item {
                    padding: 25px 30px;
                    display: flex;
                    align-items: center;

                    & + & {
                        border-top: 1px solid $c-grey-5;
                    }

                    &-img {
                        width: 60px;
                        height: 60px;
                        margin-right: 25px;
                    }

                    &-content {
                        font-family: $f-avenir;
                        margin-right: auto;

                        &-title {
                            font-family: $f-avenir-medium;
                            margin-right: 10px;
                            font-size: 16px;
                            line-height: 22px;
                            color: $c-grey-dark2;

                            & > strong {
                                font-weight: 600;
                            }
                        }

                        &-txt {
                            margin-top: 5px;
                            margin-bottom: 0;
                            display: block;
                            font-size: 14px;
                            line-height: 19px;
                            color: $c-grey-light2;
                        }

                        &-point {
                            font-family: $f-avenir-medium;
                            text-align: center;
                            font-size: 10px;
                            line-height: 14px;
                            border-radius: 10px;
                            padding: 3px 6px;
                            font-weight: 600;
                        }

                        &-title,
                        &-point {
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }

                    &-cta {
                        &-txt,
                        &-btn {
                            display: inline-block;
                            vertical-align: middle;
                        }

                        &-txt {
                            font-family: $f-avenir-medium;
                            font-size: 12px;
                            line-height: 16px;
                            color: $c-error-fid;
                            margin-right: 10px;
                            font-weight: 500;
                        }

                        &-btn {
                            font-family: $f-museo-500;
                            font-size: 14px;
                            line-height: 17px;
                            color: $c-grey-light2;
                            background-color: $c-grey-light;
                            padding: 9px 14px;
                        }
                    }
                }
            }

            &[data-type="others"] {
                #{$block}-head {
                    background-color: $c-pink-fid;
                    color: $c_white;
                }
            }

            &[data-type="100pts"] {
                #{$block}-head {
                    background-color: $c-blue-bg-fid;
                    color: $c-black;

                    &-title {
                        & > span {
                            color: $c-blue-fid;
                        }
                    }
                }

                #{$block}-list {
                    &-item-content-point {
                        border: 1px solid $c-blue-bg-fid;
                        color: $c-blue-fid;
                    }
                }
            }

            &[data-type="200pts"] {
                #{$block}-head {
                    background-color: $c-orange-bg-fid;
                    color: $c-black;

                    &-title {
                        & > span {
                            color: $c-orange-fid;
                        }
                    }
                }

                #{$block}-list {
                    &-item-content-point {
                        border: 1px solid $c-orange-bg-fid;
                        color: $c-orange-fid;
                    }
                }
            }

            &[data-type="400pts"] {
                #{$block}-head {
                    background-color: $c-red-bg-fid;
                    color: $c-black;

                    &-title {
                        & > span {
                            color: $c-red-fid;
                        }
                    }
                }

                #{$block}-list {
                    &-item-content-point {
                        border: 1px solid $c-red-bg-fid;
                        color: $c-red-fid;
                    }
                }
            }
        }
    }

    &-btn.action.save {
        float: right;
        margin: 0;
        width: auto;
        display: inline-block;
    }

    @include breakpoint(only-xl) {
        &-head {
            margin-top: 0;
            margin-bottom: 20px;
            display: block;

            &-title {
                font-size: 26px;
                line-height: 28px;
            }

            &-card {
                display: block;
                margin-top: 10px;

                .info {
                    text-align: left;
                }
            }
        }

        &-points {
            display: block;
            margin-bottom: 35px;

            &-totals,
            &-count {
                display: block;
                width: 100%;
            }

            &-totals {
                text-align: left;
                background: none;
                width: initial;
                height: initial;
                margin: 0 0 10px;

                &-content {
                    position: relative;
                    left: initial;
                    top: initial;
                    transform: none;

                    &-number,
                    &-label {
                        font-size: 42px;
                        display: inline-block;
                        vertical-align: middle;
                    }

                    &-label {
                        margin: 0 0 -5px 5px;
                    }
                }
            }

            &-count {
                &-bar {
                    width: 100%;
                    margin: 0 0 30px;

                    &-step {
                        $step: &;
                        &-content {
                            img,
                            span {
                                display: none;
                            }
                        }

                        &[data-selected="true"] {
                            #{$step}-content {
                                img[data-active-step] {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        &-info {
            padding: 40px 30px;
        }

        &-offers {
            &-block {
                &-list-item {
                    &-cta {
                        min-width: 160px;
                        text-align: right;

                        &-txt {
                            display: block;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(only-sm) {
        &-offers {
            &-block {
                &-head {
                    padding: 15px 20px;
                    font-size: 16px;
                    line-height: 22px;

                    img {
                        width: 30px;
                        height: 22px;
                    }
                }

                &-list {
                    &-item {
                        padding: 20px;
                        flex-wrap: wrap;

                        &-img {
                            width: 50px;
                            height: 50px;
                            margin-right: 15px;
                        }

                        &-content {
                            width: calc(100% - 65px);
                            margin: 0;

                            &-title {
                                font-size: 14px;
                            }
                            &-txt {
                                display: block;
                                margin-top: 5px;
                            }
                        }

                        &-cta {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            margin-top: 20px;
                            width: 100%;

                            &-txt {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
