// ==============================================
// Panel : customer
// ==============================================

.mt-panel-container {

    .block-customer-links {
        padding: 3rem 2rem;

        @include breakpoint(md) {
            padding: 4rem 3rem;
        }

        .customer-links {

            a {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 1rem 0;
                transition: color .4s;

                &:hover,
                &:active {
                    color: $c-pink;
                }

                [class*="icon-"] {
                    font-size: 3rem;
                }

                .customer-links__label {
                    margin-left: 3.5rem;
                    font-family: $f-museo-500;
                    font-size: 1.6rem;
                }
            }
        }
    }
}
