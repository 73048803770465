// ==============================================
// TITLES
// ==============================================

.title-1,
.title-2,
.title-3,
.title-4 {
    margin: 0;
    font-family: $f-museo-700;
    line-height: 1.4;
    text-transform: uppercase;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.title-1 {
    font-size: 2.6rem;
    color: $c-red;

    @include breakpoint(md) {
        font-size: 3rem;
    }
}

.title-2 {
    font-size: 1.8rem;

    @include breakpoint(md) {
        font-size: 2rem;
    }
}

.title-3 {
    font-size: 1.5rem;

    @include breakpoint(md) {
        font-size: 1.7rem;
    }
}

.title-4 {
    font-size: 1.4rem;

    @include breakpoint(md) {
        font-size: 1.5rem;
    }
}

.title-1-alt {
    display: block;
    font-family: $f-museoSlab-700;
    font-size: 3.5rem;
    line-height: 4.2rem;
    color: $c-grey-dark;

    small {
        display: block;
        font-family: $f-halohandletter;
        font-size: 4rem;
        font-weight: normal;
        transform: rotate(-2deg);
        color: $c-red;
    }

    @include breakpoint(only-md) {
        font-size: 2.5rem;

        small {
            font-size: 3.6rem;
        }
    }
}
