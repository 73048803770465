//
// Reassurance
// ______________________________________________

.product-reassurance {
    text-align: center;

    @include breakpoint(only-md) {
        padding: 0 2rem 1.5rem;
        margin: 0 -2rem 2.5rem;
        border-bottom: 1px solid $c-grey-medium;
    }

    @include breakpoint(only-md) {
        .product-reassurance-list {
            display: flex;
            justify-content: space-between;
        }
    }

    @include breakpoint(md) {
        margin-bottom: 6rem;

        .product-reassurance-list {
            display: flex;
            justify-content: space-around;
            align-items: center;
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;

        }
    }

    .product-reassurance-item {
        display: flex;
        align-items: center;
        padding: 1rem 0;

        @include breakpoint(only-md) {
            flex-direction: column;
            padding: 0 2px;
        }

        @include breakpoint(md) {
            padding: .5rem 2rem;
        }

        strong {
            font-family: $f-avenir-black;
        }

        [class*="icon-"] {
            margin-right: 2rem;
            font-size: 3rem;

            @include breakpoint(only-md) {
                margin-right: 0;
                font-size: 3.5rem;
                padding-bottom: .4rem;
            }

            &.icon-return2 {
                font-size: 5rem;
                @include breakpoint(only-md) {
                    font-size: 3.5rem;
                }
            }

            &.icon-phone2 {
                font-size: 3.5rem;
                @include breakpoint(only-md) {
                    font-size: 3.5rem;
                }
            }
        }

        .reassurance-label {

            @include breakpoint(only-md) {
                font-size: 1.18rem;
                line-height: 1.3rem;

                strong {
                    display: block;
                    margin-bottom: 2px;
                }
            }
            

            br {
                display: none;
            }
        }
    }
}
