//
// Sizes
// ______________________________________________

.product-options-wrapper {

    label {
        display: block;
        margin-bottom: 1rem;
        font-size: 1.3rem;
        color: $c-black;
        text-transform: uppercase;
    }
}
