// ==============================================
// FORMS - Radios
// ==============================================

//
//  Configuration
//  _____________________________________________

$radio-width:                   28px;
$radio-height:                  $radio-width;
$radio-width-small:             16px;
$radio-height-small:            $radio-width-small;
$radio-width-medium:            20px;
$radio-height-medium:           $radio-width-medium;
$radio-border-weight:           1px;
$radio-color:                   #999;
$custom-radio-width:            18px;
$custom-radio-height:           18px;

.choice {
    input[type="radio"] {
        & + label {
            font-size: 1.4rem;
        }
    }
}

// Exept for the checkout
body {

    input[type="radio"] {
        @extend .visually-hidden;

        & + label {
            position: relative;
            display: inline-block;
            padding-left: calc(#{$radio-width} + 7px);
            min-height: $radio-width;
            line-height: $radio-width;
            cursor: pointer;

            @include breakpoint(md) {
                padding-left: calc(#{$radio-width} + 15px);
            }

            &::before,
            &::after {
                position: absolute;
                content: "";
                cursor: pointer;
                border-radius: 100%;
                transition: all .2s;
            }

            // Fake radio
            &::before {
                left: 0;
                top: 0;
                width: $radio-width;
                height: $radio-height;
                border: $radio-border-weight solid $radio-color;
                background-color: $c-white;
            }

            // Round
            &::after {
                width: calc(#{$radio-width} - 11px);
                height: calc(#{$radio-height} - 11px);
                left: calc((#{$radio-width} + #{$radio-border-weight} * 2) / 2);
                top: calc((#{$radio-height} + #{$radio-border-weight} * 2) / 2);
                background-color: $radio-color;
                opacity: 0;
                transform: translate(-50%, -50%);
            }
        }

        &:checked {
            & + label {

                // Round
                &::after {
                    opacity: 1;
                    background-color: $c-red;
                    transition: opacity .2s;
                }
            }
        }

        &.radio--small {

            & + label {
                padding-left: calc(#{$radio-width-small} + 1rem);
                min-height: $radio-width-small;
                line-height: $radio-width-small;

                // Fake radio
                &::before {
                    width: $radio-width-small;
                    height: $radio-height-small;
                }

                // Round
                &::after {
                    width: calc(#{$radio-width-small} - 6px);
                    height: calc(#{$radio-height-small} - 6px);
                    left: calc((#{$radio-width-small} + #{$radio-border-weight} * 2) / 2);
                    top: calc((#{$radio-height-small} + #{$radio-border-weight} * 2) / 2);
                }
            }
        }

        &.radio--medium {

            & + label {
                padding-left: calc(#{$radio-width-medium} + 10px);
                min-height: calc(#{$radio-width-medium} + 2px);
                line-height: calc(#{$radio-width-medium} + 2px);

                // Fake radio
                &::before {
                    width: $radio-width-medium;
                    height: $radio-height-medium;
                }

                // Round
                &::after {
                    width: calc(#{$radio-width-medium} - 8px);
                    height: calc(#{$radio-height-medium} - 8px);
                    left: calc((#{$radio-width-medium} + #{$radio-border-weight} * 2) / 2);
                    top: calc((#{$radio-height-medium} + #{$radio-border-weight} * 2) / 2);
                    background-color: $c-red;
                }
            }
        }

        // Custom - graphical redesign
        &.radio--custom {
            & + label {
                padding-left: calc(#{$custom-radio-width} + #{$radio-border-weight} * 2);
                min-height: $custom-radio-height;
                line-height: $custom-radio-height;

                // Fake checkbox
                &::before {
                    border-color: $c-grey-3;
                    background-color: $c-white;
                    width: $custom-radio-width;
                    height: $custom-radio-height;
                }

                // Check icon
                &::after {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @extend %icomoon;
                    content:"\e971";
                    transform: translate(0);
                    font-size: 8px;
                    color: $c-white;
                    background: none;
                    left: 0;
                    top: 0;
                    width: calc(#{$custom-radio-width} + #{$radio-border-weight} * 2);
                    height: calc(#{$custom-radio-height} + #{$radio-border-weight} * 2);
                }
            }

            &:checked {
                & + label {
                    // Fake checkbox
                    &::before {
                        border-color: $c-grey-dark2;
                        background-color: $c-grey-dark2;
                    }
                    // Check icon
                    &::after {}
                }
            }
        }
    }
}
