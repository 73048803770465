// ==============================================
// VIEW - Grouped product
// ==============================================

// Configuration

$grouped-product-table-cell-padding:    10px;
$grouped-product-table-border:          1px solid darken($c-white, 5%);

//
//  Common
//  _____________________________________________

.page-product-grouped {

    .table-wrapper.grouped {

        .product-info-main & {
            margin-bottom: 15px;
        }

        .table.data.grouped {

            border: $grouped-product-table-border;

            thead {
                border-bottom: $grouped-product-table-border;
            }

            tr + tr {
                border-top: $grouped-product-table-border;
            }

            th,
            td {
                padding: $grouped-product-table-cell-padding;

                &.col.item {
                    border-right: $grouped-product-table-border;
                }
            }

            th.col.item {
                text-align: left;
            }

            td.col.item {
                vertical-align: middle;
            }
        }
    }
}