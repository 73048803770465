// ==============================================
// LAYOUT - Breadcrumbs
// ==============================================

// Configuration
$breadcrumbs-margin:            35px 0 20px;
$breadcrumbs-items-space:       5px;
$breadcrumbs-font-size:         1.3rem;
$breadcrumbs-font-color:        $c-black;
$breadcrumbs-arrow-color:       $c-black;
$breadcrumbs-arrow-size:        1.3rem;

.breadcrumbs {
    @extend .container;

    @include breakpoint(only-md) {
        @include visually-hidden;
    }

    .items {
        font-size: 0; // Hack to handle space between items

        @include breakpoint(md){
            margin: $breadcrumbs-margin;
        }

        > li {
            display: inline-flex;
            align-items: center;

            > a,
            > strong {
                font-size: $breadcrumbs-font-size;
                line-height: 1.4;
            }

            a {
                color: $breadcrumbs-font-color;
                transition: color .3s;

                &:hover {
                    color: $c-pink;
                    text-decoration: underline;
                }
            }

            > strong {
                font-family: $f-avenir-black;
                color: $c-red;
            }

            & + li {
                padding-left: $breadcrumbs-items-space;

                &::before {
                    content: ">";
                    font-size: $breadcrumbs-arrow-size;
                    line-height: 1.4;
                    color: $breadcrumbs-arrow-color;
                    padding-right: $breadcrumbs-items-space;
                }

                &:last-child {

                    &::before {
                        color: $c-red;
                    }
                }
            }
        }
    }
}
