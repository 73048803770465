/**************************
*
*	GENERAL
*
**************************/
/*.cycle-slideshow1:after {
   content: ".";
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}*/
.cycle-slideshow1, .cycle-slideshow1 * { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
.cycle-slideshow1 { padding: 0; overflow: hidden;}
.cycle-slideshow1 .slide { width: 100%; }
//.cycle-slideshow1 .slide img {
//    position: absolute; top: 0; left: 0;
//    width: 100%; height: auto; padding: 0; display: block;
//}
//.cycle-slideshow1 img:first-child {
//    position: static; z-index: 100;
//}
.cycle-slideshow1 .hotspot img{
    /*
    width: auto;
    height: auto;
    */
    width:32px;
    position: absolute;
    max-width: none;

}
/** Pager style **/
.pagernav ul{
    text-align: center; width: 100%; z-index: 500; position: absolute; bottom: 0px; overflow: hidden;
}
.pagernav ul li{
    display: inline-block; color: #ddd; cursor: pointer;
}
.pagernav ul li.selected { color: #D69746;}
.pagernav ul li.thumb {
    display: inline-block; color: #ddd; cursor: pointer;
}
/***********************/
.pagernav {
    display: block;
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.pagernav ul {
    padding: 3px 4px 8px;
    position: relative;
    display: block;
    text-align: center;
}
.pagernav ul li.thumb {
    display: inline-block;
    padding: 0 4px;
    margin: 0 !important;
    overflow: hidden;
}
.pagernav ul li.thumb > img {
    border: 1px solid;
    cursor: pointer;
    margin-top: 5px;
    vertical-align:bottom;
    opacity: 0.5;
}
.pagernav ul li.thumb:hover > img,
.pagernav ul li.thumb.cycle-slide-active > img{
    opacity: 1;
}
.pagernav ul li.thumb img{
    border: 4px solid #e6e6e6;
    -moz-box-shadow: 0px 3px 6px rgba(0,0,0,.5);
    -webkit-box-shadow: 0px 3px 6px rgba(0,0,0,.5);
    box-shadow: 0px 3px 6px rgba(0,0,0,.5);
    margin: 0 auto;
    width: auto;
    height: auto;
    display: inline-block;
}

.pagernav ul > * { cursor: pointer;}
.cycle-caption { position: absolute; color: white; bottom: 15px; right: 15px; z-index: 700; }
.cycle-overlay {
    font-family: tahoma, arial;
    position: absolute; bottom: 0; width: 100%; z-index: 1;
    background: black; color: white; padding: 15px; opacity: .5;
}
#progress {
    position: absolute;
    bottom: 0;
    height: 6px;
    width: 0px;
    background: #b7b7b7;
    z-index: 500;
}
.pagernav ul.cycle {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;
}
.pagernav ul.cycle li {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    margin: 20px 5px;
    position: relative;
    text-align: left;
    text-indent: -9999px;
    width: 16px;
    background: #b7b7b7;
}
.pagernav ul.cycle li {
    -moz-box-shadow:
        0px 1px 0px rgba(255,255,255,1),
        inset 0px 1px 1px rgba(0,0,0,0.2);
    -webkit-box-shadow:
        0px 1px 0px rgba(255,255,255,1),
        inset 0px 1px 1px rgba(0,0,0,0.2);
    box-shadow:
        0px 1px 0px rgba(255,255,255,1),
        inset 0px 1px 1px rgba(0,0,0,0.2);
}
.pagernav ul.cycle li > span {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    height: 8px;
    left: 4px;
    overflow: hidden;
    position: absolute;
    top: 4px;
    width: 8px;
}
.pagernav.progressive ul.cycle li {
    cursor: default;
}
.pagernav:not(.progressive) ul.cycle li:hover > span {
    -moz-box-shadow:
        0px 1px 0px rgba(255,255,255,1),
        inset 0px 1px 1px rgba(0,0,0,0.2);
    -webkit-box-shadow:
        0px 1px 0px rgba(255,255,255,1),
        inset 0px 1px 1px rgba(0,0,0,0.2);
    box-shadow:
        0px 1px 0px rgba(255,255,255,1),
        inset 0px 1px 1px rgba(0,0,0,0.2);
}
.pagernav ul.cycle li.cycle-pager-active > span {
    -moz-box-shadow: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
    background: #434648;
}
/********* prev / next links ************/
.slide-prev, .slide-next, .slide_commands {
    cursor: pointer;
    height: 40px;
    margin-top: -20px;
    position: absolute;
    top: 50%;
    width: 40px;
    z-index: 999;
    background: #d8d8d8;
    background: rgba(216, 216, 216, 0.85);
}
.slide-prev {
    left: 0;
}
.slide-prev > span {
    background: url(../images/lookbook/camera_skins.png) no-repeat 0 0;
    display: block;
    height: 40px;
    width: 40px;
}
.slide-next {
    right: 0;
}
.slide-next > span {
    background: url(../images/lookbook/camera_skins.png) no-repeat -40px 0;
    display: block;
    height: 40px;
    width: 40px;
}

.slide_commands {
    right: 41px;
}
//.slide-prev.hover,
//.slide-next.hover,
//.slide_commands.hover {
//    display: none;
//}
//.cycle-slideshow1:hover .slide-prev.hover,
//.cycle-slideshow1:hover .slide-next.hover,
//.cycle-slideshow1:hover .slide_commands.hover {
//    display: block;
//}

.pagernav .slide-prev,
.pagernav .slide-next {
    width: 20px;
    height: 30px;
}
.pagernav .slide-prev > span{
    background: url(../images/lookbook/camera_skins.png) no-repeat -10px -5px;
    width: 20px;
    height: 30px;
}
.pagernav .slide-next > span{
    background: url(../images/lookbook/camera_skins.png) no-repeat -50px -5px;
    width: 20px;
    height: 30px;
}

.slide_commands > .slide_play {
    background: url(../images/lookbook/camera_skins.png) no-repeat -80px 0;
    height: 40px;
    width: 40px;
}
.slide_commands > .slide_stop {
    background: url(../images/lookbook/camera_skins.png) no-repeat -120px 0;
    display: block;
    height: 40px;
    width: 40px;
}

.disabled { opacity: .5; filter:alpha(opacity=50); }
.showIt { display: none; }

.cycle-paused:after {
    content: 'Paused'; color: white; background: black; padding: 10px;
    z-index: 500; position: absolute; top: 10px; right: 10px;
    border-radius: 10px;
    opacity: .5; filter: alpha(opacity=50);
}

/*Hotspots*/
.content-before, .content-after {
    display: block;
    clear:both;
}
.hotspot {
    position:absolute;
}

.hotspot:hover .product-info,
.hotspot.hover .product-info{
    visibility: visible;
}

.hotspot:hover .hotspot-icon,
.hotspot.hover .hotspot-icon {
    z-index:101;
}

.hotspot .product-info {
    background: url(../images/lookbook/info-bg.png) repeat 0 0 transparent;
    border-top: 2px solid #FFFFFF;
    color: #686767;
    overflow:hidden;
    font: normal 12px Tahoma,Arial;
    min-width: 220px;
    max-width: 300px;
    padding: 12px;
    position: absolute;
    display:block;
    visibility: hidden;
    z-index:999;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: #666 2px 2px 3px;
    -moz-box-shadow: #666 2px 2px 3px;
    box-shadow: #666 2px 2px 3px;
}
.hotspot .product-info h2,
.hotspot .product-info a {
    color: #686767;
    font: normal 12px Tahoma,Arial;
    margin-top: 0px;
    margin-bottom: 5px;
}

.hotspot .product-info .price-box .price-label{
    display: none;
}

.hotspot .product-info .price-box .price {
    font: bold 14px Tahoma,Arial;
    color: #7dad01;
    padding-top: 8px;
    float: left;
    clear: both;
}

.hotspot .product-info .price-box .old-price .price{
    font: bold 11px Tahoma,Arial;
    color: #686767;
    text-decoration: line-through;
}

.hotspot .product-info  .out-of-stock {
    margin-top:5px;
    color: #D83820;
}
.hotspot .product-info .desc{
    padding-bottom: 5px;
}
.hotspot .product-info .actions-primary {
    float: right;

}

.hotspot-icon {
    position: absolute;
}
.hotspot .shop-block {
    float:right;
    padding-top: 8px;
}
.pro-detail-div .desc img {float:left; padding: 0 5px 5px 0; position: relative;}

.clearfix:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.lookbookslider-container {
   /* background: url(../images/lookbook/spinner.gif) 50% 50% no-repeat;*/
   position: relative;
}

.pinit_button{
    position: absolute;
    top: 0px;
}


/** Adaptive style **/

@media screen and (max-width:600px) {
    .hotspot .product-info .desc{
        display: none;
    }
    .hotspot .product-info .price{
        font-size: 12px;
        padding-top: 0px;
    }
    .hotspot .product-info label{
        font-size: 10px;
        float: left;
        margin-left: 60px;
    }
    .hotspot .product-info h2{
        margin-bottom: 0px;
        font-size: 10px;
    }
    .hotspot .product-info .shop-block{

        width: 100%;
        margin-top: -20px;
    }
    .hotspot .product-info input.qty{
        height: 18px;
        width: 30px;
        font-size: 12px;
        padding: 0px;
    }
    .hotspot .product-info .btn-cart{
        width: 100%;
        margin-top: 5px;

    }

}
/*hotspot options*/
.swatch-opt {
    margin:0 20px 0;
}
.swatch-attribute-label {
    font-weight: bold;
    position: relative;
}
.swatch-attribute-selected-option {
    /* color: #646464; */
    padding-left: 17px;
}
.swatch-attribute-options {
    margin-top: 10px;
}
.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden;
}
.swatch-option {
    width: 30px;
    padding: 1px 2px;
    min-width: 30px;
    max-width: 90px;
    height: 20px;
    float: left;
    margin: 0 10px 5px 0;
    text-align: center;
    cursor: pointer;
    position: relative;
    border: 1px solid rgb(218, 218, 218);
    overflow: hidden;
    text-overflow: ellipsis;
}
.swatch-option:not(.disabled):hover {
    outline: 1px solid #999;
    border: 1px solid #fff;
    color: #333;
}
.swatch-option.image:not(.disabled):hover, .swatch-option.color:not(.disabled):hover {
    outline: 2px solid #ee0000;
    border: 1px solid #fff;
}
.swatch-option.selected {
    outline: 2px solid #ff5501;
    border: 1px solid #fff;
    color: #333;
}
.swatch-option.custom-text {
    background: #f0f0f0;
    color: #686868;
    font-size: 12px;
    /*font-weight: 700;
    line-height: 20px;
    padding: 4px 8px;*/
    min-width: 22px;
    margin-right: 7px;
}
.swatch-option.custom-text.selected {
    background-color: #ffffff !important;
}
.emptyAttr {
    border: 1px solid red;
}

.swatch-attribute {
    margin-top: 1rem;
}
/*hotspot options ends*/

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
    .cycle-slideshow1 { min-width: 200px;}
    .cycle-slideshow1 .cycle-overlay { padding: 4px }
    .cycle-slideshow1 .cycle-caption { bottom: 4px; right: 4px }
    .pagernav {display: none}
    .hotspot {display: none}
}

@media screen and (max-width:400px) {
    .hotspot {
        display: none;
    }

    .camera_thumbs_cont{
        display: none;
    }

    .camera_target_content {
        overflow: hidden;
    }

}

/**
    START: Preloader
**/

.lookbookslider_preloader_container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height:100%;
    z-index: 9999;
}

.lookbookslider_preloader_sk-double-bounce {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
}

.lookbookslider_preloader_sk-double-bounce .lookbookslider_preloader_sk-child {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /*background-color: #333;*/
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: lookbookslider_preloader_sk-doubleBounce 2s infinite ease-in-out;
    animation: lookbookslider_preloader_sk-doubleBounce 2s infinite ease-in-out;
}

.lookbookslider_preloader_sk-double-bounce .lookbookslider_preloader_sk-double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes lookbookslider_preloader_sk-doubleBounce {
    0%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0); }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes lookbookslider_preloader_sk-doubleBounce {
    0%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0); }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/**
    : Preloader
**/
