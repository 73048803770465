// ==============================================
// MIXINS - Old browsers CSS hacks
// ==============================================

//
//  IE10+
//  _____________________________________________

@mixin only_ie {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

//
//  Edge
//  _____________________________________________

@mixin only_edge {
    @supports (-ms-ime-align:auto) {
        @content;
    }
}