//
// Product infos details
//  _____________________________________________

.product-info-detailed {
    @include breakpoint(only-md) {
        border-top: 1px solid $c-new-grey-5;
        border-bottom: 1px solid $c-new-grey-5;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        margin-top: 2rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 2.4rem;
    }

    &.has-sample {

        @include breakpoint(only-lg) {
            .product-info-detailed__content {
                padding-bottom: 2rem;
                margin-bottom: 3rem;
                border-bottom: 0;
            }

            .sample-request-container {
                padding: 0 2rem 3rem;
                margin: 0 -2rem;
                border-bottom: 1px solid $c-grey-medium;

                > .sample-request {
                    margin-bottom: 0;
                }
            }
        }

        @include breakpoint(lg) {
            display: flex;
            flex-wrap: wrap;

            .product-info-detailed__main {
                width: 50%;
            }

            // Content {
            .product-info-detailed__content {
                flex-direction: column;

                > .product-additional,
                > .product-description {
                    width: 100%;
                    margin-left: 0;
                }

                > .product-additional {
                    margin-bottom: 2.2rem;
                }

                .product-description {
                    .product-description__title {
                        margin-bottom: 2.4rem;
                    }
                }
            }

            // Sample
            .sample-request-container {
                width: 45%;
                max-width: 63.5rem;
                margin-left: auto;
            }
        }
    }

    // Title
    .product-info-detailed__title {
        @extend %product-page-block-title;
        display: none;

        @include breakpoint(lg) {
            display: block;
        }
    }

    // Content {
    .product-info-detailed__content {
        @extend %product-page-block-content;

        @include breakpoint(lg) {
            display: flex;
            //flex-wrap: wrap;

            > .product-additional {
                width: 50%;
            }

            > .product-description {
                width: 50%;
                margin-left: auto;
            }
        }

        @include breakpoint(md) {
            [data-role="content"] {
                display: block !important;
            }
        }
    }

    // Product Details
    .product-details {
        flex: 1;

        @include breakpoint(only-md) {
            margin-bottom: 2rem;
        }
    }

    .product-details-title {

        @include breakpoint(only-md) {
            font-size: 1.8rem;
            font-family: $f-avenir-medium;
            padding-right: 40px;
            position: relative;

            // Icon
            &[aria-expanded=false],
            &[aria-expanded=true] {
                & + * {
                    visibility: visible;
                    position: static;
                }
            }

            &[aria-expanded=true] {
                &::after {
                    transform: translateY(-50%) scale(-1) rotate(90deg);
                }
            }

            &::after {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%) scale(1) rotate(90deg);
                @include icomoon;
                content:"\e930";
                transition: transform .3s ease-out;
            }

            // Content margin
            & + * {
                visibility: hidden;
                position: absolute;
                margin-top: 20px;
            }
        }

        @include breakpoint(md) {
            margin-bottom: 2.8rem;
            font-family: $f-avenir-medium;
            color: #747474;
        }
    }

    .product-offers {
        display: none;
    }

    .product-details {

    }
}

// Additionnal attributes
.additional-attributes-wrapper {

    > .additional-attributes {

        @include breakpoint(sm) {
            column-count: 2;
        }

        > li {
            display: block;
            padding-bottom: 2rem;

            @include breakpoint(md) {
                break-inside: avoid;
                padding-bottom: 2.8rem;
            }
        }

        .attribute-item {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .attribute-label {
                font-family: $f-avenir-black;
                color: $c-grey-dark;

                &::after {
                    content: ':';
                    margin-right: .5rem;
                    margin-left: .2rem;
                }
            }

            .attribute-data {
                flex: 1;
                min-width: 10rem;
            }


            .care-list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                > .care-symbol {
                    margin-right: .5rem;
                    cursor: pointer;

                    > .care-symbole__description {
                        @extend %tooltip;
                        box-shadow: 0 0 53px 0 #AAA;
                    }

                    &:hover,
                    &:focus,
                    &:active {

                        > .care-symbole__description {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

// Descrption
.product-description {

    .product-details-title {
        @include breakpoint(md) {
            font-family: $f-avenir-black;
            color: $c-grey-dark;
        }
    }

    .product-description__content {

        div.value {
            line-height: 1.4;
        }
    }
}

// Dictionary
.product-description,
.additional-attributes {

    .dictionary {
        @extend %dictionary;
    }
}

// Fix column-count behavior
.additional-attributes {

    .dictionary {

        &::after {
            position: static;
        }
    }
}

// Sample
.sample-request {
    max-width: 63.5rem;
    position: relative;

    @include breakpoint(only-lg) {
        margin: 0 auto 30px;
    }

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 26rem;
        padding: 2rem;
        background-color: #fff;
    }

    &__visual {
        display: block;
    }

    &__title {
        max-width: 15rem;
        margin: 0 auto 1.4rem;
        font-family: $f-museo-700;
        font-size: 1.4rem;
        line-height: 1.3;
        text-align: center;
        text-transform: uppercase;

        @include breakpoint(md) {
            margin: 0 auto 2rem;
            font-size: 1.6rem;
        }
    }

    &__btn {
        width: 100%;
    }
}

// Sample modal
.sample-request-description {


    &__title {
        font-family: $f-museo-700;
        font-size: 1.6rem;
    }

    &__content {
        font-size: 1.4rem;

        p,
        ul {
            margin: 1em 0;
            line-height: 1.4;
        }
    }
}
