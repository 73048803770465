// ==============================================
// CUSTOMER ACCOUNT CREATE
// ==============================================

body.customer-account-create {

    // Page title
    .page-title-wrapper {

        > .page-title {
            @extend .title-2;
            color: $c-base;
            text-transform: inherit;
        }

        @include breakpoint(only-md) {
            margin-top: 2rem !important;
            padding-bottom: 2rem;
            border-bottom: 1px solid $border-color;
            text-align: center;

            > .page-title {
                font-size: 1.7rem;
                color: $c-pink;
                text-transform: uppercase;
            }
        }
    }

    @include breakpoint(only-md) {

        block.block-search.algolia-search-block {
            display: none;
        }
    }
}

//
// Content
// ____________________________________________________________

.block-create-account {

    @include breakpoint(lg) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

//
// Form
// ____________________________________________________________

.form.form-create-account {
    width: 100%;
    max-width: 80rem;

    // Fieldset
    .fieldset {

        &:not(.fidelity) {

            .legend {
                @extend .visually-hidden;
            }
        }

        &.create.account {
            margin-top: 0;
        }

        // Fidelity
        &.fidelity {
            margin-top: 3rem;

            @include breakpoint(lg) {
                margin-top: 4rem;
            }

            > .legend {
                text-transform: inherit;
            }
        }

        // CGV
        &.cgv {
            padding-left: 4rem;
        }
    }

    @include breakpoint(md) {

        .fieldset-content,
        .fullname.customer-name-prefix {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    // Field
    .field {

        &.field-name-lastname,
        &.field-name-firstname,
        &.email,
        &.telephone,
        &.password {
            margin-top: 1rem;

            @include breakpoint(sm) {
                margin-top: 2rem;
            }

            label.label {
                @extend .visually-hidden;
            }

            @include breakpoint(md) {
                width: calc(50% - 1rem);
                margin-top: 3rem;
            }
        }

        @include breakpoint(md) {

            &.email {
                @include only_ie {
                    margin-left: -2rem;
                }
            }
        }


        &.fullname.customer-name-prefix {

            label.label {
                @extend .visually-hidden;
            }
        }

        &.password {

            .minimum-password-length-container {
                margin: 1rem 0 0 1rem;

                @include breakpoint(sm) {
                    margin: 2rem 0 0 1rem;
                }
            }
        }

        &.field-name-prefix,
        &.newsletter,
        &.fidelity {

            @include breakpoint(md) {
                width: 100%;
            }
        }

        &.field-name-prefix {

            label {
                margin-right: 2rem;
                margin-bottom: .5rem;

                &:last-child {
                    margin-right: 0;
                }

                @include breakpoint(md) {
                    margin-right: 4rem;
                }
            }

            input[type="radio"] {
                @extend .radio--medium;
            }
        }

        &.fidelity {

            .fidelity-details {
                padding-left: 4rem;
            }
        }

        &.newsletter {
            margin-top: 2rem;

            @include breakpoint(md) {
                margin-top: 3rem;
            }
        }
    }

    .hr-like {
        height: .5rem;
        margin-top: 3rem;
        background-image: url("../images/background/sep-h.png");
        background-position: top left;
        background-repeat: repeat-x;

        @include breakpoint(lg) {
            margin-top: 4rem;
        }
    }

    // Toolbar
    .actions-toolbar {

        .primary {

            @include breakpoint(only-md) {
                width: 100%;

                .submit {
                    font-size: 1.6rem;
                }
            }
            .submit {
                text-transform: uppercase;
            }
        }
    }
}

//
// Illustration
// ____________________________________________________________
.create-account-illustration {
    position: relative;
    width: calc(80rem - 7rem);
    max-width: 43.9rem;
    max-height: 70rem;

    > img {
        width: 100%;
    }

    @include breakpoint(xxl) {

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: -2.6rem;
            left: -6rem;
            width: 100%;
            height: 100%;
            background-color: $c-grey-light;
        }
    }
}
