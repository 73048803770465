// ==============================================
// CS BLOCK : Images block
// ==============================================

$grid-gutter: 20px;

.csb-images-block {
    padding: 2rem 0;

    @include breakpoint(md) {
        padding: 3rem 0;
    }

    @include breakpoint(lg) {
        padding: 4rem 0;
    }

    > .container {
        max-width: 1250px;
    }

    .checkout-onepage-success & {
        padding-bottom: 0;
    }
}

// Grid container
.csb-images-block-grid {

    @include breakpoint(sm) {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -#{$grid-gutter};

        .image-block--first {
            position: absolute;
            left: auto;
            right: 0;
        }

        .image-block-2 {
            margin-top: 18rem;
        }

        .image-block-3,
        .image-block-5 {
            margin-top: -8rem;
        }
    }

    @include breakpoint(lg) {

        .image-block--first {
            left: 50%;
            transform: translateX(-50%);
            right: auto;
        }

        .image-block-3,
        .image-block-5 {
            margin-top: 0;
        }

        .image-block-4 {
            margin-top: -8rem;
        }

        .image-block-6 {
            margin-top: -7.5rem;
        }
    }
}

// Items
.image-block {
    padding: 0 $grid-gutter;
    margin: 0 0 3rem;

    @include breakpoint(sm) {
        width: 50%;
    }

    @include breakpoint(lg) {
        width: 33.3333%;
        padding: 0 $grid-gutter;
        margin: 0 0 4rem;
    }
}

.image-block__inner {
    display: block;
    max-width: 37rem;
    margin: 0 auto;
    color: $c-black;

    &:hover,
    &:focus,
    &:active {
        color: $c-black;
    }
}

// Image block Fancybox
// --------------------------------------
.image-block-first__visual {
    max-width: 5.5rem;
    margin: 0 auto;

    .checkout-onepage-success & {
        width: 9px;
        height: 24px;
        margin-bottom: 1rem;

        @include breakpoint(xl) {
            margin-top: -1.5rem;
        }
    }
}

.image-block-first__title {
    margin-bottom: 1rem;
    font-family: $f-halohandletter;
    font-size: 5rem;
    line-height: 6rem;
    text-align: center;

    .checkout-onepage-success & {

        @include breakpoint(only-md) {
            font-size: 4rem;
        }
    }
}

.image-block-first__subtitle {
    font-family: $f-museoSlab-700;
    font-size: 1.6rem;
    color: $c-red;
    text-align: center;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;

    .checkout-onepage-success & {
        font-size: 1.1rem;
        color: $c-base;

        strong {
            display: block;
            margin-top: .5rem;
            font-size: 1.8rem;
            color: $c-black;
        }
    }
}

// Image block
// --------------------------------------
.image-block__link {

    &:hover,
    &:focus,
    &:active {

        .image-block__visual img {
            transform: scale(1.05);
        }
    }
}

.image-block__visual {
    margin-bottom: 1.5rem;

    img {
        display: block;
        transition: transform .4s;
    }

    @include breakpoint(lg) {
        margin-bottom: 3.5rem;
    }
}

.image-block__title {
    margin-bottom: 1.5rem;

    @include breakpoint(lg) {
        margin-bottom: 2rem;
    }
}

.image-block__description {
    font-size: 1.5rem;

    p {
        margin: 1em 0;
    }
}

.image-block__price {
    margin-top: 1.5rem;
    font-family: $f-museo-500;
    font-size: 2rem;

    @include breakpoint(lg) {
        margin-top: 2rem;
    }
}

// Modal

.image-block-first__modal {

    .modal-inner-wrap {
        padding: 0;
    }

    .action-close {
        top: 0;
        right: 0;
    }

    .modal-footer {
        display: none;
    }
}
