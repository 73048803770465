// ==============================================
// TOOLBAR
// ==============================================

// Configuration

//
//  Magento native reset
//  _____________________________________________

// Top toolbar
.toolbar-products .pages {
    display: none;
}

// Bottom toolbar
.products.wrapper ~ .toolbar-products {

    @include breakpoint(only-lg) {
        margin-top: 0;
        margin-bottom: 0;
    }

    .pages {
        display: block;
        margin: 0 auto;
    }

    .modes,
    .toolbar-amount {
        display: none;
    }
}

//
//  Listing products toolbar
//  _____________________________________________

.toolbar-products {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @include breakpoint(only-lg) {
        min-height: 18px; //prevent space for amasty filter if toolbar is empty
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    // Page items info
    .toolbar-amount {
        margin-bottom: 0;
        font-family: $f-avenir-black;
        font-size: 1.3rem;
    }

    // Sort by filter
    .toolbar-sorter {
        display: flex;
        align-items: center;
        margin-left: auto;

        // Sort by label
        .sorter-label {
            white-space: nowrap;
        }

        .sorter-options {
            padding-top: 0;
            padding-bottom: 0;
            border: none;
            cursor: pointer;

            @include only_ie {
                background-size: 10%;
            }
        }
    }
}

// Current filters inside toolbar in catalog category view & result search page
.catalogsearch-result-index,
.catalog-category-view {

    #amasty-shopby-product-list {

        > .toolbar.toolbar-products:first-child {

            @include breakpoint(only-lg) {
                .toolbar-sorter.sorter {
                    display: none;
                }
            }

            @include breakpoint(only-md) {
                .toolbar-amount {
                    display: none;
                }
            }

            @include breakpoint(md) {
                .toolbar-amount {
                    order: 1;
                    margin-right: 4rem;
                }
                .amshopby-filter-current {
                    order: 2;
                }

                .toolbar-sorter.sorter {
                    order: 3;
                }
            }
        }
    }
}

