// ==============================================
// LAYOUT - Minicart
// ==============================================

//
//  Configuration
//  _____________________________________________

$minicart-min-height:           15rem;
$minicart-padding-s:            2rem;
$minicart-padding-m:            3rem;
$minicart-items-delimiter:      1px solid $c-grey-medium;

//
//  Minicart menu
//  _____________________________________________

.minicart-link {
    position: relative;
    display: block;

    // Cart button
    > .action.showcart {
        position: relative;
    }

    .counter {
        // Counter
        &.qty {
            position: absolute;
            top: -24px;
            right: -11px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include circle(2rem);
            padding: 4px;
            background-color: $c-red;
            font-family: $f-museo-500;
            color: $c-white;
            font-size: 1rem;
            transition: background-color .2s;

            // Hide qty if cart is empty
            &.empty {
                display: none;
            }

            @include breakpoint(only-lg) {
                right: -11px;
                text-indent: 0;
            }
        }

        .counter-label {
            @extend .visually-hidden;
        }
    }

    &.active {
        .block-minicart {
            visibility: visible;
        }

        .counter {
            &.qty {
                background-color: $c-red;
                transition: background-color .3s;
            }
        }
    }
}

//
//  Minicart popin
//  _____________________________________________

.block-minicart {

    // Empty cart
    .minicart-empty {
        padding: $minicart-min-height/2 0;
        min-height: $minicart-min-height;
        text-align: center;
    }
}

//
//  Minicart header
//  _____________________________________________

.minicart-header {
    min-height: 6.6rem;
    padding: 2.5rem 2rem 2rem;
    background-color: $c-grey-light;

    @include breakpoint(md) {
        min-height: 8.8rem;
        padding: 3.5rem 3rem 3rem;
    }

    // Title
    .block-title {

        .text {
            @extend .title-3;
            margin-right: 1rem;
        }
    }
}

//
//  Minicart content
//  _____________________________________________

.minicart-content {
    padding: 0 calc(#{$minicart-padding-s} - 1rem) 0 $minicart-padding-s;
    margin-bottom: 3rem;

    @include breakpoint(md) {
        padding: 0 calc(#{$minicart-padding-m} - 1rem) 0 $minicart-padding-m;
    }

    // Sub title
    .subtitle {
        @extend .visually-hidden;
    }

    // Items
    .minicart-items-wrapper {
        height: auto !important;
        max-height: 39rem;
        padding-right: 1rem;
        overflow-y: auto;
        border-bottom: $minicart-items-delimiter;

        @include custom-scrollbar();

        // Products list
        .minicart-items {
            li {
                padding: $minicart-padding-s 0;
                border-bottom: $minicart-items-delimiter;

                &:last-child {
                    border-bottom: 0;
                }

                // Product
                > .product {
                    display: flex;
                    width: 100%;
                    position: relative;
                }
            }
        }
    }

    // Visual
    .product-item-photo {
        width: 75px;
        height: 75px;
        overflow: hidden;
        margin-right: 15px;

        > .product-image-container,
        .product-image-wrapper,
        .product-image-photo {
            width: 100% !important;
            height: 100% !important;
        }

        @include breakpoint(md) {
            width: 95px;
            height: 95px;
            margin-right: 24px;
        }
    }

    // Product item
    .product-item-details {
        width: calc(100% - 90px);
        flex: 1;
        color: $c-black;

        @include breakpoint(md) {
            width: calc(100% - 119px);
        }

        // Product-item-top
        .product-item-details-top {
            display: flex;
            margin-bottom: 1.4rem;

            // Actions
            .actions {
                margin-left: auto;

                .action.delete {
                    font-size: 2rem;
                }

                span {
                    @extend .visually-hidden;
                }
            }
        }

        // Title
        .product-item-name {
            padding-right: 1rem;
            font-family: $f-museo-700;
            font-size: 1.5rem;
            color: $c-base;

            @include breakpoint(md) {
                font-size: 1.6rem;
            }
        }

        // Options
        > .options {
            margin-bottom: 1.3rem;
            @extend %collapsible-inline;

            .list {
                @include clearer;

                .label,
                .values {
                    float: left;
                    margin-top: 6px;
                }

                .label {
                    clear: left;
                    font-weight: bold;
                    margin-right: 6px;

                    &::after {
                        content: " :";
                    }
                }
            }
        }

        // Price
        .product-item-pricing {
            margin-left: auto;
            text-align: right;

            .old-price {
                display: block;
                margin-bottom: .5rem;
                text-decoration: line-through;
            }

            .new-price {
                font-family: $f-avenir-black;
                color: $c-red;
            }
        }

        // Actions wrapper
        .product-item-actions {
            display: flex;
            align-items: center;
        }

        // Quantity
        .details-qty {
            flex: 1;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            > label {
                margin-right: 1.5rem;
            }

            input {
                background-color: white;
                border-radius: 0;
                max-width: 4rem;
            }

            .soon-qty-updater,
            span[class*="cart-item-qty-"] {
                height: 3.5rem;
                width: 3.5rem;
                line-height: 3.5rem;
                background-color: $c-grey-light;
                text-align: center;
            }

            > .cart-item-qty {

                + span[class*="cart-item-qty-"] {
                    margin-right: 1.5rem;
                }
            }
        }
    }
}

//
//  Minicart footer
//  _____________________________________________

.minicart-footer {
    padding: 0 $minicart-padding-s;
    margin-bottom: 2rem;

    @include breakpoint(md) {
        padding: 0 $minicart-padding-m;
    }

    // Total
    .subtotal {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 1.6rem;
    }

    .grandtotal {
        display: none;
        width: 0;
        height: 0;
        transform: scale(0);
        font-size: 0;
    }

    html[lang="de"] & {

        .tax-amount {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 1rem;
            font-size: 1.6rem;
        }
    }

    // Free shipping
    > .freeshipping {
        margin: 4rem 0;
        text-align: center;

        > .freeshipping {

            + .freeshipping {
                margin-top: .5rem;
            }

            strong {
                font-family: $f-avenir-black;
            }
        }
    }
}
