// ==============================================
// Sitemap
// ==============================================

body.mageworx_htmlsitemap-index-index {

    // Page title
    .page-title-wrapper {

        .page-title {
            @extend .title-1;
            margin: 3rem auto;
            letter-spacing: 4px;
            text-align: center;
            text-transform: uppercase;

            @include breakpoint(lg) {
                margin: 4rem auto;
            }
        }
    }

    ul.products {
        @extend .visually-hidden;
    }

    ul.categories {
        margin-bottom: 2rem;
    }

    li.level-0 > a,
    .xsitemap-pages > h3 {
        display: block;
        @extend .title-1;
        padding: 1.4rem 1rem;
        margin-bottom: 2rem;
        background-color: $c-grey-light;
        color: $c-base;
        text-align: center;
        text-transform: uppercase;

        @include breakpoint(only-sm) {
            font-size: 2.2rem;
        }

        @include breakpoint(lg) {
            padding: 3.4rem 1rem;
            margin-bottom: 3rem;
        }
    }

    li.level-0 > ul.categories,
    .xsitemap-pages > ul.pages {
        padding: 0 1rem;

        @include breakpoint(md) {
            padding: 0 2rem;
        }

        @include breakpoint(lg) {
            padding: 0 3rem;
        }
    }

    li.level-2,
    li.page-url {

        > a {
            display: block;
            padding: 1rem 0;
            font-size: 1.9rem;
            text-transform: lowercase;
            transition: color .4s;

            &:hover,
            &:active {
                color: $c-pink;
            }
        }
    }
}

//
// Categories
// __________________________________________________

.xsitemap-categories {

    > ul.categories {

        > li.level-0 {

            > a {
                transition: color .4s;

                &:hover,
                &:active {
                    color: $c-pink;
                }
            }

            > ul.categories {

                @include breakpoint(md) {
                    display: flex;
                    flex-wrap: wrap;
                }

                > li.level-1 {

                    @include breakpoint(md) {
                        flex: 0 0 33.3333%;
                    }

                    @include breakpoint(lg) {
                        flex: 0 0 25%;
                    }

                    > a {
                        display: block;
                        @extend .title-3;
                        margin: 0 0 1rem;
                        color: $c-red;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

//
// Pages
// __________________________________________________

.xsitemap-pages {

    > ul.pages {
        column-count: 2;

        @include breakpoint(md) {
            column-count: 3;
        }

        @include breakpoint(lg) {
            column-count: 4;
        }

        li.page-url {

            > a {

                &[href*="styleguide"] {
                    display: none;
                }
            }
        }
    }
}
