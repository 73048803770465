body.spring {
    --season-pattern-1: url('../images/pattern-season/spring/pattern-1.jpg');
    --season-pattern-2: url('../images/pattern-season/spring/pattern-2.jpg');
    --season-pattern-3: url('../images/pattern-season/spring/pattern-3.jpg');
    --season-pattern-4: url('../images/pattern-season/spring/pattern-4.jpg');
}
body.summer {
    --season-pattern-1: url('../images/pattern-season/summer/pattern-1.jpg');
    --season-pattern-2: url('../images/pattern-season/summer/pattern-2.jpg');
    --season-pattern-3: url('../images/pattern-season/summer/pattern-3.jpg');
    --season-pattern-4: url('../images/pattern-season/summer/pattern-4.jpg');
}
body.autumn {
    --season-pattern-1: url('../images/pattern-season/autumn/pattern-1.jpg');
    --season-pattern-2: url('../images/pattern-season/autumn/pattern-2.jpg');
    --season-pattern-3: url('../images/pattern-season/autumn/pattern-3.jpg');
    --season-pattern-4: url('../images/pattern-season/autumn/pattern-4.jpg');
}
body.winter {
    --season-pattern-1: url('../images/pattern-season/winter/pattern-1.jpg');
    --season-pattern-2: url('../images/pattern-season/winter/pattern-2.jpg');
    --season-pattern-3: url('../images/pattern-season/winter/pattern-3.jpg');
    --season-pattern-4: url('../images/pattern-season/winter/pattern-4.jpg');
}
