// ==============================================
// CHECKOUT - Shipping : Colissimo
// ==============================================

.checkout-index-index {

    .colissimo-pickup-content {
        position: relative;
        padding: 0 2rem 2rem;
        margin-top: -4px;
        background-color: $c-white;
        border: 1px solid $c-grey-dark2;
        border-top: 1px solid $c-white;

        &::after {
            content: '';
            display: table;
            clear: left;
        }

        .colissimo-header {
            @include visually-hidden;
        }

        .colissimo-content {
            padding: 0;
        }

        .colissimo-address,
        .colissimo-list,
        .colissimo-telephone {

            &::after {
                content: '';
                display: table;
                clear: both;
            }

            input,
            select {
                min-width: 10rem;
                padding-left: 1rem;
                padding-right: 1rem;
                height: 4rem;
                border-color: $border-color;
                font-size: 1.4rem;
                line-height: 4rem;
            }
        }

        .colissimo-map,
        .colissimo-address {
            border: none;
        }

        // Tel
        .colissimo-telephone {
            clear: both;
            float: none;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            height: auto;
            margin-top: 2rem;
            border: none;

            > label {
                margin-bottom: 0;
                margin-right: 1rem;
            }

            > input {

                &.error {
                    background: $c-white;
                    border-color: $c-error;
                }
            }
        }

        .colissimo-map {
            width: 40rem;
            z-index: 1;
        }

        // Address
        .colissimo-address {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            padding: 1rem;
            gap: 10px;

            @include breakpoint(only-sm) {
                display: block;
            }

            p {
                float: none;
                margin-top: 0;
                margin-right: 0;

                @include breakpoint(only-sm) {
                    width: 100%;
                }

                label {
                    margin-bottom: .5rem;
                }
            }

            .colissimo-actions {
                margin-left: auto;
            }
        }

        .colissimo-next {

            &.btn {
                @extend .btn;

                span {
                    padding: 0;
                    background-color: transparent;
                    line-height: inherit;
                }

                &:hover,
                &:focus,
                &:active {
                    span {
                        background: transparent;
                    }
                }
            }
        }
    }

    // Stores
    .colissimo-list {
        width: 100%;

        // Error
        > p {
            padding: 1rem;
            border-color: $c-error;
            color: $c-error;
        }

        // List
        ul {
            width: calc(100% - 40rem);
            padding-right: 2rem;
            border: none;
        }

        li {
            width: 100%;
            padding: 1.6rem;
            border-color: $border-color;

            &.active {
                background-color: $c-grey-light;
            }

            label {
                width: 100%;
                padding: 0 0 0 3rem !important;

                a {
                    display: inline-block;
                    margin-top: 1rem;
                    background-color: $c-pink;
                }
            }
        }

        // Actions
        .colissimo-actions {
            position: static;
            text-align: center;

            button {
                font-weight: normal;
            }

            .colissimo-previous {
                display: none;
            }

            .colissimo-next {
                float: none;
            }
        }
    }
}

@media screen and (max-width: 920px) {

    .checkout-index-index {

        .colissimo-pickup-content {
            padding: 2rem 0;
            margin-top: 0;
            border: none;

            .colissimo-list {
                display: flex;
                flex-direction: column;

                > ul,
                .colissimo-map {
                    display: block;
                    width: 100%;
                }

                > ul {
                    order: 2;
                    margin-bottom: 2rem;
                }

                .colissimo-map {
                    order: 1;
                }

                .colissimo-telephone {
                    order: 3;
                    padding-top: 2rem;
                    margin-top: 0;
                    margin-bottom: 0;
                    border-top: 1px solid $border-color;;
                }

                .colissimo-actions {
                    order: 4;
                    position: static;
                    width: 100%;
                    margin-bottom: 2rem;
                    text-align: center;
                }
            }

            .colissimo-address {
                padding: 1rem 0;
                margin-bottom: 0;

                p {
                    margin-bottom: 1rem;
                }

                .colissimo-street,
                .colissimo-postcode,
                .colissimo-city,
                .colissimo-country {
                    width: 100%;
                }

                .colissimo-actions {
                    width: 100%;
                    text-align: center;
                }
            }

            .colissimo-telephone {
                width: 100%;
                margin-bottom: 2rem;
            }
        }
    }
}

@media screen and (max-width: 320px) {

    .checkout-index-index {

        .colissimo-pickup-content {
            padding: 0;
            border: none;

            .colissimo-address {

                p {
                    width: 100%;
                    margin-right: 0;
                }

                input,
                select {
                    width: 100% !important;
                }
            }

            .colissimo-telephone {

                label {
                    margin-bottom: .5rem;
                }

                input {
                    width: 100%;
                }
            }
        }
    }
}
