@import "../amasty/_amasty-checkbox.scss";
.flowbox {
    // tag bar
    .tag-bar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        @include breakpoint(only-lg) {
            justify-content: normal;
        }
    }

    .flowbox-tag {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        background-color: $c-white;
        font-family: $f-avenir-medium;
        font-size: 1.2rem;
        color: $c-base;
        text-transform: uppercase;
        cursor: pointer;
        transition: color .3s;

        @include breakpoint(only-lg) {
            position: relative;
            padding: 20px 20px 20px 30px;
        }

        @include breakpoint(lg) {
            padding: 1.5rem 1.5rem 1.5rem 1rem;
        }

        @include breakpoint(xl) {
            padding: 2.5rem 4rem 2.5rem 3rem;
        }
    }

    .flowbox-tag--checked {
        font-weight: bold;
        @include breakpoint(only-lg) {
            color: $c-pink;
            font-weight:normal;
        }
    }

}
