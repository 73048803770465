// ==============================================
// BLOG - List
// ==============================================

.contentmanager-contentlist-view {

    .column.main {
        max-width: 1210px;
        margin-right: auto;
        margin-left: auto;
    }
}

//
//  Configuration
//  _____________________________________________

.blog-list-page {

    //
    // Header
    // __________________________________________

    .blog-list-header {

        @include breakpoint(md) {
            background: #fcf1e8 url('../images/background/bg-tutos.jpg') no-repeat center center;
            background-size: cover;
        }

        .blog-list-header__inner {
            margin: auto;
            padding: 2rem;
            max-width: 125rem;

            @include breakpoint(md) {
                padding: 3rem 2rem;
            }
        }

        .blog-list-header__title {
            margin: 0;
            font-family: $f-halohandletter;
            font-size: 3.6rem;
            color: $c-pink;
            text-transform: inherit;

            @include breakpoint(md) {
                font-size: 5.5rem;
            }
        }

        .blog-list-header__description {
            margin-top: 0;
            font-family: $f-museoSlab-700;
            font-size: 1.5rem;
            line-height: 1.22;

            @include breakpoint(md) {
                font-size: 1.8rem;
            }
        }
    }

    //
    // Search & Filters
    // __________________________________________

    .blog-list__search-filters {

        @include breakpoint(only-md) {
            margin-bottom: 2rem;
            border: 8px solid $c-salmon;

            // btn
            &[data-tabs="filters"] button[data-tab="filters"],
            &[data-tabs="search"] button[data-tab="search"] {
                font-family: $f-avenir-black;
                color: $c-pink;
            }

            // filters
            &:not([data-tabs="filters"]){

                .block-filter {
                    display: none;
                }
            }

            // search
            &:not([data-tabs="search"]){

                .tutos-search {
                    display: none;
                }
            }
        }
    }

    .search-filters__tabs {
        display: flex;

        button {
            width: 50%;
            height: 4rem;
            line-height: 4rem;
            border-bottom: 1px solid $c-salmon;
            text-align: center;

            + button {
                border-left: 1px solid $c-salmon;
            }
        }
    }

    //
    // Search
    // __________________________________________

    .tutos-search {

        @include breakpoint(md) {
            padding: .5rem;
            background-color: $c-grey-light;
        }

        .tutos-search-input {
            position: relative;
            display: block;
        }

        .input-text {
            display: block;
            height: 5rem;
            padding: 0 5rem 0 2rem;
            border: none;
            font-size: 1.6rem;
        }

        button[type="submit"] {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 5rem;
            height: 5rem;
            font-size: 2.2rem;
            line-height: 5rem;
        }

        .magnifying-glass {

            &::before {
                @extend %icomoon;
                content: "\e921";
            }
        }
    }

    //
    // Filters
    // __________________________________________

    .block-filter {

        @include breakpoint(md) {
            margin-top: 4rem;
            margin-bottom: 3rem;
        }

        > .block-title {
            @include visually-hidden;
        }

        > .block-content {

            > .block-subtitle {
                @include visually-hidden;
            }

            // Options
            > .filter-options {

                @include breakpoint(md) {
                    display: flex;
                }

                > .filter-options-item {
                    position: relative;

                    @include breakpoint(only-md) {

                        + .filter-options-item {
                            border-top: 1px solid $c-salmon;
                        }
                    }

                    @include breakpoint(md) {
                        width: 25%;
                        border: 1px solid $c-grey-light;

                        + .filter-options-item {
                            border-left: 0;
                        }
                    }

                    > .filter-options-title {
                        height: 4rem;
                        line-height: 3.8rem;
                        background-color: #fff;
                        text-align: center;
                        cursor: pointer;

                        @include breakpoint(md) {
                            height: 7rem;
                            line-height: 6.8rem;
                        }

                        &::after {
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 1.2rem;
                            @extend %icomoon;
                            font-size: 1.2rem;
                            content: "\e930";
                            transform: rotate(90deg);
                        }
                    }

                    > .filter-options-content {
                        padding: 1.5rem;
                        background-color: $c-white;

                        @include breakpoint(md) {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 100%;
                            padding: 2.5rem 1.5rem;
                            box-shadow: $filters-box-shadow;
                            z-index: 1;
                        }

                        > .items {
                            display: flex;
                            flex-wrap: wrap;

                            > .item {
                                width: 50%;
                                margin-bottom: 1rem !important;

                                > a {
                                    display: flex;
                                    align-items: center;
                                    min-height: 3rem;
                                    padding: 0 1rem 0 0;
                                    line-height: 1;

                                    &:hover,
                                    &.current {
                                        color: $c-pink;

                                        .fake-checkbox {

                                            &::before {
                                                opacity: 1;
                                            }
                                        }
                                    }

                                    .fake-checkbox {
                                        position: relative;
                                        width: 3rem;
                                        height: 2.9rem;
                                        margin-right: .5rem;
                                        border: 1px solid $checkbox-border-color;

                                        &::before {
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            transform: translate(-50%, -50%);
                                            @extend %icomoon;
                                            content:"\e925";
                                            opacity: 0;
                                            font-size: 3rem;
                                            color: $checkbox-border-color;
                                            transition: opacity .3s;
                                        }
                                    }

                                    .label {
                                        width: calc(100% - 3rem);
                                        margin: 0;
                                    }
                                }

                                .count {
                                    @include visually-hidden;
                                }
                            }
                        }
                    }

                    &.active {

                        > .filter-options-title {
                            position: relative;
                            z-index: 2;

                            &::after {
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
            }

            > .block-actions.filter-actions {
                display: none;
            }
        }
    }

    //
    // Current filters
    // __________________________________________

    .filter-current {
        margin: 2rem auto 3rem;

        @include breakpoint(md) {
            margin: 3rem auto 4rem;
        }

        > .filter-current {
            margin-bottom: 0;
        }

        .block-subtitle {
            @include visually-hidden;
        }

        .items {
            display: flex;
            flex-wrap: wrap;

            > .item {

                + .item {
                    margin-left: 1rem;
                }

                > .action.remove {
                    display: flex;
                    align-items: center;
                    padding: .7rem 1rem;
                    border-radius: 1px;
                    font-size: 1.4rem;
                    color: #5a5a59;
                    background-color: $c-grey-4;

                    > .filter-label {

                        &::after {
                            content: ':';
                            margin: 0 .3rem;
                        }
                    }

                    > .filter-value {
                        font-family: $f-avenir-black;
                    }

                    > span.filter-remove {
                        margin-left: 1rem;
                        font-size: 0;

                        &::before {
                            @extend %icomoon;
                            content: "\e908";
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }

    //
    // List
    // __________________________________________

    .blog-list {

        @include breakpoint(md) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -2rem;
        }

        &__item {
            margin-bottom: 4rem;

            @include breakpoint(md) {
                padding: 0 2rem;
                width: $w-half;

                &:nth-child(2n + 2) {
                    margin-top: 12rem;
                }
            }

            @include breakpoint(lg) {
                width: $w-third;

                &:nth-child(2n + 2) {
                    margin-top: 0;
                }

                &:nth-child(3n + 2) {
                    margin-top: 14rem;
                }
            }
        }
    }

    //
    // Item
    // _______________________________________

    .blog-item {

        // Image
        .blog-item__image {
            margin-bottom: 2.8rem;

            .ct-link {
                display: block;
                width: 100%;
                overflow: hidden;

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }

            img {
                display: block;
                width: 100%;
                transition: transform 2s;
            }
        }

        // Title
        .blog-item__title {
            font-size: 1.9rem;
            line-height: 1.2;

            > .blog-item__link {
                color: $c-pink;
            }
        }

        // Presentation
        .blog-item__presentation {
            margin: 2rem 0;
            line-height: 1.42;

            @include breakpoint(only-md) {
                font-size: 1.2rem;
            }
        }

        // Read more
        .blog-item__read-more {
            margin-bottom: 2rem;

            > .read-more-link {
                border-bottom: 1px solid currentColor;
                font-family: $f-museoSlab-700;
                transition: color .3s;

                &::before {
                    content: '>';
                }

                &:hover {
                    color: $c-pink;
                }
                @include breakpoint(only-md) {
                    font-size: 1.2rem;
                }

            }
        }

        .blog-item__date,
        .tutos-comments {
            display: inline-flex;

            @include breakpoint(only-md) {
                font-size: 1.2rem;
            }
        }

        .blog-item__date {

            &::after {
                margin-left: 0.5rem;
                content: '/';
            }
        }

        // Comments
        .tutos-comments {

            .contenttype-listing-reviews {
                transition: color .3s;

                &:hover {
                    color: $c-pink;
                }
            }
        }
    }

    //
    // Toolbar
    // _______________________________________

    .toolbar-bottom {

        .toolbar-amount {
            @include visually-hidden;
        }

        .pages {
            display: flex;
            justify-content: center;
        }
    }
}
