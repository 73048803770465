// ==============================================
// CUSTOMER ACCOUNT WISHLIST
// ==============================================

.account.wishlist-index-index {

    .column.main {
        position: relative;
    }
}

// Top content
.wishlist-top-content {
    padding-bottom: 3rem;
    margin-bottom: 1.5rem;
    background-image: url('../images/background/sep-h.png');
    background-position: bottom left;
    background-repeat: repeat-x;

    @include breakpoint(lg) {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }

    // Page title
    .page-title-like {
        @extend .title-1;
        color: $c-base;
        text-transform: inherit;

        @include breakpoint(only-lg) {
            @include visually-hidden;
        }

        @include breakpoint(xl) {
            font-size: 3.5rem;
        }
    }

    // Toolbar
    .actions-toolbar {
        margin-top: 0;

        @include breakpoint(only-lg) {
            width: 100%;

            .primary {
                display: flex;
                width: 100%;
            }
        }

        @include breakpoint(lg) {
            margin-left: auto;
        }

        .action {

            &.print,
            &.share {
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-size: 1.2rem;
            }

            @include breakpoint(md) {
                &.share {
                    margin-right: 1rem;
                }
            }

            @include breakpoint(only-lg) {
                width: 100%;
                font-size: 1.2rem;

                &.print {
                    display: none;
                }
            }

            &.print {
                @extend .btn;
                @extend .btn--green;
            }
        }
    }
}

// Product list
.products-grid.wishlist {

    .product-items {
        display: flex;
        flex-wrap: wrap;

        li.product-item {
            width: $w-half;

            @include breakpoint(lg) {
                width: $w-fourth;
            }
        }
    }

    .product-item {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        margin-bottom: 2rem;
    }

    // Item
    .product-item-info {
        padding: 0;
    }

    // img
    .product-item-photo {
        margin-top: 3rem;
        margin-bottom: 1.5rem;
    }

    .product-image-container {
        display: block;
        width: 100% !important;

        > .product-image-wrapper {
            display: block;
            width: 100% !important;
            padding-bottom: 0 !important;

            > .product-image-photo {
                display: block;
                width: 100%;
            }
        }
    }

    // Remove
    .product-item-actions {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0;
        justify-content: flex-end;
        z-index: 10;

        @include breakpoint(only-sm) {
            display: block;
        }

        .btn-remove {
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            padding-left: 1rem;
            height: 3rem;

            > span {
                @include visually-hidden;
            }

            &::before {
                font-size: 2rem;
                content: "\e907";
            }
        }
    }

    // Comment box
    .product-item-inner {
        display: none;
    }

    // Price-box
    .price-box {
        margin-bottom: 0;

        .price {
            font-family: $f-museoSlab-700;
            font-size: 1.7rem;
        }
    }
}

//
// Form share
//__________________________________________________________

.wishlist-index-share {

    // Page title
    .page-title-wrapper {
        @include visually-hidden;
    }

    .form.wishlist.share {

        // Legend
        div.legend {
            @extend .title-2;
            padding-bottom: 1.8rem;
            margin-bottom: 2rem;
            text-transform: inherit;
            background-image: url('../images/background/sep-h.png');
            background-position: bottom left;
            background-repeat: repeat-x;

            @include breakpoint(lg) {
                padding-bottom: 2.8rem;
                margin-bottom: 3rem;
            }
        }

        // Actions toolbar
        .actions-toolbar {
            display: flex;
            align-items: center;

            .primary {
                margin-left: auto;
            }
        }
    }
}
