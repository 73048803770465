//
// Top infos
//_______________________________________________

// Top infos
.product-top-infos {

    // List
    .infos-list {

        @include breakpoint(sm) {
            column-count: 2;
        }

        > li {
            padding-right: 1rem;
            margin-bottom: 1rem;

            + li {
                margin-top: 0;
            }
        }
    }
}
