// ==============================================
// CHECKOUT - Payment
// ==============================================

.checkout-payment-method {

    // form
    .form.payments > .fieldset > hr {
        display: none;
    }
    .checkout-billing-address {
        margin-bottom: 2rem;

        input[type="checkbox"].checkbox--custom + label::after {
            margin-top: 1px;
        }

        div[data-form="billing-new-address"] {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 100%;

            .label {
                @include visually-hidden;
            }
            div[name="billingAddressshared.prefix"] {
                order: 1;

                .admin__field-control {
                    display: flex;
                    flex-wrap: wrap;

                    .admin__field.admin__field-option {
                        margin-right: 2rem;

                        label {
                            margin-bottom: 0.5rem;

                            padding-left: calc(#{$radio-width-medium} + 10px);
                            min-height: calc(#{$radio-width-medium} + 2px);
                            line-height: calc(#{$radio-width-medium} + 2px);

                            // Fake radio
                            &::before {
                                width: $radio-width-medium;
                                height: $radio-height-medium;
                            }

                            // Round
                            &::after {
                                width: calc(#{$radio-width-medium} - 8px);
                                height: calc(#{$radio-height-medium} - 8px);
                                left: calc((#{$radio-width-medium} + #{$radio-border-weight} * 2) / 2);
                                top: calc((#{$radio-height-medium} + #{$radio-border-weight} * 2) / 2);
                                background-color: $c-red;
                            }
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        @include breakpoint(md) {
                            margin-right: 4rem;
                        }
                    }
                }
            }

            div[name="billingAddressshared.firstname"] {
                order: 2;
            }
            div[name="billingAddressshared.lastname"] {
                order: 3;
            }

            .field.street {
                order: 4;
            }

            div[name="billingAddressshared.postcode"] {
                order: 5;
            }

            div[name="billingAddressshared.city"] {
                order: 6;
            }

            div[name="billingAddressshared.region_id"] {
                order: 7;
            }

            div[name="billingAddressshared.country_id"] {
                order: 8;
            }

            div[name="billingAddressshared.telephone"] {
                order: 9;
            }

            @include breakpoint(sm) {
                flex-direction: row;
                justify-content: space-between;

                > div,
                > .field.street {
                    width: 100%;
                }

                > div[name="billingAddressshared.city"],
                > div[name="billingAddressshared.postcode"],
                > div[name="billingAddressshared.country_id"] {
                    width: 48%;
                }
            }

            // Phone
            div[name="billingAddressshared.telephone"] {

                input {
                    width: 100%;
                }

                // Remove tooltip
                .field-tooltip.toggle {
                    display: none;
                }
            }
        }

        .actions-toolbar {

            .primary {

                button {
                    @extend .btn;

                    &.action-cancel {
                        @extend .btn--bordered;
                    }

                    + button {

                        @include breakpoint(only-md) {
                            margin-top: 1.5rem;
                        }

                        @include breakpoint(md) {
                            margin-left: 2rem;
                        }
                    }
                }
            }
        }
    }

    // Methods
    .payment-method {
        margin-bottom: 20px;
        transition: ease-in-out 0.2s;
        border: 1px solid $c-grey-light;

        &._active {
            border-color: $c-grey-dark2;
        }

        +#hpp_actionModalWrapper {
            border-bottom: 0;
        }


        // Title
        .payment-method-title {

            input[type="radio"] {
                @extend .radio--custom;

                & + label.label {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                    padding: 1.6rem 2rem 1.6rem 5rem !important;
                    width: 100%;
                    font-size: 1.5rem;

                    div.adyen-sprite.adyen_cc {
                        width: 296px;
                        height: 32px;
                        margin-right: 2rem;
                        background: url('../images/logo/cb.png') center no-repeat;
                        background-size: cover;

                        @include breakpoint(only-xs) {
                            width: 250px;
                            height: 27px;
                            margin-right: 0;
                            margin-bottom: 1rem;
                        }
                    }

                    img.payment-icon {
                        width: auto;
                        height: 30px;
                    }

                    img {
                        max-height: 35px;
                        width: auto;
                        margin-left: auto;
                        vertical-align: middle;
                        order: 2;
                    }

                    > span {
                        order:1;
                        @include breakpoint(only-xs) {
                            line-height: 1.3;
                        }
                        display:inline-block;
                    }

                    &[for="giftcard_standard"] {
                        >span {
                            display: flex;
                            align-items: center;

                            &::before {
                                content: '';
                                display: block;
                                width: 44px;
                                height: 27px;
                                margin-right: 1rem;
                                background: url('../images/logo/buybox.svg') center no-repeat;
                                background-size: cover;
                            }
                        }
                    }

                    // Input radio design
                    &::before,
                    &::after {
                        top: initial;
                        left: 16px;
                    }
                }
            }
        }

        // Hide Place Order button for each Payment method
        .payment-method-content {
            display: none;
            .actions-toolbar {
                display: none;
            }

            form {
                padding: 20px;
            }

            .message {
                display: block;
                padding: 0 20px;
                color: $c-error;
                &.hpp-message {
                    margin: 0;
                }
            }

            .adyen-checkout__checkbox__label {

            }

            .mollie-component {
                min-height: 35px;
                height: auto;
            }
        }

        &._active {
            .payment-method-content {
                display:block;
                padding: 0 10px;
            }
        }

        // Input
        .adyen-checkout__input {
            border-color: $border-color !important;
            border-radius: 0 !important;

            &:hover {
                border-color: $border-color !important;
            }
        }

        .fieldset.payment.items.ccard.adyen_cc {

            > .field {

                &.number.cardContainerField {

                    .adyen-checkout__label__text {
                        font-family: $f-base;
                        font-size: 1.4rem;
                        line-height: 1.2;
                    }
                }

                &.choice {
                    margin-top: 2rem;

                    @include breakpoint(only-xs) {

                        > .label {
                            line-height: 1.2;
                        }
                    }
                }
            }
        }

        .checkout-agreements {
            padding-top: 3rem;
            margin-top: 1rem;
            background-image: url('../images/background/sep-h.png');
            background-position: top left;
            background-repeat: repeat-x;

            @include breakpoint(md) {
                margin-top: 2rem;
            }

            > .checkout-agreement {
                button.action-show {
                    font-family: $f-avenir;
                    text-align: left;
                }
            }
        }

        // MT Fidelity payment method
        &.fidelity {
            border: 1px solid $c-blue-fid2;
            border-left: 5px solid $c-blue-fid;
            background-color: $c-blue-fid3;
            border-radius: 4px;

            &.unavailable {
                pointer-events: none;

                .payment-method-title .label {
                    color: $c-grey-light2;
                }
                .unvailable-message {
                    display: block;
                    padding-left: 5rem;
                    margin: 0;
                }
            }

            .unvailable-message {
                display: none;
            }

            .payment-method-title {
                margin: 10px;
            }

            .payment-method-content form {
                padding-top: 0;
            }
        }
    }

    .payment-methods {
        display: block;
        margin-bottom: 30px;
    }

    // Agreements
    .checkout-agreement {

        button.action.action-show {
            font-family: $f-base;
            color: $c-base;
            text-decoration: underline;
        }
    }

    .opc-payment {

        .actions-toolbar {

            button {
                text-transform: uppercase;
            }

            @include breakpoint(lg) {
                margin-top: 0;
                text-align: right;
            }
        }
    }

    .place-order-section {
        text-align: center;
    }
}
