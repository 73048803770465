// ==============================================
// CS BLOCK : User Guides
// ==============================================

$picture-width: 350px;

.csb-user-guides {
    margin: 3rem 0;

    strong, b {
        font-family: $f-avenir-black;
    }

    .container {
        padding: 0;
    }

    em, i {
        font-style: italic;
    }

    // Main link
    .csb-user-guides__link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    // Main title
    .csb-user-guides__title {
        @extend .title-2;
        margin-bottom: $gutter;
    }

    // Description
    .csb-user-guides__desc {
        img {
            width: 100%;
            height: auto;
        }
    }

    // Items
    .csb-user-guides__list {
        margin-top: $gutter*2;
    }

    .csb-user-guides__item {
        position: relative;
        width: 100%;
        display: block;
        margin-bottom: $gutter*3;

        &::after {
            content: "";
            display: block;
            clear: both;
        }

        @include breakpoint(only-sm) {
            margin-bottom: 30px;
        }

        .item-title {
            width: 100%;
            margin-bottom: 25px;
        }

        .csb-user-guides__item-picture {
            float: left;
            margin-right: 30px;

            img {
                width: $picture-width;
                height: $picture-width;
                object-fit: contain;
            }

            @include breakpoint(only-sm) {
                float: none;
                display: block;
                width: 100%;
                margin-bottom: 20px;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .csb-user-guides__item-content {

            @include breakpoint(only-sm) {
                width: 100%;
                padding: 0;
            }

            .item-title {
                font-size: 2rem;
            }

            .item-text a {
                @extend .link-1;
                color: $link-color;
                padding: 0 2px;
            }

            .item-link {
                margin-top: $gutter;
                text-align: right;

                p {
                    text-decoration: underline;
                }
            }
        }
    }

    // Actions

    .csb-user-guides__actions {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .csb-user-guides__cta {
        margin-left: $gutter;
        margin-right: $gutter;

        @extend .btn;
    }
}
