// ==============================================
// Lookbook Slider
// ==============================================

[class*="cms-inspirations-"].cms-page-view.page-layout-1column {

    .ugc {

        > h1 {
            @extend .title-1;
            color: $c-red;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 4px;
        }
    }
}

.lookbookslider-top {

    // Share
    .lookbook-share {
        margin: 2rem 0;
        text-align: center;

        @include breakpoint(md) {
            margin: 3rem 0;
        }

        .lookbook-share__link {
            display: inline-block;
            vertical-align: middle;
            padding: 0 2rem;
            color: $c-black;
            transition: color .3s;

            > [class*="icon-"] {
                font-size: 2.8rem;
            }

            &:hover {
                color: $c-pink;
            }
        }
    }

    // Content text
    .content-before,
    .content-after {
        margin: 2rem 0;
        text-align: center;

        @include breakpoint(md) {
            margin: 3rem 0;
        }
    }
}

//
// Slideshow
// ______________________________________________

.lookbook-scene {

    .lookbookslider-container {
        z-index: 1;

        .slide.altima-slide {

            + .slide.altima-slide {
                visibility: hidden;
            }
        }
    }


    // Mobile
    @include breakpoint(only-xs) {

        .pagernav {
            display: block;
        }

        .pagernav-list {

            .slick-list {
                width: calc(100% - (20px * 2)) !important;

                .slick-slide {
                    width: 82px !important;
                    margin: 0 5px !important;

                    img {
                        min-height: auto !important;
                    }
                }
            }
        }
    }

    // Tablette
    @include breakpoint(only-lg) {

        .pagernav {
            margin-top: 2rem;

            .pagernav-list {
                z-index: 0; // Prevent hotspot which appear under it
                display: flex;
                align-items: center;

                .slick-list {
                    width: calc(100% - (58px * 2));

                    .slick-slide {
                        width: 183px;
                        margin: 0 0.5rem;
                    }
                }

                .slick-arrow {
                    position: static;
                    margin: 0 auto;
                    transform: translate(0, 0);

                    &::before {
                        content: "\e923";
                    }

                    &.slick-prev {

                        &::before {
                            transform: rotate(90deg);
                        }
                    }

                    &.slick-next {

                        &::before {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }
        }
    }

    // Desktop
    @include breakpoint(lg) {
        display: flex;
        justify-content: space-between;
        max-width: 120rem;
        margin: 0 auto;

        .lookbookslider-container {
            flex: 1;
        }

        .pagernav {
            z-index: 1;
            display: flex;
            align-items: center;
            width: 210px;

            > .pagernav-list {

                .slick-list {
                    margin: 2rem 0;

                    .altima-thumb {
                        margin: 1rem 0;
                        cursor: pointer;

                        img {
                            min-height: 10rem;
                        }
                    }
                }

                .slick-arrow {
                    position: static;
                    margin: 0 auto;
                    transform: translate(0, 0);

                    &::before {
                        content: "\e923";
                    }

                    &.slick-prev {

                        &::before {

                            transform: scaleY(-1);
                        }
                    }
                }
            }

            .slick-track {
                flex-direction: column;
            }
        }

        .pagernav-list {
            width: 187px;
            margin: 0 auto;
        }
    }

    // Content
    .cycle-slideshow1 {
        overflow: inherit;
        margin: 0;

        .hotspot img{
            width: auto;
            max-width: 100%;

        }
    }

    // Progress bar
    #progress {
        display: none;
    }

    // Hotspot
    .hotspot {
        display: block !important;
    }

    // Item
    .product-info.product-item-info {
        min-width: 207px;
        max-width: 207px;
        height: auto !important;
        min-height: 344px !important;
        padding: 0;
        background: $c-white;
        border: none;
        border-radius: 0;
        box-shadow: 0 2px 4px rgba($c-black, 0.5);

        .product-item-details {
            padding-top: 0;

            .product-image {
                padding: 1rem;

                img {
                    position: static;
                    display: block;
                    width: 100%;
                }
            }

            .product-name {
                display: block;
                padding: 0 1rem 1rem 1rem;
                min-height: 50px;
                font-family: $f-avenir;
                font-size: 1.4rem;

                a {
                    margin: 0;
                    font-family: $f-avenir;
                    font-size: 1.4rem;
                    color: $c-base;
                }
            }

            .price-box {
                padding: 0 1rem;
                margin-bottom: 2rem;
                @extend %price-box--small;

                .price {
                    float: none;
                }
            }

            .actions-primary {
                float: none;
                padding: 0 1rem 1rem;

                button {
                    width: 100%;
                }
            }

            .product-stock.out-of-stock {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 0 10px;
                text-align: right;
                font-size: 1.6rem;
                color: $c-base;

                &::before {
                    content: "";
                    display: inline-block;
                    margin: 0 10px 0 0;
                    @include circle(1rem);
                    background: $c-red;
                }
            }
        }
    }
}
