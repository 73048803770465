// ==============================================
// UI - Tabs
// ==============================================

// Configuration

$ui-tabs-background-color:              $c-white;
$ui-tabs-not-active-background-color:   darken($c-white, 5%);
$ui-tabs-border-color:                  darken($c-white, 10%);

.product.data.items {
    position: relative;
    z-index: 1;
    display: block; // Remove display flex
    padding: 0;
    margin: 0 0 20px;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    > .item {

        &.content {
            display: block;
            float: right;
            width: 100%;
            padding: 35px 35px 35px 35px;
            margin-left: -100%;
            margin-top: 39px;
            background: $ui-tabs-background-color;
            border: 1px solid $ui-tabs-border-color;

            @include breakpoint(only-md) {
                float: none;
                padding: 10px 15px 30px;
                margin: 0 0 5px;
            }

            &:before,
            &:after {
                display: table;
                content: '';
            }

            &:after {
                clear: both;
            }

            &.active {
                display: block;
            }
        }

        &.title {
            float: left;
            width: auto;
            margin: 0 -1px 0 0;

            @include breakpoint(only-md) {
                float: none;
                width: 100%;
                margin: 0 0 5px;
            }

            > .switch {
                position: relative;
                z-index: 2;
                display: block;
                height: 40px;
                padding: 1px 35px;
                background: $ui-tabs-not-active-background-color;
                border: 1px solid $ui-tabs-border-color;
                font-size: 1.4rem;
                line-height: 40px;
                font-weight: 400;

                @include breakpoint(only-md) {
                    padding: 1px 15px 1px;
                }
            }

            &.active {

                > .switch {
                    background: $ui-tabs-background-color;
                    border-bottom: 1px solid $ui-tabs-background-color;

                    @include breakpoint(only-md) {
                        border-bottom: 1px solid $ui-tabs-border-color;
                    }
                }
            }
        }
    }
}

/* Grid support
-------------------------- */
@supports (display: grid) {

    .product.data.items {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        grid-template-rows: auto;
        grid-template-areas:
        "title1 title2 title3 . . . . . ."
        "content content content content content content  content content content";

        @include breakpoint(only-md) {
            display: block;
        }

        > .item {

            &.content {
                grid-area: content;
                float: none;
                margin-left: 0;
                margin-top: -1px;
            }

            &.title {
                float: none;
                width: auto;
                margin: 0 -1px 0 0;

                &:first-child {
                    grid-area: title1;
                }

                &:nth-child(3) {
                    grid-area: title2;
                }

                &:nth-child(5) {
                    grid-area: title3;
                }

                > .switch {
                    padding: 1px 0;
                    text-align: center;
                }

                @include breakpoint(only-md) {
                    margin: 0 0 5px;
                }
            }
        }
    }
}
