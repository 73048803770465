.mt-l-HeaderBottom {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $c-white;

    &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-logo {
        width: 130px;
        height: auto;
        & > a {
            display: block;
            font-size: 0;
        }
    }

    &-search {
        position: relative;
        width: calc(100% - 600px);
        min-height: 40px;
        //width: calc(100% - 590px);
        //max-width: 665px;
    }

    &-links {
        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > li {
                position: relative;

                a {
                    &::before {
                        display: block;
                        @include icomoon;
                        content: '';
                        font-size: 2.4rem;
                        text-align: center;
                        line-height: 40px;
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        margin-right: 10px;
                        background-image: url('../images/icon/patate-grey.svg');
                        background-size: cover;
                        position: absolute;
                        @include center-abs-y();
                        left: 0;
                    }

                    & > span {
                        display: block;
                        padding: 5px 0 5px 50px;
                        @include fs(12px, 16px);
                    }
                }

                span {
                    &:first-child {
                        display: block;
                        font-weight: 500;
                        color: $c-new-grey-2;
                    }

                    &:nth-child(2) {
                        display: block;
                        font-weight: 600;
                        color: $c-black;
                    }
                }

                & + li {
                    margin-left: 30px;
                }

                &.stores-link {
                    a::before {
                        content: "\e947";
                    }
                }

                &.authorization-link {
                    a::before {
                        content: "\e93e";
                    }
                }

                &.minicart-link {
                    a {
                        position: relative;
                        display: block;
                        width: 40px;
                        height: 40px;

                        &::before {
                            content: "\e94c";
                        }

                        & > span {
                            display: none;
                        }

                        .counter {
                            top: 0;
                            right: -10px;
                            font-family: $f-avenir;
                            @include fs(12px, 40px);

                            .counter-number {
                                color: $c-white;
                            }
                        }
                    }
                }

                &:last-child {
                    display: none;
                }
            }
        }
    }

    &-burger {
        display: none;
        width: 30px;
        height: 23px;
        padding: 0;
        font-size: 2.5rem;
        margin-right: 55px;
        cursor: pointer;

        span {
            &:before {
                content: "\e948";
            }
        }
    }

    @include breakpoint(only-xl) {
        &-container {
            flex-wrap: wrap;
        }

        &-burger {
            display: block;
            margin-right: 110px;
        }

        &-logo {
            order: 1;
            width: calc(100% - 140px);
            height: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .logo {
                width: 78px;
                height: auto;
                margin-left: auto;
                margin-right: auto;

                &.store-custom {
                    width: 100px;
                }
            }
        }

        &-search {
            order: 3;
            width: 100%;
            max-width: initial;
            margin-top: 12px;
        }

        &-links {
            order: 2;
            width: 140px;

            ul {
                & > li {
                    a {
                        &::before {
                            font-size: 2rem;
                            line-height: 35px;
                            width: 35px;
                            height: 35px;
                        }
                    }

                    span {
                        &:first-child,
                        &:last-child {
                            display: none;
                        }
                    }

                    &.minicart-link {
                        .counter {
                            right: -6px;

                            span:first-child {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(only-sm) {
        &-burger {
            margin-right: 55px;
        }
        &-logo {
            .logo {
                margin: 0;
            }
        }
    }

    @include breakpoint(only-xxs) {
        &-burger {
            margin-right: 10%;
        }
    }
}

[data-sticky="true"] {
    .mt-l-HeaderBottom {
        position: relative;
        background-color: $c-white;
        z-index: 3;
        padding-top: 0;
        padding-bottom: 0;

        &-container {
            background-color: $c-white;
            justify-content: flex-end;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        &-logo {
            margin-right: auto;
            width: 110px;
        }

        &-search {
            max-width: calc(100% - 1125px);
        }

        &-links {
            order: 2;
            width: 140px;
            margin-left: 35px;

            ul {
                & > li {
                    a {
                        &::before {
                            font-size: 2rem;
                            line-height: 35px;
                            width: 35px;
                            height: 35px;
                        }
                    }

                    span {
                        &:first-child,
                        &:last-child {
                            display: none;
                        }
                    }

                    &.minicart-link {
                        .counter {
                            right: -6px;

                            span:first-child {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 1355px) {
            &-search {
                width: 100%;
                max-width: initial;
                position: absolute;
                left: 0;
                top: calc(100% - 10px);
                @include transition(top ease-in-out 0.5s);
                padding: 10px 20px 15px;
                margin: 0;
                background: $c-white;
                box-shadow: 0 15px 20px 0 rgba($c-black, 0.2);
                z-index: -1;
            }
        }

        @include breakpoint(only-xl) {
            border: none;
            &-container {
                height: 70px;
                padding-top: 0;
                padding-bottom: 0;
            }
            &-logo {
                width: calc(100% - 140px);
            }
            &-links {
                margin-left: 0;
            }
        }
    }
}

.checkout-index-index {
    padding-top: 125px!important;
    .mt-l-HeaderBottom {
        position: fixed;
        top: 0;
        width: 100%;
        background-color: $c-white;
        box-shadow: 0 1px 3px rgba($c-black,.14);
        padding: 15px;

        &-logo {
            width: 110px;

            img {
                height: 52px;
            }
        }
    }

    .opc-progress-bar {
        position: fixed;
        left: 30%;
        z-index: 11;
        top: 10px;
        margin-top: 0;

        .has-top-header & {
            top: 0;
        }

        @include breakpoint(only-md) {
            right: 20px;
            left: auto;
        }

        @include breakpoint(lg) {
            left: 50%;
            transform: translateX(-50%);
            min-width: 680px;

            .ie11 & {
                height: 13.5rem;
            }
        }

        li span {
            font-size: 1.6rem;
        }
    }

    [data-sticky="true"] .mt-l-HeaderBottom-container {
        padding: 0;
    }

    @include breakpoint(only-xl) {
        padding-top: 100px!important;
    }

    @media (max-width: 768px) {
        padding-top: 15px!important;
    }
}
