// ==============================================
// Panel : Storelocator
// ==============================================

// header
.storepicker-header {
    min-height: 6.6rem;
    padding: 2.5rem 7rem 2rem 2rem;
    background-color: $c-grey-light;

    @include breakpoint(md) {
        min-height: 8.8rem;
        padding: 3.5rem 8rem 3rem 3rem;
    }

    h3 {
        margin-bottom: 2.8rem;
    }

    &.storepicker-view-header {

        h3 {
            margin-top: 2rem;
            margin-bottom: 0;
        }

        .storepicker-cancel-button {
            font-size: 1.3rem;

            &::before {
                content: '<';
                margin-right: 1rem;
            }

            span {
                text-decoration: underline;
            }
        }
    }
}

// Content
.storepicker-content {
    padding: 2.5rem 2rem;

    @include breakpoint(md) {
        padding: 3.5rem 3rem;
    }

    .storepicker-info {
        padding-bottom: 3.5rem;
        margin-bottom: 3.5rem;
        background: url('../images/background/sep-h.png') no-repeat bottom center;
        text-align: center;

        > .block {

            + .block {
                margin-top: 5rem;
            }

            h4 {
                margin: 1.4rem 0;
            }

            &.purchase {
                [class*="icon"] {
                    font-size: 5.4rem;
                }
            }

            &.schedules {
                [class*="icon"] {
                    font-size: 3.4rem;
                }
            }
        }
    }

    .form-search-storepicker {
        position: relative;

        .label {
            display: block;
            margin-bottom: 1rem;

            &::after {
                content: '*';
                color: $c-red;
            }
        }

        input {
            padding-right: 5rem;
            margin-bottom: 1rem;
        }

        .button-wrapper {
            display: inline-flex;
            margin-bottom: 1rem;
            position: relative;

            @include breakpoint(md) {
                max-width: 27rem;
            }

            [class*="icon"] {
                font-size: 2.5rem;
                left: 2rem;
                position: absolute;
                top: 1.3rem;
                color: $c-white;
            }

            #search-geoloc-button {
                padding-left: 5rem;
                padding-top: 12px;
                padding-bottom: 12px;
            }

            &:hover {
                [class*="icon"] {
                    color: $c-red;
                }

            }
        }

        .input-wrapper {
            position: relative;

            input {
                border-radius: 30px;
                background-color: #f0f0f0;
            }

            button {
                bottom: 0;
                height: 100%;
                max-width: fit-content;
                position: absolute;
                right: 0;
                top: 0;
                border-radius: 0 30px 30px 0;
                padding: 13px 25px;

                .icon-arrow2 {
                    font-weight: bold;
                }
            }
        }
    }
}

// List result
.storepicker-list {
    max-height: 50rem;
    overflow-y: auto;
    padding: 1.5rem 2rem;

    @include breakpoint(md) {
        padding: 1.5rem 3rem;
    }

    > .storepicker-data {
        border-top: 1px solid #c9c9c9;

        &:last-child {
            border-bottom: 1px solid #c9c9c9;
        }
    }

    .store {
        display: flex;
        align-items: flex-end;
        padding: 3rem 0;

        .store-address {
            flex: 1;
            padding-right: 2rem;
        }

        .store-url {

            a {
                font-size: 1.4rem;
            }
        }
    }
}

// Store address
.store-address {

    > div + div {
        margin-top: 1.4rem;
    }
}

// Map
.storepicker-view-map {
    height: 20rem;
    margin: 3rem 0;
}

// Hours
.storepicker-view-schedule {
    margin-bottom: 1.6rem;

    .days {
        margin-bottom: 3.5rem;

        li + li {
            margin-top: 1.4rem;
        }
    }

    .day {
        display: flex;
        justify-content: space-between;

        .day-label {
            font-family: $f-avenir-heavy;
        }
    }

    // Special day
    .special-day {
        padding: 1.5rem;
        color: $c-error;
        border: 1px solid currentColor;
    }
}

// Actions
.storepicker-view-actions {

    a {
        width: 100%;

        + a {
            margin-top: 1.4rem;
        }
    }
}
