// ==============================================
// CS BLOCK CONFECTION : Images list
// ==============================================

.csb-images-list {

    // Title
    .title-2 {
        margin-bottom: 1.5rem;
        text-align: center;

        @include breakpoint(md) {
            margin-bottom: 2.5rem;
        }
    }

    // Description
    .images-list__description {
        margin-bottom: 2rem;
        font-size: 1.6rem;
        text-align: center;

        @include breakpoint(md) {
            margin-bottom: 4rem;
        }
    }

    // List
    .images-list__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1rem;

        > li {
            width: 50%;
            padding: 0 1rem;
            margin-bottom: 2rem;

            @include breakpoint(sm) {
                width: 33.3333%;
                margin-bottom: 3rem;
            }

            @include breakpoint(md) {
                width: 25%;
                margin-bottom: 4rem;
            }
        }
    }

    // Item
    // Item link
    .images-item__link {
        display: block;
        margin-bottom: 2rem;

        img {
            display: block;
            width: 100%;
        }
    }

    // Item title
    .images-item__title {
        margin-bottom: 1.5rem;
        text-transform: inherit;
    }

    // Item description
    .images-item__description {
        line-height: 1.4;
    }
}
