.mt-c-TutosWidget {
    position: relative;
    background: $c-new-red;
    text-align: center;
    color: white;
    padding: 4rem 2rem 7rem;
    margin: 6rem 0;

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 12px;
        background: var(--season-pattern-3);
        content: ' ';
    }
    &-title {
        margin-bottom: 3rem;
    }
    .title-1-alt small {
        color: white;
    }

    .mt-c-Btn {
        margin-top: 20px;
    }
    &-content {
        width: 1280px;
        max-width: 100%;
        margin: 0 auto;
    }
    &-list {
        display: flex;
        justify-content: space-between;

        &-item {
            min-width: 0;
            flex: 0 1 calc(33.33% - 27px);
            max-width: 400px;

            .ct-image-link {
                position: relative;
                display: block;
                height: auto;
                font-size: 0;
                line-height: 0;
                background: $c-red-dark;

                &::before {
                    display: block;
                    width: 100%;
                    padding-bottom: 105%;
                    content: '';
                }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .ct-field-drop_down {
                display: inline-block;
                position: relative;
                top: -2rem;
                background: #F7F7F7;
                transform: rotate(-2deg);
                padding: 1.2rem;
                color: $c-new-red;
                font-family: $f-avenir-medium;
            }
            .ct-field-field {
                font-size: 2rem;
                line-height: 2.4rem;
                font-family: $f-museoSlab-500;
                margin-bottom: 1.5rem;
            }
            .label {
                &::after {
                    content: ':';
                    margin: 0 .3rem;
                }
            }

            .ct-field-area {
                line-height: 2rem;

                p {
                    margin: 0;
                }
                a {
                    color: white;
                }
            }
            a.contenttype-listing-readmore {
                display: inline-block;
                font-size: 1.6rem;
                line-height: 2rem;
                font-family: $f-museoSlab-500;
                text-decoration: underline;
                margin-top: 1.5rem;
                color: white;
            }
        }
    }

    @include breakpoint(only-lg) {
        margin: 3rem 0;

        &-list {
            flex-direction: column;
            justify-content: center;

            &-item {
                flex: 0 1 100%;
                margin: 0 auto;

                + .mt-c-TutosWidget-list-item {
                    margin-top: 2rem;
                }
                .ct-field-field {
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

