.mt-u-clearfix {
    &:before,
    &:after {
        display: table;
        content: ' ';
    }
    &:after {
        clear: both;
    }
}
