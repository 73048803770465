// ==============================================
// CHECKOUT - Shipping
// ==============================================

.opc-wrapper {

    // Alerte
    .alert-message-block {
        padding: 2.8rem 1.5rem;
        margin-bottom: 2rem;
        border: 1px solid  $c-error;
        text-align: center;
    }

    // Shipping addresses
    .field.addresses {
        & + .action-show-popup {
            width: 100%;
            margin-top: 1rem;
            @extend .btn--grey-light;

            @include breakpoint(md) {
                width: auto;
                margin-top: 2rem;
            }
        }
    }

    // Shipping Methods
    .methods-shipping {

        // Method Shipping Table
        .table-checkout-shipping-method {
            margin-bottom: 6rem;
            width: 100%;

            thead  {
                @include visually-hidden;
            }

            tbody > tr {
                border: 1px solid $c-grey-3;
                border-radius: 4px;

                + tr {
                    margin-top: 2rem;

                    &.shop-title {
                        margin-top: 6rem;
                    }
                }

                // Selected store/relay
                &#mtstorepickup_pickup_address,
                &#mondialrelay_pickup_address,
                &#colissimo_pickup_address {

                    > td {
                        padding: 1.5rem;
                        background-color: $border-color;

                        @include breakpoint(sm) {
                            padding: 3rem 3rem 3rem 2rem;
                        }

                        .line + .line {
                            margin-top: .5rem;
                        }

                        .change {
                            margin-top: 2rem;

                            a {
                                font-family: $f-avenir-heavy;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .row {
                display: flex;
                align-items: center;
                position: relative;
                gap: 10px;
                padding: 1rem 1.6rem;

                @include breakpoint(only-sm) {
                    flex-wrap: wrap;

                    .col-method {order: 1;}
                    .col-carrier {order: 2;}
                    .col-title {
                        order: 4;
                        width: 100%;
                    }
                    .col-price {order: 3;}
                }

                @include breakpoint(sm) {
                    padding-left: 2rem;
                }

                &:hover {
                    cursor: pointer;
                }

                /* Others sellers table heading */
                &.shop-title {
                    padding: 0;
                    border: none;

                    & + tr {
                        margin-top: 0;
                    }

                    td {
                        width: 100%;
                    }

                    .offer-shop-name {
                        color: $c-red;
                    }
                }
            }

            input[type="radio"] {
                @extend .radio--custom;

                + label {
                    margin: 0 15px 0 0;

                    & + .selected-border {
                        display: none;
                    }
                }

                &:checked {
                    & + label + .selected-border {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: -1px;
                        right: -1px;
                        bottom: 0;
                        border: 1px solid $c-grey-dark2;
                        pointer-events: none;
                    }
                }
            }

            .col {

                &.col-check {

                    @include breakpoint(only-sm) {
                        vertical-align: middle;
                        padding: 2rem 1rem 2rem 2rem;
                    }

                    input[type="radio"] {

                        + label {

                            @include breakpoint(sm) {
                                padding-left: 0 !important;
                            }

                            + span.border {
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                border: 1px solid $border-color;
                                pointer-events: none;
                            }
                        }

                        &:checked + label + span.border {
                            border-width: 4px;
                        }
                    }
                }

                &.col-carrier {
                    width: 100px;
                    height: 70px;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    font-size: 0;

                    @include only_ie {
                        display: block;
                    }

                    &#label_carrier_chronorelaishop_chronorelaishop {
                        background-image: url("../images/logo/shipping/chronopost.png");
                    }

                    &#label_carrier_storepickup_storepickup,
                    &#label_carrier_clickcollect_storepickup {
                        background-image: url("../images/logo/logo.svg");
                        background-size: 60px;
                    }

                    &#label_carrier_pickup_colissimo,
                    &#label_carrier_homecl_colissimo,
                    &#label_carrier_homesi_colissimo,
                    &#label_carrier_CU_ADD-STD_marketplace_2000,
                    &#label_carrier_CU_ADD-STD_marketplace_2001 {
                        background-image: url("../images/logo/shipping/colissimo.png");
                        background-size: 55px;
                    }
                }

                &.col-title {
                    flex: 1;
                    font-family: $f-avenir-medium;
                    font-size: 1.5rem;

                    @include breakpoint(lg) {
                        padding-left: 16px;
                    }
                }

                &.col-method {
                    font-size: 1.6rem;
                    color: $c-black;

                    h3,
                    strong {
                        font-family: $f-avenir-heavy;
                    }

                    h3 {
                        text-transform: uppercase;

                        @include breakpoint(only-sm) {
                            position: absolute;
                            top: 2rem;
                            left: 10.8rem;
                        }

                        @include breakpoint(sm) {
                            margin-bottom: 2.3rem;
                        }

                        + br {
                            display: none;
                        }
                    }
                }

                &.col-price {
                    display: block;
                    padding-left: 2rem;
                    margin-left: auto;
                    font-family: $f-avenir;
                    font-weight: bold;
                    font-size: 1.4rem;

                    @include breakpoint(only-sm) {
                        .price {
                            font-family: $f-avenir-black;
                            font-size: 1.5rem;
                        }
                    }

                    .price {
                        &.free {
                            color: $c-green-2;
                            text-transform: uppercase;
                        }
                    }
                }
            }

            // Pickup popup inner
            tbody > div {
                margin-bottom: 2rem;
            }
        }

        .seller-name {
            display: flex;
            align-items: center;
            gap: 10px;
            padding-bottom: 1.7rem;
            margin-bottom: 1.6rem;
            font-size: 1.8rem;
            border-bottom: 1px solid $c-grey-3;
            font-family: $f-avenir-medium;

            img {
                max-width: 65px;
            }
        }

        // Selected store
        #onepage-checkout-shipping-method-additional-load {

            > #mtstorepickup-pickup-selected {
                display: none;
            }
        }

        // Action
        .actions-toolbar {
            text-align: center;
        }
    }
}

// Windows stuff
body.pc {
    .opc-wrapper {
        .methods-shipping {
            .table-checkout-shipping-method {
                .col {
                    &.col-method {
                        h3,
                        strong {
                            font-family: $f-avenir;
                            font-weight: 900;
                        }
                    }
                }
            }
        }
    }
}
