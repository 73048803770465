@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon/icomoon.eot');
    src:  url('../fonts/icomoon/icomoon.eot') format('embedded-opentype'),
   // url('../fonts/icomoon/icomoon.woff2') format('woff2'),
    url('../fonts/icomoon/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon/icomoon.woff') format('woff'),
    url('../fonts/icomoon/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@mixin icomoon {
    text-transform: none;
    font-family: 'icomoon' !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    font-display: block;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%icomoon {
    @include icomoon;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-display: block;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-purchase:before {
    content: "\e900";
}
.icon-account:before {
    content: "\e901";
}
.icon-arrow-menu:before {
    content: "\e902";
}
.icon-bag:before {
    content: "\e903";
}
.icon-cb:before {
    content: "\e904";
}
.icon-clock:before {
    content: "\e905";
}
.icon-email:before {
    content: "\e906";
}
.icon-close2:before {
    content: "\e907";
}
.icon-close3:before {
    content: "\e908";
}
.icon-credit-cart:before {
    content: "\e909";
}
.icon-facebook:before {
    content: "\e90a";
}
.icon-fidelity-card:before {
    content: "\e90b";
}
.icon-gift:before {
    content: "\e90c";
}
.icon-box:before {
    content: "\e90d";
}
.icon-house:before {
    content: "\e90e";
}
.icon-instagram:before {
    content: "\e90f";
}
.icon-check:before {
    content: "\e910";
}
.icon-letter:before {
    content: "\e911";
}
.icon-lock:before {
    content: "\e912";
}
.icon-loyalty-point:before {
    content: "\e913";
}
.icon-attention:before {
    content: "\e914";
}
.icon-eye:before {
    content: "\e915";
}
.icon-trash:before {
    content: "\e916";
}
.icon-home:before {
    content: "\e917";
}
.icon-message:before {
    content: "\e918";
}
.icon-menu:before {
    content: "\e919";
}
.icon-personal:before {
    content: "\e91a";
}
.icon-fav:before {
    content: "\e91b";
}
.icon-wallet:before {
    content: "\e91c";
}
.icon-pinterest:before {
    content: "\e91d";
}
.icon-play:before {
    content: "\e91e";
}
.icon-return:before {
    content: "\e91f";
}
.icon-round:before {
    content: "\e920";
}
.icon-search:before {
    content: "\e921";
}
.icon-shipping:before {
    content: "\e922";
}
.icon-submenu-arrow-copy:before {
    content: "\e923";
}
.icon-shop:before {
    content: "\e924";
}
.icon-success:before {
    content: "\e925";
}
.icon-account2:before {
    content: "\e926";
}
.icon-arrow:before {
    content: "\e927";
}
.icon-youtube:before {
    content: "\e928";
}
.icon-phone2:before {
    content: "\e929";
}
.icon-pin2:before {
    content: "\e92a";
}
.icon-picto_tri:before {
    content: "\e92b";
}
.icon-security:before {
    content: "\e92c";
}
.icon-share:before {
    content: "\e92d";
}
.icon-star:before {
    content: "\e92e";
}
.icon-star2:before {
    content: "\e92f";
}
.icon-arrow2:before {
    content: "\e930";
}
.icon-return2:before {
    content: "\e931";
}
.icon-interrogation:before {
    content: "\e932";
}
.icon-interrogation-2:before {
    content: "\e933";
}
.icon-laver-en-machine-30:before {
    content: "\e934";
}
.icon-no-agents-blanchissants:before {
    content: "\e935";
}
.icon-no-nettoyage-a-sec:before {
    content: "\e936";
}
.icon-no-repassage:before {
    content: "\e937";
}
.icon-no-seche-linge:before {
    content: "\e938";
}
.icon-repassage:before {
    content: "\e939";
}
.icon-sechage-seche-linge:before {
    content: "\e93a";
}
.icon-lavage-main:before {
    content: "\e93b";
}
.icon-tiktok:before {
    content: "\e93c";
}
.icon-changer-d-avis:before {
    content: "\e93d";
}
.icon-compte:before {
    content: "\e93e";
}
.icon-conseillers-MT:before {
    content: "\e93f";
}
.icon-croix:before {
    content: "\e940";
}
.icon-favoris:before {
    content: "\e941";
}
.icon-fleche-gauche-droite:before {
    content: "\e942";
}
.icon-fleche-haut-bas:before {
    content: "\e943";
}
.icon-guillemets:before {
    content: "\e944";
}
.icon-hashtag:before {
    content: "\e945";
}
.icon-loupe:before {
    content: "\e946";
}
.icon-magasins:before {
    content: "\e947";
}
.icon-menu-burger:before {
    content: "\e948";
}
.icon-moins:before {
    content: "\e949";
}
.icon-newsletter:before {
    content: "\e94a";
}
.icon-paiement-securise:before {
    content: "\e94b";
}
.icon-panier:before {
    content: "\e94c";
}
.icon-player:before {
    content: "\e94d";
}
.icon-plus:before {
    content: "\e94e";
}
.icon-refresh:before {
    content: "\e94f";
}
.icon-retrait-magasin:before {
    content: "\e950";
}
.icon-aide:before {
    content: "\e951";
}
.icon-materiel-de-couture:before {
    content: "\e952";
}
.icon-patrons:before {
    content: "\e953";
}
.icon-rubanerie:before {
    content: "\e954";
}
.icon-tissus-ameublement:before {
    content: "\e955";
}
.icon-tissus-enfants:before {
    content: "\e956";
}
.icon-tissus-habillement:before {
    content: "\e957";
}
.icon-tous-les-tissus:before {
    content: "\e958";
}
.icon-tricot-crochet:before {
    content: "\e959";
}
.icon-broderie:before {
    content: "\e95a";
}
.icon-linge-de-maison:before {
    content: "\e95b";
}
.icon-livres:before {
    content: "\e95c";
}
.icon-loisirs-creatifs:before {
    content: "\e95d";
}
.icon-machine-a-coudre:before {
    content: "\e95e";
}
.icon-tickets-fid:before {
    content: "\e95f";
}
.icon-scan:before {
    content: "\e960";
}
.icon-accessoires-sacs:before {
    content: "\e961";
}
.icon-idees-cadeaux:before {
    content: "\e962";
}
.icon-mode-grid:before {
    content: "\e963";
}
.icon-ouate:before {
    content: "\e964";
}
.icon-outil-tapissier:before {
    content: "\e965";
}
.icon-mode-list:before {
    content: "\e966";
}
.icon-stain:before {
    content: "\e967";
}
.icon-favoris-2:before {
    content: "\e968";
}
.icon-tag:before {
    content: "\e969";
}
.icon-arrow-left:before {
    content: "\e96a";
}
.icon-arrow-right:before {
    content: "\e96b";
}
.icon-sellers:before {
    content: "\e96c";
}
.icon-zoom:before {
    content: "\e96d";
}
.icon-certificat:before {
    content: "\e96e";
}
.icon-information:before {
    content: "\e96f";
}
.icon-question:before {
    content: "\e970";
}
.icon-check-2:before {
    content: "\e971";
}
.icon-returns:before {
    content: "\e972";
}
.icon-invoice:before {
    content: "\e973";
}
