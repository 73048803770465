// ==============================================
// CS BLOCK CONFECTION : Request form
// ==============================================

.block-quote-request {

    // Title
    .quote-request-title {
        margin-bottom: 3rem;
        font-family: $f-avenir-black;
        font-size: 2.2rem;
        text-align: center;
    }

    // Form
    .quote-request-form {

        .fields {

            @include breakpoint(md) {
                display: block;
                margin-right: 0;
                margin-left: 0;
            }
        }

        // Section
        .fields-section {
            padding-right: 1.4rem;
            padding-left: 1.4rem;

            &.formula {
                position: relative;
                padding-bottom: 2.6rem;
                margin-bottom: 2rem;
                border-bottom: 1px solid $border-color;
            }

            &.details {
                margin-bottom: 2rem;
            }

            &.customer-type {
                margin-bottom: 2.4rem;
                text-align: center;

                .request-form-label {
                    margin-bottom: 1rem;
                    font-family: $f-avenir;
                    font-size: 1.4rem;
                }
            }
        }

        // Label
        .request-form-label {
            display: block;
            margin-bottom: 1em;
            font-family: $f-avenir-black;
            font-size: 1.6rem;
            letter-spacing: -.56px;
            text-align: center;
        }

        // Select
        .formula-type {
            margin-bottom: 2.4rem;

            select {

                @include only_ie {
                    background: $input-background url('../images/icon/arrow_down.svg') no-repeat 95% center;
                    background-size: 5%;
                }
            }
        }

        // Details
        .details-inputs {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            > div {
                width: calc(50% - 6px);
                margin-bottom: 17px;

                &.email {
                    width: 100%;
                }
            }
        }

        // Radio
        .customer-type-choice {
            margin: 0 1rem;

            input[type="radio"] {
                @extend .radio--small;

                + label {
                    margin-bottom: 0;
                }
            }
        }

        // Validation
        .buttons-set {
            margin-bottom: 2.4rem;
            text-align: center;

            button {
                font-size: 1.4rem;
                text-transform: uppercase;
            }
        }

        // Phone mentions
        .phone-mention {
            margin-bottom: 2rem;
            font-size: 1.2rem;
        }
    }
}
