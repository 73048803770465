// ==============================================
// CUSTOMER ACCOUNT ADDRESS
// ==============================================

//
// Index
// _____________________________________________________

.customer-address-index {

    // Blocks
    .block {

        .block-title {

            @include breakpoint(only-md) {
                background-image: inherit;
                padding-bottom: 0;
                margin-bottom: 2rem;
                font-size: 1.9rem;
            }

            @include breakpoint(lg) {
                max-width: none;
                background-repeat: repeat-x;
                font-size: 3rem;
            }

            @include breakpoint(xl) {
                font-size: 3.5rem;
            }
        }

        // Default
        &.block-addresses-default {

            // Box
            .box {
                position: relative;

                .box-title {
                    padding-right: 9rem;
                }

                .box-actions {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-top: 0 !important;
                }
            }
        }

        // List
        &.block-addresses-list {
            margin-bottom: 0 !important;

            .block-title {

                @include breakpoint(md) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 1.8rem;
                    font-size: 2rem;
                }

                strong {
                    display: block;

                    @include breakpoint(only-md) {
                        margin-bottom: 2rem;
                    }
                }

                .action.add {
                    @extend .btn;
                    @extend .btn--green;
                    padding-top: 1.2rem;
                    padding-bottom: 1.2rem;
                    font-size: 1.2rem;

                    > span {

                        &::before {
                            content: '+';
                        }
                    }

                    @include breakpoint(only-md) {
                        width: 100%;
                    }
                }
            }

            .block-content {

                p.empty {
                    margin-top: 1em;
                    margin-bottom: 0;
                }
            }
        }
    }

    // Aditional addresses
    .additional-addresses {

        @include breakpoint(md) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -1.5rem;

            > li {
                width: 50%;
                padding: 0 1.5rem;

                &:nth-child(n+3) {
                    margin-top: 3rem;
                }
            }
        }

        @include breakpoint(only-md) {

            li + li {
                margin-top: 2.5rem;
            }
        }

        // Address
        address {
            margin-bottom: 1rem;
        }

        // Actions
        .actions {
            display: flex;
            justify-content: space-between;

            a {
                transition: color .3s;

                &:hover {
                    color: $c-pink;
                }
            }

            .delete {

                &::before {
                    display: none;
                }

                span {
                    margin-left: 0;
                }

                @include breakpoint(only-md) {
                    font-family: $f-avenir-black;
                    color: $c-red;
                }
            }

            .edit {
                font-family: $f-museoSlab-700;
                text-decoration: underline;
            }
        }
    }
}

// Form
// _____________________________________________________

body.account.customer-address-form {

    // Form
    .form-address-edit {

        input[type="checkbox"] {
            @extend .checkbox--small;

            + label {
                margin-right: 3rem;
            }
        }

        // Label
        .fieldset.password label,
        .field.fullname > label,
        .field-name-prefix > label,
        .field-name-lastname > label,
        .field-name-firstname > label,
        .company label,
        .telephone label,
        .street label,
        .city label,
        .zip label,
        .country label {
            @include visually-hidden;
        }

        // input type placeholder
        .field-name-lastname,
        .field-name-firstname,
        .telephone {

            input {
                @extend .input--placeholder;
            }
        }

    }

    // Legend
    div.legend {
        @extend .title-2;
        padding-bottom: 1.8rem;
        margin-bottom: 2rem;
        text-transform: inherit;
        background-image: url('../images/background/sep-h.png');
        background-position: bottom left;
        background-repeat: repeat-x;

        @include breakpoint(lg) {
            padding-bottom: 2.8rem;
            margin-bottom: 3rem;
        }
    }

    // Fieldset
    .fieldset {

        @include breakpoint(md) {
            @include clearer;
        }
    }

    // Field
    .field {

        &.fullname {

            @include breakpoint(md) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            > .field-name-prefix {

                @include breakpoint(md) {
                    width: 100%;
                }

                > .control {

                    input[type="radio"] + label {
                        margin-right: 2rem;
                    }
                }
            }

            > .field-name-lastname,
            > .field-name-firstname {
                margin-top: 1.5rem;
            }
        }

        &.field-name-lastname,
        &.field-name-firstname,
        &.company,
        &.telephone,
        &.phone-complementary,
        &.city,
        &.zip,
        &.country {

            @include breakpoint(md) {
                width: 48%;
            }

            @include breakpoint(lg) {
                width: 46%;
            }
        }

        &.phone-complementary,
        &.country,
        &.telephone,
        &.company,
        &.city,
        &.zip {

            @include breakpoint(md) {
                float: left;
            }
        }

        &.phone-complementary,
        &.zip{

            @include breakpoint(md) {
                margin-left: 4%;
            }

            @include breakpoint(lg) {
                margin-left: 8%;
            }
        }

        &.phone-complementary {
            font-size: 1.2rem;
            line-height: 1.4;
            font-style: italic;

            strong {
                font-family: $f-avenir-black;
            }
        }

        &.street {

            > .control {

                .address-first-description,
                .address-second-description {
                    margin-top: .5rem;
                    font-size: 1.2rem;
                    line-height: 1.4;
                    font-style: italic;
                }

                > .address-first,
                > .nested {

                    @include breakpoint(md) {
                        width: 48%;
                    }

                    @include breakpoint(lg) {
                        width: 46%;
                    }
                }

                @include breakpoint(md) {

                    > .address-first {
                        float: left;
                    }

                    > .nested {
                        float: right;

                        > .field {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        &.region {
            display: none;
            width: 0;
            height: 0;
            transform: scale(0);
            font-size: 0;
        }

        &.country {
            //max-width: 10rem;
            margin-top: 40px;
        }
    }

    // Message
    .message.info,
    .field.choice.set {
        display: block;
        margin-top: 2rem;

        @include breakpoint(md) {
            clear: both;
            margin-top: 0;

            &::before {
                content: '';
                display: block;
                height: 2rem;
            }
        }
    }
}
