// ==============================================
// CUSTOMER ACCOUNT
// ==============================================

//
//  Configuration
//  _____________________________________________



.marketplace-shop-view {


    .seller-shop {
        color: $c-grey-dark;

        & + .seller-shop {
            margin-top: 70px;
        }

        .block-content {
            margin: 0;
            &.banner {
                max-width: none;
                text-align: center;
                margin: 0;

                img {
                    width: 100%;
                }
            }

            &.top-details {
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 40px;
                justify-content: space-between;
                margin-top: 40px;

                @include breakpoint(lg) {
                    flex-direction: row;
                    align-items: flex-start;
                }
            }

            .top-details-left {
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 24px;
                flex: 1;

                @include breakpoint(md) {
                    flex-direction: row;
                    align-items: flex-start;
                }

                img {

                    width: 200px;
                    border: 1px solid $c-new-grey-5;
                }
            }

            .seller-details {
                flex: 1;
            }

            .page-title-wrapper {
                margin-bottom: 8px;
                color: $c-grey-dark;
                text-align: left;
            }

            .seller-description {
                margin-bottom: 16px;
                line-height: 1.45;
            }

            .seller-info {
                line-height: 1.5;

                ul li {
                    width: auto;
                    font-size: 1.2rem;
                    border: none;
                    padding-right: 0;
                    margin-right: 0;

                    & + li {
                        border-left: 1px solid $c-new-grey-5;
                        padding-left: 12px;
                        margin-left: 12px;
                    }
                }
            }
        }

        .mt-messages {
            .icon span {
                font-size: 2.9rem;
            }
        }

        .shop-review-rate {
            display: flex;
            align-items: center;
            gap: 5px;

            .rating-result {
                margin-bottom: 0;
            }
        }
    }

    .shop-navigation {
        overflow: auto;
        white-space: nowrap;
        border-top: 1px solid $c-new-grey-5;
        border-bottom: 1px solid $c-new-grey-5;
        position: sticky;
        top: 0;
        z-index: 1;

        @include custom-scrollbar();
        &::-webkit-scrollbar {
            width: auto;
            height: 4px;
        }

        .anchors {
            display: flex;
            align-items: center;
            gap: 20px;
            background-color: $c-white;
        }
        .anchor {
            &.current,
            &:hover {
                > a span::after {
                    transform: translateY(0);
                }
            }

            > a {
                display: block;
                padding: 0 20px;
            }

            span {
                display: block;
                padding-top: 20px;
                padding-bottom: 20px;
                position: relative;
                font-size: 1.6rem;
                overflow: hidden;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 4px;
                    background-color: $c-pink;
                    transition: transform .3s ease-in-out;
                    transform: translateY(4px);
                }
            }
        }
    }

    .shop-contents {
        margin-top: 60px;
    }

    .shop-content {

        & + .shop-content {
            padding-top: 20px;
            margin-top: 50px;
        }

        @include breakpoint(md) {
            display: flex;
        }

        .title {
            text-transform: initial;
            color: $c-black;

            @include breakpoint(md) {
                width: 300px;
                padding-right: 20px;
            }
        }

        .content {
            flex: 1;
            margin-top: 10px;

            h2,
            h3 {
                @extend .title-2;
                margin-bottom: 2rem;

                @include breakpoint(md) {
                    font-size: 2.2rem;
                    margin-bottom: 2.4rem;
                }
            }
            h4 {
                @extend .title-3;
            }
        }

        table.additional-attributes {
            width: 100%;
            font-size: 1.2rem;
            @include breakpoint(md) {
                font-size: 1.4rem;
            }


            .table-caption {
                @include visually-hidden;
            }

            tbody {
                tr {
                    th,
                    td {
                        border: 1px solid $c-new-grey-5;
                    }

                    th,
                    td:first-child {
                        background-color: $c-new-grey-3;
                        font-weight: bold;
                        padding: 24px 16px;
                        width: 130px;
                        @include breakpoint(lg) {
                            width: 280px;
                            font-size: 1.5rem;
                        }

                        & + td {
                            background: none;
                            font-weight: normal;
                            font-size: 1.4rem;
                        }
                    }

                    td {
                        padding: 24px 16px 24px 30px;
                    }
                }
            }
        }

        // Universe
        &#product_universe {
            .univers-list {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;

                @include breakpoint(md) {
                    gap: 24px;
                }

                > li {
                    flex: 1;

                    &,
                    > a {
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        align-items: flex-start;
                        width: 100%;
                    }

                    // Image animation if redirect link
                    > a {
                        &:hover {
                            .image-wrapper > img {
                                object-fit: cover;
                                width: 100%;
                                height: 100%;
                                transform: scale(1.15);
                            }
                            h4 {
                                color: $c-red;
                                &::after {
                                    right: 0;
                                }
                            }
                        }

                        .image-wrapper > img {
                            transform: scale(1);
                            transition: transform .3s ease-in-out;
                        }
                    }
                }

                .image-wrapper {
                    width: 200px;
                    height: 200px;
                    margin-bottom: 2rem;
                    border-radius: 100%;
                    overflow: hidden;
                    align-self: center;

                    @include breakpoint(md) {
                        margin-bottom: 2.4rem;
                    }

                    > img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }

                h4 {
                    font-family: $f-avenir-black;
                    font-size: 1.5rem;
                    text-transform: none;
                    text-align: left;
                    position: relative;

                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                        right: 100%;
                        transition: right .3s ease-in-out;
                        background-color: currentColor;
                    }
                }
            }
        }
    }

    .footer-top {
        display: none;
    }
}

// To remove after MVP
.hide-during-mvp {
    display: none !important;
}
