// ==============================================
// CHECKOUT - Shipping : Store Pickup
// ==============================================

.checkout-index-index {

    .checkout-storelist-wrapper {
        padding: 2rem 0;

        @include breakpoint(lg) {
            position: relative;
            padding: 0 2rem 2rem;
            margin-top: -4px;
            background-color: $c-white;
            border: 1px solid $c-grey-dark2;
            border-top: 1px solid $c-white;
        }

        // Searcbox
        .storelist-searchbox {
            display: flex;
            max-width: 32rem;
            margin: 0 auto;

            .sl-search-button {
                margin-left: 2rem;
            }
        }

        // Content
        .storelist-content {
            display: flex;
            flex-direction: column-reverse;
            margin: 2rem 0;

            @include breakpoint(lg) {
                flex-direction: row;
            }
        }

        // Store shipping information
        .storelist-stock-info {
            margin-bottom: 3rem;
            padding-left: 30px;
            padding-right: 30px;
            font-family: $f-museo-500;
        }

        .stock-info-label {
            font-size: 1.6rem;
            text-align: center;

            &:hover {
                cursor: pointer;
            }

            &::before {
                content: "\e933";
                @extend %icomoon;
                margin-right: 5px;
            }

            span {
                text-decoration: underline;
            }
        }

        .stock-info-more {
            margin-top: $gutter;
        }

        // List
        .storelist-content__wrapper {

            @include breakpoint(lg) {
                width: calc(100% - 42rem);
                height: 45rem;
                overflow-y: auto;
            }
        }

        .stores-list {

            @include breakpoint(only-lg) {
                height: 35rem;
                overflow-y: auto;
            }

            li.store {

                @include breakpoint(lg) {
                    padding-right: 2rem;
                }
            }

            .store-content {
                padding-bottom: 2rem;
                margin-bottom: 2rem;
                border-bottom: 1px solid $border-color;

                .store-name {
                    margin-bottom: .5rem;

                    label {
                        padding-left: 3rem;
                    }

                    strong {
                        font-family: $f-avenir-black;
                        font-size: 1.6rem;
                    }
                }

                address {
                    padding-left: 3rem;
                }
            }
        }

        // Map
        .storelist-map {
            width: 100%;
            height: 30rem;
            margin-bottom: 2rem;

            @include breakpoint(lg) {
                width: 42rem;
                height: 45rem;
                margin-bottom: 0;
            }

            // Store hours
            .sl-open-hours {
                font-size: 1.3rem;

                > strong {
                    display: block;
                    margin: .5rem 0;
                    font-family: $f-avenir-black;
                }

                .days {

                    li + li {
                        margin-top: .5rem;
                    }

                }

                .hours {
                    color: $c-red;
                }
            }
        }

        // Phone
        .storelist-phone {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            input {
                width: 100%;
            }

            .btn {
                margin: 2rem auto 0;

                @include breakpoint(sm) {
                    margin: 0;
                }
            }
        }

        // Error message
        .mage-error {
            margin-top: .6rem;
            font-size:  1.2rem;
        }

        // Actions
        .storelist-actions {
            position: static;
            margin-top: 2rem;
            text-align: center;
        }
    }

    // Withdrawal Label
    .withdrawal-label {
        font-family: $f-avenir-heavy;
        color: $c-pink;
    }
}
