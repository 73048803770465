// ==============================================
// PRODUCT ITEM
// ==============================================


.product-item--large .product-item-info .product-item-details{
    justify-content: flex-end;

    .price-box {
        margin-top: 0;
    }
}

.product-item-info {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: hidden;
    height: 100%;

    // Container of the image and action items displayed to hover
    .product-item-info-top {
        position: relative;
        overflow: hidden;
    }

    // Hover support
    @media (hover : hover), (-ms-high-contrast : none) {

        &:hover {

            .product-item-inner {
                transform: translateY(0);
                opacity: 1;
            }
        }

        .product-item-inner {
            position: absolute;
            z-index: 2;
            right: -1px;
            bottom: 0;
            left: -1px;
            padding-left: 2px;
            padding-right: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 9rem;
            background-color: $c-white;
            opacity: 0;
            transform: translateY(100%);
            transition: transform 0.4s, opacity 0.4s;

            @include breakpoint(only-lg) {
                display: none;
            }
        }
    }
    // Add to wishlist
    .actions-secondary {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        transform: translateY(-50%);
        width: 5rem;
        height: 5rem;
        padding: 0;
        margin-right: 0;
        background-color: transparent;
        border: none;
        font-size: 0;

        .action {

            span {
                @extend .visually-hidden;
            }

            &:hover {
                color: $c-pink;
            }

            &::before {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                font-size: 2.6rem;
            }
        }
    }

    //
    //  Visual
    //  _____________________________________________
    .product-item-photo {
        display: flex;
        justify-content: center;

        > .product-image-container {
            display: block;
            width: 100%;

            > .product-image-wrapper {
                display: block;
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 0;
                padding-top: 100%;

                & > img {
                    @include center-abs-xy();
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    //
    //  Details
    //  _____________________________________________
    .product-item-details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;
        padding-top: 2rem;
        background-color: $c-white;

        @include breakpoint(only-sm) {
            padding-top: 1rem;
        }
    }

    // Title
    .product-item-name {
        display: block;
        margin-bottom: 10px;
        font-size: 1.3rem;

        @include breakpoint(md) {
            font-size: 1.5rem;
        }
    }

    // Price
    .price-box {
        @extend %price-box--small;
        margin-bottom: 1rem;
        margin-top: auto;
        font-weight: bold;
    }

    // Reviews
    .product-item-reviews {
        margin-bottom: 10px;
        height: 20px;
    }
    .product-reviews-summary {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .rating-summary {
            @extend %rating-summary;
            margin-right: 0.5rem;

            @include breakpoint(only-sm) {

                > .rating-result::before,
                > .rating-result > span::before {
                    height: 1.2rem;
                    font-size: 1rem;
                }
            }
        }

        .reviews-actions {

            > a.action.view {

                &::before {
                    content: '(';
                }

                &::after {
                    content: ')';
                }

                @include breakpoint(only-sm) {
                    font-size: 1.2rem;
                }

                > span {
                    @extend .visually-hidden;
                }
            }
        }
    }

    // Actions
    .product-item-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .action.tocompare {
            display: none;
        }

        @include breakpoint(only-lg) {
            display: none;
            .mt-c-Btn {
                @include fs(14px, 20px);
                padding: 10px 20px;
            }
        }
    }

    // Fake product
    .product-item--fake & {
        display: block;
        padding-top: 140%;

        .product-item-photo {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            .product-image-wrapper {
                height: 100%;
                padding: 0;
            }
        }
        img.product-image-photo {
            position: absolute;
            object-fit: cover;
            object-position: center;
            max-width: inherit;
            width: 100%;
            height: 100%!important;
        }
    }

    .product-item--fake.product-item--large & {
        padding-top: 133%;

        @include breakpoint(only-lg) {
            padding-top: 140%;
        }
    }
}
