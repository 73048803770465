// ==============================================
// BUTTONS - Custom
// ==============================================

$button-color:              $c-pink;
$button--bordered-color:    $c-red;
$booleanBtnCursorWidth: 1.6rem;

// Reset global buttons
button {
    font-size: 1.4rem;
    padding: 0;
    border: 0 none;
    background-color: transparent;
    cursor: pointer;
    font-family: $f-avenir;
}

.btn {
    display: inline-block;
    transition: background-color .3s, color .3s;
    padding: 12px 30px;
    border: 2px solid $c-new-pink;
    border-radius: 30px;
    font-family: $f-avenir-medium;
    @include fs(15px, 22px);
    letter-spacing: 0.5px;
    background-color: $c-new-pink;
    color: $c-white;
    text-align: center;

    &:hover:not(:disabled),
    &:focus:not(:disabled),
    &:active:not(:disabled) {
        color: $c-new-pink-2;
        background-color: $c-white;
        border-color: $c-new-pink-2;
    }

    // Small
    &.btn--small {
        font-size: 1.2rem;
    }

    // Full width
    &.btn--full-width {
        width: 100%;
    }

    // Bordered
    &.btn--bordered {
        background-color: $c-transparent;
        border-color: $button--bordered-color;
        color: $button--bordered-color;

        &:hover,
        &:focus,
        &:active {
            background-color: $button--bordered-color;
            color: $c-white;
        }
    }

    // With picto
    &.btn--with-picto {
        position: relative;
        padding: 15px 25px 15px 55px;
    }

    // Btn grey
    &.btn--grey {
        background-color: $c-grey;
        border-color: $c-grey;

        &:hover,
        &:focus,
        &:active {
            color: $c-grey-dark !important;
        }
    }

    // Btn grey
    &.btn--grey-light {
        background-color: $c-grey-light;
        border-color: $c-grey-light;
        color: $c-grey;

        &:hover,
        &:focus,
        &:active {
            border-color: $c-grey;
            color: $c-grey !important;
        }
    }

    // Btn green
    &.btn--green {
        background-color: $c-green;
        border-color: $c-green;
        color: $c-white;

        &:hover,
        &:focus,
        &:active {
            background-color: $c-white;
            border-color: $c-green;
            color: $c-green !important;
        }
    }
}


// Boolean btn
.is-active .boolean-btn,
.boolean-btn.is-active {
    background-color: $c-new-pink;
    .boolean-btn-cursor {
        margin-left: calc(100% - #{$booleanBtnCursorWidth});
        transform: rotate(0);
        color: $c-black ;
        font-size: 1.2rem;

        &::before {
            content: "\e925";
        }
    }
}
.boolean-btn {
    background-color: $c-new-grey-5;
    border-radius: 30px;
    padding: 4px;
    width: 46px;
    transition: background-color .2s ease-in;

    &:hover {
        cursor: pointer;
    }

    > .boolean-btn-cursor {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        text-align: center;
        transition: margin .2s ease-in;
        transform: rotate(140deg);
        background-color: $c-white;
        color: $c-red;
        font-size: 1rem;
        @include circle($booleanBtnCursorWidth);

        &::before {
            @extend %icomoon;
            content: "\e94e";
        }
    }
}
