.mt-u-a11yhidden {
    @include hide-accessibly();
}

#nq-u-display-desk,
#nq-u-display-tab,
#nq-u-display-mob {
    display: none;
}

@include breakpoint(xl) {
    #nq-u-display-desk {
        display: block;
    }
}

@include breakpoint(only-lg) {
    #nq-u-display-tab {
        display: block;
    }
}

@include breakpoint(only-xl) {
    #nq-u-display-mob {
        display: block;
    }
}
