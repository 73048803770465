// ==============================================
// CS BLOCK : 1-3 ; 1-3/2-3 ; 2-3/1-3
// ==============================================

$csb-grid-gutter: 10px;
$csb-1-3-item-max-width: 38rem;

.csb-1-3-2-3,
.csb-1-2-3 {
    padding: 2rem 0;

    @include breakpoint(md) {
        padding: 3rem 0;
    }

    @include breakpoint(lg) {
        padding: 4rem 0;

        .catalog-category-view & {
            margin-left: -1rem;
            margin-right: -1rem;
        }
    }

    .catalog-category-view & {

        > .container {
            padding-left: 0;
            padding-right: 0;

            &.container--1-3 {
                max-width: 100%;
            }
        }
    }
}

.container--1-3 {
    max-width: $csb-1-3-item-max-width * 3;
}

//
// Grid
// ----------------------------------------------

.csb-grid {

    @include breakpoint(md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -#{$csb-grid-gutter};
    }
}

//
// Item
// ----------------------------------------------
.csb-item {
    margin: 0 auto;
    text-align: center;

    + .csb-item {
        margin-top: 4rem;
    }

    @include breakpoint(md) {
        padding: 0 $csb-grid-gutter;
        margin: 0;

        + .csb-item {
            margin-top: 0;
        }
    }

    // 1-3
    &.csb-item--1-3 {
        max-width: $csb-1-3-item-max-width;

        @include breakpoint(md) {
            width: 33.3333%;

            &.pi__item {
                margin-bottom: 4rem;
            }
        }
    }

    // 2-3
    &.csb-item--2-3 {

        @include breakpoint(md) {
            width: 66.6666%;
        }
    }
}

.csb-item__link {
    display: block;
    transition: color .4s;

    img {
        width: 100%;
        transition: transform .6s;
    }

    &:hover,
    &:focus,
    &:active {
        color: $c-pink;

        img {
            transform: scale(1.05);
        }
    }
}

.csb-item__title {
    margin-top: 3rem;

    &.pi__title {
        margin-top: 0;
        margin-bottom: 3rem;
    }
}
