// ==============================================
// MIXINS - Font face
// ==============================================

@mixin font-face($font_name, $file_name, $font-weight) {
    @font-face {
        font-family: $font_name;
        font-style: normal;
        font-weight: $font-weight;
        src: local($font_name),
            url('../fonts/#{$file_name}.woff2') format('woff2'),
            url('../fonts/#{$file_name}.woff') format('woff');
        font-display: swap;
        unicode-range: U+000-5FF; /* Latin glyphs */
    }
}

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@function calculateRem($size) {
    $remSize: $size / 10px;
    @return $remSize * 1rem;
}

@mixin fs($size, $lh: false) {

    @if $lh {
        font-size: $size;
        font-size: calculateRem($size);
        line-height: #{strip-unit($lh/$size) * 1};
    } @else {
        font-size: $size;
        font-size: calculateRem($size);
    }
}

@mixin fs0() {
    @include fs(0px);
}
