// ==============================================
// FORMS - Inputs
// ==============================================

//
//  Configuration
//  _____________________________________________

$input-border-color:            $border-color;
$input-background:              $c-white;

.input-text:not(textarea),
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="date"] {

    // Reset
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    // Custom
    padding: 15px 20px;
    font-size: 1.4rem;
    line-height: 1;
    color: $c-base;
    background-color: $input-background;
    border: 1px solid $input-border-color;

    @include breakpoint(only-sm) {
        padding: 10px 20px;
    }

    // Full border
    &.input--placeholder {
        padding: 6px 0;
        border: none;
        border-bottom: 1px solid $input-border-color;

        @include breakpoint(md) {
            padding: 16px 0;
        }
    }

    // Chrome/Opera/Safari
    &::-webkit-input-placeholder {
        color: $c-base;
    }

    // Firefox 19+
    &::-moz-placeholder {
        color: $c-base;
        opacity: 1;
    }

    // IE 10+
    &:-ms-input-placeholder {
        color: $c-base;
    }

    // Firefox 18-
    &:-moz-placeholder {
        color: $c-base;
        opacity: 1;
    }
}

input[type="number"] {
    width: 5rem;
    height: 3rem;
    padding: 6px 5px;
    font-size: 1.2rem;
    text-align: center;

    @include only_ie {
        padding: 0 5px;
        line-height: 3rem;
    }

    @include breakpoint(md) {
        width: 6rem;
        font-size: 1.4rem;
    }
}

.input-text:not(.qty),
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
    display: block;
    width: 100%;

    &.mage-error {
        border-color: $c-error;

        &.input--placeholder {
            border-bottom: 1px solid $c-error !important;
        }
    }
}

// Qty
.field.qty {
    .control.mage-error-ui {
        margin-bottom: 1rem;

        div.mage-error {
            @include breakpoint(only-sm){
                top: calc(100% - 0.5rem);
                width: calc(100vw - 6rem);
                margin-top: 0;
            }
        }
    }
}

input.qty {
    max-width: 8rem;
    height: 3.5rem;
    padding: 0 3px;
    border: 1px solid $border-color;
    text-align: center;

    &.mage-error {
        margin-bottom: 0;

        + div.mage-error {
            position: absolute;
            top: 100%;
            margin-top: 0.5rem;
            margin-left: 0;
        }
    }

    @include only_ie {
        padding: 0 12px;
        line-height: 4rem;
    }
}

// Disable status
.input-text,
input,
button {
    &:disabled {
        opacity: 0.4 !important;
        &:hover {
            cursor: auto;
        }
    }
}
