// ==============================================
// FORMS - Checkbox
// ==============================================

//
//  Configuration
//  _____________________________________________

$amasty-checkbox-width:                20px;
$amasty-checkbox-height:               19px;
$amasty-checkbox-border-weight:        1px;
$amasty-checkbox-border-color:         #999;

.am-ranges {

    input[type="checkbox"] {
        @extend .visually-hidden;

        & + a {
            position: relative;
            align-items: center;
            font-size: 1.2rem;
            line-height: $amasty-checkbox-height;
            padding-top: 1px;
            padding-left: calc(#{$amasty-checkbox-width} + 10px);
            min-height: $amasty-checkbox-height;
            cursor: pointer;

            &::before,
            &::after {
                position: absolute;
                content: "";
                cursor: pointer;
                transition: all .2s;
            }

            // Fake checkbox
            &::before {
                left: 0;
                width: $amasty-checkbox-width;
                height: $amasty-checkbox-height;
                border: $amasty-checkbox-border-weight solid $amasty-checkbox-border-color;
                background-color: $c-white;
            }

            // Check icon
            &::after {
                @extend %icomoon;
                content:"\e910";
                left: calc((#{$amasty-checkbox-width} + #{$amasty-checkbox-border-weight} * 2) / 2);
                opacity: 0;
                font-size: 1.5rem;
                color: $c-black;
                transform: translateX(-50%);
            }
        }

        &:checked {
            & + a {

                // Check icon
                &::after {
                    opacity: 1;
                    transition: opacity .2s;
                }
            }
        }
    }
}

//
// For Itonomy module tags
//
.flowbox {

    input[type="radio"] {
        @extend .visually-hidden;
        & + .flowbox-tag {
            &::before,
            &::after {
                display: none;
            }
        }
    }
}
@include breakpoint(only-lg) {
    .flowbox {
        justify-content: normal;

        input[type="radio"] {
            & + .flowbox-tag {
                position: relative;
                width:50%;
                align-items: center;
                font-size: 1.2rem;
                line-height: $amasty-checkbox-height;
                padding-top: 1px;
                padding-left: calc(#{$amasty-checkbox-width} + 10px);
                min-height: $amasty-checkbox-height;
                cursor: pointer;

                &::before,
                &::after {
                    display: block;
                    border-radius:unset;
                    position: absolute;
                    content: "";
                    cursor: pointer;
                    transition: all .2s;
                }

                // Fake checkbox
                &::before {
                    left: 0;
                    width: $amasty-checkbox-width;
                    height: $amasty-checkbox-height;
                    border: $amasty-checkbox-border-weight solid $amasty-checkbox-border-color;
                    background-color: $c-white;
                }

                // Check icon
                &::after {
                    @include icomoon;
                    content: "\e910";
                    left: calc((#{$amasty-checkbox-width} + #{$amasty-checkbox-border-weight} * 2) / 2);
                    top: calc((#{$amasty-checkbox-height} + 1px * 2) / 2);
                    opacity: 0;
                    font-size: 1.5rem;
                    color: $c-black;
                    background-color: transparent;
                }
            }

            &:checked {
                & + .flowbox-tag {

                    // Check icon
                    &::after {
                        opacity: 1;
                        transition: opacity .2s;
                    }
                }
            }
        }
    }
}
