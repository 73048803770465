/* ----------------------------------------------------------
  Print Page
---------------------------------------------------------- */

/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.page-print .logo {
    display: block;
    float: none;
    text-align: left;
}
@media print {
    * {
        -webkit-filter: none !important;
        background: transparent !important;
        color: #000000 !important;
        filter: none !important;
        text-shadow: none !important;
    }
    a,
    a:visited {
        text-decoration: underline !important;
    }
    pre,
    blockquote {
        border: 1px solid #999999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    .table-wrapper table {
        width: 100%;
        table-layout: fixed;
    }
    .table-wrapper table td {
        width: auto;
    }
    .table-wrapper table > tfoot > tr:first-child {
        border-top: 1px solid #999999;
    }
    .box,
    tr,
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    @page {
        margin: 1cm;
    }
    .block-content,
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    .block-content {
        page-break-before: avoid;
    }
    .block-title,
    h2,
    h3 {
        page-break-after: avoid;
    }
    .nav-toggle {
        display: none !important;
    }
    .sidebar,
    .nav-sections,
    .header.content > *[class],
    .panel.wrapper > *[class],
    .footer.content > *[class] {
        display: none;
    }
    .logo,
    .footer .copyright {
        display: block !important;
        margin: 10px 0;
    }
    .order-details-items .order-items .order-gift-message:not(.expanded-content) {
        height: auto;
        visibility: visible;
    }
    .column.main {
        float: none !important;
        width: 100% !important;
    }
    .breadcrumbs {
        margin: 0 auto;
        text-align: right;
    }
    .footer.content {
        padding: 0;
    }
    .hidden-print {
        display: none !important;
    }
}

.page-print {
    padding: 20px 10px;

    div.page-wrapper {
        position: relative;
        margin: 0 auto;
        max-width: 800px;
        padding: 50px;
        border: 2px solid #CCC;
    }

    .page-main {
        padding: 0 !important;

        > .logo {
            display: block;
            width: 150px;
            margin: 0 auto 70px auto;
        }

        > .columns {
            padding-bottom: 0;
        }
    }

    // Login
    .block-login {
        position: absolute;
        top: 10px;
        right: 0;
        text-transform: capitalize;

        .customername {
            margin-bottom: 0;
            font-family: $f-halohandletter;
            font-size: 3.5rem;
        }

        .icon {
            margin-right: .5rem;
            font-size: 3.5rem;

            &::before {
                content: "\e926";
            }
        }
    }

    h1 {
        margin: 0 0 20px;
        @extend .title-1;
        font-size: 3.5rem;
        color: $c-base !important;
        text-transform: inherit !important;
    }

    .products-grid.wishlist {
        margin: 2rem 0 0;

        > .product-items {
            padding: 0;
        }

        .product-item {
            display: block;
            width: 100%;
            padding: 20px;
            border-bottom: 1px solid $border-color;
            font-size: 1.4rem;

            &:first-child {
                padding: 0 20px 20px;
            }
        }

        .product-item-info {
            display: flex;
            width: 100%;
            max-width: 100%;
        }

        .product-item-photo {
            width: 150px;
            margin-right: 30px;
            margin-bottom: 0;
        }

        .product-item-name {
            padding-top: 30px;
            margin-bottom: 0;

            > a {
                display: block;
                margin-bottom: 10px;
                font-family: $f-avenir-black;
                text-decoration: none;
            }

            > span {
                color: #7b7b7b;
            }
        }

        .price-box {
            padding-top: 30px;
            margin-left: auto;
            font-family: $f-museoSlab-700;
            font-size: 1.7rem;
        }
    }

    .modals-wrapper {
        display: none;
    }

    // Order
    &.sales-order-print {

        div.page-wrapper {
            padding: 50px 20px;
        }

        .page-title-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-size: 1.8rem;

            .page-title {
                font-size: 2rem;
            }

            .order-status {
                margin-left: .5rem;

                &::before {
                    content:'-';
                    margin-right: .5rem;
                }
            }

            .order-date {
                width: 100%;
                margin-top: 1rem;
                font-family: $f-avenir;
                font-size: 1.4rem;
                text-align: left;

                > .label {
                    font-family: $f-avenir-black;
                }
            }
        }

        // Order
        .order-details-items {
            font-size: 12px;

            .order-title {
                padding-bottom: 1rem;
                margin-bottom: 2rem;
                border-bottom: 1px solid $border-color;

                > strong {
                    @extend .title-2;
                    text-transform: inherit !important;
                }

                > span.separator {
                    display: none;
                }
            }

            // Table
            .table-wrapper.order-items {

                th,
                td {
                    padding: 1rem 2rem;

                    @include breakpoint(only-md) {
                        padding: 1rem 2rem;
                    }
                }

                @include breakpoint(only-md) {
                    table {
                        thead {
                            tr {
                                th {
                                    display: table-cell;
                                }
                            }
                        }

                        tbody {
                            display: table-row-group;
                        }

                        tr {
                            display: table-row;
                        }

                        tbody {
                            tr {
                                td {
                                    display: table-cell;
                                    border-bottom: none;
                                    padding: 1rem 2rem;

                                    // Show data-th
                                    &:not(.actions) {
                                        &::before {
                                            display: none;
                                        }
                                    }

                                    // Add tbody delimiter
                                    & + td:last-child {
                                        border-bottom: 0;
                                        padding-bottom: 10px;
                                    }
                                }
                            }
                        }
                    }
                }

                tbody {

                    > tr {

                        > td {
                            padding-top: 1.5rem;
                            padding-bottom: 1.5rem;

                            &.price,
                            &.subtotal {
                                font-family: $f-museo-500;
                            }
                        }
                    }
                }

                tfoot {

                    > tr {
                        border: 1px solid $border-color;
                        border-top: 0;

                        td {
                            font-family: $f-museo-500;
                            text-align: right;

                            strong {
                                color: $c-red;
                            }
                        }
                    }
                }
            }

            // Toolbar
            .actions-toolbar {
                display: none;
            }
        }

        .block.block-order-details-view {
            margin-bottom: 0 !important;
            font-size: 12px;

            > .block-title {
                max-width: 100%;
                padding-bottom: 1rem;
                margin-bottom: 2rem;
                background: none;
                border-bottom: 1px solid $border-color;
            }

            .block-content {

                @include breakpoint(only-md) {
                    flex-direction: row !important;
                }

                > .box {

                    @include breakpoint(only-md) {
                        width: 48% !important;

                        + .box {
                            margin-top: 0 !important;
                        }
                    }

                    &.box-order-shipping-address,
                    &.box-order-shipping-method {
                        margin-bottom: 2rem;
                    }
                }
            }
        }
    }
}
