// ==============================================
// FORMS
// ==============================================

form {
    legend {
        @extend .title-2;
    }

    label,
    .label {
        display: block;
        font-size: 1.4rem;
        margin-bottom: 10px;
        text-align: left;
    }

    .required {
        em {
            margin-left: 1px;
            font-style: normal;
        }
    }

    .fieldset {
        border: 0 none;

        & + .fieldset {
            margin-top: 15px;

            @include breakpoint(sm) {
                margin-top: 20px;
            }

            @include breakpoint(md) {
                margin-top: 30px;
            }
        }

        > .field + .field,
        > .fields + .fields,
        > .field + .fields,
        > .fields + .field,
        > .field + .field-recaptcha,
        > .fields + .field-recaptcha {
            margin-top: 15px;

            @include breakpoint(sm) {
                margin-top: 20px;
            }
        }

        // Note
        .note + .field {
            margin-top: 15px;
        }
    }

    .field {
        position: relative;

        // Password (with strength meter)
        &.password {

            .control {
                position: relative;
                display: flex;
                flex-direction: column;

                .mage-error {
                    order: 2;
                }

                .input-text {
                    order: 0;
                }
            }

            .check-password {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 5rem;
                height: 100%;
                max-height: 5rem;
                font-size: 2.6rem;

                &.is-visible {
                    color: $c-success;
                }

                .icon-eye {
                    pointer-events: none;
                }
            }
        }

        // Add-on
        &.with-addon {
            .addon {
                display: flex;
                align-items: center;

                input {
                    flex-grow: 1;
                    margin-right: 10px;
                }
            }
        }

        // Captcha
        &.captcha {
            .captcha-image {
                margin-top: 10px;
            }
        }

        // Required
        &.required,
        &._required {
            & > .label:first-child {
                &::after {
                    margin-left: 2px;
                    content:"*";
                    color: $c-error;
                }
            }
        }

        // Qty
        &.qty {

            > .control {
                display: flex;
                align-items: center;

                .soon-qty-updater {
                    width: 3.5rem;
                    height: 3.5rem;
                    line-height: 3.5rem;
                    text-align: center;
                    background-color: $c-grey-light;
                    cursor: pointer;
                }
            }
        }
    }

    // Horizontal multiple fields
    .fields,
    .select-box {
        @include breakpoint(md) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-left: -10px;
            margin-right: -10px;
        }
    }

    .fields .field,
    .select-box > select {
        @include breakpoint(md) {
            flex: 1;
            margin: 0 10px;
        }
    }

    .fields + .field,
    .select-box > select + select {
        @include breakpoint(only-md) {
            margin-top: 20px;
        }
    }

    // Address
    .street {
        .label {
            font-weight: normal;
            margin: 0 0 10px 0;
        }

        .field {
            margin: 0 0 10px 0;

            &.additional {
                margin: 15px 0 0 0;
            }

            // Hide duplicate required element
            .label::after {
                display: none;
            }
        }

        @include breakpoint(sm){
            .field {
                &.additional {
                    margin: 25px 0 0 0;
                }
            }
        }
    }

    // Errors
    .control {

        .field-error,
        .mage-error {
            color: $c-error;
        }

        div.field-error,
        div.mage-error {
            margin-top: .6rem;
            margin-left: 1rem;
            font-size:  1.2rem;
        }
    }

    // Actions
    .actions-toolbar {
        margin-top: 2rem;

        .secondary {
            margin-top: 17px;

            .action.back {
                display: none;
            }
        }

        .remind {
            border-bottom: 1px solid currentColor;
            transition: color .4s, border-color .4s;

            &:hover,
            &:active {
                color: $c-pink;
            }
        }
    }

    // Forgot password
    &.form.password.forget {

        label.label {
            @include visually-hidden;
        }

        .actions-toolbar {
            display: flex;
            align-items: center;
            margin-top: 2rem;

            .primary {
                margin-left: auto;
            }
        }
    }
}

.message + form {
    margin-top: 20px;
}
