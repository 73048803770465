//
//  Configuration
//  _____________________________________________

$c-grey-sidebar:                    $c-grey-3;

/** SEARCH BOX */

.ais-search-box--powered-by {
    font-size: .8em;
    text-align: right;
    margin-top: 2px;
}

input::-ms-clear {
    display: none;
}

/** PAGINATION */

#instant-search-pagination-container {
    width: 100%;
    text-align: center;
}
.ais-pagination {
    background-color: transparent;
    border: none;
    box-shadow: none;
    margin: 20px auto;
}
.ais-pagination li {
    display: inline-block;
}
.ais-pagination--item a {
    padding: 5px 10px;
    margin: 0 5px;
    line-height: 25px;
    background-color: white;
    color: #727272;
}

.ais-pagination li.ais-pagination--item__disabled {
    display: none;
}

.ais-pagination--item__active a {
    font-weight: bolder;
    color: #606060;
    text-decoration: underline;
}

.ais-pagination--item.ais-pagination--item__next a,
.ais-pagination--item.ais-pagination--item__previous a {
    font-weight: bold;
    color: #606060;
}

/** REFINEMENT LIST */

.ais-menu--link,
.ais-hierarchical-menu--link,
.ais-price-ranges--link,
.ais-refinement-list--label {
    padding: 4px 10px;
    cursor: pointer;
    color: #636363;
    text-decoration: none;
    display: block;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
}

.ais-hierarchical-menu--item__active > div > .ais-hierearchical-link-wrapper > .ais-hierarchical-menu--link,
.ais-refinement-list--item__active > div > .ais-hierarchical-list--link,
.ais-refinement-list--item__active > div > .ais-refinement-list--label,
.ais-price-ranges--item__active > .ais-price-ranges--link{
    font-weight: bold;
    box-shadow: inset 3px 0 0 #3399cc;
}

.ais-hierearchical-link-wrapper, .ais-refinement-list--item {
    padding: 7px 0;
}

.ais-refinement-list--label, .ais-hierarchical-menu--link {
    line-height: 1.0em;
    padding: 0 10px;
}

.ais-refinement-list--label img {
    display: inline;
}

.ais-hierarchical-menu--item__active .ais-hierearchical-link-wrapper .cross-circle,
.ais-refinement-list--item__active .cross-circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    background:  url("data:image/svg+xml;utf8,<svg width=\'34\' height=\'34\' viewBox=\'0 0 34 34\' xmlns=\'http://www.w3.org/2000/svg\'><title>testvg</title><g fill=\'%23000\' fill-rule=\'evenodd\'><path d=\'M17.163 0C7.95 0 .41 7.578.353 16.893c-.03 4.542 1.693 8.82 4.847 12.053 3.156 3.23 7.367 5.026 11.857 5.054h.11c9.21 0 16.75-7.578 16.81-16.893C34.035 7.735 26.54.06 17.163 0zm.015 30.842v1.08l-.09-1.08c-3.656-.023-7.085-1.485-9.654-4.115-2.57-2.63-3.97-6.116-3.948-9.814C3.533 9.33 9.673 3.158 17.262 3.158c7.548.048 13.65 6.297 13.605 13.93-.05 7.585-6.19 13.754-13.69 13.754z\'/><path d=\'M22.362 10.23l-5.186 5.245-5.186-5.244c-.417-.42-1.092-.42-1.51 0-.416.422-.416 1.105 0 1.526L15.668 17l-5.186 5.244c-.416.42-.416 1.104 0 1.525.21.21.483.316.755.316.273 0 .546-.106.755-.317l5.186-5.245 5.186 5.244c.208.21.482.316.754.316.273 0 .546-.106.755-.317.417-.422.417-1.105 0-1.526L18.685 17l5.187-5.244c.417-.42.417-1.104 0-1.525-.416-.42-1.09-.42-1.508 0z\'/></g></svg>")no-repeat center center / contain;
    opacity: 0;
}

.ais-hierarchical-menu--item__active .ais-hierearchical-link-wrapper:hover .cross-circle,
.ais-refinement-list--item__active:hover .cross-circle {
    text-decoration: underline;
    vertical-align: bottom;
    margin-left: 6px;
    opacity: 1;
}

.ais-refinement-list--item:hover {
    text-decoration: underline;
}

.ais-price-ranges--input {
    width: 70px !important;
}

.ais-price-ranges--label {
    margin: 5px;
}

.ais-refinement-list--checkbox {
    margin-right: 10px;
}

.ais-current-refined-values--link {
    cursor: pointer;
    color: #636363;
    border-radius: 2px;
    border: solid 1px #DDDDDD;
    background-color: #f4f4f4;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    padding: 0 8px 0 0;
    height: 28px;
    overflow: hidden;
}

.ais-current-refined-values--clear-all {
    position: absolute;
    right: 6px;
    top: 3px;
    color: #666666;
    font-weight: 500;
}

a.ais-current-refined-values--link:hover {
    text-decoration: none;
}

.ais-current-refined-values--item {
    margin-top: 8px;
}

.ais-current-refined-values--item:after {
    clear: both;
    content: "";
    display: block;
}

.ais-current-refined-values--item .current-refinement-label {
    font-weight: bold;
}

.ais-current-refined-values--item .cross-wrapper {
    font-weight: bold;
    background-color: #DDDDDD;
    border-radius: 1px 0 0 1px;
    float: left;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 26px;
}

.ais-current-refined-values--link:hover .cross-wrapper {
    text-decoration: none;
}

.ais-current-refined-values--link .cross-wrapper img {
    display: inline;
    margin-top: 6px;
}


.ais-current-refined-values--item .current-refinement-wrapper {
    float: left;
    width: calc(100% - 26px);
    height: 26px;
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 26px;
}

.ais-current-refined-values--link:hover .current-refinement-wrapper {
    text-decoration: line-through;
}

.ais-menu--link:hover,
.ais-price-ranges--link:hover,
.ais-hierarchical-menu--link:hover {
    color: #636363;
}

.ais-menu--count, .ais-hierarchical-menu--count, .ais-refinement-list--count {
    float: right;
    color: #666;
}

#algolia_instant_selector .ais-refinement-list--list .sbx-sffv__wrapper {
    width: 94%;
    margin: 0 auto 5px;
}

#algolia_instant_selector .ais-refinement-list--list .sbx-sffv__submit {
    display: none;
}

#algolia_instant_selector .ais-refinement-list--list .sbx-sffv__reset {
    width: 10px;
    margin-top: -30px;
    margin-right: 7px;
    background: none;
    border: none;
    box-shadow: none;
}

#algolia_instant_selector .ais-refinement-list--list .sbx-sffv__reset:hover {
    background: none;
}

#algolia_instant_selector .ais-refinement-list--list .sbx-sffv__reset svg {
    width: 15px;
    height: 15px;
    fill: #636363;
}

#algolia_instant_selector .ais-refinement-list--list .sffv-no-results {
    padding-left: 3%;
}

/** RANGE SLIDER */

.ais-range-slider .rheostat {
    overflow: visible;
    margin-top: 3em;
    margin-bottom: 4em;
    width: 88%;
    margin-left: 6%;
}

.ais-range-slider .rheostat-background {
    background-color: #F3F4F7;
    border: 1px solid #DDD;
    border-right-width: 2px;
    border-left-width: 2px;
    position: relative;
}

.ais-range-slider .rheostat-horizontal .rheostat-background {
    height: 6px;
    top: 0;
    width: 100%;
}

.ais-range-slider .rheostat-horizontal .rheostat-progress {
    background-color: #b5b6b9;
    position: absolute;
    height: 4px;
    top: 1px;
}

.ais-range-slider .rheostat-horizontal .rheostat-handle {
    margin-left: -12px;
    top: -7px;
}

.ais-range-slider--handle {
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    border: 1px solid #c8c8c8;
    border-radius: 50%;
    cursor: pointer;
}

.ais-range-slider--handle-lower {
    margin-left: -9px !important;
}

.ais-range-slider--tooltip {
    position: absolute;
    background: #FFFFFF;
    top: -2em;
    left: -50%;
    min-width: 20px;
    text-align: center;
    font-size: .8em;
}

.ais-range-slider--value {
    width: 40px;
    position: absolute;
    text-align: center;
    margin-left: -20px;
    padding-top: 15px;
    font-size: .8em;
}

.ais-range-slider--marker {
    position: absolute;
    margin-left: -1px;
    width: 1px;
    height: 5px;
}

.ais-range-slider--marker-large {
    background: #DDD;
    width: 2px;
    margin-left: -2px;
    height: 12px;
}

.ais-range-slider--marker-large:first-child {
    margin-left: 0;
}

/** Auto-completion menu */

#algolia-autocomplete-container .aa-dropdown-menu .before_special {
    color: #aaaaaa;
    text-decoration: line-through;
    font-size: 12px;
}

#algolia-autocomplete-container .aa-dropdown-menu .tier_price {
    color: #666666;
    font-size: 10px;
}

#algolia-autocomplete-container .aa-dropdown-menu .tier_price .tier_value {
    color: #54A5CD;
    font-size: 12px;
}

#algolia-autocomplete-container .aa-dropdown-menu .info-without-thumb .category-tag {
    color: #3284b6;
}

#algolia-autocomplete-container .aa-dropdown-menu .info-without-thumb .details {
    font-size: 10px;
    color: #666;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#algolia-autocomplete-container .aa-dropdown-menu .info-without-thumb .details em {
    color: #222222;
}

#algolia-autocomplete-tt.algolia-autocomplete {
    width: 100%;
    display: inline-block !important;
}

#algolia-autocomplete-container .aa-dropdown-menu {
    position: absolute;
    margin-top: -1px;
    right: 0;
    width: 100%;
    z-index: 1000 !important;
    border: 1px solid #BBB;
    border-top: 3px solid #8EB4D0;
    border-radius: 1px;
    background: white;
}

@media (min-width: 992px) {
    #algolia-autocomplete-container .aa-dropdown-menu {
        width: 71.1%;
        min-width: 800px;
    }
}

#algolia-autocomplete-container .aa-dropdown-menu .col-2 {
    position: relative;
}

#algolia-autocomplete-container .aa-dropdown-menu .col-2 .col-left {
    width: 67%;
}

#algolia-autocomplete-container .aa-dropdown-menu .col-2 .col-right {
    width: 33%;
}

#algolia-autocomplete-container .aa-dropdown-menu .aa-no-results-products {
    padding: 40px 40px;
    min-height: 250px;
}

#algolia-autocomplete-container .aa-dropdown-menu .aa-no-results-products .title {
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 16px;
}

#algolia-autocomplete-container .aa-dropdown-menu .aa-no-results-products .suggestions {
    margin-bottom: 30px;
}

#algolia-autocomplete-container .aa-dropdown-menu .aa-no-results-products .see-all a {
    color: #636363;
    font-weight: bold;
}

#algolia-autocomplete-container .aa-dropdown-menu .aa-no-results {
    padding: 10px;
    font-style: italic;
}

#algolia-autocomplete-container .aa-dropdown-menu .category {
    // padding: 4px;
    color: rgb(166, 166, 166);
    text-align: left;
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: bold;
}

#algolia-autocomplete-container .aa-dropdown-menu .category-suggestions {
    padding: 4px;
    color: #54A5CD;
    text-align: left;
    font-size: 0.7em;
    text-transform: uppercase;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit {
    display: block;
    position: relative;
    padding: 5px 10px;
    color: #000;
    text-align: left;
    text-decoration: none;
}

#algolia-autocomplete-container .aa-dropdown-menu .other-sections .aa-dataset-suggestions .algoliasearch-autocomplete-hit {
    padding-left: 30px;
}

#algolia-autocomplete-container .aa-suggestions svg.algolia-glass-suggestion.magnifying-glass {
    position: absolute;
    right: auto;
    left: 5px;
    top: 7px;
    fill: #A6A6A6;
    stroke: #A6A6A6;
}

#algolia-autocomplete-container .aa-dropdown-menu.aa-without-products .col9 {
    background-color: #F9F9F9;
}

#algolia-autocomplete-container .aa-dropdown-menu.aa-without-products #autocomplete-products-footer {
    display: none;
}

#algolia-autocomplete-container .aa-dropdown-menu .aa-dataset-products .aa-suggestion {
    display: inline-block;
    width: 100%;
}

@media (min-width: 768px) {
    #algolia-autocomplete-container .aa-dropdown-menu .aa-dataset-products .aa-suggestion {
        display: inline-block;
        width: 50%;
    }
}


#algolia-autocomplete-container .aa-dropdown-menu .aa-dataset-products .algoliasearch-autocomplete-hit {
    padding: 15px 10px;
}

.autocomplete-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}

.autocomplete-wrapper:after {
    clear: both;
    content: '';
}

#algolia-autocomplete-container .col9 {
    float: right;
    box-sizing: border-box;
}

#algolia-autocomplete-container .col3 {
    float: right;
    box-sizing: border-box;
}

#algolia-autocomplete-container.reverse .col3 {
    float: left;
    min-width: 100%;
}

#algolia-autocomplete-container.reverse .col9 {
    float: left;
    min-width: 100%;
    display: flex;
    height: 100%;
    flex: 1;
}

@media (min-width: 768px) {
    #algolia-autocomplete-container .col9 {
        border-left: solid 1px #eeeeee;
        width: 70%;
        right: 0;
        height: 100%;
    }

    #algolia-autocomplete-container .col3 {
        float: left;
        width: 30%;
    }

    #algolia-autocomplete-container.reverse .col3 {
        float: left;
        width: 30%;
    }

    #algolia-autocomplete-container.reverse .col9 {
        border-right: solid 1px #eeeeee;
        float: left;
        width: 70%;
    }
}

#algolia-autocomplete-container .other-sections {
    margin: 20px 10px 70px;
}


.aa-dataset-products .aa-suggestions {
    margin: 10px auto 10px auto;
}

.aa-dataset-suggestions {
    display: none;
}

@media (min-width: 768px) {
    .aa-dataset-suggestions {
        display: block;
    }
}


@media (min-width: 768px) {
    .aa-dataset-products .aa-suggestions {
        margin: 10px auto 50px auto;
    }
}


.aa-dataset-products .aa-suggestions:after {
    content:'';
    display:block;
    clear: both;
}

#algolia-autocomplete-container .aa-dropdown-menu .other-sections .algoliasearch-autocomplete-hit {
    padding-left: 10px;
}

#algolia-autocomplete-container .aa-dropdown-menu .other-sections .aa-suggestions {
    margin-bottom: 20px;
}

#algolia-autocomplete-container .aa-dropdown-menu .aa-cursor .algoliasearch-autocomplete-hit {
    background-color: #f2f2f2;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit em {
    font-weight: bold;
    font-style: normal;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-price {
    font-size: 1.1em;
    color: #54A5CD;
    height: 22px;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit .thumb {
    float: left;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit .thumb img {
    width: 50px;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit .info {
    margin-left: 60px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

#autocomplete-products-footer {
    background-color: #F9F9F9;
    text-align: center;
    display: none;
    position: absolute;
    width:70%;
    padding: 10px 0;
    bottom: 0;
    left: 30%;
}

#algolia-autocomplete-container.reverse #autocomplete-products-footer {
    right: auto;
    left: 0;
}

@media (min-width: 768px) {
    #autocomplete-products-footer {
        display: block;
    }
}

#autocomplete-products-footer span {
    color: #15769c;
    font-weight: 600;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit .info .algoliasearch-autocomplete-category {
    font-size: 0.8em;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit .info .algoliasearch-autocomplete-category em {
    color: #222;
}

#algolia-autocomplete-container .aa-dropdown-menu .footer_algolia {
    position: absolute;
    width: 100%;
    padding: 10px;
    text-align: center;
    bottom: 0;
    left: 4px;
    font-size: 13px;
}

#algolia-autocomplete-container.reverse .aa-dropdown-menu .footer_algolia {
    left: auto;
    right: 4px;
}

@media (min-width: 768px) {
    #algolia-autocomplete-container .aa-dropdown-menu .footer_algolia {
        width: 30%;
    }
}

#algolia-autocomplete-container .aa-dropdown-menu .footer_algolia span {
    color: #B8B8B8;
    font-size: 10px;
}

#algolia-autocomplete-container .aa-dropdown-menu .footer_algolia img {
    display: inline;
    height: 1.5em;
    vertical-align: bottom;
    max-width: 130px;
}

/** Search Box */

#algolia-searchbox {
    position: relative;
}

#algolia-searchbox .clear-cross, #algolia_instant_selector .clear-cross {
    position: absolute;
    display: none;
    background: url("data:image/svg+xml;utf8,<svg width=\'12\' height=\'12\' viewBox=\'0 0 12 12\' xmlns=\'http://www.w3.org/2000/svg\' opacity=\'0.6\'><path d=\'M.566 1.698L0 1.13 1.132 0l.565.566L6 4.868 10.302.566 10.868 0 12 1.132l-.566.565L7.132 6l4.302 4.3.566.568L10.868 12l-.565-.566L6 7.132l-4.3 4.302L1.13 12 0 10.868l.566-.565L4.868 6 .566 1.698z\'></path></svg>") no-repeat center center / contain;
    cursor: pointer;
    width: 16px;
    height: 16px;
}

#algolia-searchbox .clear-query-autocomplete {
    bottom: 22px;
    right: 9px;
}

#algolia_instant_selector .cross-wrapper .clear-refinement {
    display: block;
    position: relative;
    top: 5px;
    left: 5px;
}

#algolia-searchbox .magnifying-glass {
    position: absolute;
    bottom: 21px;
    right: 7px;
    width: 20px;
    height: 20px;
    display: block;
    background: url("data:image/svg+xml;utf8,<svg width=\'40\' height=\'40\' viewBox=\'0 0 40 40\'  fill=\'%23A6A6A6\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M15.553 31.107c8.59 0 15.554-6.964 15.554-15.554S24.143 0 15.553 0 0 6.964 0 15.553c0 8.59 6.964 15.554 15.553 15.554zm0-3.888c6.443 0 11.666-5.225 11.666-11.668 0-6.442-5.225-11.665-11.668-11.665-6.442 0-11.665 5.223-11.665 11.665 0 6.443 5.223 11.666 11.665 11.666zm12.21 3.84a2.005 2.005 0 0 1 .002-2.833l.463-.463a2.008 2.008 0 0 1 2.833-.003l8.17 8.168c.78.78.78 2.05-.004 2.833l-.462.463a2.008 2.008 0 0 1-2.834.004l-8.168-8.17z\' fill-rule=\'evenodd\'/></svg>") no-repeat center right / 20px;
}

@media (min-width: 768px) {
    #algolia-searchbox .magnifying-glass {
        bottom: 6px;
    }
}

@media (min-width: 768px) {
    #algolia-searchbox .clear-query-autocomplete {
        bottom: 8px;
    }
}


#algolia-searchbox .algolia-search-input:focus:not([value=""]) {
    background: transparent;
}

#algolia-searchbox .algolia-search-input {
    position: static !important;
}

#algolia-searchbox .algolia-search-input:focus {
    outline: 0;
    box-shadow: none;
    border: solid 1px #54A5CD;
}

/** Instant Search */

#algolia_instant_selector {
    margin: 10px;
}

#algolia_instant_selector a,
#algolia_instant_selector a:hover,
#algolia_instant_selector a:focus {
    outline: 0;
}

#algolia_instant_selector:after {
    content: "";
    clear: both;
    display: block;
}

#algolia_instant_selector .title img {
    width: 200px;
}

#algolia_instant_selector .text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

#algolia_instant_selector #instant-search-bar {
    float: left;
    width: calc(100% - 140px);
    height: 40px;
    font-family: "Raleway", "Helvetica Neue", Verdana, Arial, sans-serif;
    border: solid 2px #54A5CD;
    border-left: none;
    padding-left: 6px;
    padding-top: 2px;
}

#algolia_instant_selector.with-facets #algolia-left-container {
    min-height: 1px;
}

#algolia_instant_selector .hits .infos {
    margin: 0 15px;
    padding: 6px 10px;
    color: #aaa;
    text-align: center;
    background: #F4F4F4;
    font-size: 12px;
    clear: both;
    line-height: 32px;
}

#algolia_instant_selector .hits .infos::after {
    margin: 0 15px;
    padding: 6px 10px;
    color: #aaa;
    text-align: center;
    background: #F4F4F4;
    font-size: 12px;
    clear: both;
}

#algolia_instant_selector .hits .infos strong {
    color: #555;
}

#algolia_instant_selector .hits .sort-by-label {
    margin-right: 8px;
}

#algolia_instant_selector .hits .no-results {
    margin: 40px 25px;
    color: #636363;
    font-size: 16px;
}

#algolia_instant_selector a:hover {
    color: #666666;
}

#algolia_instant_selector .hits .no-results strong {
    color: #555;
}

#algolia_instant_selector .hits .no-results .clear-button {
    cursor: pointer;
}

#algolia_instant_selector .hits .no-results .popular-searches {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 30px;
}

#algolia_instant_selector .ais-hits--item .product-reviews-summary {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.ais-infinite-hits--showmore {
    clear: both;
}

/**
 * Facets
 */

#algolia_instant_selector .infos .algolia-logo {
    font-size: 10px;
}

#algolia_instant_selector .facets {
    padding-top: 50px;
}

@media (max-width: 992px) {
    #algolia_instant_selector .facets {
        padding-top: 10px;
    }
}

#algolia_instant_selector .ais-current-refined-values.facet .ais-current-refined-values--body {
    padding-left: 8px;
}

#algolia_instant_selector .facet {
    margin-bottom: 15px;
    padding-bottom: 6px;
    background-color: white;
    border: solid 1px #efefef;
}

#algolia_instant_selector .facet:not(:first-child) {
    margin-top: 10px;
}

#algolia_instant_selector .ais-header {
    margin-bottom: 7px;
}

#algolia_instant_selector .ais-header.ais-current-refined-values--header {
    margin-bottom: 13px;
}

#algolia_instant_selector .facet .name {
    padding: 4px 8px;
    font-weight: 500;
    background-color: #f4f4f4;
    color: #757575;
    text-align: left;
    text-transform: uppercase;
}

.ais-current-refined-values.facet {
    position: relative;
}

#algolia_instant_selector .as-range-slider--base {
    margin-top: 45px;
}

#algolia_instant_selector .hierarchical .ais-hierarchical-menu--list {
    padding-left: 10px;
}

#algolia_instant_selector .hierarchical .ais-hierarchical-menu--list.ais-hierarchical-menu--list__lvl0 {
    padding-left: 0;
}

#algolia_instant_selector .facet .sub_facet:not(:last-child) {
    border-bottom: 1px dotted #e0e0e0;
}

#algolia_instant_selector .text-center {
    text-align: center;
}

#algolia_instant_selector em {
    background-color: #CFEAFA;
    font-style: normal;
}

#algolia_instant_selector h1 {
    margin: 0;
}

#algolia_instant_selector .result {
    display: block;
    margin: 15px 0;
    padding: 16px 24px;
    border: solid 1px #EDEDED;
    background-color: white;
}

#algolia_instant_selector .price-wrapper {
    height: 25px;
}

#algolia_instant_selector .result:hover {
    border-color: #b9b9b9;
    text-decoration: none;
}

#algolia_instant_selector .result .result-thumbnail {
    text-align: center;
}

#algolia_instant_selector .result .result-thumbnail img {
    display: inline;
    height: 148px;
    width: auto;
}

#algolia_instant_selector .result .result-thumbnail .no-image {
    display: block;
    height: 148px;
    background: #FAFAFA;
}

#algolia_instant_selector .result .ratings .rating-box {
    margin: 0 auto 10px;
    float: inherit;
}

#algolia_instant_selector h3
{
    margin-bottom: 0;
}

#algolia_instant_selector .result .price {
    text-align: center;
    font-size: 1.2em;
    color: #54A5CD;
}

#algolia_instant_selector .result .result-title {
    font-size: 14px;
    text-align: center;
    color: #636363;
    line-height: 1.3;
}

#algolia_instant_selector .ui-widget-header {
    background-color: #bcbcbc;
}

#algolia_instant_selector .result-thumbnail img {
    width: 100%;
}


/** INSTANT SEARCH BAR **/

#algolia_instant_selector #instant-search-bar-container {
    padding-top: 0;
    margin-bottom: 15px;
    width: 100%;
    position: relative;
}

#algolia_instant_selector #instant-search-bar-container:after {
    clear: both;
    content: "";
    display: block;
}

#algolia_instant_selector .as-search-box--input {
    width: 100%;
    height: 40px;
    font-family: "Raleway", "Helvetica Neue", Verdana, Arial, sans-serif;
}

#algolia_instant_selector #instant-search-bar:focus {
    outline: 0;
    box-shadow: none;
}

#algolia_instant_selector #instant-search-box label {
    display: none;
}

#algolia_instant_selector .instant-search-bar-label {
    background-color: #54A5CD;
    height: 40px;
    line-height: 24px;
    padding: 8px 12px;
    color: white;
    float: left;
    width: 140px;
    text-align: right;
}

.ais-search-box--magnifier {
    background: transparent;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    top: 8px;
    left: 7px;
}

.ais-search-box--magnifier svg {
    display: block;
    vertical-align: middle;
    height: 23px;
    width: 23px;
    fill: #FFFFFF;
    stroke: #FFFFFF;
}

.ais-search-box--reset {
    background: none !important;
    box-shadow: none !important;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    margin: 0;
    border: 0;
    padding: 7px 8px !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.ais-search-box--reset svg {
    display: block;
    width: 17px;
    height: 17px;
    fill: #636363;
}

#algolia_instant_selector .before_special {
    color: #aaaaaa;
    text-decoration: line-through;
    font-size: 12px;
}

#algolia_instant_selector .tier_price {
    color: #666666;
    font-size: 12px;
}

#algolia_instant_selector .pull-left {
    float: left;
}

#algolia_instant_selector .pull-right {
    float: right;
}

#algolia_instant_selector #refine-toggle {
    font-family: "Raleway", "Helvetica Neue", Verdana, Arial, sans-serif;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border: solid 1px #EDEDED;
    margin: 10px 0 20px 0;
    font-weight: bold;
    cursor: pointer;
}

#algolia-static-content {
    margin-bottom: 20px;
}

/** GRID */

#algolia_instant_selector, #algolia_instant_selector *,
#search_mini_form, #search_mini_form * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
#algolia_instant_selector:before, #algolia_instant_selector:after,
#algolia_instant_selector *:before, #algolia_instant_selector *:after,
#search_mini_form:before, #search_mini_form:after,
#search_mini_form *:before, #search_mini_form *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#search_mini_form label {
    display: none;
}

#algolia_instant_selector .row {
    margin-left: -15px;
    margin-right: -15px;
}

#algolia_instant_selector .col-md-3, #algolia_instant_selector .col-md-4, #algolia_instant_selector .col-md-9, #algolia_instant_selector .col-md-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 768px) {
    #algolia_instant_selector .col-sm-6 {
        width: 50%;
        float: left;
    }
}

@media (min-width: 992px) {
    #algolia_instant_selector .col-md-3, #algolia_instant_selector .col-md-4, #algolia_instant_selector .col-md-9, #algolia_instant_selector .col-md-12 {
        float: left;
    }

    #algolia_instant_selector .col-md-12 {
        width: 100%;
    }

    #algolia_instant_selector .col-md-9 {
        width: 75%;
    }

    #algolia_instant_selector .col-md-4 {
        width: 33.33333333%;
    }

    #algolia_instant_selector .col-md-3 {
        width: 25%;
    }
}
#algolia_instant_selector .row:before,
#algolia_instant_selector .row:after {
    content: " ";
    display: table;
}

#algolia_instant_selector .row:after {
    clear: both;
}

#algolia_instant_selector .visible-xs, #algolia_instant_selector .visible-sm {
    display: none!important
}

#algolia_instant_selector .hidden-xs, #algolia_instant_selector .hidden-sm {
    display: block !important
}

@media (max-width: 767px) {
    #algolia_instant_selector .visible-xs {
        display: block !important
    }

    #algolia_instant_selector .hidden-xs {
        display: none!important
    }

    .algolia-search-block {
        clear: both;
    }
}

@media (max-width: 992px) {
    #algolia_instant_selector .visible-sm {
        display: block !important
    }

    #algolia_instant_selector .hidden-sm {
        display: none!important
    }
}

.algolia-clearfix {
    clear: both;
}

#algolia-autocomplete-container:after, .autocomplete-wrapper:after {
    clear: both;
    content: '';
}

#algolia-banner {
    margin: 0 15px;
}

#algolia-banner img {
    padding-top: 15px;
}

/** BACKEND RENDERING CSS */

.algolia-filter-list  .filter-actions {
    margin-bottom : 0;
    float: right;
    margin-top: 8px;
}

.algolia-filter-list .filter-current {
    border: solid 1px #efefef;
    margin-bottom: 15px;
}

.algolia-filter-list .filter-current .item {
    display: inline-block;
    background-color: #f4f4f4;
    padding: 5px 7px 5px 35px;
    margin: 5px 0;
    border: solid 1px #DDDDDD;
    border-radius: 2px;
    color: #636363;
}

.algolia-filter-list .filter-current .block-subtitle {
    color: #757575;
    font-weight: 500;
    text-transform: uppercase;
    background-color: #f4f4f4;
}

.algolia-filter-list .filter-current .action.remove {
    background-color: #DDDDDD;
}

.algolia-filter-list .filter-current .action.remove:hover {
    background-color: #DDDDDD;
}

.algolia-filter-list .filter-current .action.remove::before {
    font-size: 18px;
    margin: 8px 5px;
    font-weight: 600;
}

.algolia-filter-list .filter-options-content{
    padding: 5px;
}

.algolia-filter-list .filter-options-title{
    background-color: #f4f4f4;
    color: #757575;
    font-weight: 500;
    padding: 4px 40px 4px 10px;
}

.algolia-filter-list .filter-options-item{
    border: solid 1px #efefef;
    margin-bottom: 15px;
    padding-bottom: 0;
}

.algolia-filter-list .filter-options-title::after {
    top: 5px;
}

.algolia-filter-list .items .item {
    position: relative;
}

.algolia-filter-list .items .item .filter-label,
.algolia-filter-list .items .item .filter-value {
    color: #006bb4;
}

.algolia-filter-list .items .item a label {
    cursor: pointer;
}

.algolia-filter-list .items .item a:hover {
    background-color: white;
}

.algolia-filter-list .items .item a:hover label span:first-child {
    text-decoration: underline;
}

.algolia-filter-list .items .item input {
    top : 0;
}

.algolia-filter-list .items .item a .count {
    position : absolute;
    right: 0;
    font-weight: 500;
}

.algolia-filter-list .items .item a .count::before,
.algolia-filter-list .items .item a .count::after {
    content : '';
}

/* SLIDER */
.algolia-filter-list .algolia-range-slider [data-role=from-label] {
    display: block;
    float: left;
    padding: 5px 0 10px;
    font-size: .8em;
    min-width: 20px;
    font-weight: 400;
    text-align: center;
}

.algolia-filter-list .algolia-range-slider [data-role=to-label] {
    display: block;
    float: right;
    padding: 5px 0 10px;
    font-size: .8em;
    min-width: 20px;
    font-weight: 400;
    text-align: center;
}

.algolia-filter-list .algolia-range-slider .actions-toolbar {
    margin: 17px 8px 5px;
    display: block;
}

.algolia-filter-list .algolia-range-slider .actions-primary {
    float: right;
}

.algolia-filter-list .algolia-range-slider .actions-primary .primary {
    padding: 5px;
}

.algolia-filter-list .algolia-range-slider .ui-slider {
    height: 2px;
    margin: 10px;
    clear: both;
    background-color: #F3F4F7;
    border: 1px solid #DDD;
}

.algolia-filter-list .algolia-range-slider .ui-slider-handle {
    background-color: grey;
    padding: 0;
    margin: -8px 0 0 -10px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background-color: #FFFFFF;
    border: 1px solid #c8c8c8;
}

.algolia-rendering .sorter-action {
    display: none;
}

//
//  Options
//  _____________________________________________

#algolia_instant_selector #algolia-left-container {

    .item {
        margin: 0 !important;

        > .am-collapse-icon {
            top: 12px;
            left: 100%;
            width: 11px;
            height: 11px;

            &::before,
            &::after{
                content: '';
                position: absolute;
                background-color: $c-grey-sidebar;
            }

            &::before {
                top: 5px;
                width: 11px;
                height: 1px;
            }

            &::after {
                top: 0;
                right: auto;
                left: 50%;
                transform: translateX(-50%);
                width: 1px;
                height: 11px;
                margin-top: 0;
                border: none;
            }

            &._active {

                &::after {
                    opacity: 0;
                }

                + input + a {
                    color: $c-red;
                }
            }
        }

        > a {
            display: flex;
            align-items: baseline;
            padding: 15px 35px 15px 0;
            transition: color .4s;

            > .label {
                margin-right: .5rem;
                margin-bottom: 0;
            }

            &:hover {
                color: $c-red;
            }
        }

        > ul.items,
        > ul.items.items-children {
            padding: 0;
            margin: 0;

            &.level-1 {

                > li {

                    > span {
                        &._active {

                            + input + a {
                                color: $c-base;
                            }
                        }
                    }

                    > a {
                        font-family: $f-avenir-black;

                        > .count {
                            color: $c-red;
                        }
                    }

                    > ul.level-2 {
                        padding-top: 1.5rem;
                        padding-bottom: 3rem;
                        padding-left: 1rem;
                        margin-bottom: 3rem;
                        border-bottom: 1px solid  $c-grey-3;

                        > li {

                            > a {

                                &.am_shopby_link_selected {
                                    color: $c-red;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    > ul.items {

        > li.item {

            > ul.items {

                > li.item {

                    > .am-collapse-icon {
                        top: 12px;
                    }

                    a {

                        &.am_shopby_link_selected {
                            color: $c-red;

                            + ul {
                                display: block !important;

                                span.label {
                                    font-size: 1.4rem;
                                }
                            }
                        }

                        &:not(.am_shopby_link_selected) {
                            padding-top: 1rem;
                            padding-bottom: 1rem;
                            font-family: $f-avenir;

                            .label {
                                font-size: 1.2rem;
                            }

                            > .count {
                                color: currentColor;
                            }

                            + ul {
                                font-size: 1.2rem;

                                .label {
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    ul.level-3 {
        padding-left: 1.5rem;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid  $c-grey-3;

        > li {

            > a {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
    }
}
