//@import "../../../../../../../../../app/code/Kaliop/OutdatedBrowser/view/frontend/web/scss/settings";
// ==============================================
// OUTDATED BROWSER - Settings
// ==============================================

// Configuration
$ob-popin-overlay-background:               rgba(0, 0, 0, 0.8);

$ob-popin-width:                            900px;
$ob-popin-main-background-color:            #FFF;
$ob-popin-radius:                           0;

$ob-popin-top-background-color:             #F4F5FC;
$ob-popin-top-padding:                      40px;

$ob-popin-debug-mode:                       false;
// Configuration
$ob-popin-overlay-background:               $overlay;
$ob-popin-width:                            1024px;
$ob-popin-top-background-color:             $c-grey-light;

//@import "../../../../../../../../../app/code/Kaliop/OutdatedBrowser/view/frontend/web/scss/main";
// ==============================================
// OUTDATED BROWSER
// ==============================================

.ob-popin {
    text-align: center;
}

.ob-popin__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $ob-popin-overlay-background;
    z-index: 1000;
}

.ob-popin__inner {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: $ob-popin-width;
    max-height: 100vh;
    background-color: $ob-popin-main-background-color;
    overflow: auto;
    pointer-events: auto;
    border-radius: $ob-popin-radius;
    z-index: 1001;

    @media screen and (min-width: 1024px) {
        width: 100%;
    }
}

//
//  Top
//  _____________________________________________

.ob-popin__top {
    padding: $ob-popin-top-padding;
    background-color: $ob-popin-top-background-color;
}

//
//  Main
//  _____________________________________________

.ob-popin__main {
    padding: 20px;
}

.ob-popin__list {
    li {
        display: inline-block;
        width: 28%;

        @media screen and (min-width: 1024px) {
            width: auto;
        }

        > a {
            display: block;

            &:hover {
                .ob-popin-item__action {
                    opacity: 1;
                    transition: opacity .3s;
                }
            }
        }

        &.chrome {
            .ob-popin-item__visual {
                background-position: 0 center;
            }
        }

        &.firefox {
            .ob-popin-item__visual {
                background-position: -70px center;
            }
        }

        &.safari {
            .ob-popin-item__visual {
                background-position: -140px center;
            }
        }

        &.opera {
            .ob-popin-item__visual {
                background-position: -210px center;
            }
        }

        &.edge {
            .ob-popin-item__visual {
                background-position: -280px center;
            }
        }

        &.ie {
            .ob-popin-item__visual {
                background-position: -350px center;
            }
        }
    }
}

.ob-popin-item__visual {
    margin: 0 auto 10px;
    height: 70px;
    width: 70px;
    background: url("../Kaliop_OutdatedBrowser/images/icon/browsers-sprite-2x.png") no-repeat;
    background-size: auto 100%;
}

.ob-popin-item__content {
    margin-bottom: 10px;
}

.ob-popin-item__action {
    opacity: 0;
    transition: opacity .3s;
}

//
//  Bottom
//  _____________________________________________

.ob-popin__bottom {
    padding: 10px 20px 30px;
}

// Debug mode
@if $ob-popin-debug-mode {
    .ob-popin {
        display: block !important;
    }
}
