.mt-l-HeaderSearch #search_mini_form {
    input {
        height: 40px;
        border: none;
        border-radius: 20px;
        @include fs(14px, 19px);
        padding: 10px 55px 10px 35px;
        background-color: $c-new-grey;
        font-family: $f-avenir;

        @include breakpoint(only-sm)  {
            padding: 10px 55px 10px 20px;
        }

        &::placeholder {
            color: $c-new-grey-2;
        }

        &:focus {
            border-color: $c-new-pink;
        }
    }

    // Button
    .magnifying-glass,
    .clear-cross {
        display: flex;
        align-items: center;
        padding: 0 20px 0 15px;
        width: 50px;
        height: 40px;
        background: {
            image: none;
            color: $c-new-pink;
        }
        border-radius: 0 20px 20px 0;
        color: $c-white;
        top: 0;
        right: 0;
        bottom: 0;

        &::before {
            @extend %icomoon;
            font-size: 1.8rem;
            pointer-events: none;
        }
    }

    .magnifying-glass {
        &::before {
            content: "\e946";
        }
    }
    .clear-cross {
        display: none;
        &::before {
            content: "\e908";
        }
    }

    // Scan
    .syte-camera {
        &::before {
            background-image: url('../images/icon/scan.svg');
            margin: 4px 6px;
            width: 30px;
            height: 20px;
        }
    }
}


/* ======================
   Algolia Upgrade
======================= */
[data-sticky="true"] .preload-block-search {
    @media (max-width: 1355px) {
        top: 10px;
        left: 20px;
        right: 20px;
        bottom: 15px;
    }
}
.preload-block-search {
    display: flex;
    align-items: center;
    width: auto;
    padding: 10px 55px 10px 35px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $c-new-grey;
    border-radius: 20px;
    color: $c-new-grey-2;
    font-family: $f-avenir;

    @include breakpoint(only-sm) {
        padding: 10px 55px 10px 20px;
    }

    &::after {
        display: flex;
        align-items: center;
        content: "\e946";
        @include icomoon;
        position: absolute;
        padding: 0 20px 0 15px;
        width: 50px;
        height: 40px;
        background-color: $c-new-pink;
        border-radius: 0 20px 20px 0;
        color: #ffffff;
        top: 0;
        right: 0;
        bottom: 0;
        font-size: 1.8rem;
        box-sizing: border-box;
    }

    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.algolia-search-input {
    position: relative;
    z-index: 1;
    .aa-Autocomplete {}
    .aa-Form {
        position: relative;
    }

    // Search input
    input {
        height: 40px;
        border: none;
        border-radius: 20px;
        @include fs(14px, 19px);
        padding: 10px 55px 10px 35px;
        background-color: $c-new-grey;
        font-family: $f-avenir;
        width: 100%;

        &[type="search"]::-webkit-search-decoration,
        &[type="search"]::-webkit-search-cancel-button,
        &[type="search"]::-webkit-search-results-button,
        &[type="search"]::-webkit-search-results-decoration {
            -webkit-appearance:none;
        }

        @include breakpoint(only-sm) {
            padding: 10px 55px 10px 20px;
        }

        &::placeholder {
            color: $c-new-grey-2;
        }

        &:focus {
            border: 1px solid $c-new-pink;
            background: $c-white;
        }
    }

    // Search Actions
    .aa-InputWrapperPrefix {
        .aa-Label {
            margin-bottom: 0;
        }
    }
    .aa-InputWrapperSuffix {}

    .aa-SubmitButton,
    .aa-ClearButton {
        position: absolute;
        padding: 0 20px 0 15px;
        width: 50px;
        height: 40px;
        background-color: $c-new-pink;
        border-radius: 0 20px 20px 0;
        color: $c-white;
        top: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;

        &::before {
            @extend %icomoon;
            font-size: 1.8rem;
            pointer-events: none;
        }

        svg {
            display: none;
        }
    }

    .aa-SubmitButton {
        &::before {
            content: "\e946";
        }
    }

    .aa-ClearButton {
        &::before {
            content: "\e908";
        }
    }

    .aa-LoadingIndicator {
        position: absolute;
        left: 8px;
        top: 10px;
    }
}
