// ==============================================
// CS BLOCK CONFECTION : Customer reviews
// ==============================================

.csb-customer-reviews {

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    // Title
    .title-2 {
        margin-bottom: 2rem;
        text-transform: uppercase;

        @include breakpoint(md) {
            margin-bottom: 3rem;
        }

        @include breakpoint(lg) {
            margin-bottom: 5rem;
        }
    }

    .cr__list {

        li {
            padding: 2rem 0;

            @include breakpoint(md) {
                padding: 3rem 0;
            }
            @include breakpoint(lg) {
                padding: 4rem 0;
            }

            &:first-child {
                padding-top: 0;
            }

            + li {
                border-top: 1px solid $border-color;
            }
        }
    }

    // Item
    .cr__item {
        display: flex;
        flex-wrap: wrap;
    }

    // Img
    .cr__picture {
        width: 4.8rem;
        height: 4.8rem;

        img {
            width: 100%;
        }

        @include breakpoint(md) {
            width: 10.8rem;
            height: 10.8rem;
        }
    }

    // Content
    .cr__content {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        margin-left: 5.9821%;
    }

    // Name
    .cr__name {
        flex: 1;
        padding-right: 1rem;
        font-family: $f-museoSlab-700;
        font-size: 1.6rem;
        -webkit-font-smoothing : antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    // Date
    .cr__date {
        font-size: 1.4rem;
        text-align: right;
        color: rgba($c-grey, 0.6);
    }

    // Review
    .cr__review {
        flex: 100%;
        margin-top: 1rem;
        margin-bottom: 0;
        font-size: 1.4rem;
        line-height: 1.4;

        @include breakpoint(md) {
            margin-top: 2rem;
        }

        @include breakpoint(lg) {
            margin-top: 3rem;
        }
    }
}
