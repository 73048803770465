// ==============================================
// PRODUCTS MARKETING
// ==============================================

.marketing-picto-container,
.commercial-operation,
.green-label-container {
    position: absolute;
    z-index: 1;
    top: 1.5rem;

    & + .sewing-machine {
        top: 5rem;
    }
}

.green-label-container {
    right: 1.2rem;

    img {

        + span {
            @extend .visually-hidden;
        }
    }

    span:not(:empty) {
        display: block;
        padding-right: 1rem;
        padding-left: 1rem;
        height: 3rem;
        background-color: $c-black;
        font-size: 1rem;
        line-height: 3rem;
        color: $c-white;
    }
}

.marketing-picto-container,
.commercial-operation {
    left: 0;

    img {
        width: auto;
        height: 3rem;
    }
}

.commercial-operation--view {
    position: static;
    margin-top: 3rem;
    color: $c-grey-2;
}

.marketing-picto-container--view {

    @include breakpoint(md) {
        left: 127px;
    }

    .block-quickview-product & {

        @include breakpoint(md) {
            left: 0;
        }
    }
}

// commercial image
.category-marketing {

    img {
        display: block;
        width: 100%;
    }
}
