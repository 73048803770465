// ==============================================
// CS BLOCKS - COMMON STYLES
// ==============================================

//
// WYSIWYG ELEMENTS
//

.csb-user-guides .item-text,
.csb-user-guides__desc {

    // Titles

    h1, h2, h3, h4, h5, h6 {
        @extend .title-2;
    }

    h1 {
        margin: 0 0 3rem;
        font-size: 2rem;
        color: $c-red;
        text-align: center;

        &.entry-title {
            color: $c-base;
            text-align: left;
        }

        @include breakpoint(md) {
            font-size: 2.4rem;
        }

        @include breakpoint(lg) {
            font-size: 3rem;
        }
    }

    h2 {
        margin: 0 0 3rem;
        line-height: 1;
        text-align: center;
    }

    h3 {
        margin: 0 0 3rem;
        @extend .title-3;
        line-height: 1;
    }

    h4 {
        @extend .title-4;
    }

    h5 {
        font-size: 1.2rem;
    }

    h6 {
        font-size: 1rem;
    }

    // Basic element

    p {
        margin: 0 0 3rem;
        line-height: 1.5;
        text-align: justify;

        a {
            color: $c-pink;
            transition: color .3s;

            &:hover {
                color: $c-base;
            }
        }
    }

    a {
        color: $c-pink;
        transition: color .3s;

        &:hover {
            color: $c-base;
        }
    }

    pre {
        font: revert;
    }

    // List

    ol,
    ul {
        padding-left: 1rem;
        margin: 0 0 2rem;
        line-height: 1.5;

        li {
            font-size: 1.4rem;
        }
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: initial;
    }

    // Spacing between elements

    h1, h2, h3, h4, h5, h6, p, ul {

        & + h1, & + h2 {
            margin-bottom: 3rem;
        }

        & + h3, & + h4, & + h5, & + h6 {
            margin-bottom: 3rem;
        }

        & + p,  & + ul {
            margin-top: 0;
            margin-bottom: 3rem;
        }

        img {
            margin-bottom: 0;
        }

        strong {
            font-family: $f-avenir-black;
        }
    }

    // Table

    table {

        tr {

            td {
                vertical-align: middle;
                padding: 1rem 1.5rem;
                font-size: 1.4rem;
                line-height: 1.5;
                border: 1px solid;
            }
        }
    }

    // Button

    .button,
    .pink-button {
        @extend .btn;
    }
}