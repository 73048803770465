// ==============================================
// MODALS
// ==============================================

// Configuration
$modal-background-overlay:              rgba(0, 0, 0, 0.5);
$modal-radius:                          0;
$modal-width:                           950px;
$modal-padding:                         40px;
$modal-background:                      $c-white;
:root {
    --vh: #100vh;
}

body._has-modal {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.modals-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $modal-background-overlay;
    z-index: 99;
}

.modal-slide,
.modal-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    min-width: 0;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    visibility: hidden;
    opacity: 0;
    overflow-y: auto;
    z-index: 100;

    &._show,
    &._show .modal-inner-wrap,
    &._show .modal {
        visibility: visible;
        opacity: 1;
    }

    .modal-inner-wrap {
        position: absolute;
        left: 50%;
        top: 20px;
        transform: translateX(-50%);
        width: 90%;
        max-width: $modal-width;
        max-height: calc(var(--vh, 1vh) * 100 - 20px);
        padding: $modal-padding / 2;
        background-color: $modal-background;
        opacity: 0;
        visibility: hidden;
        overflow: auto;
        transition: opacity 250ms ease-in 100ms;
        pointer-events: auto;

        @include breakpoint(xs) {
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            max-height: 90vh;
            padding: $modal-padding;
            border-radius: $modal-radius;
        }
    }

    // Header
    .modal-header {

        .modal-title {
            @extend .title-3;
        }
    }

    // Footer
    .modal-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;

        button {
            @extend .btn;
            margin: 0 3rem;

            @include breakpoint(only-sm) {
                width: 100%;

                + button {
                    margin-top: 1.5rem;
                }
            }

            &.secondary,
            &.action-secondary {
                @extend .btn--bordered;
            }
        }
    }

    // Forms
    .form {
        .field {
            text-align: left;
        }
    }

    // Action buttons
    .actions-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    // Close button
    .action-close {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 10px;
        right: 10px;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
        z-index: 2;

        &::after {
            @extend %icomoon;
            content: "\e907";
            font-size: 2.5rem;
        }

        > span {
            @extend .visually-hidden;
        }

        &.disabled,
        &[disabled] {
            cursor: not-allowed;
            pointer-events: none;
            opacity: .5
        }

        &:hover {
            color: $c-red;
        }
    }

    // Modal confirm
    &.confirm {

        .modal-content {
            font-size: 1.6rem;
            text-align: center;
        }
    }
}

fieldset[disabled] {
    .modal-popup,
    .modal-slide {
        .action-close {
            cursor: not-allowed;
            pointer-events: none;
            opacity: .5
        }
    }
}

//
// Popin newsletter
// -------------------------------------------

.soon-ajaxnewsletter__modal {

    .modal-inner-wrap {
        max-width: 1080px;
    }
    .modal-content {
        width: 660px;
        max-width: 100%;
        margin: 0 auto;
    }
    .modal-footer {
        display: none;
    }
}

.newsletter-popin {

    &.fancybox-content {
        width: 100%;
        max-width: 58rem;
    }

    .newsletter__title {
        margin-bottom: 2.5rem;
        text-align: center;
        line-height: 5rem;

        strong {
            font-size: 4.5rem;
            display: block;
            font-family: $f-museoSlab-700;
            color: $c-grey-dark;
        }
        span {
            display: block;
            transform: rotate(-2deg);
            color: $c-red;
            font-family: $f-halohandletter;
            font-size: 4rem;
            letter-spacing: 0;
        }
    }
    .newsletter__intro {
        font-size: 1.6rem;
        margin-bottom: 4rem;
        text-align: center;
    }

    label.label {
        font-size: 1.6rem;
        font-family: $f-avenir-medium;

        sup {
            margin-left: 2px;
            color: $c-new-red;
        }
    }

    input[type='text'],
    input[type='email'] {
        border-radius: 100px;
        background: $c-new-grey;
        padding: 11px 15px;
    }

    .field {
        margin-bottom: 2.5rem;
    }

    .rgpd {
        color: $c-new-grey-2;
        margin-bottom: 4rem;
    }

    .actions {
        text-align: center;

        .action.subscribe {
            border-radius: 100px;
            font-family: $f-avenir;
            font-size: 1.6rem;
            font-weight: 500;
            letter-spacing: 0.5px;
            text-align: center;
            text-transform: uppercase;
            background: $c-new-pink;
            border-color: $c-new-pink;
        }
        //.action.subscribe:hover:not(:disabled)
    }

    .required {
        text-align: center;
        margin-top: 1rem;
        font-size: 1.6rem;
        color: $c-grey-1;

        sup {
            color: $c-new-red;
        }
    }

    .messages {
        margin-top: 2rem;
    }
}

//
// Popin Shipping
// -------------------------------------------

.infos-shipping__modal {

    .modal-inner-wrap {
        padding: 0;
    }

    .modal-footer {
        display: none;
    }

    .modal-content {

        > div > p:first-child {
            padding: 2rem 4rem 0 4rem;
            margin-bottom: 2rem;
            font-family: $f-avenir-black;
        }
    }

    .table_metrage {
        border-collapse: separate;
        border-spacing: .2rem;

        .title {
            vertical-align: middle;
            height: 5rem;
            background: $c-pink;
            font-family: $f-museo-500;
            font-size: 1.6rem;
            color: $c-white;
            text-align: center;
            text-transform: uppercase;

            @include breakpoint(only-xs) {
                font-size: 1.4rem;
            }
        }

        td {
            width: 33.3333%;
            vertical-align: middle;
            padding: 1rem 0;
            border-bottom: 1px solid $border-color;

            strong,
            &:last-child {
                font-family: $f-museo-500 !important;
            }
        }
    }
}

//
// Popin Connexion
// -------------------------------------------

.popup-authentication {

    .block-authentication {

        @include breakpoint(md) {
            display: flex;

            .block {
                width: 50%;
            }
        }

        .block-title {
            margin-bottom: 1rem;
            font-family: $f-museoSlab-700;
            color: $c-red;
            -webkit-font-smoothing : antialiased;
            -moz-osx-font-smoothing: grayscale;

            @include breakpoint(md) {
                max-width: 30rem;
            }
        }

        .block {

            &.block-new-customer {

                @include breakpoint(md) {
                    padding-right: 5rem;
                }
            }

            &.block-customer-login {

                @include breakpoint(only-md) {
                    position: relative;
                    padding-top: 3rem;
                    margin-top: 3rem;

                    &::before {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 3px;
                        background: url('../images/background/sep-v.png') repeat-x top left;
                        transform: rotate(0deg);
                    }
                }

                @include breakpoint(md) {
                    padding-left: 5rem;
                    background: url('../images/background/sep-v.png') repeat-y top left;
                }
            }
        }

        .actions-toolbar {
            margin-top: 2rem;

            .secondary {
                margin-top: 0;
            }
        }
    }
}

//
// Popin fidelity
// -------------------------------------------

.fidelity-details__modal {

    .modal-footer {
        display: none;
    }

    // Inner
    .modal-inner-wrap {
        max-width: 60rem;
    }

    // Header
    .fidelity-details-modal__header {
        text-align: center;

        > h2 {
            margin-bottom: 1.5rem;
        }

        > p {
            margin: 1em 0;
            font-size: 1.5rem;
            letter-spacing: 1px;

            > strong {
                display: block;
                font-family: $f-avenir-black;
                font-size: 1.5rem;
                color: $c-red;
                text-transform: uppercase;

                @include breakpoint(sm) {
                    margin-bottom: 1.8rem;
                }
            }
        }
    }

    // Content
    .fidelity-details-modal__content {

        // Steps
        > .fidelity-steps {

            @include breakpoint(only-sm) {

                > li {

                    + li {
                        border-top: 1px solid;
                    }
                }
            }

            @include breakpoint(sm) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                > li {
                    width: 33.3333%;
                    max-width: 18rem;

                    + li {
                        border-left: 1px solid;
                    }
                }
            }

            // Item
            .fidelity-step {
                padding: 1rem;
                text-align: center;

                @include breakpoint(only-sm) {

                    br {
                        display: none;
                    }
                }

                // Number
                .step-number {
                    font-family: $f-avenir-black;
                    font-size: 3.6rem;
                    line-height: 1.4;
                    color: $c-red;
                }

                // Title
                .step-title {
                    margin-bottom: 1rem;
                    font-family: $f-avenir-black;
                    font-size: 1.2rem;
                    line-height: 1.4;
                    color: $c-red;
                    text-transform: uppercase;

                    @include breakpoint(sm) {
                        margin-bottom: 1.7rem;
                    }
                }

                // Description
                .step-description {
                    font-size: 1.3rem;
                    line-height: 1.4;

                    > strong {
                        display: block;
                        font-family: $f-avenir-black;
                    }
                }
            }
        }
    }
}

//
//  Review
//  _____________________________________________

.modal__form-review {

    .modal-footer {
        display: none;
    }

    > .modal-inner-wrap {
        padding: 0;

        h3.title-1 {
            padding: 3rem 2rem 2rem;
            background-color: $c-grey-light;
            color: $c-base !important;
            text-transform: inherit !important;

            @include breakpoint(md) {
                padding: 5rem 4rem 4rem;
            }
        }
    }

    // Form
    .review-form {
        padding: 3rem 2rem;

        @include breakpoint(md) {
            padding: 3rem 4rem 5rem;
        }

        // Fieldset
        .fieldset.review-fieldset {
            display: flex;
            flex-wrap: wrap;

            > .field {
                width: 100%;

                label {
                    font-family: $f-museoSlab-700;
                    text-transform: uppercase;
                }

                @include breakpoint(sm) {

                    + .field {
                        margin-top: 0;
                    }

                    &.review-field-nickname,
                    &.review-field-summary {
                        width: 49%;
                    }

                    &.review-field-nickname {
                        margin-right: 2%;
                    }

                    &.review-field-text {
                        margin-top: 2rem;
                    }
                }
            }
        }

        // Actions
        .actions-toolbar.review-form-actions {
            flex-wrap: wrap;
            margin-top: 4rem;
        }

        // Stars
        .review-field-ratings-container {

            @include breakpoint(only-sm) {
                width: 100%;
            }

            // Field
            .review-field-rating {
                display: flex;
                flex-wrap: wrap;

                > .label {
                    height: 1.8rem;
                    margin-bottom: 0;
                    margin-right: 2rem;
                    font-family: $f-museoSlab-700;
                    line-height: 1.8rem;
                    text-transform: uppercase;
                }

                .review-control-vote {
                    @extend %rating-vote;
                }
            }
        }

        // Submit
        .primary.actions-primary {

            @include breakpoint(only-sm) {
                width: 100%;
                margin-top: 2rem;

                button {
                    width: 100%;
                }
            }
        }

        .required-field {
            margin-top: 1rem;
            margin-bottom: 0;
            font-size: 1.2rem;
            text-align: right;
        }
    }
}

//
//  Size guide
//  _____________________________________________

.size-guide__modal {

    .modal-footer {
        display: none;
    }

    .size-guide-title {
        margin: 0 0 2rem 0;
        color: $c-red;
        text-transform: uppercase;
    }

    .size-guide-subtitle {
        margin: 0 0 2.5rem 0;
        font-size: 1.4rem;
    }

    .table_metrage {
        margin-bottom: 25px;
        width: 100%;
        color: $c-base;

        th {
            vertical-align: middle;
            height: 5rem;
            background-image: url('../images/background/sep-h.png');
            background-position: bottom left;
            background-repeat: repeat-x;
            font-family: $f-museoSlab-700;
            font-size: 1.6rem;
            color: $c-red;
            text-align: left;

            &.title {
                background: none;
                background-color: $c-pink;
                color: $c-white;
                text-align: center;
                text-transform: uppercase;
            }
        }

        td {
            vertical-align: middle;
            height: 4rem;
            border-bottom: 1px solid $border-color;

            &:first-child {
                font-family: $f-museoSlab-700;
                font-size: 1.2rem;
            }
        }

        &.child_metrage {

            .age_tr {

                > th {
                    background: none;

                    &:first-child {
                        width: 30% !important;
                    }

                    &.age {
                        width: 20%;
                        font-size: 1.2rem;
                        color: $c-base;
                        text-transform: none;
                    }
                }
            }
        }
    }
}

//
//  Confection get a quote
//  _____________________________________________

.get-a-quote-form__modal {

    .modal-footer {
        display: none;
    }

    .modal-inner-wrap {
        max-width: 60rem;
        padding: 2rem;

        @include breakpoint(md) {
            padding: 3rem;
        }

        @include breakpoint(lg) {
            padding: 3rem 5rem;
        }
    }
}

//
//  Add to cart
//  _____________________________________________

.add-to-cart-popup {

    .modal-inner-wrap {
        max-height: none;

        &::before {
            display: block;
            width: 5.2rem;
            height: 5.3rem;
            margin: 0 auto 2.4rem;
            content: '';
            background: url("../images/background/success.svg") no-repeat top center;
            background-size: cover;

            @include breakpoint(md) {
                width: 10.4rem;
                height: 10.6rem;
            }
        }
    }

    // Title
    .up-sell-modal-title {
        margin-bottom: 2rem;
        font-family: $f-museo-700;
        font-size: 1.8rem;
        text-align: center;

        @include breakpoint(md) {
            margin-bottom: 3rem;
            font-size: 2.4rem;
        }
    }

    // CTA
    .up-sell-modal-action {
        margin-bottom: 4rem;
        text-align: center;

        @include breakpoint(md) {
            margin-bottom: 6rem;
        }

        a {
            @extend .btn;
        }
    }

    // Upsell
    .block-title {
        margin-bottom: 2rem !important;
        font-family: $f-museo-700 !important;
        font-size: 1.6rem !important;
        text-align: center !important;
        text-transform: lowercase !important;

        &::first-letter {
            text-transform: uppercase;
        }

        @include breakpoint(md) {
            font-size: 2rem !important;
        }
    }

    // Slideshow
    .products-upsell {
        margin-bottom: 0;

        > .product-items {
            max-width: 86rem;
            margin-right: auto !important;
            margin-left: auto !important;

            &.list {
                // Override desktop catalog product items grid displaying
                display: flex;
                align-items: center;
            }

            > li.product-item {

                @include breakpoint(md) {
                    max-width: 30rem !important;
                    height: auto !important;
                    padding: 0 1rem !important;
                    flex: 1;
                }
            }

            &.slick-slider {

                .slick-slide {
                    width: 50% !important;
                    max-width: 30rem;
                    padding-left: 1rem;
                    padding-right: 1rem;

                    @include breakpoint(md) {
                        width: 33.3333% !important;
                        max-width: 30rem;
                        padding-left: 2rem;
                        padding-right: 2rem;
                    }

                    > div {
                        width: 100%;

                        > li.product-item {
                            float: none;
                            max-width: none;
                            height: auto;
                            width: 100% !important;
                            padding: 0;
                        }
                    }
                }
            }

            // Remove add to cart item
            .product-item-actions {
                display: none;
            }
        }
    }
}

// Product sellers's offers
.modal-popup.modal-product-offers {
    .modal-inner-wrap {
        padding: 0;
    }

    .modal-header {
        background-color: $c-grey-4;
        text-align: center;
        padding: 20px;
        @include breakpoint(md) {
            padding: 32px;
        }

        .modal-title {
            @include breakpoint(md) {
                font-size: 3rem;
            }
        }
    }
    .modal-content {
        padding: 20px;
        @include breakpoint(md) {
            padding: 32px 24px 32px 32px;
        }
    }

    .product-offers {
        display: block;
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100px;
            background: linear-gradient(transparent, $c-white);
            pointer-events: none;
        }
    }

    .table-offers {
        width: 100%;
        padding-bottom: 60px;
        overflow: auto;
        max-height: 50vh;
        padding-right: 15px;
        @include custom-scrollbar(3px, #E8E8E8, $c-black);

        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
        }

        .offer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid $c-grey-3;
            padding: 16px;
            gap: 10px;

            & + .offer {
                margin-top: 16px;
            }
        }
    }

    .offer-state {
        margin-top: 4px;
        margin-bottom: 6px;

        span {
            display: inline-block;
            padding: 6px 10px;
            background-color: $c-red;
            border-radius: 40px;
            color: $c-white;
            font-size: 1.2rem;
            text-transform: uppercase;
        }
    }

    .offer-seller-name {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-bottom: 10px;

        .icon-tag {
            font-size: 1.75rem;
        }

        a {
            color: $c-red;
        }
    }

    .offer-price-qty {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;

        .price-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 2px;
        }
        .price {
            font-size: 1.5rem;
            font-weight: bold;
        }
    }

    .offer-delivery,
    .offer-shipping {
        margin-top: 10px;
        span,
        .price {
            font-weight: normal;
            font-size: 1.4rem;
            color: $c-grey-dark2;
        }
    }

    .field.qty {
        .label {
            display: none;
        }

        > .control {
            display: flex;
            align-items: center;

            .soon-qty-updater {
                width: 3.5rem;
                height: 3.5rem;
                line-height: 3.5rem;
                text-align: center;
                background-color: $c-grey-light;
                cursor: pointer;
            }
        }
    }

    .action.tocart {
        display: flex;
        align-items: center;
        gap: 5px;
        padding-top: 7px;
        padding-bottom: 7px;

        .icon-bag {
            font-size: 2.2rem;
        }
    }
}



// Popin

#cgv-alert-popin {
    h1 {
        @extend .title-2;
        margin: 0 0 2rem 0 !important;
        color: $c-red;
    }
    p {
        margin: 0 0 2.5rem 0;
        font-size: 1.4rem;
    }
    a {
        @extend .btn;
    }
}

.modal-video {
    .modal-inner-wrap {
        padding: 0;
        font-size: 0;
        line-height: 0;
        overflow: visible;
    }
    button.action-close {
        top: -45px;
        right: 0;
        color: white;

        &::after {
            font-size: 3rem;
        }
    }
}

//
//  Popin Recherche Produit / PLU
//  _____________________________________________

#pluredirect-popin {
    &-form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .pluredirect__intro {
            margin: 0;
        }

        .input-btn {
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
            justify-content: space-between;
        }

        .field {
            width: 100%;
        }

        .actions {
            position: absolute;
            right: 20px;
            height: 48px;
            display: flex;

            @include breakpoint(xs) {
                right: 40px;
            }

            .action {
                border-radius: 0 30px 30px 0;
                border: 1px solid $c-new-pink;
                &:hover {
                    border: 1px solid $c-new-pink;
                }
            }
        }

        #pluredirect_sku {
            border-radius: 100px;
            background-color: $c-white;
            color: $c-black;
            height: 47.5px;

            &::placeholder {
                color: $c-new-grey-2;
            }

            &:placeholder-shown {
                text-overflow: ellipsis;
            }

            &:focus {
                border: 1px solid $border-color;
            }
        }

        .message:nth-last-of-type(1) {
            margin-bottom: 0;
        }

        #pluredirect_sku-error {
            margin-top: 1rem;
        }

        .message.error > div {
            display: flex;
            flex-direction: column;
            padding: 28px 15px;

            &::before {
                position: relative;
                top: 0;
                margin-left: 0;
            }

            @include breakpoint(md) {
                flex-direction: row;
                padding: 28px 15px 28px 50px;

                &::before {
                    position: absolute;
                    top: 50%;
                    margin-left: -40px;
                }
            }
        }

        .message-success {
            display: none;
        }
    }
}
