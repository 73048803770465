// ==============================================
// Styleguide
// ==============================================

// Configuration
$styleguide-title-color:            $c-base;
$styleguide-title-border-color:     $c-red;
$styleguide-section-spacement:      60px;

.styleguide {
    @extend .container;

    .styleguide__part {

        + .styleguide__part {
            margin-top: $styleguide-section-spacement;
        }

        .title-3 {
            margin-bottom: 1em;
        }
    }

    .styleguide-part__List {

        li + li {
            margin-top: 1.4rem;
        }
    }

        // Fonts
    .fonts__list {

        span {
            font-size: 2rem;

            &.avenir {
                font-family: $f-avenir;
            }

            &.avenir-medium {
                font-family: $f-avenir-medium;
            }

            &.avenir-heavy {
                font-family: $f-avenir-heavy;
            }

            &.avenir-black {
                font-family: $f-avenir-black;
            }

            &.halohandletter {
                font-family: $f-halohandletter;
                font-size: 4rem;
            }

            &.museo-500 {
                font-family: $f-museo-500;
            }

            &.museo-700 {
                font-family: $f-museo-700;
            }

            &.museo-slab-300 {
                font-family: $f-museoSlab-300;
            }

            &.museo-slab-500 {
                font-family: $f-museoSlab-500;
            }

            &.museo-slab-700 {
                font-family: $f-museoSlab-700;
            }
        }
    }

    // Colors
    .colors__list {
        display: flex;
        flex-wrap: wrap;
    }

    .color__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px;
        text-align: center;
    }

    .color__visual {
        @include circle(72px);
        margin-bottom: 10px;

        &.color__visual--grey {
            background-color: $c-grey;
        }

        &.color__visual--grey-light {
            background-color: $c-grey-light;
        }

        &.color__visual--grey-dark {
            background-color: $c-grey-dark;
        }

        &.color__visual--red {
            background-color: $c-red;
        }

        &.color__visual--pink {
            background-color: $c-pink;
        }

        &.color__visual--orange {
            background-color: $c-orange;
        }

        &.color__visual--green {
            background-color: $c-green;
        }

        &.color__visual--lavender {
            background-color: $c-lavender;
        }
    }

    // Title
    .section-title {
        color: $styleguide-title-color;
        padding-left: 10px;
        margin-bottom: 40px;
        border-left: 5px solid $styleguide-title-border-color;
    }

    // Common list
    .styleguide-list {
        li {
            margin-top: 10px;
        }
    }

    .toggle-menu {
        @extend %collapsible-inline;
    }

    // Icons list
    .icons-list {
        > li {
            display: inline-block;
            margin: 0 30px 20px 0;
            font-size: 3rem;
        }
        [class^="icon-"] {
            @extend %icomoon;
        }
    }

    // btn
    .btns-list {

        .btn--with-picto {

            &::before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin-left: -30px;
                @extend %icomoon;
                content: "\e903";
                font-size: 2rem;
            }
        };
    }
}
