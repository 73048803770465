// ==============================================
// FORMS - Select
// ==============================================

// Exclude .action because of add apparented product button
.select:not(.action),
select {
    // Reset
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand { display: none; } /* hide default down arrow in IE10 */
    &:focus { outline: none; }
    &:focus::-ms-value { background: none; color: $c-base;}
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }

    // Custom
    position: relative;
    display: block;
    width: 100%;
    padding: 12px 30px 12px 15px;
    background-clip: padding-box;
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-color: $input-background;
    background-image: url('../images/icon/arrow_down.svg');
    background-size: auto 12px;
    border: 1px solid $input-border-color;
    border-radius: 0;
    font-family: $f-avenir;
    font-size: 1.4rem;
    line-height: 1;
    color: $c-base;
    text-overflow: '';

    &:hover {
        cursor: pointer;
    }

    @include breakpoint(only-sm) {
        padding: 12px 30px 12px 10px;
    }

    @include only_ie {
        background-position: right 5px center;
        background-size: 20%;

        .form-shipping-address & {
            background-size: 5%;
        }
    }

    &:disabled {
        opacity: 0.4 !important;
    }

    option {
        color: $c-base;
    }

    &.mt-select {
        background-color: $c-grey-5;
        border-color: $c-grey-5;
        border-radius: 40px;
        background-image: url('../images/icon/arrow-down.svg');
        background-size: auto 6px;

        @include breakpoint(md) {
            min-width: 150px;
        }
    }
}
