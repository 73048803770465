// ==============================================
// CHECKOUT - Onepage
// ==============================================

//
//  Configuration
//  _____________________________________________

$opc-sidebar-width: 40%;
$opc-sidebar-gutter: 40px;
$opc-sidebar-padding: 20px;
$opc-sidebar-border: 1px solid $c-grey-3;
$opc-sidebar-bg-color: $c-grey-light;

//
//  Global onepage page
//  _____________________________________________

.checkout-index-index {

    @include breakpoint(only-md) {

        .page-wrapper {
            padding-top: 9rem;
        }

        .page-footer {
            display: none;
        }
    }

    @include breakpoint(lg) {
        padding-top: 19.5rem;
    }

    .page-main {
        max-width: 1440px !important;
    }

    .page-title {
        @extend .visually-hidden;
    }

    .checkout-container {
        @include breakpoint(lg) {
            display: flex;
            flex-wrap: wrap;
        }

        .messages {
            width: 100%;
        }
    }

    // Addresses
    .billing-address-details,
    .shipping-information-content,
    .field.addresses {
        line-height: 1.2;
    }

    .shipping-information-content {
        display:none;
        margin-bottom: 2rem;
    }

    // Header
    .page-header {
        position: fixed;
        top: 0 !important;
        left: 0;
        right: 0;
        width: 100%;
        box-shadow: 0 1px 0 $c-grey-medium;

        @include breakpoint(md) {
            box-shadow: 0 1px 3px rgba(black, 0.14);
        }
    }

    .header-body {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;

        @include breakpoint(only-md) {

            .header-logo {
                margin-left: 3rem;
            }
        }

        @include breakpoint(lg) {
            min-height: 13.5rem;
            padding-top: 2.6rem;
            padding-bottom: 2.6rem;
        }
    }

    // Back btn
    .opc-back-container {
        margin-bottom: 3rem;
        width: 100%;

        a {
            transition: color .4s;

            &:hover {
                color: $c-pink;
            }

            &::before {
                content: "←";
                margin-right: 5px;
                font-size: 1.6rem;
            }
        }
    }

    // &.has-top-header {

    //     .opc-back-container {

    //         @include breakpoint(only-md) {
    //             top: 8rem;
    //         }
    //     }
    // }

    // Sticky footer
    .opc-sticky-footer {
        display: none;

        @include breakpoint(only-md) {
            display: block;
            background-color: $c-grey-light;
            padding: 2rem;
        }
    }
}

//
//  Estimated Wrapper
//  _____________________________________________

.opc-estimated-wrapper {
    @extend .visually-hidden;
}

//
//  Main content
//  _____________________________________________

.opc-wrapper {

    @include breakpoint(lg) {
        display: flex;
        justify-content: flex-end;
        width: calc(100% - #{$opc-sidebar-width});
    }

    @include breakpoint(xl) {
        padding-left: 13rem;
    }

    // OPC Steps Block Wrapper
    .opc {
        @include breakpoint(sm) {
            max-width: $small;
            width: 100%;
        }

        // OPC Steps Block
        & > li {
            margin-bottom: 3rem;

            @include breakpoint(md) {
                margin-bottom: 6rem;
            }
        }
    }

    // Step Title
    .step-title:not(:empty) {
        margin-bottom: 2rem;
        @extend .title-2;

        @include breakpoint(md) {
            margin-bottom: 3rem;
        }

        @include breakpoint(lg) {
            font-size: 2.2rem;
        }

        .icons {
            position: relative;
            display: inline-flex;
            vertical-align: middle;
            margin-right: 2px;
            font-size: 2.9rem;

            .icon-stain {
                color: $c-new-pink;
            }

            .icon-check-2 {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $c-white;
                font-size: 1rem;
            }
        }
    }

    // Login Form
    .form-login {
        border-bottom: 1px solid lighten($c-black, 80%);
        padding-bottom: 30px;
        margin-bottom: 30px;

        .actions-toolbar a.action {
            display: inline-block;
            width: auto;
        }
    }

    // Payment Method
    .checkout-payment-method {

        .payments .legend {
            @extend .visually-hidden;
            & + br {
                display: none;
            }
        }

        .payment-option {
            margin-top: 20px;
            @extend %collapsible-inline;

            .payment-option-title {
                font-weight: bold;
                padding: 14px 40px 14px 0;
            }
        }
    }

    .actions-toolbar button.action {
        width: auto;
        margin-left: auto;
        margin-right: auto;
        text-transform: none;
    }
}

//
//  Sidebar
//  _____________________________________________

.opc-sidebar {
    @include breakpoint(lg) {
        width: $opc-sidebar-width;
        padding-left: $opc-sidebar-gutter;


        .modal-inner-wrap {
            position: sticky;
            top: 100px;
        }
        #opc-sidebar {
            max-width: 325px;
        }
    }

    .action-close {
        display: none;
    }

    &._show {
        .action-close {
            display: block;
        }
    }
}

// Collapsible Sidebar
.opc-sidebar-summary {
    background-color: $opc-sidebar-bg-color;

    // Title
    .opc-sidebar-summary-title {
        position: relative;
        padding: 1.4rem $opc-sidebar-padding;
        text-align: center;

        &::after {
            position: absolute;
            top: 19px;
            right: 23px;
            content: '';
            width: 10px;
            height: 10px;
            border-top: 1px solid black;
            border-right: 1px solid black;
            transform: rotate(-45deg);
        }

        @include breakpoint(md) {
            display: none;
        }
    }

    &.active {

        .opc-sidebar-summary-title {

            &::after {
                top: 13px;
                transform: rotate(135deg);
            }
        }
    }

    // Content
    .opc-sidebar-summary-content {
        padding: $opc-sidebar-padding;
        border-top: 1px solid $border-color;

        @include breakpoint(md) {
            display: block !important; // Prevent Collapsible state
            border-top: 0;
        }
    }
}

// Sidebar content
.opc-block-summary {

    // Sidebar Title
    > .title {
        display: block;
        text-transform: inherit;
        border-bottom: $opc-sidebar-border;
        margin-left: -$opc-sidebar-padding;
        margin-right: -$opc-sidebar-padding;
        padding-left: $opc-sidebar-padding;
        padding-right: $opc-sidebar-padding;
        padding-bottom: 16px;
        @extend .title-2;

        @include breakpoint(lg) {
            font-size: 2.2rem;
        }
    }

    // Items Container
    .items-in-cart {
        display: none; // Hide on redesign

        > .title > strong {

            &::before {
                content: "(";
                margin-right: -3px;
            }

            &::after {
                content: ")";
                margin-left: -3px;
            }
        }
    }

    // Table
    .data.table.table-totals {
        width: 100%;
        margin-top: 2rem;

        .table-caption {
            @extend .visually-hidden;
        }

        tr {

            th,
            td {
                padding: 5px 0;
            }

            th {
                width: 65%;
                text-align: left;
            }

            td {
                width: 35%;
                text-align: right;
            }

            &.totals-tax {
                font-size: 1.1rem;
                display: none;

                > th {
                    padding: 0;

                    > span {

                        &::before {
                            content: "(";
                        }

                        &::after {
                            content: ")";
                        }
                    }
                }
            }

            &.totals.sub {
                th,
                td {
                    border-bottom: $opc-sidebar-border;
                    padding-bottom: 13px;
                }
                td {
                    font-weight: bold;
                }
            }

            &.totals.shipping {
                th,
                td {
                    padding-bottom: 13px;
                    padding-top: 13px;
                }
                .label {
                    font-family: $f-avenir-medium;

                    &::before {
                        display: inline-block;
                        vertical-align: bottom;
                        @extend %icomoon;
                        content: "\e922";
                        font-size: 1.6rem;
                        margin-right: 5px;
                    }
                }
            }

            &.grand.totals {
                font-weight: bold;
                font-size: 1.8rem;

                th,
                td {
                    border-top: $opc-sidebar-border;
                    padding-top: 13px;
                }
            }
        }
    }
}

// DE Sidebar Tax Render
html[lang="de"] {
    .opc-block-summary .data.table.table-totals tr.totals-tax {
        display: none;
    }
}

// Sidebar Shipping Information
.opc-block-shipping-information {
    margin-top: 2.4rem;
    text-align: center;

    @include breakpoint(only-md) {
        display: none;
    }

    .ship-to {
        margin-bottom: 20px;
    }

    .shipping-information-title {
        margin-bottom: .5rem;
        font-family: $f-avenir-black;
        font-size: 1.6rem;
        display: none;

        .action-edit {
            &::before {
                display: none;
            }
        }
    }

    > .shipping-information {

        // Remove reassurance in payment step
        + .opc-block-reassurance {
            display: none;
        }
    }
}

// Sidebar reassurance
.opc-block-reassurance {

    @include breakpoint(only-md) {
        display: none;
    }

    > .reassurance__list {

        > li + li {
            margin-top: 1.6rem;
        }
    }


    .reassurance__label,
    img {
        display: inline-block;
        vertical-align: middle;
    }

    .reassurance__label {
        margin-right: 1.6rem;
        font-weight: bold;
    }

    .reassurance-payment__img {
        max-height: 2.15rem;
    }

    .reassurance-call__img {
        max-height: 2.2rem;
    }
}
