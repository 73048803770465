.mt-m-storeLocator-homePush {
    margin: 0 0 1rem;
    padding: 0 $gutter;

    &-container {
        min-height: 300px;
        background: $c-red;
        width: 100%;
        max-width: 1280px;
        display: flex;
        justify-content: space-between;
        color: white;
        margin: 0 auto;

        &-left {
            min-width: 0;
            flex: 0 1 54%;
            padding: 4rem 6rem;

            &-content {
                width: 480px;
                max-width: 100%;
            }
            h3 {
                margin-bottom: 1rem;
                color: white;

                small {
                    color: white;
                }
            }
            p {
                font-size: 1.6rem;
                line-height: 2rem;
                margin-bottom: 2rem;
            }
        }
        &-right {
            min-width: 0;
            flex: 0 1 46%;
            max-width: 585px;
            font-size: 0;
            line-height: 0;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    @include breakpoint(only-lg) {
        &-container {
            flex-direction: column;
            max-width: 585px;

            &-left {
                padding: 2rem 2rem 3rem;

                h3 {
                    margin-bottom: 1rem;

                    small {
                        font-size: 3.6rem;
                    }
                    strong {
                        font-size: 2.5rem;
                    }
                }
            }
        }
    }
}
