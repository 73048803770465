// ==============================================
// CUSTOMER PUBLIC PAGES
// ==============================================

.customer-account-logoutsuccess,
.customer-account-create,
.customer-account-createpassword,
.customer-account-forgotpassword,
.customer-account-login,
.contact-index-index {
    .page-title {
        @extend .title-1;
    }

    .block-title {
        @extend .title-2;
    }
}

.customer-account-createpassword,
.customer-account-forgotpassword,
.contact-index-index {

    .column.main {
        max-width: 80rem;
    }
}

body.customer-account-create,
body.customer-account-login,
body.customer-account-logoutsuccess,
body.customer-account-createpassword,
body.customer-account-forgotpassword {

    // Page layout
    &.page-layout-1column {

        > .page-wrapper {

            > .page-main {
                max-width: 143rem !important;
            }
        }
    }

    // Page title
    .page-title-wrapper {
        margin-top: 3rem;
        margin-bottom: 2rem;

        @include breakpoint(lg) {
            margin-top: 6rem;
            margin-bottom: 3rem;
        }
    }
}

//
//  Login
//  _____________________________________________

.customer-account-login {

    .login-container {

        @include breakpoint(md) {
            display: flex;
            justify-content: space-between;
        }

        > .block {

            @include breakpoint(md) {
                width: 48%;
            }

            @include breakpoint(only-md) {
                & + .block {
                    margin-top: 20px;
                }
            }

            &.block-new-customer {

                .actions-toolbar {
                    margin-top: 3rem;
                }
            }

            .block-title {
                margin-bottom: 2rem;

                @include breakpoint(md) {
                    margin-bottom: 3rem;
                }
            }
        }
    }
}

//
//  Forget password
//  _____________________________________________

body.customer-account-forgotpassword {

    .column.main {
        max-width: 75rem;
        margin-right: auto;
        margin-left: auto;
    }

    .page-title-wrapper {
        margin-top: 3rem;
        text-align: center;
    }
}

//
//  Forget password
//  _____________________________________________

.customer-account-logoutsuccess {
    text-align: center;
}
