// ==============================================
// Panel : Quickview
// ==============================================

$quickview-width: 34rem;

.block-quickview-product {

    .mt-l-Header,
    .mt-l-Search,
    .mt-m-FlowBox,
    .product-info-detailed,
    .product-review-container {
        display: none;
    }

    // Reset styles
    .page-main {
        padding: 0;

        // Show item hidden by algolia
        .columns {
            display: block;
        }

        // Remove unsused
        > #algolia-autocomplete-container,
        > #contentarea,
        #product-review-container {
            display: none;
            width: 0;
            height: 0;
            transform: scale(0);
            font-size: 0;
        }

        > .columns {
            padding-bottom: 0;

            > .column.main {
                float: none;
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: $quickview-width;
                margin: 8.5rem auto;

                > .product.media {
                    order: 1;
                }

                > .product-info-main {
                    order: 2;
                }
            }
        }
    }

    // Media
    .product.media {
        margin-bottom: 2rem;

        > #gallery-prev-area,
        > #gallery-next-area,
        > .action-skip-wrapper,
        > .product-share {
            display: none;
        }

        > .gallery-placeholder {

            .fotorama__stage__shaft {
                max-width: 100% !important;

                > .fotorama__stage__frame {

                    + .fotorama__stage__frame {
                        display: none;
                    }
                }
            }

            .fotorama__nav-wrap {
                display: none !important;
            }
        }
    }

    // Content
    .product-info-main {
        position: relative;
        float: none;
        text-align: center;
        width: 100%;

        > .product-top-infos {
            display: none;
            width: 0;
            height: 0;
            transform: scale(0);
            font-size: 0;
        }

        // Img
        > .qv-image {
            width: 100%;
            min-height: 30rem;
            margin-bottom: 2rem;

            img {
                min-height: 30rem;
            }

            > .slick-dots {

                @include breakpoint(lg) {
                    margin-top: 0;
                }
            }
        }

        // Hide seller information from product view
        .seller-name {
            display: none;
        }
        .qv-seller-name {
            display: inline-block;

            img {
                display: inline-block;
                vertical-align: bottom;
                margin-left: 2px;
            }
        }

        // Name
        > .page-title-wrapper > .page-title,
        > .qv-product-name {
            @extend .title-3;
            padding-left: 5rem;
            padding-right: 5rem;
            margin-bottom: 2rem;
            text-align: center;
            color: $c-grey-dark;
        }

        // Review
        > .product-reviews-summary {
            display: flex;
            justify-content: center;
            margin-bottom: 3rem;

            &.empty {
                display: none;
            }
        }

        // Go to product page
        > .product-info-price {
            margin-bottom: 2.4rem;
            text-align: center;

            a {
                font-size: 1.4rem;
                color: $c-red;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        // Sizes
        .product-size label {
            display: none;
        }

        // Colors
        .product-colors {

            > .product-colors__list {
                position: relative;
                padding-right: 0;

                > li {
                    width: 4rem;
                    height: 4rem;
                }

                > .show-more-products {
                    position: absolute;
                    top: 7px;
                    right: -30px;
                    left: auto;
                }
            }
        }

        // Form add to
        > .product-add-form {
            position: relative;
            margin-bottom: 1rem;

            // Price
            .price-box {
                padding-top: 0;
                margin-bottom: 2.4rem;

                .old-price,
                .percent-price {
                    margin-right: 1rem;
                }

                .old-price {

                    .price {
                        font-size: 1.9rem;
                    }
                }

                .percent-price {
                    font-size: 1.7rem;
                }

                .price {
                    font-size: 2.4rem;
                }

                .price-after {
                    margin-left: .3rem;
                    font-size: 2rem;
                }
            }

            // Add to cart
            .box-tocart {

                // Field
                .field {

                    .label {
                        font-size: 1.3rem;
                        color: #000;
                        text-transform: uppercase;
                        margin-bottom: 1rem;
                    }

                    &.qty {
                        margin-bottom: 2.4rem;
                    }
                }

                // Size guide
                .product-size-guide {
                    display: none;
                }

                // Stocks
                .stock {
                    top: 3.2rem;
                    font-size: 1.4rem;

                    &.available {

                        &::before {
                            background-color: $c-available;
                        }
                    }
                }

                // Actions
                .actions {
                    margin-left: 0;
                    margin-right: 55px;
                }
            }

            // Double wishlist
            .action.towishlist {
                position: absolute;
                top: auto;
                right: 0;
                bottom: 0;
                width: 5rem;
                height: 5rem;
                padding: 0;
                margin-right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background: none;
                border: none;

                &:hover {
                    border: none;

                    &::before {
                        color: $c-pink;
                    }
                }

                &::before {
                    font-size: 2.4rem;
                    margin-right: 0;
                }

                span {
                    @include visually-hidden;
                }
            }
        }

        > .product-offers-choicebox {
            display: none;
        }

        // Storelocator
        > .product-shipping-method {
            display: block;

            .stock-info + .stock-info {
                border-top: 1px solid $c-new-grey-5;
                border-left: none;
            }
        }

        > .shipping-reassurance {
            @include visually-hidden;
        }
    }

    // Hide push product
    .mt-m-ProductsTabs-content-item {
        display: none;
    }
}
