// ==============================================
// CHECKOUT - Shipping : home
// ==============================================

$shipping-item-border-radius:   4px;
$shipping-address-gutter:       16px;

.checkout-index-index {

    // Add new address
    #shipping-new-address-form {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        max-width: 57rem;

        // hide label
        .label,
        legend {
            @include visually-hidden;
        }

        // Update order
        div[name="shippingAddress.prefix"] {
            order: 1;
            margin-top: 1rem;

            .admin__field-control {
                display: flex;
                flex-wrap: wrap;

                .admin__field.admin__field-option {
                    margin-right: 2rem;

                    label {
                        margin-bottom: .5rem;
                        padding-left: calc(#{$radio-width-medium} + 10px);
                        min-height: calc(#{$radio-width-medium} + 2px);
                        line-height: calc(#{$radio-width-medium} + 2px);

                        // Fake radio
                        &::before {
                            width: $radio-width-medium;
                            height: $radio-height-medium;
                        }

                        // Round
                        &::after {
                            width: calc(#{$radio-width-medium} - 8px);
                            height: calc(#{$radio-height-medium} - 8px);
                            left: calc((#{$radio-width-medium} + #{$radio-border-weight} * 2) / 2);
                            top: calc((#{$radio-height-medium} + #{$radio-border-weight} * 2) / 2);
                            background-color: $c-red;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    @include breakpoint(md) {
                        margin-right: 4rem;
                    }
                }
            }
        }

        div[name="shippingAddress.firstname"] {
            order: 2;
        }
        div[name="shippingAddress.lastname"] {
            order: 3;
        }

        .field.street {
            order: 4;
        }

        div[name="shippingAddress.street.0"] {

            .field-note {
                margin-top: 5px;
                font-size: 1.2rem;
                color: $c-error;
            }
        }

        div[name="shippingAddress.postcode"] {
            order: 5;

            input[type="number"] {
                height: inherit;
                padding: 15px 20px;
            }
        }

        div[name="shippingAddress.city"] {
            order: 6;
        }

        div[name="shippingAddress.region_id"] {
            order: 7;
        }

        div[name="shippingAddress.country_id"] {
            order: 8;
        }

        div[name="shippingAddress.telephone"] {
            order: 9;
        }

        @include breakpoint(sm) {
            flex-direction: row;
            justify-content: space-between;

            > div,
            > .field.street {
                width: 100%;
            }

            > div[name="shippingAddress.city"],
            > div[name="shippingAddress.postcode"],
            > div[name="shippingAddress.country_id"],
            > div[name="shippingAddress.region_id"] {
                width: 48%;
            }
        }

        // Phone
        div[name="shippingAddress.telephone"] {

            input {
                width: 100%;
            }

            // Remove tooltip
            .field-tooltip.toggle {
                display: none;
            }
        }
    }

    // Select already address
    .shipping-address-items {
        display: flex;
        flex-wrap: wrap;
        gap: $shipping-address-gutter;

        // Item
        .shipping-address-item {
            position: relative;
            width: 100%;
            padding: 2.4rem;
            padding-right: 4rem;
            border: 1.5px solid transparent;
            border-radius: $shipping-item-border-radius;
            cursor: pointer;


            @include breakpoint(sm) {
                width: calc( ( 100% - ( #{$shipping-address-gutter} ) ) / 2 )
            }

            &:hover {
                border-color: $c-grey;
            }

            &.selected-item {
                border-color: $c-grey-dark2;

                .shipping-address-item-selector {
                    background-color: $c-grey-dark2;
                    color: $c-white;
                    border: 1px solid $c-grey-dark2;

                    &::before {
                        @extend %icomoon;
                        content:"\e971";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: block;
                        font-size: 8px;
                    }
                }
            }
        }

        // Select
        .shipping-address-item-selector {
            position: absolute;
            top: 1.6rem;
            right: 1.6rem;
            display: block;
            @include circle(2rem);
            border: 1px solid $c-grey-3;
        }
         // Content
        .shipping-address-item-content {
            padding-right: 4.5rem;

            @include breakpoint(lg) {
                font-size: 1.6rem;
                line-height: 1.75;
            }
        }

        // btn
        .action-select-shipping-item,
        .edit-address-link {
            font-size: 0;

            &::before {
                content: '';
                display: block;
                position: absolute;
                z-index: -1;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 1px solid $c-grey-3;
                border-radius: $shipping-item-border-radius;
            }
        }
    }
}
