// ==============================================
// CS BLOCK CONFECTION : How to
// ==============================================

.how-to-block {
    margin-bottom: 4rem;

    @include breakpoint(lg) {
        margin-bottom: 6rem;
    }

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    // Title
    .how-to-title {
        @extend .title-2;
        margin-bottom: 2.5rem;
        color: $c-red;
        text-align: center;
        text-transform: uppercase;
    }

    // List
    .how-to-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 4rem;
        background-image: url("../images/background/sep-h.png");
        background-position: left bottom;
        background-repeat: repeat-x;

        > li {
            flex: 1;
            padding-right: 3rem;
            padding-left: 3rem;
            margin-bottom: 1.5rem;

            @include breakpoint(only-sm) {
                padding-right: 2rem;
                padding-left: 2rem;
            }

            + li {
                background-image: url("../images/background/sep-v.png");
                background-position: left;
                background-repeat: no-repeat;

                @include breakpoint(only-sm) {
                    background-image: url("../images/background/sep-h.png");
                    background-position: center top;
                }
            }

            &:first-child .ht__item {
                padding-top: 0;
            }
        }
    }

    // Item
    .how-to-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1.7rem;

        @include breakpoint(only-sm) {
            flex-direction: row;
        }
    }

    // Img
    .how-to-ico {
        display: block;
        width: 10rem;
        height: 10rem;
        margin-bottom: 1.8rem;

        img {
            display: block;
            width: 100%;
        }

        @include breakpoint(only-sm) {
            width: 6.4rem;
            height: 6.4rem;
            margin-right: 1rem;
            margin-bottom: 0;
        }
    }

    // Content
    .how-to-step {
        width: 100%;
        font-size: 1.6rem;
        text-align: center;

        @include breakpoint(only-sm) {
            width: calc(100% - 6.4rem - 1rem);
        }
    }
}
