// ==============================================
// FORMS - Checkbox
// ==============================================

//
//  Configuration
//  _____________________________________________

$checkbox-width:                30px;
$checkbox-height:               29px;
$checkbox-width-small:          24px;
$checkbox-height-small:         23px;
$checkbox-border-weight:        1px;
$checkbox-border-color:         #999;
$checkbox-border-radius:        2px;


$checkbox-width-custom:         15px;
$checkbox-height-custom:        15px;
$checkbox-border-color-custom:  $c-grey-dark2;
$checkbox-color-custom:         $c-new-pink;

.choice {
    input[type="checkbox"] {
        & + label {
            font-size: 1.4rem;
        }
    }
}

input[type="checkbox"] {
    @extend .visually-hidden;
    & + label {
        position: relative;
        display: inline-block;
        font-size: 1.4rem;
        line-height: $checkbox-height;
        padding-top: 1px;
        padding-left: calc(#{$checkbox-width} + 10px);
        min-height: $checkbox-height;
        cursor: pointer;

        &::before,
        &::after {
            position: absolute;
            content: "";
            cursor: pointer;
            transition: all .2s;
            box-sizing: border-box;
        }

        // Fake checkbox
        &::before {
            left: 0;
            top: 0;
            width: $checkbox-width;
            height: $checkbox-height;
            border: $checkbox-border-weight solid $checkbox-border-color;
            background-color: $c-white;
        }

        // Check icon
        &::after {
            @extend %icomoon;
            content:"\e925";
            left: calc(#{$checkbox-width} / 2);
            top: calc(#{$checkbox-height} / 2);
            opacity: 0;
            font-size: 3rem;
            color: $checkbox-border-color;
            transform: translate(-50%, -50%);
        }
    }

    &:checked {
        & + label {

            // Check icon
            &::after {
                opacity: 1;
                transition: opacity .2s;
            }
        }
    }

    &.checkbox--small {
        & + label {
            line-height: $checkbox-height-small;
            padding-left: calc(#{$checkbox-width-small} + 10px);
            min-height: $checkbox-height-small;

            // Fake checkbox
            &::before {
                width: $checkbox-width-small;
                height: $checkbox-height-small;
                border: $checkbox-border-weight solid $checkbox-border-color;
            }

            // Check icon
            &::after {
                content:"\e925";
                left: calc(#{$checkbox-width-small} / 2);
                top: calc(#{$checkbox-height-small} / 2);
                font-size: 2.4rem;
                color: $checkbox-border-color;
                transform: translate(-50%, -50%);
            }
        }

        &:checked {
            & + label {

                // Check icon
                &::after {
                    opacity: 1;
                    transition: opacity .2s;
                }
            }
        }
    }

    // Custom - graphical redesign
    &.checkbox--custom {
        & + label {
            line-height: $checkbox-height-custom;
            padding-left: calc(#{$checkbox-width-custom} + 10px);
            min-height: $checkbox-height-custom;

            // Fake checkbox
            &::before {
                width: $checkbox-width-custom;
                height: $checkbox-height-custom;
                border: $checkbox-border-weight solid $checkbox-border-color-custom;
                border-radius: $checkbox-border-radius;
            }

            // Check icon
            &::after {
                content:"\e94e";
                left: calc(#{$checkbox-width-custom} / 2);
                top: calc(#{$checkbox-height-custom}  / 2);
                font-size: 1rem;
                color: $checkbox-color-custom;
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }

        &:checked {
            & + label {

                // Check icon
                &::after {
                    opacity: 1;
                    transition: opacity .2s;
                }
            }
        }
    }
}
