// ==============================================
// PRODUCT GALLERY
// ==============================================

@import "../../vendor/gallery";

// Configuration

$gallery-nav-thumb-padding:                         0;
$gallery-nav-thumb-margin:                          10px;
$gallery-nav-thumb-border-width:                    1px;
$gallery-nav-thumb-border-color:                    transparent;
$gallery-nav-thumb-active-border-width:             2px;
$gallery-nav-thumb-active-border-color:             $c-pink;
$gallery-nav-thumb-border-radius:                   0;

$gallery-nav-thumb-array-color:                     $c-black;
$gallery-nav-thumb-array-background-color:          $c-white;

$gallery-nav-thumb-marker-border-color:             $c-black;
$gallery-nav-thumb-marker-background:               transparent;

$gallery-nav-array-width:                           80px;
$gallery-nav-array-color:                           lighten($c-black, 60%);
$gallery-nav-array-background-color:                transparent;

$gallery-product-zoom-color:                        $c-base;

$gallery-play-icon-circle-width-large:              108px;
$gallery-play-icon-circle-width-small:              44px;
$gallery-play-icon-color:                           $c-white;
$gallery-play-icon-background-color:                $c-black;

$gallery-fullpage-icon-width:                       80px;
$gallery-fullpage-icon-height:                      80px;
$gallery-fullpage-icon-hover-background-color:      darken($c-white, 5%);
$gallery-fullpage-icon-color:                       $c-black;

$share-icon-size:                                   2.4rem;

%share-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    @include circle(4.2rem);
    background-color: $c-white;
}

//
//  Base Markup
//  _____________________________________________

.catalog-product-view {

    .action-skip-wrapper {
        display: none;
    }

    // Zoom & Share
    .product.media {
        position: relative;
    }

    // Fotorama override
    .fotorama {

        .fotorama__wrap {

            .fotorama__caption,
            .fotorama__thumb-border {
                display: none;
            }

            // Reset prev/next shadow
            .fotorama__stage.fotorama__shadows--left:before,
            .fotorama__stage.fotorama__shadows--right:after,
            .fotorama__stage.fotorama__shadows--top:before,
            .fotorama__stage.fotorama__shadows--bottom:after,
            .fotorama__nav.fotorama__shadows--left:before,
            .fotorama__nav.fotorama__shadows--right:after,
            .fotorama__nav.fotorama__shadows--top:before,
            .fotorama__nav.fotorama__shadows--bottom:after {
                background-image: none;
                background-position: 0;
                bottom: 0;
            }

            .fotorama__stage {
                .fotorama-grab-cursor,
                .fotorama__grab {
                    cursor: pointer;
                }

                @include breakpoint(md) {
                    .fotorama__stage__frame .fotorama__img {
                        top: 0;
                        transform: translate3d(-50%, 0%, 0);
                    }
                }

                // Gallery nav arrows
                .fotorama__arr--prev,
                .fotorama__arr--next {
                    // Override Fotorama modules important styles
                    transform: none !important;
                    opacity: 1 !important;
                }
                .fotorama__arr--prev .fotorama__arr__arr {
                    &:before {
                        content: "\e96a";
                        @extend %icomoon;
                    }
                }
                .fotorama__arr--next .fotorama__arr__arr {
                    &:before {
                        content: "\e96b";
                        @extend %icomoon;
                    }
                }

                .fotorama__arr {
                    &,
                    .fotorama__arr:hover,
                    .fotorama__arr:active {
                        background-color: transparent;
                    }

                    .fotorama__arr__arr {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 35px;
                        height: 35px;
                        background-color: $c-white;
                        border-radius: 100%;
                        box-shadow: 2px 4px 10px 0 rgba(0,0,0,0.15);

                        &:hover {
                            background-color: $c-pink;

                            &:before {
                                color: $c-white;
                            }
                        }
                    }
                }
            }

            // Reset icon shadow
            .fotorama__fullscreen-icon:focus {
                box-shadow: none;
                &:after {
                    box-shadow: 0 0 0 0 transparent;
                }
            }

            // Thumbs
            .fotorama__thumb {
                background-color: transparent;
            }

            .fotorama__thumb-border {
                border-color: $gallery-nav-thumb-marker-border-color;
                background: $gallery-nav-thumb-marker-background;
            }

            // Thumbs arrows (Default)
            .fotorama__thumb__arr {
                background-color: $gallery-nav-thumb-array-background-color;
                transition: all .5s;

                // Arrows
                &::after {
                    @include absolute-center;
                    z-index: 100;
                    @include icomoon;
                    font-size: 1.2rem;
                    color: $gallery-nav-thumb-array-color;
                }
            }

            .fotorama__arr--disabled {
                opacity: 0;
                transition: all .5s;
            }

            // Thumbs icons navigation
            .fotorama__thumb--icon {
                display: none;
            }

            // Thumbs navigation
            .fotorama__nav-wrap {
                padding-top: 12px;
                padding-bottom: 12px;

                &.fotorama__nav-wrap--horizontal {
                    @include breakpoint(only-md) {
                        .fotorama__nav__frame + .fotorama__nav__frame {
                            margin-left: $gallery-nav-thumb-margin;
                        }
                    }

                    @include breakpoint(md) {
                        margin-top: 12px;
                    }

                    .fotorama__thumb__arr--left::after {
                        content: "\e96a";
                    }
                    .fotorama__thumb__arr--right::after {
                        content: "\e96b";
                    }

                    .fotorama__nav__frame + .fotorama__nav__frame {
                        @include breakpoint(md) {
                            margin-left: 10px;
                        }
                    }
                }

                &.fotorama__nav-wrap--vertical {
                    @include breakpoint(md) {
                        .fotorama__nav__shaft.fotorama__grab {}

                        .fotorama__nav__frame + .fotorama__nav__frame {
                            margin-top: $gallery-nav-thumb-margin;
                        }
                    }

                    .fotorama__thumb__arr--left::after {
                        content: "\e943";
                    }
                    .fotorama__thumb__arr--right::after {
                        content: "\e943";
                        transform:translate(-50%, -50%) scaleY(-1);
                    }
                }
            }

            .fotorama__nav__frame {
                padding: $gallery-nav-thumb-padding !important;
                border: $gallery-nav-thumb-border-width solid $gallery-nav-thumb-border-color;
                border-radius: $gallery-nav-thumb-border-radius;
                overflow: hidden;
                box-sizing: border-box;
                transition: border-color .5s;

                @include breakpoint(only-md) {

                    &.fotorama__nav__frame--dot {
                        width: 6px;
                        height: 6px;

                        .fotorama__dot {
                            top: 0;
                            left: 0;
                            @include circle(4px);
                            background-color: $c-pink-light3;
                            border: 1px solid $c-pink-light3;
                        }

                        &.fotorama__active {

                            .fotorama__dot {
                                background-color: $c-red;
                                border-color: $c-red;
                            }
                        }
                    }
                }

                @include breakpoint(md) {

                    &:hover {
                        cursor: pointer;
                    }

                    &.fotorama__active {
                        border-width: $gallery-nav-thumb-active-border-width;
                        border-color: $gallery-nav-thumb-active-border-color;
                        transition: border-color .5s;
                    }
                }
            }
        }
    }
}

// Picto video
.video-thumb-icon {
    &::before,
    &::after {
        @include absolute-center;
    }

    // Play icon
    &::after {
        @extend %icomoon;
        content: "\e91e";
        color: $gallery-play-icon-color;
        z-index: 2;
        font-size: 5rem;
    }

    // Play circle
    &::before {
        content: "";
        background-color: rgba($gallery-play-icon-background-color, .35);
        z-index: 1;
        @include circle($gallery-play-icon-circle-width-small);
    }
}

// Video box container
.fotorama-video-container {

    // Video box
    .product-video {
        width: 100%;
        height: 75%;
        margin: auto;

        @include breakpoint(lg) {
            height: 100%;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    // Play icon
    &::before,
    &::after {
        display: none;
    }

    .fotorama__img {
        display: none;
        width: 0;
        height: 0;
        opacity: 0;
    }
}


//
//  Fotorama fullscreen
//  _____________________________________________

html.fotorama__fullscreen {

    // Zoom buttons
    .fotorama__fullscreen-icon,
    .fotorama__zoom-in,
    .fotorama__zoom-out {
        display: block !important;
        width: $gallery-fullpage-icon-width;
        height: $gallery-fullpage-icon-height;
        background-image: none;

        @include breakpoint(only-sm) {
            width: calc(#{$gallery-fullpage-icon-width} / 2);
            height: calc(#{$gallery-fullpage-icon-height} / 2);
        }

        &:hover {
            background-color: $gallery-fullpage-icon-hover-background-color !important;
        }

        &:before {
            @include absolute-center;
            font-size: 3.6rem;
            color: $gallery-fullpage-icon-color;

            @include breakpoint(only-sm) {
                font-size: 2.6rem;
            }
        }
    }

    .fotorama__fullscreen-icon {

        &:before {
            content: "\e907";
            @extend %icomoon;
        }
    }

    .fotorama__zoom-in {

        &:before {
            content: '+';
        }
    }

    .fotorama__zoom-out {

        @include breakpoint(only-sm) {
            top: calc(#{$gallery-fullpage-icon-height} / 2);
        }

        &:before {
            content: '−';
        }
    }

    .fotorama__nav--thumbs {
        display: flex;
        justify-content: center;
    }

    .catalog-product-view .fotorama .fotorama__wrap .fotorama__stage {

        @include breakpoint(md) {
            margin-left: 0;
        }
    }
}
