// ==============================================
// Panel : global
// ==============================================

// Panel
.mt-panel {
    position: fixed;
    z-index: 40;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 50rem;
    background-color: $c-white;
    transform: translateX(100%);
    transition: transform .4s ease-out;
    overflow-y: hidden;

    &.is-open {
        transform: translateX(0);
        transition: transform .4s ease-out;
    }

    > .mt-panel-container {
        height: 100%;
        overflow-y: auto;
    }
}

// btn close
.mt-panel__btn-close {
    position: absolute;
    z-index: 100;
    top: 2rem;
    right: 2rem;
    display: block;
    width: 2.6rem;
    height: 2.6rem;
    font-size: 2.6rem;

    @include breakpoint(md) {
        top: 3rem;
        right: 3rem;
        width: 3.2rem;
        height: 3.2rem;
        font-size: 3.2rem;
    }
}

// panel inside
.mt-panel-container {
    height: 100%;

    // Title
    .block-title {
        max-width: none;
        padding-bottom: 0;
        margin-bottom: 0;
        background: none;
    }
}
