// ==============================================
// BUTTONS
// ==============================================

@import "custom";
@import "native";

.mt-c-Btn {
    display: inline-block;
    background-color: $c-pink-light;
    @include transition(ease 0.5s);
    padding: 15px 30px;
    border-radius: 30px;
    font-family: $f-avenir;
    @include fs(15px, 22px);
    letter-spacing: 0.5px;
    font-weight: 500;
    color: $c-new-red;

    &:hover {
        background-color: $c-pink-light-2;
    }

    &[data-color="pink"] {
        border: 2px solid $c-new-pink;
        background-color: $c-new-pink;
        color: $c-white;

        &:hover {
            color: $c-new-pink-2;
            background-color: $c-white;
            border-color: $c-new-pink-2;
        }
    }

    &[data-color="white"] {
        background-color: $c-peach;
        border-color: $c-peach;
        color: $c-new-red;
        &:hover {
            color: $c-new-red;
            background-color: $c-white;
            border-color: $c-white;
        }
    }
}
