//
//  Flowbox
//  _____________________________________________

.catalog-product-view {

    .page-bottom {
        > .content {
            > .flowbox {
                margin-bottom: 2rem;

                @include breakpoint(md) {
                    margin-bottom: 3rem;
                }
            }
        }
    }

    // Nav control
    .bzfy-c-carousel-control {
        background-color: inherit !important;

        // Icon
        .bzfy-icon {
            svg {
                display: none;
            }

            // Arrows
            &::before {
                @extend %icomoon;
                content: "\e930";
                font-size: 2rem;
                color: $c-grey;

                @include breakpoint(md) {
                    font-size: 3.4rem;
                }
            }

            &.bzfy-icon--arrow-left {
                &::before {
                    transform: scaleX(-1);
                }
            }
        }
    }

    // Item
    .bzfy-t-feed-carousel {
        .bzfy-c-carousel-post {
            box-shadow: inherit !important;
        }

        .bzfy-o-body {
            &:empty {
                padding: 0;
            }
        }
    }

    // Modal
    .bzfy-feed-gate-modal {
        @include breakpoint(only-md) {
            margin-bottom: 30px !important;
        }

        .bzfy-gate-info--products,
        .bzfy-gate-product:not(:last-of-type) {
            border-bottom: 1px solid $c-grey-medium !important;
        }

        // CTA
        .bzfy-gate-product-buy-button {
            background-color: $button-color !important;
            border: 2px solid $button-color !important;;
            font-family: $f-museoSlab-500 !important;
            line-height: 28px !important;
            transition: background-color .2s, color .2s;

            &:hover:not(:disabled),
            &:focus:not(:disabled),
            &:active:not(:disabled) {
                cursor: pointer;
                transition: background-color .2s;
                background-color: $c-white !important;
                color: $button-color !important;
            }
        }

        // Powered by
        + .bzfy-gate-powered-by {
            display: none !important;
        }
    }
}
