//
//  Review
//  _____________________________________________

.product-review-container {
    margin-top: 3rem;

    @include breakpoint(md) {
        margin-top: 4rem;
        text-align: center;
    }

    // title
    > .product-review__title {
        @extend %product-page-block-title;
        margin-bottom: 2rem;

        @include breakpoint(xl) {
            margin-bottom: 2rem; // Use same value to reset only xl styles
        }
    }

    // No reviews message
    .no-reviews-message {
        margin-bottom: 1em;
        text-align: center;
    }

    // List
    > .review-list {
        margin-bottom: 3rem;
        text-align: left;

        @include breakpoint(md) {
            display: flex;
            flex-wrap: wrap;
            margin-right: -2rem;
            margin-left: -2rem;
        }

        @include breakpoint(xl) {
            margin-right: -5rem;
            margin-left: -5rem;
        }

        > li {
            width: 33.3333%;
            padding: 2rem;

            @include breakpoint(only-md) {
                width: 100%;
                padding: 0;

                + li {
                    margin-top: 4rem;
                }
            }

            @include breakpoint(xl) {
                padding: 2rem 5rem;
            }
        }

        // Item
        .review {
            position: relative;

            @include breakpoint(only-md) {
                border-bottom: 1px solid #CCC;
                padding-bottom: 20px;
            }

            .review-rating-date {
                display: flex;
                align-items: center;
                margin-bottom: 2rem;
            }

            .review-ratings {
                // Stars
                .rating-summary {
                    @extend %rating-summary;
                }
            }

            .review-date {
                position: relative;
                top: 3px;
                opacity: 0.6;
                margin-left: $gutter;
            }

            .review-title {
                font-family: $f-avenir-medium;
                font-size: 2rem;
                margin-bottom: 2rem;

                @include breakpoint(xl) {
                    padding-right: 10rem;
                }
            }

            .review-description {
                margin-bottom: 2rem;
                line-height: 1.4;

                p {
                    max-width: none;
                }

                .description-btn {
                    @extend .link-1;
                    font-family: $f-base;
                }
            }

            .review-author {
                margin-bottom: 0.5rem;
                font-size: 1.2rem;

                &::before {
                    display: inline-block;
                    content: "- ";
                }

                @include breakpoint(xl) {
                    font-size: 1.4rem;
                }
            }

            // Response admin
            .review-answer {
                padding-left: 2rem;
                margin-top: 2rem;
                border-left: 1px solid $border-color;

                .review-answer-header {
                    margin-bottom: 1rem;
                }

                .review-author {
                    margin-bottom: 0.5rem;
                    font-family: $f-avenir-medium;
                    font-size: 1.6rem;
                }

                .review-date {
                    opacity: 0.6;
                }
            }
        }
    }

    // btns
    > .review-btn {

        @include breakpoint(only-md) {
            display: block;
            max-width: 23rem;
            margin: 0 auto;

            + .review-btn {
                margin-top: 1.5rem;
            }
        }

        @include breakpoint(md) {

            + .review-btn {
                margin-left: 2rem;
            }
        }
    }
}
