// ==============================================
// UI COMPONENTS - Rating
// ==============================================

//
//  Configuration
//  _____________________________________________

$rating-icon-count                : 5;

$rating-icon-content              : '\e92e';
$rating-icon-color                : #F8D5CA;

$rating-icon-content-active       : '\e92f';
$rating-icon-color-active         : #FFB800;

//
//  Mixins
//  _____________________________________________

@mixin rating-icon-default($rating-icon-color) {
    @include icomoon;
    color:  $rating-icon-color;
    font-size: $_icon-font-size;
    height: $_icon-font-size;
    letter-spacing: $_icon-letter-spacing;
    line-height: $_icon-font-size;

    // Reset default styles input radio
    // scss/common/forms/_radio.scss
    width: auto;
    border: none;
    background-color: transparent;
    transition: none;
}

@mixin rating-iteration($rating-icon-content, $rating-icon-count) {
    @for $_index from $rating-icon-count to 0 {
        .rating-#{$_index} {
            z-index: $rating-icon-count - ($_index - 2);
            width: calc((#{$_icon-font-size} + #{$_icon-letter-spacing}) * #{$_index});
            &:before {
                @include rating-icons-content($_index, $rating-icon-content);
                width: calc((#{$_icon-font-size} + #{$_icon-letter-spacing}) * #{$_index});
            }
        }
    }
}

@mixin rating-icons-content($rating-icon-count, $rating-icon-content) {
    $rating-icon-content-result: $rating-icon-content;

    @for $_index from $rating-icon-count to 1 {
        $rating-icon-content-result:  $rating-icon-content-result + $rating-icon-content;
    }

    content: $rating-icon-content-result;
}

//
//  Rating vote
//  _____________________________________________

$_icon-font-size            : 14px;
$_icon-letter-spacing       : 10px;

%rating-vote {
    overflow: hidden;
    width: calc((#{$_icon-font-size} + #{$_icon-letter-spacing}) * #{$rating-icon-count});

    // Inactive stars
    &::before {
        display: block;
        position: absolute;
        z-index: 1;

        @include rating-icon-default($rating-icon-color);
        @include rating-icons-content($rating-icon-count, $rating-icon-content);
    }

    input[type="radio"] {
        @extend .visually-hidden;

        &:focus,
        &:checked {
            + label {
                &::before {
                    opacity: 1;
                }
                // Reset default styles input radio
                &::after {
                    display: none;
                }
            }
        }
    }

    label {
        display: block;
        position: absolute;
        cursor: pointer;
        margin-bottom: 0;

        span {
            @extend .visually-hidden;
        }

        // Active stars
        &::before {
            @include rating-icon-default($rating-icon-color-active);
            opacity: 0;
        }

        // Reset default styles input radio
        // scss/common/forms/_radio.scss
        &::after {
            display: none;
        }

        &:hover {
            &::before {
                opacity: 1;

                // Reset default styles input radio
                // scss/common/forms/_radio.scss
                width: auto;
                border: none;
                background-color: transparent;
                transition: none;
            }

            ~ label {
                &::before {
                    opacity: 0;
                }
            }
        }
    }

    @include rating-iteration($rating-icon-content-active, $rating-icon-count);
}

//
//  Rating summary
//  _____________________________________________

$_icon-font-size            : 13px;
$_icon-letter-spacing       : 4px;

%rating-summary {
    overflow: hidden;
    white-space: nowrap;
    margin-right: 6px;

    .label {
        @extend .visually-hidden;
    }

    .rating-result {
        width: ($_icon-font-size * $rating-icon-count) + ceil($_icon-letter-spacing * ($rating-icon-count - 1));
        display: inline-block;
        position: relative;
        vertical-align: middle;

        // Inactive stars
        &::before {
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
            @include rating-icon-default($rating-icon-color);
            @include rating-icons-content($rating-icon-count, $rating-icon-content);
        }

        > span {
            display: block;
            overflow: hidden;

            // Active stars
            &:before {
                position: relative;
                z-index: 2;
                display: block;
                @include rating-icon-default($rating-icon-color-active);
                @include rating-icons-content($rating-icon-count, $rating-icon-content-active);
            }

            span {
                @extend .visually-hidden;
            }
        }
    }
}
