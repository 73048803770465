// ==============================================
// BLOG - View
// ==============================================

.contentmanager-content-view {

    .column.main {
        max-width: 1210px;
        margin-right: auto;
        margin-left: auto;
    }

    // Back
    .tutos-back {
        margin: 2rem 0;

        a {
            @extend .btn;
            @extend .btn--grey;
            padding: 0 1.4rem;
            font-size: 1.4rem;
            line-height: 3.6rem;

            &::before {
                content :'<';
            }
        }
    }

    // Share
    .blog-share {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 3rem 0;

        h3 {
            margin-right: 2rem;
            text-transform: inherit;

            @include breakpoint(only-md) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 1rem;
                font-size: 1.5rem;
                text-align: center;
            }
        }

        .label {
            padding-right: 1rem;

            @include breakpoint(md) {
                font-size: 1.6rem;
            }
        }

        .social-links {

            > li {

                @include breakpoint(only-md) {
                    margin: 0;
                }

                + li {
                    margin-left: 1rem;
                }
            }

            a {
                transition: color .3s;

                &:hover {
                    color: $c-pink;
                }
            }

            [class*="icon-"] {
                font-size: 2rem;

                @include breakpoint(md) {
                    font-size: 2.4rem;
                }
            }
        }
    }

    // Reviews
    .product-review-container {
        margin-top: 0;
        margin-bottom: 3rem;

        @include breakpoint(md) {
            margin-bottom: 5rem;
        }

        > .review-list {
            @include breakpoint(md) {
                display: block;
            }
        }

        > .review-btn {
            @include breakpoint(only-md) {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

//
// Blog view
// _________________________________

.blog-view {
    max-width: 1034px;
    margin: 0 auto 3rem auto;

    p {
        margin: 1.4rem 0;
        line-height: 1.4;
    }

    // header
    .blog-view__header {

        @include breakpoint(md) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
        }

        // Title
        .blog-view__title {
            max-width: 69rem;
            @extend .title-1;
            line-height: 1.2;
            color: $c-pink;

            @include breakpoint(only-md) {
                max-width: inherit;
                margin-bottom: .5rem;
                font-size: 2rem;
            }
        }

        // Infos
        .blog-view__infos {
            display: flex;
            align-items: baseline;
            font-size: 1.2rem;

            @include breakpoint(md) {
                font-size: 1.5rem;
            }

            // Date
            .ct-field-date {

                &::after {
                    margin-right: .5rem;
                    margin-left: 0.2rem;
                    content: '/';
                }
            }

            // Comments
            .product-reviews-summary {

                .action.add {
                    text-decoration: none;
                }
            }

            // Share
            .blog-share {
                margin: 0 0 0 1rem;

                .social-links {

                }
            }
        }
    }

    // Presentation
    .blog-view__presentation {
        margin: 1.4rem 0;
    }

    // Media presentation
    .blog-view__media {

        .ct-image {
            display: block;
            width: 100%;
        }

        .media-iframe-container {
            position: relative;
            display: block;
            width: 100%;
            padding: 0;
            overflow: hidden;

            &::before {
                display: block;
                content: '';
                padding-top: 56.25%;
            }

            iframe {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
            }
        }
    }


    .blog-view__metas {
        padding: 2rem;
        margin-bottom: 3rem;
        background-color: $c-salmon;

        @include breakpoint(md) {
            display: flex;
        }

        .label,
        .ct-file-link {
            font-family: $f-museoSlab-700;
        }

        .label {

            &::after {
                content: ':';
                margin-left: .5rem;
            }
        }

        .ct-file-link {
            transition: color .3s;

            &:hover {
                color: $c-pink;
            }
        }

        .ct-value {
            display: inline-block;
        }

        > [class*="blog-view"] {

            @include breakpoint(md) {
                width: 50%;
                padding-right: 2rem;
            }

            @include breakpoint(lg) {
                width: 33.3333%;
            }
        }

        .blog-view__machine {

            @include breakpoint(only-md) {
                margin-top: 1rem;
            }
        }
    }

    .blog-view__material {
        margin-bottom: 3rem;

        h3 {
            letter-spacing: 0.05em;
        }

        ul {
            margin: 1.4rem 0;
            line-height: 1.4;

            li {
                line-height: 1.4;

                &::before {
                    content: '-';
                    margin-right: .2rem;
                }
            }
        }

        a {
            color: $c-pink;
            text-decoration: underline;
        }
    }

    // Product
    .blog-view__products {
        padding: 30px 20px 0 20px;
        margin: 0 auto 30px auto;

        @include breakpoint(sm) {
            adding: 45px 30px 0 30px;
        }
        @include breakpoint(lg) {
            padding: 45px 40px 0 40px;
        }

        // Slide
        .slick-slide {
            padding: 0 10px;
            margin: 0 0 20px 0;
        }

        // Arrows
        .slick-arrow {
            background-color: transparent;

            &.slick-next {
                right: -30px;
            }

            &.slick-prev {
                left: -30px;
            }
        }

        // Item
        .product-item-info {

            .product-item-photo {

                > .product-image-container {

                    >.product-image-wrapper {

                        > img {
                            width: auto;
                            margin: 0 auto;
                        }
                    }
                }
            }

            .product-name {

                a {
                    text-decoration: none;
                }
            }
        }
    }

    // Content
    .blog-view__content {

        h3 {
            @extend .title-2;
            letter-spacing: 0.05em;
        }

        a {
            text-decoration: underline;
            color: $c-pink;
        }

        img {
            display: block;
            margin: auto;
        }
    }
}
