// ==============================================
// LAYOUT - Content
// ==============================================

//
//  Containers
//  _____________________________________________

.container {
    width: 100%;
    max-width: $container-width;
    padding: 0 $gutter-mob;
    margin: 0 auto;

    @include breakpoint(md) {
        padding: 0 $gutter;
    }
}

.page-main {
    min-height: 30rem;

    .page-layout-1column:not(.cms-home):not(.cms-styleguide):not(.mt_goldeneyes-account-create) &,
    .page-layout-2columns-left & {
        position: relative;
        @extend .container;
    }
}

.cookie-status-message {
    display:none;
}
