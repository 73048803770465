.mt-l-HeaderShortcut {
    display: none;

    &-list {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        scrollbar-width: none;

        &-item {
            a {
                display: block;
                font-family: $f-avenir;
                @include fs(13px, 18px);
                @include transition(ease-in-out 0.5s);
                font-weight: 500;
                padding: 6px 12px;
                color: $c-new-red;
                border: 1px solid rgba($c-red-dark, 0.4);
                border-radius: 15px;
                white-space: nowrap;

                &:hover {
                    background-color: $c-new-red;
                    color: $c-white;
                }
            }

            & + & {
                margin-left: 10px;
            }

            &:first-child {
                margin-left: 15px;
            }

            &:last-child {
                margin-right: 15px;
            }
        }
    }

    @include breakpoint(only-xl) {
        display: block;
    }
}

[data-sticky="true"] {
    .mt-l-HeaderShortcut {
        position: absolute;
        top: 0;
    }
}
