@mixin transition($transition...) {
    -webkit-transition: $transition;
    transition: $transition;
}
@mixin transition-delay($transition-delay) {
    -webkit-transition-delay: $transition-delay;
    transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration) {
    -webkit-transition-duration: $transition-duration;
    transition-duration: $transition-duration;
}
@mixin transition-transform($transition...) {
    -webkit-transition: -webkit-transform $transition;
    -moz-transition: -moz-transform $transition;
    -o-transition: -o-transform $transition;
    transition: transform $transition;
}
