// ==============================================
// LOADER
// ==============================================

// Configuration
$loader-bg-color:           $c-white;
$loader-bg-opacity:         0.7;

.loading-mask {
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .loader {
        @extend .visually-hidden;
    }

    // Layout
    &::before {
        content: "";
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $loader-bg-color;
        opacity: $loader-bg-opacity;
        z-index: 999;

        // Fallback for browsers that doesn't support SVG animation
        @include only_ie {
            opacity: 1;
        }
    }

    // Spinner
    &::after {
        content: url('../images/loader.gif');
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
    }
}