.mt-m-compoGenerator {
    margin: {
        top: 60px;
        bottom: 60px;
    }

    &-inner {
        padding: 12px;
        background-repeat: repeat;
        background: var(--season-pattern-1);
    }

    &-container {
        background-color: $c-pink-light;
        padding: 55px 60px 80px;

        &-title {
            font-family: $f-museo-700;
            @include fs(35px, 42px);
            text-align: center;
            margin: 0 auto 80px;
            max-width: 100%;
            width: 680px;
            color: $c-grey-dark;
        }

        &-products {
            display: flex;
            justify-content: space-between;
            position: relative;
            margin: 0 auto;
            width: 1420px;
            max-width: 100%;

            &-item {
                $item: &;
                position: relative;
                display: flex;
                align-items: center;
                max-width: calc(50% - 20px);

                &:nth-child(1) {
                    top: 20px;

                    #{$item} {
                        &-image {
                            order: 2;
                            width: 275px;
                            height: 275px;
                            right: -20px;

                            &-like {
                                top: 20px;
                                right: 55px;
                            }

                            &-mask:before {
                                background-image: url('../images/mask-fabric-left.png');
                            }
                        }
                    }
                }

                &:nth-child(2) {
                    #{$item} {
                        &-image {
                            &-mask:before {
                                background-image: url('../images/mask-fabric-right.png');
                            }
                        }
                    }
                }

                &-image {
                    width: 300px;
                    height: 300px;
                    position: relative;
                    max-width: 100%;

                    &-mask {
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            z-index: 1;
                            left: -1px;
                            top: -1px;
                            width: calc(100% + 2px);
                            height: calc(100% + 2px);
                            background-size: cover;
                        }

                        img {
                            @include center-abs-xy();
                        }
                    }

                    &-like {
                        position: absolute;
                        z-index: 2;
                        right: 50px;
                        top: 50px;
                        width: 40px;
                        height: 40px;
                        display: block;
                        @include fs(20px, 22px);
                        opacity: 0.9;
                        box-shadow: 0 2px 4px 0 rgba($c-black, 0.15);
                        background-color: $c-white;
                        border-radius: 100%;

                        & > a {
                            position: relative;
                            display: block;
                            width: 100%;
                            height: 100%;

                            & > span {
                                @include center-abs-xy();
                                &:before {
                                    @include transition(ease-in 0.25s);
                                }
                            }
                        }

                        &:hover {
                            .icon-favoris:before {
                                color: $c-pink;
                            }
                        }
                    }
                }

                &-details {
                    width: 350px;
                    text-align: center;

                    &-title {
                        font-family: $f-avenir;
                        font-weight: 600;
                        @include fs(20px, 26px);
                        color: $c-grey-dark;
                        margin-bottom: 10px;
                    }

                    &-price {
                        display: block;
                        margin-bottom: 15px;
                        color: $c-black;
                        @include fs(18px, 25px);

                        & > strong {
                            font-weight: 700;
                        }
                    }

                    &-features {
                        margin-bottom: 20px;

                        &-item {
                            display: block;
                            @include fs(15px, 22px);

                            strong {
                                font-family: $f-museo-700;
                                color: $c-red;
                            }

                            span {
                                font-family: $f-museo-500;
                            }

                            & + & {
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }

            &-refresh {
                @include center-abs-x();
                top: -55px;
                z-index: 2;
                cursor: pointer;

                &-txt {
                    display: block;
                    font-family: $f-halohandletter;
                    @include fs(40px, 50px);
                    @include rotate(-3deg);
                    color: $c-new-red;
                    margin-bottom: 5px;
                }

                &-icon {
                    display: block;
                    margin: 5px auto 0;
                    width: 50px;
                    height: 50px;
                    position: relative;
                    background: {
                        image: url('../images/icon/patate-red.svg');
                        size: cover;
                        repeat: no-repeat;
                    }
                    @include fs(35px, 40px);
                    color: $c-pink-light;

                    &:before {
                        display: block;
                        @include center-abs-xy();
                    }
                }
            }
        }
    }

    @include breakpoint(only-xxl) {
        &-container {
            &-products {
                &-item {
                    &-details {
                        max-width: calc(50% - 30px);
                    }
                }
            }
        }
    }

    @include breakpoint(only-xl) {
        &-inner {
            padding: 12px 0;
        }
        &-container {
            &-title {
                margin: 0 auto 130px;
            }

            &-products {
                justify-content: center;

                &-item {
                    $item: &;
                    display: block;

                    &-image {
                        margin: 0 auto 30px;
                    }

                    &-details {
                        max-width: 100%;
                    }

                    &:nth-child(1) {
                        top: 25px;

                        #{$item} {
                            &-image {
                                right: initial;
                            }
                        }
                    }
                }

                &-refresh {
                    top: -110px;
                }
            }
        }
    }

    @include breakpoint(only-lg) {
        margin: {
            top: 40px;
            bottom: 50px;
        };
        &-inner {
            padding: 10px 0;
        }

        &-container {
            padding: 40px 15px 50px;

            &-title {
                @include fs(25px, 31px);
                width: 100%;
                max-width: 600px;
                text-align: center;
            }

            &-products {
                justify-content: center;

                &-item {
                    $item: &;
                    max-width: calc(50% - #{15px/2});

                    &-details {
                        padding: 0 10px;

                        &-title {
                            @include fs(16px, 20px);
                            margin-bottom: 5px;
                        }

                        &-price {
                            @include fs(16px, 20px);
                        }

                        &-features {
                            display: none;
                        }

                        &-btn {
                            & > span {
                                &:last-child {
                                    display: none;
                                }
                            }
                        }
                    }

                    &-image {
                        width: 240px;
                        height: initial;
                        margin-bottom: 30px;

                        &-mask {
                            width: 100%;
                            height: 0;
                            padding-top: 100%;
                        }

                        &-like {
                            right: 35px;
                            top: 35px;
                        }
                    }

                    &:nth-child(1) {
                        top: 0;

                        #{$item} {
                            &-image {
                                width: 220px;
                                height: initial;
                                top: 20px;
                                right: 0;
                                margin-bottom: 35px;

                                &-like {
                                    top: 15px;
                                    right: 40px;
                                }
                            }
                        }
                    }
                }

                &-refresh {
                    width: 100%;
                    text-align: center;

                    &-txt {
                        @include fs(36px, 46px);
                    }
                }
            }
        }
    }

    @include breakpoint(only-md) {
        &-container {
            &-products {
                &-item {
                    $item: &;

                    &-image {
                        width: 160px;
                        margin-bottom: 20px;

                        &-like {
                            width: 30px;
                            height: 30px;
                            @include fs(13px);
                            right: 25px;
                            top: 25px;
                        }
                    }

                    &:nth-child(1) {
                        top: 0;

                        #{$item} {
                            &-image {
                                width: 145px;
                                height: initial;
                                top: 20px;
                                right: 0;
                                margin-bottom: 35px;

                                &-like {
                                    top: 12px;
                                    right: 27px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ajax-loading {
    .mt-m-compoGenerator-container-products-refresh-icon {
        &:before {
            animation: rotation 0.75s infinite ease-in;
        }
    }
}

@-webkit-keyframes rotation {
    from {
        @include transform(translate(-50%, -50%) rotate(0deg));
    }
    to {
        @include transform(translate(-50%, -50%) rotate(359deg));
    }
}
