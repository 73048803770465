// ==============================================
// Amasty ShopBy
// ==============================================

$icon-filter: '\e92b';

//
// Placeholders
// ______________________________________________

%amasty-shop-by-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: $c-white;
    font-family: $f-avenir-medium;
    font-size: 1.2rem;
    color: $c-base;
    text-transform: uppercase;
    cursor: pointer;
    transition: color .3s;

    @include breakpoint(only-lg) {
        position: relative;
        width: 100%;
        border-top: 1px solid $border-color;
        text-align: left;
    }

    @include breakpoint(lg) {
        padding: 1.5rem 1.5rem 1.5rem 1rem;
    }

    @include breakpoint(xl) {
        padding: 2.5rem 4rem 2.5rem 3rem;
    }

    &::after {
        @extend %icomoon;
        content: "\e930";
        transform: rotate(90deg);
    }
}

%amasty-shop-by-content {
    position: static;
    margin-top: 0;
    padding: 0 2rem 2rem 2rem;
    background-color: $c-white;
    border: none;

    @include breakpoint(lg) {
        position: absolute;
        z-index: 3;
        left: 0;
        right: 0;
        width: 100%;
        max-height: 80vh;
        overflow-y: visible;
        padding: 3rem 3rem 2rem;
    }

    @include breakpoint(xl) {
        padding: 3rem 6rem 2rem;
    }
}

//
// Top nav
// ________________________________________________

$top-nav-height: 68px;

.catalogsearch-result-index,
.catalog-category-view {

    // loader
    #amasty-shopby-overlay {
        position: fixed !important;
        z-index: 15;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 99vw;
        height: 99vh;

        .loader {
            top: 50% !important;
            left: 50%;
            transform: translate(-50%, -50%) !important;
        }
    }

    // Sticky
    @include breakpoint(lg) {

        .column.main {
            position: relative;
        }

        #amasty-shopby-product-list {
            padding-top: 30px;
        }

        .catalog-topnav.amasty-catalog-topnav {
            position: absolute;
            right: 0;
            left: 0;

            &.is-sticky {
                position: fixed;
                height: $top-nav-height;
                z-index: 8;
                top: 0;
                right: 0;
                left: 0;
                width: 100%;
                background-color: $c-white;
                box-shadow: 0 2px 3px rgba(black, 0.14);

                #layered-filter-block {
                    width: 100%;
                    max-width: $container-width;
                    padding: 0 $gutter;
                    margin: 0 auto;
                }

                > .block.filter {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    max-width: $container-width;
                    margin: 0 auto;

                    > .block-content.filter-content {
                        width: 100%;
                        max-width: 116rem;
                    }
                }

                .filter-options-title,
                .button-toggle-filters {
                    height: $top-nav-height;
                    max-height: $top-nav-height !important;
                }
            }

            // Add spacing between elements due to filter's absolute position
            & + * {
                margin-top: 55px;
            }
        }

        // With top header
        &.has-top-header {

            .catalog-topnav.amasty-catalog-topnav {

                &.amasty-catalog-topnav--to-top {
                    animation: topNavToTopTH .4s;
                }

                &.amasty-catalog-topnav--to-bottom {
                    transform: translateY($header-full-height);
                    animation: topNavToDownTH .4s;
                }

                @keyframes topNavToTopTH {
                    0% {
                        transform: translateY($header-full-height);
                    }

                    100% {
                        transform: translateY(0%);
                    }
                }

                @keyframes topNavToDownTH {
                    0% {
                        transform: translateY(0%);
                    }

                    100% {
                        transform: translateY($header-full-height);
                    }
                }
            }
        }

        // Without top header
        &.has-not-top-header {

            .catalog-topnav.amasty-catalog-topnav {

                &.amasty-catalog-topnav--to-top {
                    animation: topNavToTop .4s;
                }

                &.amasty-catalog-topnav--to-bottom {
                    transform: translateY($header-body-height-l);
                    animation: topNavToDown .4s;
                }

                @keyframes topNavToTop {
                    0% {
                        transform: translateY($header-body-height-l);
                    }

                    100% {
                        transform: translateY(0%);
                    }
                }

                @keyframes topNavToDown {
                    0% {
                        transform: translateY(0%);
                    }

                    100% {
                        transform: translateY($header-body-height-l);
                    }
                }
            }
        }
    }

    .catalog-topnav.amasty-catalog-topnav {
        display: block;
        margin-top: 0;

        @include breakpoint(only-lg) {
            position: relative;
        }

        #layered-filter-block {
            background-color: $c-white;
        }

        > .block.filter {

            @include breakpoint(only-lg) {

                > .open-filters-container {
                    position: absolute;
                    top: -2rem;
                    right: -2rem;
                    transition: top 0.3s ease;

                    &.is-sticky {
                        position: fixed;
                        z-index: 9;
                        top: 0;
                        left: 0;
                        right: 0;
                        display: flex;
                        justify-content: flex-end;
                        background-color: $c-white;
                        box-shadow: 0 2px 3px rgba(black, 0.14);
                        transition: top 0.6s ease;
                    }

                    .block-title.filter-title {
                        display: flex;
                        align-items: center;
                        padding: 2rem;

                        &::before {
                            margin-right: 1rem;
                            @include icomoon;
                            content: $icon-filter;
                            font-size: 2.6rem;
                        }

                        > strong {
                            pointer-events: none;
                        }
                    }
                }

                > .block-content.filter-content {
                    display: none;
                    position: fixed;
                    z-index: 13;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    background-color: $c-white;

                    > .filter-subtitle {
                        display: none;
                    }

                    > .filter-content-header {
                        display: flex;
                        align-items: center;

                        > .filter-close {
                            padding: 2.6rem;
                            font-size: 0;

                            [class*="icon-"] {
                                pointer-events: none;
                                font-size: 2rem;
                            }

                        }

                        > strong {
                            font-family: $f-museoSlab-700;
                            font-size: 2rem;
                        }
                    }

                    .toolbar-sorter.sorter {
                        display: flex;
                        align-items: center;
                        padding: 2rem 1rem 2rem 2rem;
                        border-top: 1px solid $border-color;
                        font-size: 1.2rem;
                        color: $c-base;
                        text-transform: uppercase;

                        &::after {
                            position: absolute;
                            right: 2rem;
                            content: '+';
                            font-size: 1.4rem;
                            pointer-events: none;
                        }

                        .sorter-label {
                            width: 7rem;
                        }

                        .sorter-options {
                            padding: 0;
                            background: none;
                            border: 0;
                            font-size: 1.2rem;
                            color: $c-base;
                            text-transform: uppercase;

                        }
                    }

                }

                &.active {

                    > .block-content.filter-content {
                        display: block;
                    }
                }
            }

            @include breakpoint(lg) {

                > .open-filters-container {
                    @include visually-hidden;
                }
            }

            // Options
            .filter-options {
                position: relative;
                min-height: auto;
                padding: 0;
                margin: 0;
                border: none;

                @include breakpoint(lg) {
                    display: flex;
                    flex-wrap: wrap;
                }

                // Validation
                .am_shopby_apply_filters {

                    &.mobile-filter {

                        @include breakpoint(only-lg) {
                            bottom: 0;
                        }

                        @include breakpoint(lg) {
                            display: none !important;
                        }
                    }

                    &.desktop-filter {

                        @include breakpoint(only-lg) {
                            display: none;
                        }

                        @include breakpoint(lg) {
                            position: static;
                            display: block;
                            margin-top: 1rem;
                            visibility: visible;
                        }
                    }

                    > .am-show-button {

                        &.-vertical,
                        &.-horizontal {
                            padding: 0;
                            background: none;
                            border: none;
                            border-radius: 0;
                            box-shadow: none;

                            &::before,
                            &::after {
                                display: none;
                            }

                            > .am-items {
                                @include visually-hidden;
                            }

                            > button {
                                @extend .btn;
                                width: 100%;
                                margin: 0;
                                border-radius: 0;
                                letter-spacing: .5px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }

                // Item
                .filter-options-item {

                    @include breakpoint(only-lg) {
                        display: block;
                        width: 100%;
                    }

                    // Active state
                    &.active {

                        @include breakpoint(lg) {

                            .filter-options-title {
                                position: relative;
                                z-index: 4;
                            }

                            .filter-options-content {
                                box-shadow: $filters-box-shadow;
                            }
                        }
                    }

                    // Title
                    .filter-options-title {
                        @extend %amasty-shop-by-title;
                    }

                    // Content
                    .filter-options-content {
                        @extend %amasty-shop-by-content;

                        .am-ranges {
                            overflow-y: inherit;
                            overflow-x: inherit;

                            .select-storelocator {
                                margin-bottom: 2rem;

                                > a {
                                    text-decoration: underline;
                                    transition: color .3s;

                                    &:hover {
                                        color: $c-pink;
                                    }
                                }
                            }

                            .items {
                                display: flex;
                                flex-wrap: wrap;
                                min-width: auto;
                                max-width: none;

                                @include breakpoint(md) {
                                    max-height: 500px;
                                    overflow-y: auto;
                                }

                                > .item {
                                    width: 50%;
                                    font-size: 1.2rem;

                                    @include breakpoint(md) {
                                        width: 33.3333%;
                                    }

                                    @include breakpoint(xl) {
                                        width: 25%;
                                    }

                                    > a {
                                        padding-top: 1rem;
                                        padding-bottom: 1rem;

                                        > .label {
                                            margin-right: 0;
                                            font-size: 1.2rem;
                                        }

                                        > .count {
                                            display: none;
                                        }

                                        &:hover {
                                            color: $c-pink;
                                        }

                                        &.am_shopby_link_selected {
                                            font-family: $f-avenir-black;
                                            color: $c-pink;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // More options
                .bloc-more-filters {

                    @include breakpoint(only-lg) {
                        display: block;
                    }

                    // Active state
                    &.active {

                        .button-toggle-filters {
                            position: relative;
                            z-index: 4;
                        }

                        @include breakpoint(lg) {

                            .more-filters-container {
                                box-shadow: $filters-box-shadow;
                            }
                        }
                    }

                    // btn
                    .button-toggle-filters {
                        position: relative;
                        @extend %amasty-shop-by-title;
                        justify-content: flex-start;
                        max-height: 6.2rem;

                        span {
                            margin-right: 1.5rem;
                        }

                        &::before {
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 1rem;
                            @include icomoon;
                            content: $icon-filter;
                            font-size: 2.4rem;
                        }

                        &::after {
                            margin-left: auto;
                        }

                        @include breakpoint(lg) {
                            padding-right: 4rem;
                        }

                        @include breakpoint(xl) {
                            padding-top: 2rem;
                        }
                    }

                    // content
                    .more-filters-container {
                        @extend %amasty-shop-by-content;
                        padding: 0;

                        @include breakpoint(lg) {

                            .top-filters-category {
                                display: flex;

                                .filter-options-item {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            // Stock
            .am-filter-items-stock .am_shopby_link_selected,
            .am-filter-items-rating .am_shopby_link_selected {
                background-color: transparent;
            }
        }
    }
}

.catalog-category-view{

    .columns{
        display:none;
    }
}

.catalogsearch-result-index{

    .columns{
        display:none;
    }
}

//special sticky reg
.mt-l-Header[data-sticky="true"] ~ .page-main .amasty-catalog-topnav>.block.filter>.open-filters-container.is-sticky,
.mt-l-Header[data-sticky="true"] ~ .page-main .amasty-catalog-topnav>.block.filter>.open-filters-container.is-sticky {
    top: 120px; //sticky header height
}

// Current filters
.amshopby-filter-current {

    .catalog-topnav.amasty-catalog-topnav & {
        display: none;
    }

    .toolbar.toolbar-products & {
        display: block;

        &.filter-current {
            max-width: 100%;
            margin-bottom: 0;
            overflow-x: auto;
        }
    }

    > .block-subtitle {
        @include visually-hidden;
    }

    > .amshopby-items {
        display: flex;
        padding: 0;

        > .item.amshopby-item {
            position: relative;
            padding: 7px 10px;
            background-color: $c-grey-light;
            border-radius: 2px;
            color: #5a5a59;

            + .item.amshopby-item {
                margin-left: 1rem;
            }

            > .amshopby-remove {
                position: relative;
                margin-right: .5rem;

                &:hover {
                    &::before,
                    &::after {
                        background-color: $c-pink;
                    }
                }
            }

            > .amshopby-filter-name {
                @include visually-hidden;
            }

            .swatch-option {
                &.image {
                    display: none;
                }
            }

            .am-shopby-swatch-label {
                max-width: fit-content;
                margin-left: 0;

                > .label {
                    margin-bottom: 0;
                }
            }

        }
    }
}
