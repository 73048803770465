// ==============================================
// CS BLOCK CONFECTION : Links
// ==============================================

.buttons-block {
    margin: 3rem 0;

    .container {
        padding-right: 0;
        padding-left: 0;
    }

    .buttons-block__section {
        text-align: center;

        .btn {
            margin: 1rem;

            @include breakpoint(only-md) {
                display: block;
                width: 100%;
                margin: 0;

                + .btn {
                    margin-top: 1rem;
                }
            }
        }
    }
}
