.mt-l-HeaderTop {
    color: $c-white;
    background-color: $c-new-red;
    overflow: hidden;

    &-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &-info {
        width: calc(100% - 330px);
        font-family: $f-avenir-medium;
        @include fs(15px, 20px);
        font-weight: 500;
        letter-spacing: 0;
        text-align: center;

        p {
            margin-bottom: 0; // force reset paragraph margin

            a {
                //display: flex;
                padding: 11px 10px;
                justify-content: center;
                align-content: center;
                width: 100%;
                color: $c-white;
                text-decoration: none;
            }

        }

        a {
            color: $c-white;
            text-decoration: none;
        }

        span {
            text-transform: uppercase;
        }
    }

    &-links {
        display: flex;
        align-items: center;
        align-self: stretch;
        position: relative;
        width: 330px;
        padding: 0 20px;
        background-color: $c-red-dark;
        font-family: $f-avenir-medium;
        font-weight: 300;
        @include fs(12px, 18px);
        color: $c-white;

        &:after {
            content: '';
            display: block;
            width: 1520px;
            height: 100%;
            background-color: $c-red-dark;
            position: absolute;
            left: 100%;
            top: 0;
        }

        li {
            position: relative;
            padding: 8px 0;

            a {
                color: $c-white;
                text-decoration: none;

                &:hover {
                    & > span {
                        text-decoration: underline;
                    }
                }

                & > * {
                    display: inline-block;
                    vertical-align: middle;
                }

                &::before {
                    @include icomoon;
                    font-size: 1.7rem;
                    vertical-align: sub;
                    margin-right: 5px;
                }
            }

            & + li {
                &:before {
                    content: '';
                    margin: 0 12px;
                    background-color: $c-white;
                    display: inline-block;
                    vertical-align: middle;
                    width: 3px;
                    height: 3px;
                    border-radius: 100%;
                    text-decoration: none;
                }
            }

            &.contact-link {
                a {
                    &::before {
                        content: "\e951";
                    }
                }
            }

            &.fidelity-link {
                a {
                    &::before {
                        content: "\e95f";
                    }
                }
            }
        }
    }

    @include breakpoint(only-lg) {
        &-info {
            width: 100%;
            padding: 0 15px;
            @include fs(12px, 18px);
        }
        &-links {
            display: none;
        }
    }

    @include breakpoint(only-sm) {
        padding: 0;
    }
}

