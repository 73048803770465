#cookie-notice {
    display: none;
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 1rem 4rem 1rem 1rem;
    background-color: $c-red;
    text-align: center;

    .cookie-notice-text {
        margin: 0;
        color: $c-white;
        text-align: center;
    }

    a {
        color: $c-white;
        text-decoration: underline;
        transition: text-decoration .4s;

        &:hover {
            text-decoration: none;
        }

        &.close-cookie {
            display: block;
            margin-top: 2px;
        }
    }

    .close-cookie-image {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 3rem;
        height: 3rem;
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background: $c-white;
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }
}


