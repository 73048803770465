// ==============================================
// LINKS
// ==============================================

$link-color:              $c-pink;

.link-1,
.link-2 {
    text-decoration: underline;
    transition: color .2s;

    &:hover {
        color: $link-color;
        transition: color .2s;
    }
}

.link-2 {
    font-family: $f-museo-500;
}
